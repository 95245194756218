import React, { Component } from 'react';
import { Grid, Header, Divider, Table, Label, Button, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionCreators from "./actions";

export class TagList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteShowModal: false,
            currentCompanyId: this.props.match.params.companyId,
            idForRemove: null

        };

        this.props.clearStore();
        this.props.initListData({ companyId: this.state.currentCompanyId });
        this.handleCloseopenDeleteModal = this.handleCloseopenDeleteModal.bind(this);
        // Operations usually carried out in componentWillMount go here
    }


    getDataForTable() {
        return this.props.tag.tagListSummury;

    }
    componentWillReceiveProps(nextProps) {

        if (!!nextProps.tag && nextProps.tag.isShouldReload) {
            this.props.clearStore();
            this.props.initListData({ companyId: this.state.currentCompanyId });

        }
        if (this.props.match.params.companyId != nextProps.match.params.companyId) {

            this.setState({
                currentCompanyId: nextProps.match.params.companyId
            })
            this.props.initListData({ companyId: nextProps.match.params.companyId });
        }
    }

    handleCloseopenDeleteModal(e, button) {
        this.setState({ openDeleteShowModal: false })
        if (button.positive) {
            this.props.deleteTag(
                {
                    id: this.state.idForRemove,
                    companyId: this.state.currentCompanyId
                }
            );
        }
    }
    onRemoveTag(id) {
        console.log(id);
        this.setState({ openDeleteShowModal: true, idForRemove: id })
    }

    getItemById(id) {
        if (!this.props.tag || !id) {
            return {};
        }

        return this.props.tag.tagListSummury.find(x => x.id === id) || {};
    }

    render() {
        return <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='huge'>Список Тегов</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Grid.Row>
                        <Button color="green" as={Link} to={"/Company/" + this.state.currentCompanyId + "/Tag/New"}>Добавить новый статус тег</Button>
                    </Grid.Row>
                    <Table >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Название</Table.HeaderCell>
                                <Table.HeaderCell>Цвет</Table.HeaderCell>
                                {/*<Table.HeaderCell>Порядок сортировки</Table.HeaderCell>*/}
                                <Table.HeaderCell>Действия</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {this.getDataForTable().map((status) => {
                                return <Table.Row key={status.id}>
                                    <Table.Cell>
                                        {status.id}
                                    </Table.Cell>
                                    <Table.Cell>{status.name}</Table.Cell>
                                    <Table.Cell>
                                        <Label circular color={status.color}></Label>
                                    </Table.Cell>
                                    {/*<Table.Cell>
                                    {status.order} 
                                </Table.Cell>*/}
                                    <Table.Cell>
                                        {/*<Button as={Link} to = {"/Company/"+this.state.currentCompanyId + "/Tag/"+status.id } icon='search' content='Перейти' />      */}
                                        <Button as={Link} to={"/Company/" + this.state.currentCompanyId + "/Tag/" + status.id + "/Edit"} icon='edit' content='Редактировать' />
                                        <Button onClick={() => { this.onRemoveTag(status.id) }} icon='remove' color="red" content='Удалить' />
                                    </Table.Cell>
                                </Table.Row>
                            })
                            }
                        </Table.Body>
                        <Table.Footer>

                        </Table.Footer>
                    </Table>
                    <Modal
                        open={this.state.openDeleteShowModal}
                        onClose={this.handleCloseopenDeleteModal}
                    >
                        <Modal.Header>Удалить Тег "{this.getItemById(this.state.idForRemove).name}"</Modal.Header>
                        <Modal.Content >
                            Уверены что хотите удалить Тег?
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseopenDeleteModal}  >Нет</Button>
                            <Button positive onClick={this.handleCloseopenDeleteModal} labelPosition='right' icon='checkmark'
                                content='Да' />
                        </Modal.Actions>
                    </Modal>

                </Grid.Column>
            </Grid.Row>
        </Grid>;

    }
};


function mapStateToProps(state) {

    return {

        tag: state.tagList
    };
}


export const TagListContainer = connect(mapStateToProps, actionCreators)(TagList);
