export const clearStore = "COPANY_GROUP_LIST_STORE";
export const clearItemStore = "COPANY_GROUP_ITEM_CLEAR";

export const listLoadStart = 'COPANY_GROUP_LIST_LOAD_START';
export const listLoadSuccess = 'COPANY_GROUP_LIST_LOAD_SUCCESS';
export const listLoadFail = 'COPANY_GROUP_LIST_LOAD_FAIL';

export const itemSaveStart = 'COPANY_GROUP_SAVE_START';
export const itemSaveSuccess = 'COPANY_GROUP_SAVE_SUCCESS';
export const itemSaveFail = 'COPANY_GROUP_SAVE_FAIL';

export const itemLoadStart = 'COPANY_GROUP_ITEM_LOAD_START';
export const itemLoadSuccess = 'COPANY_GROUP_ITEM_LOAD_SUCCESS';
export const itemLoadFail = 'COPANY_GROUP_ITEM_LOAD_FAIL';

export const itemDeleteStart = 'COPANY_GROUP_ITEM_DELETE_START';
export const itemDeleteSuccess = 'COPANY_GROUP_ITEM_DELETE_SUCCESS';
export const itemDeleteFail = 'COPANY_GROUP_ITEM_DELETE_FAIL';

export const itemApproveStart = 'COPANY_GROUP_ITEM_APPROVE_START';
export const itemApproveSuccess = 'COPANY_GROUP_ITEM_APPROVE_SUCCESS';
export const itemApproveFail = 'COPANY_GROUP_ITEM_APPROVE_FAIL';





