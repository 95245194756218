import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == "ORDER_CLEAR_STORE") {
        state = {
            orderDetail: {},
            orderPrimeCost: {},
            orderEdit: {},
            orderList: [],
            orderListSummury: [],
            orderListMediumSummury: [],
            orderPayment: [],
            orderHistory: [],
            orderCustomerInfo: undefined,
            isCustomerInfoLoading: false,
            invoce: {},
            isInvoceLoading: false,
            isSaved: false,
            isLoaded: false,
            isLoading: false,
            id: null,
            isChangedStatus: false,
            isChangingStatus: false,
        };
    }
    //"ORDER_NEW_SAVE_START","ORDER_NEW_SAVE_SUCCESS","ORDER_NEW_SAVE_FAIL"
    //"ORDER_LIST_LOAD_START","ORDER_LIST_LOAD_SUCCESS","ORDER_LIST_LOAD_FAIL"
    //"ORDER_LIST_SUMMURY_LOAD_START","ORDER_LIST_SUMMURY_LOAD_SUCCESS","ORDER_LIST_SUMMURY_LOAD_FAIL"
    //"ORDER_DETAIL_LOAD_START","ORDER_DETAIL_LOAD_SUCCESS","ORDER_DETAIL_LOAD_FAIL"
    //"ORDER_CHANGE_STATUS_START","ORDER_CHANGE_STATUS_SUCCESS","ORDER_CHANGE_STATUS_FAIL"

    //"ORDER_CUSTOMER_INFO_LOAD_START","ORDER_CUSTOMER_INFO_LOAD_SUCCESS","ORDER_CUSTOMER_INFO_LOAD_FAIL"
    switch (action.type) {
        case "ORDER_NEW_SAVE_START":
            return { ...state, ...{ isLoading: true, error: undefined } };
        case "ORDER_NEW_SAVE_SUCCESS":
            return {
                ...state,
                ...{
                    isSaved: true,
                    id: action.data.id,
                    internalId: action.data.internalId,
                    isLoading: false,
                    amount: action.data.amount,
                },
            };
        case "ORDER_NEW_SAVE_FAIL":
            return {
                ...state,
                ...{ isLoading: false, isSaved: false, error: action.error },
            };

        case "ORDER_EDIT_SAVE_START":
            return { ...state, ...{ isLoading: true, error: undefined } };
        case "ORDER_EDIT_SAVE_SUCCESS":
            return {
                ...state,
                ...{
                    isSaved: true,
                    id: action.data.id,
                    internalId: action.data.internalId,
                    isLoading: false,
                    amount: action.data.amount,
                },
            };
        case "ORDER_EDIT_SAVE_FAIL":
            return {
                ...state,
                ...{ isLoading: false, isSaved: false, error: action.error },
            };

        case "ORDER_LIST_LOAD_START":
            return {
                ...state,
                ...{ orderList: [], isLoading: true, error: undefined },
            };
        case "ORDER_LIST_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    orderList: Array.from(action.data, (x) => {
                        return { ...x, date: new Date(x.date) };
                    }).sort(compare),
                    isLoading: false,
                },
            };
        case "ORDER_LIST_LOAD_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "ORDER_LIST_SUMMURY_LOAD_START":
            return {
                ...state,
                ...{ orderListSummury: [], isLoading: true, error: undefined },
            };
        case "ORDER_LIST_SUMMURY_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    orderListSummury: Array.from(action.data, (x) => {
                        return { ...x, date: new Date(x.date) };
                    }).sort(compare),
                    isLoading: false,
                },
            };
        case "ORDER_LIST_SUMMURY_LOAD_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "ORDER_LIST_MEDIUM_SUMMURY_LOAD_START":
            return {
                ...state,
                ...{ orderListMediumSummury: [], isLoading: true, error: undefined },
            };
        case "ORDER_LIST_MEDIUM_SUMMURY_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    orderListMediumSummury: Array.from(action.data, (x) => {
                        return { ...x, date: new Date(x.date) };
                    }).sort(compare),
                    isLoading: false,
                },
            };
        case "ORDER_LIST_MEDIUM_SUMMURY_LOAD_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "ORDER_EDIT_LOAD_START":
            return {
                ...state,
                ...{ orderEdit: {}, isLoading: true, error: undefined },
            };
        case "ORDER_EDIT_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    orderEdit: { ...action.data, date: new Date(action.data.date) },
                    isLoading: false,
                },
            };
        case "ORDER_EDIT_LOAD_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "ORDER_DETAIL_LOAD_START":
            return {
                ...state,
                ...{
                    orderDetail: {},
                    isLoading: true,
                    isLoaded: false,
                    error: undefined,
                },
            };
        case "ORDER_DETAIL_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    orderDetail: { ...action.data, date: new Date(action.data.date) },
                    isLoading: false,
                    isLoaded: true,
                },
            };
        case "ORDER_DETAIL_LOAD_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "ITEM_HISTORY_LOAD_START":
            return {
                ...state,
                ...{
                    orderHistory: [],
                },
            };
        case "ITEM_HISTORY_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    orderHistory: action.data,
                },
            };
        case "ITEM_HISTORY_LOAD_FAIL":
            return { ...state, ...{ error: action.error } };

        case "ORDER_CHANGE_STATUS_START":
            return {
                ...state,
                ...{
                    isChangedStatus: false,
                    isChangingStatus: true,
                    isChangedStatusError: undefined,
                },
            };
        case "ORDER_CHANGE_STATUS_SUCCESS":
            return {
                ...state,
                ...{
                    isChangedStatus: true,
                    isChangingStatus: false,
                },
            };
        case "ORDER_CHANGE_STATUS_FAIL":
            return {
                ...state,
                ...{
                    isChangedStatus: false,
                    isChangingStatus: false,
                    isChangedStatusError: action.error,
                },
            };

        case "ORDER_CUSTOMER_INFO_LOAD_START":
            return {
                ...state,
                ...{
                    orderCustomerInfo: undefined,
                    isCustomerInfoLoading: true,
                    isorderCustomerInfo: undefined,
                },
            };
        case "ORDER_CUSTOMER_INFO_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    orderCustomerInfo: action.data,
                    isCustomerInfoLoading: false,
                },
            };
        case "ORDER_CUSTOMER_INFO_LOAD_FAIL":
            return {
                ...state,
                ...{
                    orderCustomerInfo: undefined,
                    isCustomerInfoLoading: false,
                    isorderCustomerInfo: action.error,
                },
            };
        case "ORDER_PAYMENT_LOAD_START":
            return {
                ...state,
                ...{ orderPayment: [], isOrderPaymentIsLoading: true },
            };
        case "ORDER_PAYMENT_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    orderPayment: Array.from(action.data, (x) => {
                        return { ...x, date: new Date(x.date) };
                    }).sort((a, b) => a.order - b.order),
                    isOrderPaymentIsLoading: false,
                },
            };
        case "ORDER_PAYMENT_LOAD_FAIL":
            return {
                ...state,
                ...{
                    isOrderPaymentIsLoading: false,
                    isorderCustomerInfo: action.error,
                },
            };

        case constants.itemPrimeCostLoadStart:
            return {
                ...state,
                ...{
                    orderPrimeCost: {}, //isOrderPaymentIsLoading: true
                },
            };
        case constants.itemPrimeCostLoadSuccess:
            return {
                ...state,
                ...{
                    orderPrimeCost: action.data,
                    //isOrderPaymentIsLoading: false,
                },
            };
        case constants.itemPrimeCostLoadFail:
            return {
                ...state,
                ...{
                    //isOrderPaymentIsLoading: false,
                    //isorderCustomerInfo: action.error,
                },
            };
        case constants.itemSaveInvoiceStart:
            return {
                ...state,
                ...{ invoce: {}, isInvoceLoading: true },
            };
        case constants.itemSaveInvoiceSuccess:
            return {
                ...state,
                ...{
                    invoce: action.data,
                    isInvoceLoading: false,
                },
            };
        case constants.itemSaveInvoiceFail:
            return {
                ...state,
                ...{
                    invoce: actionData,
                    isInvoceLoading: false,
                },
            };
        case constants.itemCheckInvoiceStart:
            return {
                ...state,
                ...{ isLoading: true },
            };
        case constants.itemCheckInvoiceSuccess:
        case constants.itemCheckInvoiceFail:
            return {
                ...state,
                ...{
                    isLoading: false,
                    isChangedStatus: true,
                },
            };

        default:
            return state;
    }
}
function compare(a, b) {
    const bandA = a.date;
    const bandB = b.date;

    let comparison = 0;
    if (bandA > bandB) {
        comparison = 1;
    } else if (bandA < bandB) {
        comparison = -1;
    }
    return -comparison;
}
