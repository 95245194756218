import React, { Component } from 'react';
import { Grid, Header, Button, Divider, Form, Input, Select, Message } from 'semantic-ui-react'
import * as actionCreators from "./actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

export class EditOperatorPhone extends Component {


    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId,
            currentOperatorPhoneId: this.props.match.params.itemId,
            id: 0,
            isLoaded: false,
            id: "",
            number: "",
            color: ""
        }


        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.handleChangeOrder = this.handleChangeOrder.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
        this.handleSave = this.handleSave.bind(this);

        this.props.clearStore();


        this.props.getDetailOperatorPhone(
            {
                id: this.state.currentOperatorPhoneId,
                companyId: this.state.companyId
            }
        );
        // Operations usually carried out in componentWillMount go here
    }
    componentWillReceiveProps(nextProps) {
        if (!this.state.isLoaded && nextProps.operatorPhone.isLoaded) {
            this.setState({
                id: nextProps.operatorPhone.data.id,
                number: nextProps.operatorPhone.data.number,
                color: nextProps.operatorPhone.data.color,
                isLoaded: true
            });
        };
        if (this.props.match.params.itemId !== nextProps.match.params.itemId || this.props.match.params.companyId !== nextProps.match.params.companyId) {

            this.setState({
                companyId: this.props.match.params.companyId,
                currentOperatorPhoneId: this.props.match.params.itemId,
                isLoaded: false
            });

            this.props.getDetailOperatorPhone(
                {
                    id: nextProps.match.params.itemId,
                    companyId: nextProps.match.params.companyId
                }
            );
        }
    }

    handleChangeNumber() {
        //this.setState({number: target.value});
    }

    handleChangeOrder(e, target) {
        this.setState({ order: target.value });
    }

    handleChangeColor(e, target) {
        this.setState({ color: target.value });
    }


    handleSave(e) {
        e.preventDefault();
        this.props.saveOperatorPhone({
            name: this.state.name,
            id: this.state.id,
            companyId: this.state.companyId,
            number: this.state.number,
            color: this.state.color
        });
    }


    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size='huge'>Редактирвоание номера оператора #</Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Form success={this.props.operatorPhone.isSave} error={!!this.props.operatorPhone.error} loading={this.props.operatorPhone.isLoading} >
                            <Form.Field disabled control={Input} label='Id' placeholder='Id номера оператора' value={this.state.id} />
                            <Form.Field control={Input} label='Номер' placeholder='Название статуса' onChange={this.handleChangeNumber}
                                icon='dont' value={this.state.number} />
                            <Form.Field control={Select} label='Цвет ' placeholder='нет цвета' onChange={this.handleChangeColor} value={this.state.color}
                                options={[
                                    { value: 'red', text: 'Красный', label: { empty: true, circular: true, color: "red" } },
                                    { value: 'orange', text: 'Оранжевый', label: { empty: true, circular: true, color: "orange" } },
                                    { value: 'yellow', text: 'Желтый', label: { empty: true, circular: true, color: "yellow" } },
                                    { value: 'olive', text: 'Оливковый', label: { empty: true, circular: true, color: "olive" } },
                                    { value: 'green', text: 'Зеленый', label: { empty: true, circular: true, color: "green" } },
                                    { value: 'teal', text: 'Голубой', label: { empty: true, circular: true, color: "teal" } },
                                    { value: 'blue', text: 'Синий', label: { empty: true, circular: true, color: "blue" } },
                                    { value: 'violet', text: 'Фиолетовый', label: { empty: true, circular: true, color: "violet" } },
                                    { value: 'purple', text: 'Пурпурный', label: { empty: true, circular: true, color: "purple" } },
                                    { value: 'pink', text: 'Розовый', label: { empty: true, circular: true, color: "pink" } },
                                    { value: 'brown', text: 'Коричневый', label: { empty: true, circular: true, color: "brown" } },
                                    { value: 'grey', text: 'Серый', label: { empty: true, circular: true, color: "grey" } },
                                    { value: 'black', text: 'Черный', label: { empty: true, circular: true, color: "black" } },
                                    { value: '', text: 'Не указан', label: { empty: true, circular: true, color: "grey" } }
                                ]}
                            />
                            <Message
                                error
                                header='Ошибка'
                                content={this.props.operatorPhone.error}
                            />
                            <Message success >
                                <Message.Content>
                                    Успешно Сохранено
                                <br />
                                    <br />
                                    <Button as={Link} to={"/Company/" + this.state.companyId + "/operatorPhone"}>
                                        Вернуться к списку
                                </Button>
                                </Message.Content>
                            </Message>
                            <Form.Field color="green" onClick={this.handleSave} control={Button}>Сохранить</Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>

            </Grid>);

    }
};

function mapStateToProps(state) {

    return {

        operatorPhone: state.operatorPhoneEdit


    };
}


export const OperatorPhoneEditContainer = connect(mapStateToProps, actionCreators)(EditOperatorPhone);
