import * as  config from "Config";


export function clearStore() {
    return {
      type:"DISCOUNT_CLEAR_STORE"
    };
}

export function saveNewDiscount(params) {
    return {
      remote:{
          method: "post",
          url: config.apiUrl + "/api/Discount/Company/"+params.companyId,
          data:params
      },
      actions:["DISCOUNT_NEW_SAVE_START","DISCOUNT_NEW_SAVE_SUCCESS","DISCOUNT_NEW_SAVE_FAIL"]
    };
}


export function saveDiscount(params) {
    return {
      remote:{
          method: "put",
          url: config.apiUrl + "/api/Discount/"+params.id + "/Company/"+params.companyId,
          data:params
      },
      actions:["DISCOUNT_SAVE_START","DISCOUNT_SAVE_SUCCESS","DISCOUNT_SAVE_FAIL"]
    };
}

export function getDetailDiscount(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Discount/" + params.id+ "/Company/"+params.companyId
      },
      actions:["DISCOUNT_DETAIL_START","DISCOUNT_DETAIL_SUCCESS","DISCOUNT_DETAIL_FAIL"]
    };
}

