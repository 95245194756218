import * as config from "Config";


export function clearStore(newObject) {
    return {
      type:"COMPANY_LIST_CLEAR_STORE"
    };
}

export function initCompanyListData() {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Company/Summury"
      },
      actions:["COMPANY_LIST_SUMMURY_LOAD_START","COMPANY_LIST_SUMMURY_LOAD_SUCCESS","COMPANY_LIST_SUMMURY_LOAD_FAIL"]
    };
}

export function deleteCompany(id) {
    return {
      remote:{
          method: "delete",
          url: config.apiUrl + "/api/Company/"+id
      },

      actions:["COMPANY_LIST_REMOVE_START","COMPANY_LIST_REMOVE_SUCCESS","COMPANY_LIST_REMOVE_FAIL"]
    };
}

