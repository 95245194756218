import * as constants from "./constants";

export default function (state, action) {
  if (!state || action.type == constants.clearStore) {
    state = {
      list: [],
      listQueue:[],
      item: {},
      isLoading: false,
      isQueueLoading: false,
      isSave: false,
      isSaved: false,
      isLoaded: false,
      isRequreReload: false,
    };
  }
  switch (action.type) {
    case constants.clearItemStore:
      return {
        ...state,
        ...{ item: {}, isSaved: false },
      };
    case constants.listLoadStart:
      return {
        ...state,
        ...{
          list: [],
          isRequreReload: false,
          isLoading: true,
          error: undefined,
        },
      };
    case constants.listLoadSuccess:
      return {
        ...state,
        ...{
          list: Array.from(action.data, (x) => {
            return {
              ...x,
              ...{
                date: new Date(x.date),
                
              },
            };
          }),
          isLoading: false,
        },
      };
    case constants.listLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.itemLoadStart:
      return {
        ...state,
        ...{ item: {}, isLoading: true, error: undefined },
      };
    case constants.itemLoadSuccess:
      return {
        ...state,
        ...{
          item: {
            ...action.data,
            creationDate: new Date(action.data.creationDate),
          },
          isLoading: false,
        },
      };
    case constants.itemLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.itemSaveStart:
      return {
        ...state,
        ...{
          isRequreReload: false,
          isSaved: false,
          isSaving: true,
          error: undefined,
        },
      };
    case constants.itemSaveSuccess:
      return { ...state, isSaved: true, isSaving: false, isRequreReload: true };
    case constants.itemSaveFail:
      return {
        ...state,
        ...{
          isRequreReload: false,
          isSaved: false,
          isSaving: false,
          error: action.error,
        },
      };

    case constants.itemDeleteStart:
      return { ...state, ...{ isRequreReload: false, error: undefined } };
    case constants.itemDeleteSuccess:
      return { ...state, isRequreReload: true };
    case constants.itemDeleteFail:
      return { ...state, ...{ isRequreReload: false, error: action.error } };

      case constants.listQueueLoadStart:
      return {
        ...state,
        ...{
          listQueue: [],
          isRequreReload: false,
          isQueueLoading: true,
          error: undefined,
        },
      };
    case constants.listQueueLoadSuccess:
      return {
        ...state,
        ...{
          listQueue: Array.from(action.data, (x) => {
            return {
              ...x,
              ...{
                creationDate: new Date(x.creationDate),
                complitedDate: x.complitedDate
                  ? new Date(x.complitedDate)
                  : null,
              },
            };
          }).sort(compare),
          isLoading: false,
        },
      };
    case constants.listQueueLoadFail:
      return { ...state, ...{ isQueueLoading: false, error: action.error } };

    default:
      return state;
  }
}

function compare(a, b) {
  const bandA = a.creationDate;
  const bandB = b.creationDate;

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return -comparison;
}

