import React, { Component } from "react";
import {
  Grid,
  Header,
  Button,
  Divider,
  Form,
  Input,
  Select,
  Message,
  Checkbox,
  Radio,
} from "semantic-ui-react";
import * as actionCreators from "./actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { initListData as loadCategoryList } from "../../Category/actions";
import { initListData as loadItemList } from "../../Item/actions";

import * as utils from "@hitek-crm-utils";
import { Karta } from "./Karta";

export class DiscountNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCompanyId: this.props.match.params.companyId,
      currentDiscountId: this.props.match.params.itemId,
      name: "",
      type: "%",
      userForType: "all",
      order: 0,
      count: 0,
      value: 0,
      items: [],
      usedFor: [],
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeDependency = this.handleChangeDependency.bind(this);
    this.props.clearStore();

    // Operations usually carried out in componentWillMount go here
  }

  loadData(state) {
    if (state.discountId) {
      this.props.getDetailDiscount({
        id: this.state.currentDiscountId,
        companyId: this.state.currentCompanyId,
      });
    }
    this.props.loadCategoryList({
      companyId: state.companyId,
    });
    this.props.loadItemList({
      companyId: state.companyId,
    });
  }
  componentDidMount() {
    this.loadData({
      companyId: this.state.currentCompanyId,
      discountId: this.state.currentDiscountId,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.companyId != nextProps.match.params.companyId) {
      this.setState({
        currentCompanyId: nextProps.match.params.companyId,
      });
      this.loadData({
        companyId: nextProps.match.params.companyId,
      });
      //this.resetForm();
    }

    if (
      this.props.discount.isLoaded != nextProps.discount.isLoaded &&
      nextProps.discount.isLoaded
    ) {
      this.setState({
        name: nextProps.discount.data.name,
        id: nextProps.discount.data.id,
        order: nextProps.discount.data.order,
        type: nextProps.discount.data.type,
        value: nextProps.discount.data.value,
        usedFor: Array.from(nextProps.discount.data.detail || [], (x) => {
          return { ...x, _id: new Date().getTime() - x.categoryId - x.itemId };
        }),
        userForType: Array.isArray(nextProps.discount.data.detail) ? "custom" : "all",
        isLoaded: true,
      });
      console.log(this.state);
    }

    if (
      this.props.discount.isSave != nextProps.discount.isSave &&
      nextProps.discount.isSave
    ) {
      if (this.state.currentDiscountId) {
        this.props.history.push(utils.getRoute(this.props.match.url, 2));
      } else {
        this.props.history.push(utils.getRoute(this.props.match.url, 1));
      }
    }
  }

  handleChangeName(e, target) {
    this.setState({ name: target.value });
  }

  handleChangeOrder(e, target) {
    this.setState({ order: target.value });
  }

  handleChangeType(e, target) {
    this.setState({ type: target.value });
  }
  handleChangeValue(e, target) {
    this.setState({ value: target.value });
  }
  handleOnChange(e, target) {
    this.setState({ [target.name]: target.value });
  }

  handleAddDependency(e, target) {
    this.setState({});
  }
  handleChangeDependency(value) {
    this.setState({
      usedFor: value,
    });
  }

  handleSave(e) {
    e.preventDefault();
    const data = {
      name: this.state.name,
      companyId: this.state.currentCompanyId,
      type: this.state.type,
      value: this.state.value,
      detail: this.state.userForType !== "all" ? this.state.usedFor : null,
    };

    if (!this.state.currentDiscountId) {
      this.props.saveNewDiscount(data);
    } else {
      data.id = this.state.currentDiscountId;

      this.props.saveDiscount(data);
    }
  }

  render() {
    console.log(this.props.categoryStore);
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header size="huge">{this.state.currentDiscountId ? "Редактирование  скидки" : "Новая скидка"}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column>
            <Form
              success={this.props.discount.isSave}
              error={!!this.props.discount.error}
              loading={this.props.discount.isLoading}
            >
              {this.state.id && (
                <Form.Field
                  disabled
                  control={Input}
                  label="Id"
                  placeholder="Id скидки"
                  value={this.state.id}
                />
              )}
              <Form.Field
                control={Input}
                label="Название"
                placeholder="Название Скидки"
                onChange={this.handleChangeName}
                value={this.state.name}
              />

              <Form.Field>
                <Radio
                  label="Общая скидка"
                  name="userForType"
                  value="all"
                  checked={this.state.userForType === "all"}
                  onChange={this.handleOnChange}
                />

                <Radio
                  label="Настраиваемая скидка"
                  name="userForType"
                  value="custom"
                  checked={this.state.userForType === "custom"}
                  onChange={this.handleOnChange}
                />
              </Form.Field>

              <Form.Field
                control={Select}
                label="Тип "
                placeholder="-"
                onChange={this.handleChangeType}
                value={this.state.type}
                disabled={this.state.userForType != "all"}
                options={[
                  { value: "%", text: "%" },
                  { value: "fixed", text: "Фиксированная" },
                ]}
              />
              <Form.Field
                control={Input}
                label="Значение"
                placeholder="Значение скидки"
                disabled={this.state.userForType != "all"}
                onChange={this.handleChangeValue}
                value={this.state.value}
              />

              <Message
                error
                header="Ошибка"
                content={this.props.discount.error}
              />
              {this.state.userForType != "all" &&
                <Karta
                  items={this.props.itemStore.itemListSummury}
                  categories={this.props.categoryStore.categoryList}
                  value={this.state.usedFor}
                  onChange={this.handleChangeDependency}
                />
              }
              <Form.Field
                disabled={
                  this.props.discount.isLoading || this.props.discount.isSave
                }
                onClick={this.handleSave}
                control={Button}
              >
                Сохранить
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    discount: state.discountEdit,
    itemStore: state.itemList,
    categoryStore: state.categoryList,
  };
}

export const DiscountNewContainer = connect(mapStateToProps, {
  ...actionCreators,
  loadCategoryList,
  loadItemList,
})(DiscountNew);
