import React, { Component } from 'react';
import { Grid, Header, Divider, Table, Label, Button, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionCreators from "./actions";

export class OperatorPhoneList extends Component {

    constructor(props) {
        super(props);
        this.state = {

            companyId: this.props.match.params.companyId,
            openDeleteShowModal: false,
            openTokenResetShowModal: false,
            idForRemove: null,
            idForResetToken: null,
            isShowAPKModal:false

        };

        this.props.clearStore();
        this.props.initListData({ companyId: this.state.companyId });
        this.handleCloseOpenDeleteModal = this.handleCloseOpenDeleteModal.bind(this);
        this.handleCloseOpenResetTokenModal = this.handleCloseOpenResetTokenModal.bind(this);
        this.handleOpenAPKModal = this.handleOpenAPKModal.bind(this);
        this.handleCloseOpeAPKModal = this.handleCloseOpeAPKModal.bind(this);
        // Operations usually carried out in componentWillMount go here
    }


    getDataForTable() {
        return this.props.operatorPhone.phoneListSummury;

    }
    componentWillReceiveProps(nextProps) {

        if (!!nextProps.operatorPhone && nextProps.operatorPhone.isShouldReload) {
            this.props.clearStore();
            this.setState({
                openDeleteShowModal: false,
                openTokenResetShowModal: false,
                idForRemove: null,
                idForResetToken: null,
                companyId: nextProps.match.params.companyId
            });

            this.props.initListData({
                companyId: nextProps.match.params.companyId
            });

        }
        if (this.props.match.params.companyId != nextProps.match.params.companyId) {

            this.setState({
                openDeleteShowModal: false,
                openTokenResetShowModal: false,
                idForRemove: null,
                idForResetToken: null,
                companyId: nextProps.match.params.companyId
            });
            this.props.initListData({ companyId: nextProps.match.params.companyId });
        }
    }

    handleCloseOpenDeleteModal(e, button) {
        debugger
        this.setState({ openDeleteShowModal: false })
        if (button.positive) {
            this.props.deleteOperatorPhone(
                {
                    id: this.state.idForRemove,
                    companyId: this.state.companyId
                }
            );
        }
    }
    onRemoveOperatorPhone(id) {
        console.log(id);
        this.setState({ openDeleteShowModal: true, idForRemove: id })
    }

    onResetTokenOperatorPhone(id) {
        console.log(id);
        this.setState({ openTokenResetShowModal: true, idForResetToken: id })
    }

    handleCloseOpenResetTokenModal(e, button) {

        this.setState({ openTokenResetShowModal: false })
        if (button.positive) {
            this.props.resetTokenOperatorPhone(
                {
                    id: this.state.idForResetToken,
                    companyId: this.state.companyId
                }
            );
        }
    }
    handleOpenAPKModal(e, button) {

        this.setState({ isShowAPKModal: true })
    }
    handleCloseOpeAPKModal(e, button) {

        this.setState({ isShowAPKModal: false })
    }


    render() {
        return <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='huge'>Список закрепленных телефонов</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Grid.Row>
                        <Button color="green" as={Link} to={"/Company/" + this.state.companyId + "/operatorPhone/New"}>Добавить новый телефон</Button>
                        <Button color="blue" 
                        onClick={this.handleOpenAPKModal}
                            to={"https://s3.eu-north-1.amazonaws.com/crm.hiteka.net/app/phone_traker_v5.apk"}
                            target="_blank" content="Скачать Apk для интеграции"
                            icon="download" />
                    </Grid.Row>
                    <Table >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Номер</Table.HeaderCell>
                                <Table.HeaderCell>Цвет</Table.HeaderCell>
                                <Table.HeaderCell>Токен</Table.HeaderCell>
                                <Table.HeaderCell>Действия</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {this.getDataForTable().map((status) => {
                                return <Table.Row key={status.id}>
                                    <Table.Cell>
                                        {status.id}
                                    </Table.Cell>
                                    <Table.Cell>{status.number}</Table.Cell>
                                    <Table.Cell>
                                        <Label circular color={status.color}></Label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {status.token}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {/*<Button as={Link} to = {"/Company/"+this.state.companyId + "/operatorPhone/"+status.id } icon='search' content='Перейти' />      */}
                                        <Button onClick={() => { this.onResetTokenOperatorPhone(status.id) }} icon='refresh' color="red" content='Сброс токена' />
                                        <Button as={Link} to={"/Company/" + this.state.companyId + "/OperatorPhone/" + status.id + "/Edit"} icon='edit' content='Редактировать' />
                                        <Button onClick={() => { this.onRemoveOperatorPhone(status.id) }} icon='remove' color="red" content='Удалить' />
                                    </Table.Cell>
                                </Table.Row>
                            })
                            }
                        </Table.Body>
                        <Table.Footer>

                        </Table.Footer>
                    </Table>
                    <Modal
                        open={this.state.openDeleteShowModal}
                        onClose={this.handleCloseopenDeleteModal}
                    >
                        <Modal.Header>Удалить Номер</Modal.Header>
                        <Modal.Content >
                            Уверены, что хотите удалить данный номер{!!this.state.idForRemove ? this.getDataForTable().find(x => x.id === this.state.idForRemove).number : ""} ?
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseopenDeleteModal}  >Нет</Button>
                            <Button positive onClick={this.handleCloseopenDeleteModal} labelPosition='right' icon='checkmark' content='Да' />
                        </Modal.Actions>
                    </Modal>
                    <Modal
                        open={this.state.openTokenResetShowModal}
                        onClose={this.handleCloseOpenResetTokenModal}
                    >
                        <Modal.Header>Сброс токена</Modal.Header>
                        <Modal.Content >
                            Уверены, что хотите сбросить токен для телефона {!!this.state.idForResetToken ? this.getDataForTable().find(x => x.id === this.state.idForResetToken).number : ""} ?
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseOpenResetTokenModal}  >Нет</Button>
                            <Button positive onClick={this.handleCloseOpenResetTokenModal} labelPosition='right' icon='checkmark' content='Да' />
                        </Modal.Actions>
                    </Modal>
                    <Modal
                        open={this.state.isShowAPKModal}
                        onClose={(this.handleCloseOpeAPKModal)}
                    >
                        <Modal.Header>Скачать приложение для интеграции</Modal.Header>
                        <Modal.Content >
                            <img src = {`https://chart.googleapis.com/chart?chs=450x450&cht=qr&chl=${encodeURI("https://s3.eu-north-1.amazonaws.com/crm.hiteka.net/app/phone_traker_v5.apk")}&choe=UTF-8`} />
                            <Button color="blue"  as={Link}
                                to={{pathname:"https://s3.eu-north-1.amazonaws.com/crm.hiteka.net/app/phone_traker_v5.apk"}}
                                target="_blank" content="Скачать Apk для интеграции"
                                icon="download" />
                            </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseOpeAPKModal}  >Закрыть</Button>
                        </Modal.Actions>
                    </Modal>
                </Grid.Column>

            </Grid.Row>
        </Grid>;

    }
};


function mapStateToProps(state) {

    return {

        operatorPhone: state.operatorPhoneList
    };
}


export const OperatorPhoneListContainer = connect(mapStateToProps, actionCreators)(OperatorPhoneList);
