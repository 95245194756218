import axios from "axios";
import qs from "qs";
import * as config from "Config";

const maxAttempt = 5;
const sequence = [];

let isRefreshing = false;
let expareTokenIn = new Date(localStorage.getItem("loginExpiresIn")).setSeconds(-10);

function refreshToken() {
    if (isRefreshing) return;
    isRefreshing = true;

    const axiosConfig = {
        url: `${config.apiUrl}/token/refresh`,
        timeout: 20000,
        method: "post",
        withCredentials: true,
        responseType: "json",
    };

    axiosConfig.data = {
        grantType: "refresh_token",
        refresh_token: localStorage.getItem("refresh_token"),
    };

    const instance = axios(axiosConfig);

    instance.then(
        (response) => {
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            localStorage.setItem("loginExpiresIn", new Date(response.data.expires_in));
            localStorage.setItem("roles", response.data.roles);

            expareTokenIn = new Date(response.data.expires_in).setSeconds(-10);

            isRefreshing = false;
            const token = response.data.access_token;
            while (sequence.length !== 0) {
                const param = sequence.pop();
                if (param.axiosConfig.headers.Authorization) {
                    param.axiosConfig.headers.Authorization = `Bearer ${token}`;
                }
                // eslint-disable-next-line no-use-before-define
                sendRequest(param);
            }
        },
        (error) => {
            isRefreshing = false;
            if (
                error.response.status === 401 ||
                error.response.status === 419 ||
                error.response.status === 400
            ) {
                window.location.href = `${config.appUrl}/login.html`;
            }
        }
    );
}

function sendRequest({ axiosConfig, successAct, failAct, store, attempt = 0 }) {
    if (attempt > maxAttempt) {
        return;
    }
    const instance = axios(axiosConfig);

    instance.then(
        (response) => {
            if (successAct instanceof Function) {
                successAct(response.data);
            } else {
                store.dispatch({
                    type: successAct,
                    data: response.data,
                });
            }
        },
        (error) => {
            /* store.dispatch({
         type: GLOBAL_APPLICATION_ERROR_ADD_NEW_ERROR,
         data: {
           message: `${axiosConfig.url} ${ error.message}`,
           errorCode: error.response && error.response.status,
           title:error.response && error.response.status
         },
       });*/

            if (!error.response || error.response.status === 401) {
                sequence.push({
                    store,
                    axiosConfig,
                    successAct,
                    failAct,
                    attempt: attempt + 1,
                });
                refreshToken();

                return;
            }
            const errorMessage =
                error.message + (error.response ? ", " + error.response.statusText : "");
            if (failAct instanceof Function) {
                failAct(errorMessage);
            } else {
                store.dispatch({
                    type: failAct,
                    error: errorMessage,
                    errorCode: error.response ? error.response.statusText : 0,
                });
            }
        }
    );
}
// eslint-disable-next-line consistent-return
const middleware = (store) => (next) => (action) => {
    if (action.remote === undefined) {
        return next(action);
    }

    const [startAct, successAct, failAct] = action.actions;
    const { method, url, data } = action.remote;

    const axiosConfig = {
        url,
        timeout: config.ajaxTimeOut || 20000,
        method,
        // contentType:"application/json",
        // withCredentials : true,
        responseType: action.remote.responseType || "json",
    };

    if (action.remote.token || true) {
        axiosConfig.headers = {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        };
    }

    if (data) {
        if (method === "get" || method === "delete") {
            delete data.companyId;
            axiosConfig.url += `?${qs.stringify(data)}`;
        } else {
            axiosConfig.data = data;
        } // qs.stringify(data);
    }

    if (new Date() > expareTokenIn) {
        refreshToken();
    }

    if (!isRefreshing) {
        sendRequest({
            store,
            axiosConfig,
            successAct,
            failAct,
        });
    } else {
        sequence.push({
            store,
            axiosConfig,
            successAct,
            failAct,
            attempt: 0,
        });
    }

    if (startAct instanceof Function) {
        startAct();
    } else {
        store.dispatch({
            type: startAct,
        });
    }
};

export default middleware;
