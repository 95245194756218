export const itemPrimeCostLoadStart = "ORDER_ITEM_PRIMECOST_LOAD_START";
export const itemPrimeCostLoadSuccess = "ORDER_ITEM_PRIMECOST_LOAD_SUCCESS";
export const itemPrimeCostLoadFail = "ORDER_ITEM_PRIMECOST_LOAD_FAIL";

export const itemSaveInvoiceStart = "ORDER_INVOICING_START";
export const itemSaveInvoiceSuccess = "ORDER_INVOICING_SUCCESS";
export const itemSaveInvoiceFail= "ORDER_INVOICING_FAIL";

export const itemCheckInvoiceStart = "ORDER_CHECK_INVOICING_START";
export const itemCheckInvoiceSuccess = "ORDER_CHECK_INVOICING_SUCCESS";
export const itemCheckInvoiceFail= "ORDER_CHECK_INVOICING_FAIL";

