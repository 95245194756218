import React, { forwardRef, useCallback } from "react";
import { default as ReactDatePicker } from "react-datepicker";
import { Input } from "semantic-ui-react";

const ExampleCustomInput = forwardRef((props, ref) => {
    const { onClick, value, startDate, endDate } = props;
    let _value = value;
    if (startDate) {
        _value = startDate.toLocaleDateString();
    }
    if (endDate) {
        _value += "-> " + endDate.toLocaleDateString();
    }
    return <Input onClick={onClick} value={_value} ref={ref} />;
});
export default function DatePicker(props) {
    //const [startDate, setStartDate] = useState(new Date());

    const handleonChange = useCallback(
        (date) => {
            if (!!props.onChange) {
                props.onChange(date, { ...props, name: props.name, value: date, type: "date" });
            }
        },
        [props.onChange]
    );
    return (
        <ReactDatePicker
            {...props}
            onChange={handleonChange}
            selected={props.selected || props.value}
            customInput={<ExampleCustomInput startDate={props.startDate} endDate={props.endDate} />}
        />
    );
}
