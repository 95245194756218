import * as config from "Config";


export function clearStore(newObject) {
    return {
      type:"ORDER_STATUS_LIST_CLEAR_STORE"
    };
}

export function initListData(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/OrderStatus/Company/"+params.companyId + "/Summury"
      },
      actions:["ORDER_STATUS_LIST_SUMMURY_LOAD_START","ORDER_STATUS_LIST_SUMMURY_LOAD_SUCCESS","ORDER_STATUS_LIST_SUMMURY_LOAD_FAIL"]
    };
}

export function deleteOrderStatus(params) {
    return {
      remote:{
          method: "delete",
          url: config.apiUrl + "/api/OrderStatus/"+params.id + "/Company/" + params.companyId
      },

      actions:["ORDER_STATUS_LIST_REMOVE_START","ORDER_STATUS_LIST_REMOVE_SUCCESS","ORDER_STATUS_LIST_REMOVE_FAIL"]
    };
}



