import React, { Component } from "react";
import { Grid, Button, Form, Input, Label, Icon } from "semantic-ui-react";
import * as utils from "@hitek-crm-utils";

export class Karta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCompanyId: this.props.companyId,
            name: "",
            price: "",
            categoriesId: [],
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleAdd = this.handleAdd.bind(this);

        this.getOptions = this.getOptions.bind(this);
    }

    componentDidMount() {
        //this.loadData.call(this, { companyId: this.state.currentCompanyId });
    }

    handleInputChange(event, target) {
        //event.preventDefault();
        //const target = event.target;
        // debugger

        let value = Array.from(this.props.value); //[];
        let id = target["data-id"];
        let v = value.find((x) => x.stockItemId == id) || {};
        const name = target.name;

        //v[name] = target.value;
        let targetValue = target.value;

        if (target.type == "int") {
            targetValue = parseInt(targetValue);
        }
        if (target.type == "float") {
            targetValue = utils.toCorrectFloat(parseFloat(targetValue), 5);
            if (target.value.length > 1 && target.value[target.value.length - 1] == ".") {
                targetValue += ".";
            }
        }
        v[name] = targetValue;

        this.props.onChange(value);
    }

    handleRemove(e, target) {
        let id = target["data-id"];
        let value = [];
        for (let i = 0; i < this.props.value.length; i++) {
            if (this.props.value[i].stockItemId === id) continue;
            value.push(this.props.value[i]);
        }

        this.props.onChange(value);
    }
    handleAdd(e, target) {
        if (!this.props.value.find((x) => x.stockItemId === 0)) {
            let id = target["data-id"];
            let value = Array.from(this.props.value);
            value.push({ stockItemId: 0, count: 0 });
            this.props.onChange(value);
        }
    }

    getOptions() {
        if (!this.props.stoсkItems) {
            return [];
        }
        let v = Array.from(this.props.value, (x) => x.stockItemId);
        return Array.from(this.props.stoсkItems, (x) => {
            return { text: x.title, value: x.id, disabled: v.includes(x.id) };
        });
    }
    render() {
        return (
            <Grid style={{ marginBottom: "15px" }}>
                <Grid.Row>
                    <Grid.Column>
                        <Button color="green" fluid onClick={this.handleAdd}>
                            Добавить Составляющую
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                {this.props.value.map((item) => (
                    <Grid.Row key={item.stockItemId} columns={"3"} style={{ padding: "3px" }}>
                        <Grid.Column width={"5"}>
                            <Form.Select
                                data-id={item.stockItemId}
                                //loading={this.props.company.isUserListLoading}
                                placeholder="Компонент"
                                label="Компонент"
                                fluid
                                selection
                                value={item.stockItemId}
                                name="stockItemId"
                                search
                                onChange={this.handleInputChange}
                                options={this.getOptions()}
                            />
                        </Grid.Column>
                        <Grid.Column width={"5"}>
                            <Form.Field
                                data-id={item.stockItemId}
                                control={Input}
                                placeholder="Количество"
                                label="Количество"
                                labelPosition="right"
                                name="count"
                                type="float"
                                value={item.count}
                                onChange={this.handleInputChange}>
                                <input />
                                <Label>
                                    {item.stockItemId &&
                                    this.props.stoсkItems.find((x) => x.id === item.stockItemId)
                                        ? this.props.stoсkItems.find(
                                              (x) => x.id === item.stockItemId
                                          ).unit
                                        : ""}
                                </Label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={"3"}>
                            <Form.Field
                                data-id={item.stockItemId}
                                control={Input}
                                placeholder="Cебестоимость"
                                label="Cебестоимость"
                                labelPosition="right"
                                readOnly
                                value={
                                    item.stockItemId &&
                                    this.props.stockItemEstimationCost.find(
                                        (x) => x.id === item.stockItemId
                                    )
                                        ? utils.toFloatToString(
                                              this.props.stockItemEstimationCost.find(
                                                  (x) => x.id === item.stockItemId
                                              ).estimateCost * item.count
                                          )
                                        : "-"
                                }>
                                <input />
                                <Label>р.</Label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={"2"}>
                            <Form.Field
                                label="Удалить"
                                data-id={item.stockItemId}
                                color="red"
                                onClick={this.handleRemove}
                                control={Button}>
                                <Icon name="remove" />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                ))}
            </Grid>
        );
    }
}
