import React from "react";
import { Grid, Header, Divider, Card, Icon } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";

export function CompanyContainer() {
    const { companyId } = useParams();

    const pref = "/Company/" + companyId;
    const settings = [
        {
            as: Link,
            to: pref + "/Category",
            header: "Категории",
            meta: "meta",
            description: "Операции с категориями",
        },
        {
            as: Link,
            to: pref + "/Item",
            header: "Товары",
            meta: "meta",
            description: "Операции с товарами в меню",
        },
        {
            as: Link,
            to: pref + "/OrderStatus",
            header: "Статусы заказов",
            meta: "meta",
            description: "Операции со статусами заказов",
        },
        {
            as: Link,
            to: pref + "/Tag",
            header: "Теги к заказам",
            meta: "meta",
            description: "Операции с тегами к заказам",
        },
        {
            as: Link,
            to: pref + "/Discount",
            header: "Скидки",
            meta: "meta",
            description: "Операции со скидками",
        },
        {
            as: Link,
            to: pref + "/OperatorPhone",
            header: "Привязанные телефоны",
            meta: "meta",
            description: "Операции с привязанными телефонами операторов",
        },

        {
            as: Link,
            to: pref + "/PaymentMethod",
            header: "Способы оплаты",
            meta: "meta",
            description: "Операции со способами оплаты",
        },

        {
            as: Link,
            to: pref + "/PaymentMethodExternal",
            header: "Внешние Способы оплаты",
            meta: "meta",
            description: "Операции со внешними способами оплаты",
        },
        {
            as: Link,
            to: pref + "/Edit",
            header: "Редактирование компании",
            meta: "meta",
            description: "Операции по редактированию компании",
        },
        {
            as: Link,
            to: pref + "/RoleCompany",
            header: "Роли пользователей",
            meta: "meta",
            description: "Редактирование разрешений для пользователей компании",
        },

        {
            as: Link,
            to: pref + "/Employee",
            header: "Сотрудники",
            meta: "meta",
            description: "Редактирование сотрудников компании",
        },
    ];
    /*
  const reports = [
    {
      as: Link,
      to: pref,
      header: "Сводка по заказм",
      meta: "meta",
      description: "График продаж по дням",
    },
    {
      as: Link,
      to: pref + "/DashBoard/MediumSummury",
      header: "Расщиреная сводка по заказам",
      meta: "meta",
      description: "График продаж по дням",
    },
    {
      as: Link,
      to: pref + "/DashBoard/Realization",
      header: "Сводка по продажам",
      meta: "meta",
      description: "График продаж по дням",
    },
    {
      as: Link,
      to: pref + "/Report/Order",
      header: "Общий отчет",
      meta: "meta",
      description: "График продаж по дням",
    },
    {
      as: Link,
      to: pref + "/Report/Payment",
      header: "Отчет по принятым платежам",
      meta: "meta",
      description: "График продаж по дням",
    },
    
    {
      as: Link,
      to: pref + "/Report/Vigoda",
      header: "Отчет о доходности за период",
      meta: "meta",
      description: "Отчет о доходности за период",
    },
    {
      as: Link,
      to: pref + "/Report/OrderVigoda",
      header: "Отчет о доходности заказов",
      meta: "meta",
      description: "Отчет о доходности за период для заказов",
    },
    
    {
      as: Link,
      to: pref + "/Report/General",
      header: "Отчет в формате Excel",
      meta: "meta",
      description: "Отчет в формате Excel" ,
    },
  ];*/
    const reports = [
        {
            as: Link,
            to: pref + "/Report",
            header: "Все отчеты для компании",
            meta: "meta",
            description: "Список всех отчетов для компании",
        },
    ];

    const actions = [
        {
            as: Link,
            to: pref + "/Order/New",
            header: "Новый заказ",
            meta: "meta",
            description: "Новый заказ",
        },
    ];

    const list = [
        {
            title: "Основнын операции",
            icon: "gem",
            items: actions,
        },
        {
            title: "Настройки компании",
            icon: "settings",
            items: settings,
        },
        {
            title: "Склад",
            icon: "boxes",
            items: [
                {
                    as: Link,
                    to: pref + "/Stock",
                    header: "Склад",
                    meta: "meta",
                    description: "Просмотр состояния склада",
                },
                {
                    as: Link,
                    to: pref + "/StockItem",
                    header: "Позиции на складе",
                    meta: "meta",
                    description: "Операции с арзициями на складе",
                },
                {
                    as: Link,
                    to: pref + "/StockGroup",
                    header: "Группы позиций на складе",
                    meta: "meta",
                    description: "Операции с группами позиций на складе",
                },
                {
                    as: Link,
                    to: pref + "/PackingList",
                    header: "Поставки",
                    meta: "meta",
                    description: "Внесение поставок на склад",
                },
                {
                    as: Link,
                    to: pref + "/Supplier",
                    header: "Поставщики",
                    meta: "meta",
                    description: "Поставщики работающие с компанией",
                },
            ],
        },

        {
            title: "Принтера и печать",
            icon: "print",
            items: [
                {
                    as: Link,
                    to: pref + "/Printer",
                    header: "Настройка принтеров",
                    meta: "meta",
                    description: "Доступные принтеры для печати",
                },

                {
                    as: Link,
                    to: pref + "/Printer/Queue",
                    header: "Очередь печати",
                    meta: "meta",
                    description: "Очередь печати",
                },
                {
                    as: Link,
                    to: pref + "/Printer/PrintInfo",
                    header: "Печать информации на предчеке",
                    meta: "meta",
                    description: "Печать информации на предчеке",
                },
            ],
        },
        {
            title: "Настройка магазина",
            icon: "shop",
            items: [
                {
                    as: Link,
                    to: pref + "/Shop",
                    header: "Настройка магазинов ",
                    meta: "meta",
                    description: "Доступные магазины",
                },
                {
                    as: Link,
                    to: pref + "/Shop/Category",
                    header: "Настройка категорий ",
                    meta: "meta",
                    description: "Доступные категории и магазине",
                },
            ],
        },
        {
            title: "Доставка",
            icon: "truck",
            items: [
                {
                    as: Link,
                    to: pref + "/Delivery",
                    header: "Местоположение курьеров",
                    meta: "meta",
                    description: "Местоположение курьеров",
                },
                
            ],
        },
        {
            title: "Настройка кухни",
            icon: "shop",
            items: [
                {
                    as: Link,
                    to: pref + "/Kitchen",
                    header: "Настройка кухонь ",
                    meta: "meta",
                    description: "Настройка кухонь ",
                },
            ],
        },
        {
            title: "Настройка конфигурации модулей",
            icon: "shop",
            items: [
                {
                    as: Link,
                    to: pref + "/ModuleConfiguration",
                    header: "Настройка конфигураций модулей",
                    meta: "meta",
                    description: "Настройка конфигураций модулей",
                },
            ],
        },
        {
            title: "Финансы",
            icon: "dollar sign",
            items: [
                {
                    as: Link,
                    to: pref + "/Finance/Source",
                    header: "Настройка источников финансов",
                    meta: "meta",
                    description: "Настройка источников финансов",
                },
                {
                    as: Link,
                    to: pref + "/Finance/Transaction",
                    header: "Финансовые транзации",
                    meta: "meta",
                    description: "Финансовые транзации",
                },
            ],
        },
        {
            title: "Инвентарь",
            icon: "box",
            items: [
                {
                    as: Link,
                    to: pref + "/InventoryGroup",
                    header: "Настройка групп инвентаря",
                    meta: "meta",
                    description: "Настройка групп инвентаря",
                },
                {
                    as: Link,
                    to: pref + "/Inventory/DashBoard",
                    header: "Сводка Учета инвентаря",
                    meta: "meta",
                    description: "Сводка Учета инвентаря",
                },
                {
                    as: Link,
                    to: pref + "/Inventory",
                    header: "Учет инвентаря",
                    meta: "meta",
                    description: "Учет инвентаря",
                },
            ],
        },
        {
            title: "Отчеты",
            icon: "file alternate outline",
            items: reports,
        },
    ];
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="huge">Доска компании</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row columns={1}>
                {list.map((l, i) => {
                    return (
                        <Grid.Column key={i}>
                            <Divider horizontal>
                                <Header as="h4">
                                    {l.icon && <Icon name={l.icon} />}
                                    {l.title}
                                </Header>
                            </Divider>

                            <Card.Group items={l.items} />
                        </Grid.Column>
                    );
                })}
            </Grid.Row>
        </Grid>
    );
}
