import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == constants.CATEGORY_CLEAR_STORE) {
        state = {
            data: {},
            customFieldList: [],
            isLoading: false,
            isSave: false,
            isLoaded: false,
        };
    }
    switch (action.type) {
        case constants.CATEGORY_NEW_SAVE_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.CATEGORY_NEW_SAVE_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isSave: true } };
        case constants.CATEGORY_NEW_SAVE_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.CATEGORY_SAVE_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.CATEGORY_SAVE_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isSave: true } };
        case constants.CATEGORY_SAVE_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.CATEGORY_DETAIL_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.CATEGORY_DETAIL_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isLoaded: true } };
        case constants.CATEGORY_DETAIL_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };
        case constants.CATEGORY_CF_LOAD_START:
            return { ...state, ...{ isLoading: false, error: undefined } };
        case constants.CATEGORY_CF_LOAD_SUCCESS:
            return { ...state, ...{isLoading: false, customFieldList: action.data } };
        case constants.CATEGORY_CF_LOAD_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };
        default:
            return state;
    }
}
