import React, { useCallback, useEffect, useState } from "react";
import { Menu, Dropdown, Icon, Select, Label } from "semantic-ui-react";
import { NavLink, Link, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "./actions";
import { updateReadedNotify } from "./../Notification/actions";

export function HeaderContainer() {
    const [indexCurrrentCompany, setIndexCurrrentCompany] = useState(0);
    const { headerStore, companyStore, notificationStore } = useSelector((state) => {
        return {
            headerStore: state.header,
            companyStore: state.companyList,
            notificationStore: state.notificationStore,
        };
    });
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (headerStore.logOut) {
            window.localStorage.clear();
            window.location.href = "login.html";
        }
    }, [headerStore.logOut]);

    function getCompany() {
        if (Array.isArray(companyStore.companyList) && companyStore.companyList.length > 0) {
            return [companyStore.companyList[indexCurrrentCompany]];
        }
        return [];
    }

    const handleChangeCompany = useCallback((e, target) => {
        setIndexCurrrentCompany(target.value);
    }, []);

    const handlerNotificationClick = useCallback(
        (e, data) => {
            var notify = notificationStore.list.find((x) => x.id == data.eventKey);
            if (!notify) {
                return;
            }

            dispatch(updateReadedNotify({ id: notify.id }));
            if (notify.code == "order") {
                const { orderId, companyId } = notify.data;
                history.push(`/Company/${companyId}/Order/${orderId}`);
            }
        },
        [notificationStore.list]
    );

    return (
        <Menu fixed="top" fluid inverted size="huge" borderless>
            <Menu.Item as={Link} to={"/"} header>
                Моя доска
            </Menu.Item>
            <Menu.Item header id="toggle-sidebar">
                <Icon name="sidebar" />
            </Menu.Item>
            <Menu.Item
                link
                header
                onClick={() => {
                    history.goBack();
                }}>
                <Icon name="arrow left" /> Назад
            </Menu.Item>
            <Menu.Menu position="right">
                {getCompany().map((item) => {
                    let res = [
                        //<Menu.Item   name={"Компания:"} />
                    ];
                    if (companyStore.companyList.length > 1) {
                        res.push(
                            <Menu.Item key="select-company" fitted>
                                <div> Компания: </div>
                                <Select
                                    placeholder="Выбрать компанию"
                                    selection
                                    options={Array.from(companyStore.companyList, (x, index) => {
                                        return { text: x.name, value: index };
                                    })}
                                    value={indexCurrrentCompany}
                                    onChange={handleChangeCompany}
                                />
                            </Menu.Item>
                        );
                    } else {
                        res.push(
                            <Menu.Item
                                key="company-name"
                                color="grey"
                                active
                                name={companyStore.companyList[0].name}
                            />
                        );
                    }
                    res.push(
                        <Dropdown key="company-dashboard" item text="Сводка">
                            <Dropdown.Menu>
                                <Menu.Item
                                    link
                                    as={Link}
                                    to={"/Company/" + item.id}
                                    name="Незакрытые заказы"
                                />
                                <Menu.Item
                                    link
                                    as={Link}
                                    to={"/Company/" + item.id + "/DashBoard/Summury"}
                                    name="Сводка по заказам"
                                />

                                <Menu.Item
                                    link
                                    as={Link}
                                    to={"/Company/" + item.id + "/DashBoard/MediumSummury"}
                                    name="Расширеная сводка по заказам"
                                />
                                <Menu.Item
                                    link
                                    as={Link}
                                    to={"/Company/" + item.id + "/DashBoard/Realization"}
                                    name="Сводка по продажам"
                                />
                                <Menu.Item
                                    link
                                    as={Link}
                                    to={"/Company/" + item.id + "/DashBoard/OrderCalendar"}
                                    name="Календарь заказов"
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                    res.push(
                        <Menu.Item
                            key="company-new-order"
                            link
                            as={Link}
                            to={"/Company/" + item.id + "/Order/New"}
                            icon="add square"
                            name="Новый заказ"
                        />
                    );
                    return res;
                })}
                <Dropdown
                    pointing
                    className="link item"
                    icon={"mail"}
                    scrolling
                    text={
                        <Label color={notificationStore.list.length > 0 ? "orange" : null}>
                            {" "}
                            {notificationStore.list.length}
                        </Label>
                    }>
                    <Dropdown.Menu>
                        {notificationStore.list.map((x) => (
                            <Dropdown.Item
                                key={x.id}
                                eventKey={x.id}
                                onClick={handlerNotificationClick}>
                                <h4>{x.title}</h4>
                                {x.message}
                            </Dropdown.Item>
                        ))}
                        {!notificationStore.list.length && (
                            <Dropdown.Item>Сообщений нет</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>

                {localStorage.getItem("roles") == "Admin" && (
                    <Dropdown item icon="user secret">
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={NavLink}
                                to="/Admin/Account"
                                activeClassName="active">
                                Все пользователи
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={NavLink}
                                to="/Admin/Company"
                                activeClassName="active">
                                Все Компании
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={NavLink}
                                to={"/Admin/RoleCompany"}
                                activeClassName="active">
                                Роли Компаний
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={NavLink}
                                to={"/Admin/Permission"}
                                activeClassName="active">
                                Permission
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={NavLink}
                                to={"/Admin/Account/Session"}
                                activeClassName="active">
                                Сессии
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={NavLink}
                                to={"/Admin/Metric"}
                                activeClassName="active">
                                Статистика
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={NavLink}
                                to={"/Admin/Error"}
                                activeClassName="active">
                                Ошибки системы
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={NavLink}
                                to={"/Admin/Printer/Queue"}
                                activeClassName="active">
                                Очередь печати
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={NavLink}
                                to={"/Admin/Notification/all"}
                                activeClassName="active">
                                Все сообщения нотификации
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}

                <Dropdown item text="Мой профиль">
                    <Dropdown.Menu>
                        <Menu.Item
                            icon="user circle"
                            link
                            as={Link}
                            to={"/Account/" + localStorage.getItem("userId")}
                            name="Мой профайл"
                        />
                        <Menu.Item
                            icon="user circle"
                            link
                            as={Link}
                            to={"/Account/" + localStorage.getItem("userId") + "/Session"}
                            name="Сесии"
                        />
                        <Menu.Item
                            icon="user circle"
                            link
                            as={Link}
                            to={"/Account/" + localStorage.getItem("userId") + "/Notification"}
                            name="Сообщения"
                        />
                        <Menu.Item
                            icon="building outline"
                            link
                            as={Link}
                            to="/Company"
                            name="Мои Компании"
                        />
                        <Menu.Item
                            icon="user circle"
                            link
                            as={Link}
                            to={"/CompanyGroup"}
                            name="Мои группы компаний"
                        />
                        <Menu.Item
                            icon="sign out"
                            link
                            href="/login.html"
                            onClick={() => dispatch(actionCreators.LogOut())}
                            name="Выход"
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
    );
}
