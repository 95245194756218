import * as constants from "./constants";

export default function (state, action) {
  if (!state || action.type == constants.clearStore) {
    state = {
      list: [],
      item: {},
      isLoading: false,
      isSave: false,
      isSaved: false,
      isLoaded: false,
      isRequreReload: false,
    };
  }
  switch (action.type) {
    case constants.clearItemStore:
      return {
        ...state,
        ...{ item: {}, isSaved: false },
      };
    case constants.listLoadStart:
      return {
        ...state,
        ...{
          list: [],
          isRequreReload: false,
          isLoading: true,
          error: undefined,
        },
      };
    case constants.listLoadSuccess:
      return {
        ...state,
        ...{
          list: Array.from(action.data, (x) => {
            return {
              ...x,
              ...{ date: new Date(x.date), creationDate: new Date(x.creationDate) },
            };
          }).sort(sort),
          isLoading: false,
        },
      };
    case constants.listLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.itemLoadStart:
      return {
        ...state,
        ...{ item: {}, isLoading: true, error: undefined },
      };
    case constants.itemLoadSuccess:
      return {
        ...state,
        ...{
          item: { ...action.data, date: new Date(action.data.date),creationDate: new Date(action.data.creationDate) },
          isLoading: false,
        },
      };
    case constants.itemLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.itemSaveStart:
      return {
        ...state,
        ...{
          isRequreReload: false,
          isSaved: false,
          isSaving: true,
          error: undefined,
        },
      };
    case constants.itemSaveSuccess:
      return { ...state, isSaved: true, isSaving: false, isRequreReload: true };
    case constants.itemSaveFail:
      return {
        ...state,
        ...{
          isRequreReload: false,
          isSaved: false,
          isSaving: false,
          error: action.error,
        },
      };

    case constants.itemDeleteStart:
    case constants.itemApproveStart:
      return { ...state, ...{ isRequreReload: false, error: undefined } };
    case constants.itemDeleteSuccess:
    case constants.itemApproveSuccess:
      return { ...state, isRequreReload: true };
    case constants.itemDeleteFail:
    case constants.itemApproveFail:
      return { ...state, ...{ isRequreReload: false, error: action.error } };
    default:
      return state;
  }
}

function sort(a, b) {
  if (a.date > b.date) {
    return -1;
  }
  if (a.date < b.date) {
    return 1;
  }

  if (a.creationDate < b.creationDate) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
