export function addLoadMask(idContent) {
    let content = document.getElementById("main-content");
    content.classList.add("loading");
    content.data.content = idContent;
}

export function removeLoadMask(idContent) {
    let content = document.getElementById("main-content");
    if (ontent.data.content == idContent) content.classList.remove("loading");
}

export function toCorrectFloat(value, precision) {
    if (!value) {
        return 0;
    }
    let prec = precision;
    if (precision === undefined) {
        prec = 2;
    }

    let res = value * Math.pow(10, prec) + 0.01;
    res = Math.round(res);
    res = res / Math.pow(10, prec);
    return res;
}

export function toFloatToString(value, precision) {
    let pec = precision;
    if (!precision) {
        pec = 2;
    }

    return toCorrectFloat(value, precision).toFixed(pec);
}

export function getRoute(location, step = 0) {
    const a = location.split("/");
    return a.slice(0, a.length - step).join("/");
}

export function toShortDateString(date) {
    function pad(number) {
        if (number < 10) {
            return "0" + number;
        }
        return number;
    }
    return date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate());
}

export function dateFromShortString(date, params) {
    if (!date) {
        return new Date();
    }
    const [year, month, day] = date.split("-");

    let trueDate = true;
    const yearInt = parseInt(year);
    if (isNaN(year)) {
        trueDate = false;
    }
    const monthInt = parseInt(month);
    if (isNaN(monthInt) || monthInt < 1 || monthInt > 12) {
        trueDate = false;
    }
    const dayInt = parseInt(day);
    if (isNaN(dayInt) || dayInt < 1 || dayInt > 31) {
        trueDate = false;
    }
    if (!trueDate) {
        return new Date();
    }

    if (params && params.endDate) {
        return new Date(yearInt, monthInt - 1, dayInt, 23, 59, 59);
    } else {
        return new Date(yearInt, monthInt - 1, dayInt);
    }
}

export function getFormData(formData, data, previousKey) {
    if (data instanceof Object) {
        Object.keys(data).forEach((key) => {
            const value = data[key];
            if (value instanceof Object && !Array.isArray(value)) {
                return getFormData(formData, value, key);
            }
            if (previousKey) {
                key = `${previousKey}[${key}]`;
            }
            if (Array.isArray(value)) {
                value.forEach((val, index) => {
                    if (val instanceof Object) {
                        getFormData(formData, val, `${key}[${index}]`);
                    } else {
                        formData.append(`${key}[${index}]`, val);
                    }
                });
            } else {
                formData.append(key, value);
            }
        });
    }
}

export function _guid() {
    var s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export function readparamsFromSearch(location, options) {
    let _location = location;
    if (!_location) {
        _location = {};
    }
    const newp = {};
    let searchParam = new URLSearchParams(_location.search);
    if (searchParam.has("startDate")) {
        newp.startDate = new Date(searchParam.get("startDate"));
    }

    if (searchParam.has("endDate")) {
        newp.endDate = new Date(searchParam.get("endDate"));
    }
    if (!newp.endDate || !newp.startDate) {
        if (!newp.endDate && !newp.startDate) {
            const startDate = new Date(new Date().toDateString());

            if (!options || !options.endDateIsOptional) {
                startDate.setMonth(startDate.getMonth() - 1);
                newp.endDate = new Date(new Date().toDateString());
            }
            newp.startDate = startDate;
        } else {
            if (newp.endDate && !newp.startDate) {
                const startDate = new Date(newp.endDate.toDateString());
                startDate.setMonth(startDate.getMonth() - 1);
                newp.startDate = startDate;
            } else {
                if (!options || !options.endDateIsOptional) {
                    const endDate = new Date(newp.startDate.toDateString());
                    endDate.setMonth(endDate.getMonth() + 1);
                    newp.endDate = endDate;
                }
            }
        }
    }
    if (newp.endDate) {
        newp.endDate.setMinutes(59);
        newp.endDate.setHours(23);
    }
    newp.startDate.setHours(0);
    newp.startDate.setMinutes(0);
    return newp;
}
