export const clearStore = "STOCK_ITEM_CLEAR_STORE";

export const listStockGroupLoadStart = 'STOCK_GROUP_LIST_LOAD_START';
export const listStockGroupLoadSuccess = 'STOCK_GROUP_LIST_LOAD_SUCCESS';
export const listStockGroupLoadFail = 'STOCK_GROUP_LIST_LOAD_FAIL';

export const listLoadStart = 'STOCK_ITEM_LIST_LOAD_START';
export const listLoadSuccess = 'STOCK_ITEM_LIST_LOAD_SUCCESS';
export const listLoadFail = 'STOCK_ITEM_LIST_LOAD_FAIL';

export const itemLoadStart = 'STOCK_ITEM_LOAD_START';
export const itemLoadSuccess = 'STOCK_ITEM_LOAD_SUCCESS';
export const itemLoadFail = 'STOCK_ITEM_LOAD_FAIL';

export const itemSaveStart = 'STOCK_ITEM_SAVE_START';
export const itemSaveSuccess = 'STOCK_ITEM_SAVE_SUCCESS';
export const itemSaveFail = 'STOCK_ITEM_SAVE_FAIL';

export const itemDeleteStart = 'STOCK_ITEM_DELETE_START';
export const itemDeleteSuccess = 'STOCK_ITEM_DELETE_SUCCESS';
export const itemDeleteFail = 'STOCK_ITEM_DELETE_FAIL';


export const itemBalanceLoadStart = 'STOCK_ITEM_BALANCE_LOAD_START';
export const itemBalanceLoadSuccess = 'STOCK_ITEM_BALANCE_LOAD_SUCCESS';
export const itemBalanceLoadFail = 'STOCK_ITEM_BALANCE_LOAD_FAIL';

export const itemListEstimateCostLoadStart = 'STOCK_ITEM_LIST_ESTIMATE_COST_LOAD_START';
export const itemListEstimateCostLoadSuccess = 'STOCK_ITEM_LIST_ESTIMATE_COST_LOAD_SUCCESS';
export const itemListEstimateCostLoadFail = 'STOCK_ITEM_LIST_ESTIMATE_COST_LOAD_FAIL';

