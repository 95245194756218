import {browserHistory } from 'react-router';

export default function(state ,action){
    if ( !state  || action.type=='OPERATOR_PHONE_LIST_CLEAR_STORE'){

        let oldPhoneList = undefined;
        if( !!state ){
            oldPhoneList = state.phoneList;
        }

        state =  {
            name: "Тест",

            phoneListSummury: [],
            phoneList: oldPhoneList || [],
            userList: [],
            isLoading : false,
            isSave: false,
            isLoaded: false
        };

    }
//OPERATOR_PHONE_LIST_SUMMURY_LOAD_START","OPERATOR_PHONE_LIST_SUMMURY_LOAD_SUCCESS","OPERATOR_PHONE_LIST_SUMMURY_LOAD_FAIL
//OPERATOR_PHONE_LIST_LOAD_START","OPERATOR_PHONE_LIST_LOAD_SUCCESS","OPERATOR_PHONE_LIST_LOAD_FAIL
//"OPERATOR_PHONE_LIST_REMOVE_START","OPERATOR_PHONE_LIST_REMOVE_SUCCESS","OPERATOR_PHONE_LIST_REMOVE_FAIL"
///OPERATOR_PHONE_LIST_RESET_TOKEN_START","OPERATOR_PHONE_LIST_RESET_TOKEN_SUCCESS","OPERATOR_PHONE_LIST_RESET_TOKEN_FAIL
    switch(action.type){
  /*      case "COMPANY_NEW_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "COMPANY_NEW_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "COMPANY_NEW_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
        */case "OPERATOR_PHONE_LIST_SUMMURY_LOAD_START":
            return {...state, ...{phoneListSummury: [], isLoading:true, error:undefined}};
        case "OPERATOR_PHONE_LIST_SUMMURY_LOAD_SUCCESS":
            return { ...state, ...{phoneListSummury: action.data, isLoading:false}} ;
        case "OPERATOR_PHONE_LIST_SUMMURY_LOAD_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        case "OPERATOR_PHONE_LIST_LOAD_START":
            return {...state, ...{phoneList: [], isLoading:true, error:undefined}};
        case "OPERATOR_PHONE_LIST_LOAD_SUCCESS":
            return { ...state, ...{phoneList: action.data, isLoading:false}} ;
        case "OPERATOR_PHONE_LIST_LOAD_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        case "OPERATOR_PHONE_LIST_RESET_TOKEN_START":
            return {...state, ...{isLoading:true}};
        case "OPERATOR_PHONE_LIST_RESET_TOKEN_SUCCESS":
            return { ...state, ...{isLoading:false,isShouldReload:true}} ;
        case "OPERATOR_PHONE_LIST_RESET_TOKEN_FAIL":
            return {...state, ...{isLoading:true,error:action.error}};
        default:
            return state;
    }
}