import React, { Component } from "react";
import { Grid, Header, Button, Divider, Form, Input, Message, Icon,Checkbox } from "semantic-ui-react";

import { connect } from "react-redux";
import * as actionCreators from "./actions";
import { initListData as initRoleCompanyList } from "../RoleCompany/actions";
import { FileLoader } from "./FileLoad";
import * as utils from "@hitek-crm-utils";

export class EditCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            id: "",
            name: "",
            newLogoId: null,
            logoUrl: "",
            phone: "",
            address: "",
            webSite: "",
            workTime: "",
            isRequiredFeedback:false
        };

        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleChangeLogo = this.handleChangeLogo.bind(this);

        this.handleAddUser = this.handleAddUser.bind(this);
        this.handleRemoveUser = this.handleRemoveUser.bind(this);

        this.handleSave = this.handleSave.bind(this);
        this.props.clearStore();

        if (this.props.match.params.id) {
            this.props.getDetailCompany(this.props.match.params.id);
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!this.state.isLoaded && nextProps.company.isLoaded) {
            let self = this;
            this.setState({
                name: nextProps.company.data.name,
                id: nextProps.company.data.id,
                newLogoId: null,
                logoUrl: nextProps.company.data.logoUrl,
                phone: nextProps.company.data.phone || "",
                address: nextProps.company.data.address || "",
                webSite: nextProps.company.data.webSite || "",
                workTime: nextProps.company.data.workTime || "",
                isRequiredFeedback:nextProps.company.data.isRequiredFeedback||false,
                isLoaded: true,
            });
            console.log(this.state);
        }
        if (!this.props.company.isSave && this.props.company.isSave != nextProps.company.isSave) {
            if (this.state.id) {
                this.props.history.push(utils.getRoute(this.props.match.url, 2));
            } else {
                this.props.history.push(utils.getRoute(this.props.match.url, 1));
            }
        }
        if (
            !this.props.company.isUserListSaved &&
            this.props.company.isUserListSaved != nextProps.company.isUserListSaved
        ) {
            this.props.initUserList(nextProps.match.params.id);
        }
    }

   
    handleInputChange(e, target) {

        let value = undefined;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'select-multiple':
                value = Array.from(target.selectedOptions, x => x.value);
                break;
            default:
                value = target.value;
        }
        const name = target.name;

        this.setState({
            [name]: value,
        });

    }
    handleChangeLogo(e, target) {
        this.setState({ newLogoId: target.id, logoUrl: target.url });
    }
    handleAddUser(user) {
        this.props.addUserCompany({ companyId: this.state.id, ...user });
    }
    handleRemoveUser(userId) {
        this.props.deleteUserCompany({ companyId: this.state.id, userId });
    }

    handleSave(e) {
        e.preventDefault();
        if (this.state.id) {
            this.props.saveCompany({
                name: this.state.name,
                companyId: this.state.id,
                newLogoId: this.state.newLogoId,
                isRemoveLogo: !this.state.logoUrl,
                address: this.state.address,
                webSite: this.state.webSite,
                workTime: this.state.workTime,
                phone: this.state.phone,
                isRequiredFeedback:this.state.isRequiredFeedback
            });
        } else {
            this.props.saveNewCompany({
                name: this.state.name,
                //companyId: this.state.id,
                newLogoId: this.state.newLogoId,
                address: this.state.address,
                webSite: this.state.webSite,
                workTime: this.state.workTime,
                phone: this.state.phone,
                isRequiredFeedback:this.state.isRequiredFeedback

            });
        }
    }

    _guid() {
        var s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    }

    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size="huge">
                            Редактирвоание Компании - {this.props.company.data.name}
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Form
                            success={this.props.company.isSave}
                            error={!!this.props.company.error}
                            loading={this.props.company.isLoading}>
                            <Form.Group>
                                <Form.Field
                                    disabled
                                    control={Input}
                                    label="Id"
                                    placeholder="Название компании"
                                    value={this.state.id}
                                />
                                {/*} <Form.Field control={Input} label='Last name' placeholder='Last name' value = {this.state.name} />*/}
                            </Form.Group>
                                <Form.Field
                                    control={Input}
                                    label="Название"
                                    placeholder="Название компании"
                                    onChange={this.handleInputChange}
                                    name="name"
                                    value={this.state.name}
                                />
                            <Form.Group>
                                <Form.Field
                                    control={FileLoader}
                                    label="Логотип"
                                    companyId={this.state.id}
                                    onChange={this.handleChangeLogo}
                                    value={this.state.logoUrl}
                                />
                            </Form.Group>
                                <Form.Field
                                    control={Input}
                                    label="Телефон"
                                    placeholder="Номер телефона"
                                    onChange={this.handleInputChange}
                                    name="phone"
                                    value={this.state.phone}
                                />
                                <Form.Field
                                    control={Input}
                                    label="address"
                                    placeholder="Номер телефона"
                                    onChange={this.handleInputChange}
                                    name="address"
                                    value={this.state.address}
                                />
                                <Form.Field
                                    control={Input}
                                    label="webSite"
                                    placeholder="Номер телефона"
                                    onChange={this.handleInputChange}
                                    name="webSite"
                                    value={this.state.webSite}
                                />
                                <Form.Field
                                    control={Input}
                                    label="workTime"
                                    placeholder="Номер телефона"
                                    onChange={this.handleInputChange}
                                    name="workTime"
                                    value={this.state.workTime}
                                />
                                <Form.Field control={Checkbox} label='Печатать ссылку на отзыв в предчеке' name="isRequiredFeedback" onChange={this.handleInputChange}
                                checked={this.state.isRequiredFeedback} />
                            <Message
                                error
                                header="Action Forbidden"
                                content={this.props.company.error}
                            />
                            <Message success header="Form Completed" content="Сохранено" />
                            <Form.Field color="green" onClick={this.handleSave} control={Button}>
                                Сохранить
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    
                </Grid.Row>
                {/* <Modal
                    open={this.state.openDeleteShowModal}
                    onClose={this.handleCloseopenDeleteModal}
                    >
                    <Modal.Header>Удалить Пользователя ?</Modal.Header>
                    <Modal.Content >
                        
                        Уверены,что хотите удалить пользователя?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.handleCloseopenDeleteModal}  >Нет</Button>
                        <Button positive onClick={this.handleCloseopenDeleteModal} labelPosition='right' icon='checkmark' content='Да' />
                    </Modal.Actions>
                </Modal>*/}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: state.companyEdit,
        roleCompanyStore: state.roleCompanyStore,
    };
}

export const CompanyEditContainer = connect(mapStateToProps, {
    ...actionCreators,
    initRoleCompanyList,
})(EditCompany);
