import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == constants.clearStore) {
        state = {
            list: [],
            isLoading: false,
            isShouldReload: false,
        };
    }
    switch (action.type) {
        case constants.listLoadStart:
            return { ...state, ...{ isLoading: true } };
        case "NOTIFICATION_UPDATE":
        case constants.listLoadSuccess:
            return {
                ...state,
                ...{
                    list: Array.from(action.data, (x) => {
                        return { ...x, date: new Date(x.date) };
                    }).sort(compare),
                    isLoading: false,
                },
            };
        case constants.listLoadFail:
            return { ...state, ...{ isLoading: true, error: action.error } };

        case constants.itemDeleteStart:
            return { ...state, ...{ isLoading: true, isShouldReload: false } };
        case constants.itemDeleteSuccess:
            return { ...state, ...{ isLoading: false, isShouldReload: true } };
        case constants.itemDeleteFail:
            return { ...state, ...{ isLoading: true, error: action.error } };

        default:
            return state;
    }
}

function compare(a, b) {
    const bandA = a.date;
    const bandB = b.date;

    let comparison = 0;
    if (bandA > bandB) {
        comparison = 1;
    } else if (bandA < bandB) {
        comparison = -1;
    }
    return -comparison;
}
