import * as config from "Config";
import * as constants from "./constants";

export function clearStore(newObject) {
  return {
    type: constants.CATEGORY_LIST_CLEAR_STORE,
  };
}
export function initListDataSummury(params) {
  let url = config.apiUrl + "/api/Category";
  if (params.companyId) {
    url += "/Company/" + params.companyId + "/Summury";
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId + "/Summury";
  }
  return {
    remote: {
      method: "get",
      url,
    },
    actions: [
      constants.CATEGORY_LIST_SUMMURY_LOAD_START,
      constants.CATEGORY_LIST_SUMMURY_LOAD_SUCCESS,
      constants.CATEGORY_LIST_SUMMURY_LOAD_FAIL,
    ],
  };
}
export function initListData(params) {
  let url = config.apiUrl + "/api/Category";
  if (params.companyId) {
    url += "/Company/" + params.companyId ;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId ;
  }
  return {
    remote: {
      method: "get",
      url
    },
    actions: ["CATEGORY_LIST_LOAD_START", "CATEGORY_LIST_LOAD_SUCCESS", "CATEGORY_LIST_LOAD_FAIL"],
  };
}

export function deleteCategory(params) {
  let url = config.apiUrl + "/api/Category/" + params.id;
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }

  return {
    remote: {
      method: "delete",
      url: url,
    },

    actions: [
      constants.CATEGORY_LIST_REMOVE_START,
      constants.CATEGORY_LIST_REMOVE_SUCCESS,
      constants.CATEGORY_LIST_REMOVE_FAIL,
    ],
  };
}
