import React, { Component } from "react";
import { Grid, Button, Icon, Accordion, Label } from "semantic-ui-react";

export class Tree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAccordion: [],
            heightForTree: window.innerHeight - 210
        };
        this.clickAccordion = this.clickAccordion.bind(this);
        this.renderNode = this.renderNode.bind(this);
        this.getTreeForItems = this.getTreeForItems.bind(this);
    }

    clickAccordion(event, props) {
        console.log(props);
        let openAccordion = Array.from(this.state.openAccordion);

        if (openAccordion.includes(props.index)) {
            openAccordion = openAccordion.filter(x => x != props.index);
        } else {
            openAccordion.push(props.index);
        }
        this.setState({
            openAccordion
        })
    }

    getTreeForItems() {
        let companies = this.props.categoryList;

        let res = Array.from(companies, (x) => {
            let items = this.props.itemList.filter(
                (elem) => Array.isArray(elem.categoriesId) && elem.categoriesId.includes(x.id)
            );

            let c = 0;
            for (let i = 0; i < items.length; i++) {
                c = this.props.orderPosition.filter(y => y.id == items[i].id && y.categoryId == x.id)
                items[i].count = c.length > 0 ? c[0].count : 0;
            }


            return { id: x.id, name: x.fullName, parentId: x.parentId, items };
        }
        );

        let itemsWithoutCategory = this.props.itemList.filter(
            (elem) => !Array.isArray(elem.categoriesId)
        );
        res.push(
            { id: -1, name: "-", items: itemsWithoutCategory }
        )

        return res;
    }

    renderNode(data, filterId) {
        return data.filter(x => x.parentId === filterId).map((category, index) =>
            [
                <Accordion.Title key={category.id + ".1"} index={category.id} onClick={this.clickAccordion}>
                    <Icon name='dropdown' />
                    {category.name}
                </Accordion.Title>,
                <Accordion.Content active={this.state.openAccordion.includes(category.id)} key={category.id + ".2"}>
                    <Grid columns={5}>
                        <Accordion.Accordion style={{ marginTop: "2px", paddingLeft: "30px", width: "calc(100% - 30px)", paddingTop: '5px', paddingBottom: '5px' }} >
                            {this.renderNode(data, category.id)}
                        </Accordion.Accordion>
                        {category.items.map(item =>
                            <Grid.Row key={category.id + "." + item.id} >
                                <Grid.Column width={1} textAlign="right">
                                    <Icon name="ellipsis vertical" color="green" />
                                </Grid.Column>
                                <Grid.Column width={2} textAlign="right">
                                    <Button
                                        data-id={item.id}
                                        data-category-id={category.id}
                                        onClick={this.props.handleRemovePositionFromOrder} size="tiny" color="red" icon="minus" />
                                </Grid.Column>
                                <Grid.Column textAlign="left">
                                    {item.name}
                                </Grid.Column>
                                <Grid.Column>
                                    {item.price} р.
                           </Grid.Column>
                                <Grid.Column>
                                    {
                                        item.count > 0 ?
                                            <Label color="brown" circular><strong>{item.count}</strong></Label>
                                            :
                                            0
                                    }
                                </Grid.Column>
                                <Grid.Column width={1}>

                                    <Button
                                        data-id={item.id}
                                        data-category-id={category.id}
                                        onClick={this.props.handleAddItemToOrder} size="tiny" color="green" icon="plus" />
                                </Grid.Column>
                            </Grid.Row>
                        )
                        }
                    </Grid>
                </Accordion.Content>
            ]
        );
    }
    render() {
        return <Accordion exclusive={false} size="huge" style={{
            height: this.state.heightForTree + "px",
            width: "calc(100% - 30px)",
            overflowY: "scroll",
            overflowX: "hidden"
        }}>
            {this.renderNode(this.getTreeForItems(), null)}


        </Accordion>
    }
}
export default Tree;