import * as config from "Config";

export function initCompanyListData() {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Company"
      },
      actions:["COMPANY_LIST_LOAD_START","COMPANY_LIST_LOAD_SUCCESS","COMPANY_LIST_LOAD_FAIL"]
    };
}

export function sendMetric(params){
    return {
        remote: {
          method: "post",
          url: config.apiUrl + "/api/Metrics",
          data: { url: params.url, "application": "client", applicationVersion: config.appVersion, applicationName: config.appName }
        },
        actions: ["", "", ""]
      }
}