import { browserHistory } from "react-router";
import * as constant from "./constants";

import * as utils from "@hitek-crm-utils";

export default function (state, action) {
    if (!state || action.type == "REPORT_CLEAR_STORE") {
        state = {
            data: [],
            paymentByDate: [],
            paymentMethod: [],
            generalReport: {},
            vigodaReport: [],

            isLoading: false,
            error: undefined,
        };
    }
    //""REPORT_BY_DAY_START","REPORT_BY_DAY_SUCCESS","REPORT_BY_DAY_FAIL"
    switch (action.type) {
        case "REPORT_BY_DAY_START":
            return { ...state, ...{ data: [], isLoading: true, error: undefined } };
        case "REPORT_BY_DAY_SUCCESS":
            return {
                ...state,
                ...{
                    data: Array.from(action.data, (x) => {
                        return { ...x, date: new Date(x.year, x.month - 1, x.day) };
                    }).sort((a, b) => {
                        return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                    }),
                    isLoading: false,
                },
            };
        case "REPORT_BY_DAY_FAIL":
            return {
                ...state,
                ...{ data: [], isLoading: false, error: action.error },
            };
        case constant.REPORT_VIGODA_START:
            return { ...state, ...{ data: [], isLoading: true, error: undefined } };
        case constant.REPORT_VIGODA_SUCCESS:
            return {
                ...state,
                ...{
                    data: Array.from(action.data, (x) => {
                        return { ...x, date: new Date(x.date) };
                    }).sort((a, b) => {
                        return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                    }),
                    isLoading: false,
                },
            };
        case constant.REPORT_VIGODA_FAIL:
            return {
                ...state,
                ...{ data: [], isLoading: false, error: action.error },
            };
        case constant.REPORT_PAYMENT_BY_DAY_START:
            return {
                ...state,
                ...{ paymentByDate: [], isLoading: true, error: undefined },
            };
        case constant.REPORT_PAYMENT_BY_DAY_SUCCESS:
            let res = [];
            let paymentMethod = [];
            Array.from(action.data, (x) => {
                return { ...x, date: new Date(x.year, x.month - 1, x.day) };
            }).forEach((x) => {
                let v = res.find((y) => y._id === "d" + x.year + " " + (x.month - 1) + " " + x.day);
                if (!v) {
                    res.push({
                        _id: "d" + x.year + " " + (x.month - 1) + " " + x.day,
                        date: x.date,
                        day: x.day,
                        year: x.year,
                        month: x.month,
                        [x.paymentMethodId + "amount"]: utils.toCorrectFloat(x.amount),
                        amount: utils.toCorrectFloat(x.amount),
                    });
                } else {
                    if (!v[x.paymentMethodId + "amount"]) {
                        v[x.paymentMethodId + "amount"] = 0;
                    }
                    v[x.paymentMethodId + "amount"] = utils.toCorrectFloat(
                        v[x.paymentMethodId + "amount"] + x.amount
                    );
                    v.amount = utils.toCorrectFloat(v.amount + x.amount);
                }
                if (!paymentMethod.find((y) => y.id == x.paymentMethodId)) {
                    paymentMethod.push({
                        id: x.paymentMethodId,
                        name: x.paymentMethodName,
                    });
                }
            });
            res.sort((a, b) => {
                return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
            });
            return {
                ...state,
                ...{
                    paymentByDate: res,
                    paymentMethod: paymentMethod,
                    isLoading: false,
                },
            };
        case constant.REPORT_PAYMENT_BY_DAY_FAIL:
            return {
                ...state,
                ...{ paymentByDate: [], isLoading: false, error: action.error },
            };

        case constant.REPORT_GENERAL_DOWNLOAD_START:
            return {
                ...state,
                ...{ generalReport: {}, isLoading: true, error: undefined },
            };
        case constant.REPORT_GENERAL_DOWNLOAD_SUCCESS:
            return {
                ...state,
                ...{
                    generalReport: { fileBlob: action.data },
                },
            };
        case constant.REPORT_GENERAL_DOWNLOAD_SUCCESS:
            return {
                ...state,
                ...{ isLoading: false, error: action.error },
            };



            case constant.REPORT_LOAD_START:
            return { ...state, ...{ data: [], isLoading: true, error: undefined } };
        case constant.REPORT_LOAD_SUCCESS:
            return {
                ...state,
                ...{
                    data: Array.from(action.data, (x) => {
                        return { ...x, date: new Date(x.date) };
                    }).sort((a, b) => {
                        return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                    }),
                    isLoading: false,
                },
            };
        case constant.REPORT_LOAD_FAIL:
            return {
                ...state,
                ...{ data: [], isLoading: false, error: action.error },
            };
        default:
            return state;
    }
}
