import React, { Component } from "react";
import { Grid, Button, Form, Input, Label, Icon } from "semantic-ui-react";
import * as utils from "@hitek-crm-utils";

const defaultModification = {
    id: 0,
    modificatorId: 0,
    categoryModificatorId: -1,
    portion: 0,
    price: -1,
};
export class ModificatorKarta extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleAdd = this.handleAdd.bind(this);

        this.getModificatorOptions = this.getModificatorOptions.bind(this);
        this.getCategoryOptions = this.getCategoryOptions.bind(this);
    }

    handleInputChange(event, target) {
        //event.preventDefault();
        //const target = event.target;
        // debugger;

        let value = Array.from(this.props.value); //[];
        let id = target["data-id"];
        let v = value.find((x) => x.id == id) || {};
        const name = target.name;

        //v[name] = target.value;
        let targetValue = target.value;

        if (target.type == "int") {
            targetValue = parseInt(targetValue);
        }
        if (target.type == "float") {
            targetValue = utils.toCorrectFloat(parseFloat(targetValue), 5);
            if (target.value.length > 1 && target.value[target.value.length - 1] == ".") {
                targetValue += ".";
            }
        }
        v[name] = targetValue;

        this.props.onChange(value);
    }

    handleRemove(e, target) {
        let id = target["data-id"];
        let value = [];
        for (let i = 0; i < this.props.value.length; i++) {
            if (this.props.value[i].stockItemId === id) continue;
            value.push(this.props.value[i]);
        }

        this.props.onChange(value);
    }
    handleAdd(e, target) {
        // if (!this.props.value.find((x) => x.stockItemId === 0)) {
        //  let id = target["data-id"];
        let value = Array.from(this.props.value);

        value.push({ ...defaultModification, id: -(value.length + Math.random() + 1) });
        this.props.onChange(value);
        //}
    }

    getModificatorOptions(categoryiId) {
        // debugger

        if (!categoryiId || !this.props.items) {
            return [];
        }

        let v = Array.from(this.props.value, (x) => x.modificatorId);
        const itemlist = this.props.items.filter(
            (x) =>
                x.id != this.props.currentItemId &&
                (!categoryiId ||
                    (Array.isArray(x.categoriesId) && x.categoriesId.includes(categoryiId)))
        );
        
        const res = Array.from(itemlist, (x) => {
            return { text: x.name, value: x.id, disabled: v.includes(x.id) };
        });
        res.unshift({ text: "**** Все *****", value: "0", disabled: false });
        return res;
    }

    getCategoryOptions() {
        if (!this.props.categories) {
            return [];
        }

        return Array.from(this.props.categories, (x) => {
            return { text: x.name, value: x.id };
        });
    }
    render() {
        console.log(this.props.value);
        return (
            <Grid style={{ marginBottom: "15px" }}>
                <Grid.Row>
                    <Grid.Column>
                        <Button color="green" fluid onClick={this.handleAdd}>
                            Добавить Модификатор
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                {this.props.value.map((item) => (
                    <Grid.Row key={item.id} columns={"4"} style={{ padding: "3px" }}>
                        <Grid.Column width={"4"}>
                            <Form.Select
                                data-id={item.id}
                                //loading={this.props.company.isUserListLoading}
                                placeholder="Категория"
                                label="Категория"
                                fluid
                                selection
                                value={item.categoryModificatorId}
                                name="categoryModificatorId"
                                search
                                onChange={this.handleInputChange}
                                options={this.getCategoryOptions()}
                            />
                        </Grid.Column>
                        <Grid.Column width={"4"}>
                            <Form.Select
                                data-id={item.id}
                                //loading={this.props.company.isUserListLoading}
                                placeholder="Модификатор"
                                label="Модификатор"
                                fluid
                                selection
                                value={item.modificatorId}
                                name="modificatorId"
                                search
                                onChange={this.handleInputChange}
                                options={this.getModificatorOptions(item.categoryModificatorId)}
                            />
                        </Grid.Column>
                        <Grid.Column width={"3"}>
                            <Form.Field
                                data-id={item.id}
                                control={Input}
                                placeholder="Порция"
                                label="Порция"
                                labelPosition="right"
                                name="portion"
                                type="float"
                                value={item.portion}
                                onChange={this.handleInputChange}></Form.Field>
                        </Grid.Column>
                        <Grid.Column width={"3"}>
                            <Form.Field
                                data-id={item.id}
                                control={Input}
                                placeholder="Стоимость"
                                label="Стоимость"
                                labelPosition="right"
                                name="price"
                                type="float"
                                value={item.price}
                                onChange={this.handleInputChange}>
                                <input />
                                <Label>р.</Label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={"2"}>
                            <Form.Field
                                label="Удалить"
                                data-id={item.stockItemId}
                                color="red"
                                onClick={this.handleRemove}
                                control={Button}>
                                <Icon name="remove" />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                ))}
            </Grid>
        );
    }
}
