import {browserHistory } from 'react-router';

export default function(state ,action){

    if ( !state  || action.type=='OPERATOR_PHONE_CALL_LIST_CLEAR_STORE'){

        state =  {
            listSummury: [],
            isLoading : false,
            isLoaded: false
        };

    }
//OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_START","OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_SUCCESS","OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_FAIL
    switch(action.type){
        case "OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_START":
            return {...state, ...{listSummury: [], isLoading:true, error:undefined}};
        case "OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_SUCCESS":
            return { ...state, ...{listSummury: Array.from(action.data, x=> {return {...x,startRing:new Date(x.startRing)};}).sort((a, b) => {
                return a.startRing < b.startRing ? -1 : a.startRing > b.startRing ? 1 : 0;
              }), isLoading:false}} ;
        case "OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        
        default:
            return state;
    }
}