import React, { useState, useEffect, useCallback } from "react";
import { Grid, Header, Button, Divider, Form, Input, Select, Message } from "semantic-ui-react";
import * as actionCreators from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import * as utils from "@hitek-crm-utils";

const defaultFormState = {
    name: "",
    financeSourceId: "",
    externalPaymentMethodId:"",
    order: 128,
};

function loadData(dispatch, state) {
    if (state.paymentMethodId) {
        dispatch(
            actionCreators.getDetailPaymentMethod({
                companyId: state.companyId,
                id: state.paymentMethodId,
            })
        );
    }
    dispatch(
        actionCreators.getFinanceSourceList({
            companyId: state.companyId,
        })
    );
    dispatch(
        actionCreators.getExternalPaymentMethodList({
            companyId: state.companyId,
        })
    );
}

export function PaymentMethodAddEdit() {
    const { paymentMethodId, companyId } = useParams();
    const [formState, setFormState] = useState(defaultFormState);
    const dispatch = useDispatch();
    const history = useHistory();

    const { paymentMethodStore } = useSelector((state) => {
        return {
            paymentMethodStore: state.paymentMethodList,
        };
    });

    useEffect(() => {
        loadData(dispatch, { companyId, paymentMethodId });
        if (!paymentMethodId) {
            setFormState(defaultFormState);
        }
        return () => {
            dispatch(actionCreators.clearStore({ onlyItem: true }));
        };
    }, [companyId, paymentMethodId]);

    useEffect(() => {
        const data = { ...defaultFormState, ...paymentMethodStore.item };
        setFormState(data);
    }, [paymentMethodStore.item]);

    useEffect(() => {
        if (paymentMethodStore.isSaved) {
            if (paymentMethodId) {
                history.push(utils.getRoute(location.pathname, 2));
            } else {
                history.push(utils.getRoute(location.pathname, 1));
            }
        }
    }, [paymentMethodStore.isSaved, paymentMethodId]);

    const handleInputChange = useCallback((event, target) => {
        //event.preventDefault();

        let value = undefined;
        switch (target.type) {
            case "checkbox":
                value = target.checked;
                break;
            case "select-multiple":
                value = Array.from(target.selectedOptions, (x) => x.value);
                break;
            default:
                value = target.value;
        }
        const name = target.name;

        setFormState((old) => {
            return { ...old, [name]: value };
        });
    }, []);

    const handleSave = useCallback(
        (e) => {
            e.preventDefault();
            const data = { ...formState, companyId, paymentMethodId };
            if (!paymentMethodId) {
                dispatch(actionCreators.saveNewItem(data));
            } else {
                dispatch(actionCreators.saveItem(data));
            }
        },
        [companyId, paymentMethodId, formState]
    );

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="huge">Редактирвоание Способа оплаты #</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Form
                        success={paymentMethodStore.isSave}
                        error={!!paymentMethodStore.error}
                        loading={paymentMethodStore.isLoading}>
                        <Form.Field
                            disabled
                            control={Input}
                            label="Id"
                            placeholder="Id способа оплаты"
                            value={formState.id || ""}
                        />
                        <Form.Field
                            control={Input}
                            label="Название"
                            placeholder="Название способа оплаты"
                            onChange={handleInputChange}
                            value={formState.name}
                            name="name"
                        />
                        <Form.Field
                            control={Input}
                            label="Позиция в списке"
                            placeholder="Позиция в списке"
                            onChange={handleInputChange}
                            value={formState.order}
                            name="order"
                        />
                        <Form.Field
                            control={Select}
                            label="Связаный источник финансиорования"
                            placeholder="Связаный источник финансиорования"
                            onChange={handleInputChange}
                            name="financeSourceId"
                            options={Array.from(paymentMethodStore.financeSource, (x) => {
                                return { text: x.name, value: x.id };
                            })}
                            clearable
                            value={formState.financeSourceId}
                        />
                        <Form.Field
                            control={Select}
                            label="Связаный внешние споосбы оплаты"
                            placeholder="Связаный внешние споосбы оплаты"
                            onChange={handleInputChange}
                            name="externalPaymentMethodId"
                            options={Array.from(paymentMethodStore.externalPaymentMethod, (x) => {
                                return { text: x.name, value: x.id };
                            })}
                            clearable
                            value={formState.externalPaymentMethodId}
                        />
                        <Message error header="Ошибка" content={paymentMethodStore.error} />
                        <Form.Field color="green" onClick={handleSave} control={Button}>
                            Сохранить
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
