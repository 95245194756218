import * as config from "Config";



export function LogOut() {
    return {
      remote:{
          method: "post",
          url: config.apiUrl + "/token/out"
      },
      actions:["LOG_OUT_START","LOG_OUT","LOG_OUT"]
    };
}
