import {browserHistory } from 'react-router';

export default function(state ,action){
    if ( !state  || action.type=='ORDER_STATUS_LIST_CLEAR_STORE'){

        let oldOrderStatusList = undefined;
        if( !!state ){
            oldOrderStatusList = state.orderStatusList;
        }

        state =  {
            name: "Тест",

            orderStatusListSummury: [],
            orderStatusList: oldOrderStatusList || [],
            userList: [],
            isLoading : false,
            isSave: false,
            isLoaded: false
        };

    }
//ORDER_STATUS_LIST_SUMMURY_LOAD_START","ORDER_STATUS_LIST_SUMMURY_LOAD_SUCCESS","ORDER_STATUS_LIST_SUMMURY_LOAD_FAIL
//ORDER_STATUS_LIST_LOAD_START","ORDER_STATUS_LIST_LOAD_SUCCESS","ORDER_STATUS_LIST_LOAD_FAIL
//"ORDER_STATUS_LIST_REMOVE_START","ORDER_STATUS_LIST_REMOVE_SUCCESS","ORDER_STATUS_LIST_REMOVE_FAIL"
    switch(action.type){
  /*      case "COMPANY_NEW_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "COMPANY_NEW_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "COMPANY_NEW_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
        */case "ORDER_STATUS_LIST_SUMMURY_LOAD_START":
            return {...state, ...{orderStatusListSummury: [], isLoading:true, error:undefined}};
        case "ORDER_STATUS_LIST_SUMMURY_LOAD_SUCCESS":
            return { ...state, ...{orderStatusListSummury: action.data, isLoading:false}} ;
        case "ORDER_STATUS_LIST_SUMMURY_LOAD_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        case "ORDER_STATUS_LIST_LOAD_START":
            return {...state, ...{orderStatusList: [], isLoading:true, error:undefined}};
        case "ORDER_STATUS_LIST_LOAD_SUCCESS":
            return { ...state, ...{orderStatusList: action.data, isLoading:false}} ;
        case "ORDER_STATUS_LIST_LOAD_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        case "ORDER_STATUS_LIST_REMOVE_START":
            return {...state, ...{isLoading:true}};
        case "ORDER_STATUS_LIST_REMOVE_SUCCESS":
            return { ...state, ...{isLoading:false,isShouldReload:true}} ;
        case "ORDER_STATUS_LIST_REMOVE_FAIL":
            return {...state, ...{isLoading:true,error:action.error}};
        default:
            return state;
    }
}