import * as constants from "./constants";

export default function (state, action) {
  if (!state || action.type == constants.clearStore) {
    let oldlist = undefined;
    let oldIpdateDate = undefined;
    if (!!state) {
      oldlist = state.list;
      oldIpdateDate = state.updateDate;
    }
    state = {
      list: oldlist || {},
      updateDate: oldIpdateDate,
      isLoading: false,
      isSave: false,
      isSaved: false,
      isLoaded: false,
      isRequreReload: false,
    };
  }
  switch (action.type) {
    case constants.listLoadStart:
      return {
        ...state,
        ...{ list: {}, isLoading: true, error: undefined },
      };
    case constants.listLoadSuccess:
      return {
        ...state,
        ...{
          list: action.data,
          updateDate:new Date(),
          isLoading: false,
        },
      };
    case constants.listLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.itemSaveStart:
      return {
        ...state,
        ...{ isSaved: false, isLoading: true, error: undefined },
      };
    case constants.itemSaveSuccess:
      return {
        ...state,
        ...{
          isSaved: true,
          isLoading: false,
        },
      };
    case constants.itemSaveFail:
      return {
        ...state,
        ...{ isSaved: false, isLoading: false, error: action.error },
      };

    default:
      return state;
  }
}
