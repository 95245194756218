import { browserHistory } from "react-router";

export default function (state, action) {
  if (!state || action.type == "ACCOUNT_CLEAR_STORE") {
    state = {
      data: [],
      isLoading: false,
    };
  }
  //"ACCOUNT_LIST_LOAD_START","ACCOUNT_LIST_LOAD_SUCCESS","ACCOUNT_LIST_LOAD_FAIL
  switch (action.type) {
    case "ACCOUNT_LIST_LOAD_START":
      return { ...state, ...{ isLoading: true } };
    case "ACCOUNT_LIST_LOAD_SUCCESS":
      return {
        ...state,
        ...{
          data: Array.from(action.data, (x) => {
            return {
              ...x,
              lastConnect: !!x.lastConnect ? new Date(x.lastConnect) : null,
            };
          }).sort(compare),
          isLoading: false,
        },
      };
    case "ACCOUNT_LIST_LOAD_FAIL":
      return { ...state, ...{ isLoading: true, error: action.error } };
    case "ACCOUNT_ITEM_REMOVE_START":
      return { ...state, ...{ isLoading: true } };
    case "ACCOUNT_ITEM_REMOVE_SUCCESS":
      return { ...state, ...{ isLoading: false, isShouldReload: true } };
    case "ACCOUNT_ITEM_REMOVE_FAIL":
      return { ...state, ...{ isLoading: true, error: action.error } };
    default:
      return state;
  }
}

function compare(a, b) {
    const bandA = a.lastConnect;
    const bandB = b.lastConnect;
  
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return -comparison;
  }
