import * as constants from "./constants";
export default function (state, action) {
  if (!state || action.type == constants.ITEM_LIST_CLEAR_STORE) {
    let oldItemList = undefined;
    if (!!state) {
      oldItemList = state.itemList;
    }

    state = {
      name: "Тест",

      itemListSummury: [],
      itemList: oldItemList || [],
      itemListEstimateCost:[],
      userList: [],
      isLoading: false,
      isSave: false,
      isLoaded: false,
    };
  }
  switch (action.type) {
    case constants.ITEM_LIST_SUMMURY_LOAD_START:
      return {
        ...state,
        ...{ itemListSummury: [], isShouldReload: false, isLoading: true, error: undefined },
      };
    case constants.ITEM_LIST_SUMMURY_LOAD_SUCCESS:
      return {
        ...state,
        ...{
          itemListSummury: action.data.sort((a, b) => a.name.localeCompare(b.name)),
          isLoading: false,
        },
      };
    case constants.ITEM_LIST_SUMMURY_LOAD_FAIL:
      return { ...state, ...{ isLoading: false, error: action.error } };
      case constants.ITEM_LIST_ESTIMATE_COST_LOAD_START:
        return {
          ...state,
          ...{ itemListEstimateCost: [], isShouldReload: false, isLoading: true, error: undefined },
        };
      case constants.ITEM_LIST_ESTIMATE_COST_LOAD_SUCCESS:
        return {
          ...state,
          ...{
            itemListEstimateCost: action.data,
            isLoading: false,
          },
        };
      case constants.ITEM_LIST_ESTIMATE_COST_LOAD_FAIL:
        return { ...state, ...{ isLoading: false, error: action.error } };

        
    case constants.ITEM_LIST_LOAD_START:
      return {
        ...state,
        ...{ itemList: [], isShouldReload: false, isLoading: true, error: undefined },
      };
    case constants.ITEM_LIST_LOAD_SUCCESS:
      return {
        ...state,
        ...{ itemList: action.data.sort((a, b) => a.name.localeCompare(b.name)), isLoading: false },
      };
    case constants.ITEM_LIST_LOAD_FAIL:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.ITEM_LIST_REMOVE_START:
      return { ...state, ...{ isLoading: true } };
    case constants.ITEM_LIST_REMOVE_SUCCESS:
      return { ...state, ...{ isLoading: false, isShouldReload: true } };
    case constants.ITEM_LIST_REMOVE_FAIL:
      return { ...state, ...{ isLoading: true, error: action.error } };
    default:
      return state;
  }
}
