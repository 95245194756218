import React, { Component } from 'react';
import { Grid, Header, Divider, Table, Button, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as utils from '../../utils'
import * as actionCreators from "./actions";

export class PaymentMethodList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteShowModal: false,
            currentCompanyId: this.props.match.params.companyId,
            idForRemove: null

        };

        this.props.clearStore();       
        this.props.initListData({ companyId: this.state.currentCompanyId });
        this.props.getFinanceSourceList({ companyId: this.state.currentCompanyId });
        this.props.getExternalPaymentMethodList({ companyId: this.state.currentCompanyId });

        
        this.handleCloseopenDeleteModal = this.handleCloseopenDeleteModal.bind(this);
        // Operations usually carried out in componentWillMount go here
    }


    getDataForTable() {
        return this.props.paymentMethod.paymentMethodListSummury;

    }
    componentWillReceiveProps(nextProps) {

        if (this.props.paymentMethod.isShouldReload !== nextProps.paymentMethod.isShouldReload && nextProps.paymentMethod.isShouldReload) {
            this.props.clearStore();
            this.props.initListData({ companyId: this.state.currentCompanyId });

        }
        if (this.props.match.params.companyId != nextProps.match.params.companyId) {

            this.setState({
                currentCompanyId: nextProps.match.params.companyId
            })
            this.props.initListData({ companyId: nextProps.match.params.companyId });
        }
    }

    handleCloseopenDeleteModal(e, button) {
        this.setState({ openDeleteShowModal: false })
        if (button.positive) {
            this.props.deletePaymentMethod(
                {
                    id: this.state.idForRemove,
                    companyId: this.state.currentCompanyId
                }
            );
        }
    }
    onRemovePaymentMethod(id) {
        console.log(id);
        this.setState({ openDeleteShowModal: true, idForRemove: id })
    }

    getItemById(id) {
        if (!this.props.paymentMethod || !id) {
            return {};
        }
        return this.props.paymentMethod.paymentMethodListSummury.find(x => x.id == id) || {};
    }

    render() {
        return <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='huge'>Список Способов оплаты</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Grid.Row>
                        <Button color="green" as={Link} to={"/Company/" + this.state.currentCompanyId + "/PaymentMethod/New"}>Добавить новый способ оплаты</Button>
                    </Grid.Row>
                    <Table >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Название</Table.HeaderCell>
                                <Table.HeaderCell>Финансовый источник</Table.HeaderCell>
                                <Table.HeaderCell>Внешняя система оплаты</Table.HeaderCell>
                                <Table.HeaderCell>Позиция в списке</Table.HeaderCell>
                                <Table.HeaderCell>Действия</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {this.getDataForTable().map((status) => {
                                return <Table.Row key={status.id}>
                                    <Table.Cell>
                                        {status.id}
                                    </Table.Cell>
                                    <Table.Cell>{status.name}</Table.Cell>
                                    <Table.Cell>{(this.props.paymentMethod.financeSource.find(x => x.id == status.financeSourceId)||{name:"-"}).name}</Table.Cell>
                                    <Table.Cell>{(this.props.paymentMethod.externalPaymentMethod.find(x => x.id == status.externalPaymentMethodId)||{name:"-"}).name}</Table.Cell>
                                    <Table.Cell>{status.order}</Table.Cell>
                                    <Table.Cell >
                                        {/*<Button as={Link} to = {"/Company/"+this.state.currentCompanyId + "/PaymentMethod/"+status.id } icon='search' content='Перейти' />      */}
                                        <Button as={Link} to={"/Company/" + this.state.currentCompanyId + "/PaymentMethod/" + status.id + "/Edit"} icon='edit' content='Редактировать' />
                                        <Button onClick={() => { this.onRemovePaymentMethod(status.id) }} icon='remove' color="red" content='Удалить' />
                                    </Table.Cell>
                                </Table.Row>
                            })
                            }
                        </Table.Body>
                    </Table>
                    <Modal
                        open={this.state.openDeleteShowModal}
                        onClose={this.handleCloseopenDeleteModal}
                    >
                        <Modal.Header>Удалить Способ оплаты "{this.getItemById(this.state.idForRemove).name}" </Modal.Header>
                        <Modal.Content >
                            Уверены что хотите удалить данную способ оплаты?
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseopenDeleteModal}  >Нет</Button>
                            <Button positive onClick={this.handleCloseopenDeleteModal} labelPosition='right' icon='checkmark' content='Да' />
                        </Modal.Actions>
                    </Modal>

                </Grid.Column>
            </Grid.Row>
        </Grid>;

    }
};


function mapStateToProps(state) {

    return {

        paymentMethod: state.paymentMethodList
    };
}


export const PaymentMethodListContainer = connect(mapStateToProps, actionCreators)(PaymentMethodList);
