import React,{ Component } from 'react';
import  {Grid,Header,Divider,Table,Button,Modal} from "semantic-ui-react";
import * as actionCreators from "./actions";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

export class CompanyList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            openDeleteShowModal : false,
            idForRemove: null

        };
        this.props.clearStore();
        this.props.initCompanyListData();
        this.handleCloseopenDeleteModal = this.handleCloseopenDeleteModal.bind(this);

        // Operations usually carried out in componentWillMount go here
    }

    componentWillReceiveProps(nextProps){
        if(!!nextProps.companyList &&  nextProps.companyList.isShouldReload){
            this.props.clearStore();
            this.props.initCompanyListData();
        }
    }

    handleCloseopenDeleteModal(e, button){
        this.setState({openDeleteShowModal:false})
        if(button.positive){
            this.props.deleteCompany(this.state.idForRemove)
        }
    }
    onRemoveCompany(id){
        console.log(id);
        this.setState({openDeleteShowModal:true,idForRemove:id})
    }
    render() {
      return  <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='huge'>Все компании</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider/>
            <Grid.Row>
                <Grid.Column>
                <Button color="green" as={Link}  to="/Company/New">Добавить новую компанию </Button>
                
            <Table >
                 <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>Название</Table.HeaderCell>
                        <Table.HeaderCell>Активность</Table.HeaderCell>
                        <Table.HeaderCell>Главный</Table.HeaderCell>
                        <Table.HeaderCell>Действия</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {!!this.props.companyList.companyListSummury && this.props.companyList.companyListSummury.map((item) => {
                    return <Table.Row key={item.id}>
                        <Table.Cell>
                            {item.id}
                        </Table.Cell>
                        <Table.Cell>{item.name}</Table.Cell>
                        <Table.Cell>{item.isActive ? "Аквтина": "Не Активна"}</Table.Cell>
                        <Table.Cell>{!!item.userName ? item.userName: "-"}</Table.Cell>
                        <Table.Cell>
                            <Button as={Link}  to = {"/Company/"+item.id+"/Operations"} icon='browser' color="orange" content='Операции' />   
                            <Button as={Link}  to = {"/Company/"+item.id} icon='search' content='Перейти' />     
                             <Button as={Link}  to = {"/Company/"+item.id+"/Edit"} icon='edit' color="olive" content='Редактировать' /> 
                             <Button  onClick={()=>{this.onRemoveCompany(item.id)}} icon='remove' color="red" content='Удалить' /> 
                              </Table.Cell>
                    </Table.Row>;
                    })
                    }
                </Table.Body>
                {/*<Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='5'>
                        <Menu floated='right' pagination>
                            <Menu.Item as='a' icon>
                                <Icon name='left chevron' />
                            </Menu.Item>
                            <Menu.Item as='a'>1</Menu.Item>
                            <Menu.Item as='a'>2</Menu.Item>
                            <Menu.Item as='a'>3</Menu.Item>
                            <Menu.Item as='a'>4</Menu.Item>
                            <Menu.Item as='a' icon>
                                <Icon name='right chevron' />
                            </Menu.Item>
                        </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer*/}
            </Table>
             <Modal
                open={this.state.openDeleteShowModal}
                onClose={this.handleCloseopenDeleteModal}
                >
                <Modal.Header>Удалить компанию {this.state.idForRemove}</Modal.Header>
                <Modal.Content >
                   Уверены, что хотетите удалить компанию?
                </Modal.Content>
                 <Modal.Actions>
                    <Button negative onClick={this.handleCloseopenDeleteModal}  >Нет</Button>
                    <Button positive onClick={this.handleCloseopenDeleteModal} labelPosition='right' icon='checkmark' content='Да' />
                </Modal.Actions>
                </Modal>
                </Grid.Column>
            </Grid.Row>
            </Grid>;
    
    }
};

function mapStateToProps(state) {

  return {

      companyList: state.companyList


  };
}


export const CompanyListContainer = connect(mapStateToProps,actionCreators)(CompanyList);
