import {browserHistory } from 'react-router';

export default function(state ,action){
    if ( !state ){
        state =  {
            logOut:false
        };

    }
    switch(action.type){

        case "LOG_OUT_START":
            return {...state, ...{logOut:false}};
        case "LOG_OUT":
            return { ...state, ...{logOut:true}} ;

        default:
            return state;
    }
}