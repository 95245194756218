export const CATEGORY_NEW_SAVE_START = "CATEGORY_NEW_SAVE_START";
export const CATEGORY_NEW_SAVE_SUCCESS = "CATEGORY_NEW_SAVE_SUCCESS";
export const CATEGORY_NEW_SAVE_FAIL = "CATEGORY_NEW_SAVE_FAIL";
export const CATEGORY_CLEAR_STORE = "CATEGORY_CLEAR_STORE";
export const CATEGORY_SAVE_START = "CATEGORY_SAVE_START";
export const CATEGORY_SAVE_SUCCESS = "CATEGORY_SAVE_SUCCESS";
export const CATEGORY_SAVE_FAIL = "CATEGORY_SAVE_FAIL";
export const CATEGORY_DETAIL_START = "CATEGORY_DETAIL_START";
export const CATEGORY_DETAIL_SUCCESS = "CATEGORY_DETAIL_SUCCESS";
export const CATEGORY_DETAIL_FAIL = "CATEGORY_DETAIL_FAIL";

export const CATEGORY_CF_LOAD_START = "CATEGORY_CF_LOAD_START";
export const CATEGORY_CF_LOAD_SUCCESS = "CATEGORY_CF_LOAD_SUCCESS";
export const CATEGORY_CF_LOAD_FAIL = "CATEGORY_CF_LOAD_FAIL";
