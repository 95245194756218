import React from "react";
import { Header } from "semantic-ui-react";

export function HelloWorld() {
  return (
    <div
      style={{
        paddingTop: 40,
        textAlign: "center",
      }}
    >
      <Header as="h1">Hitek Crm</Header>
      <Header as="h3">Система электронного учета заказов</Header>
      <Header as="h3">
        Версия {VERSION_APP_BUILD} , {VERSION_APP_COMMIT}
      </Header>
    </div>
  );
}

export default HelloWorld;
