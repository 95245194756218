import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Header,
  Button,
  Divider,
  Form,
  Input,
  Message,
  Icon,
  Segment,
  Label,
  Image,
  GridRow,
} from "semantic-ui-react";
import * as config from "Config";

import { connect, useDispatch } from "react-redux";
import * as actionCreators from "./actions";
import { initListData as initRoleCompanyList } from "../RoleCompany/actions";
import * as utils from "@hitek-crm-utils";

export function FileLoader({ companyId, onChange, value }) {
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(value);

  const fileInput = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    setPreviewUrl(value);
  }, [value]);

  const handleFile = (file) => {
    //you can carry out any file validations here...
    //setImage(file);
    //setPreviewUrl(URL.createObjectURL(file));

    const formData = new FormData();

    if (file) {
      formData.append("data", file, file.name);

      utils.getFormData(formData, {},undefined);

      dispatch({
        remote: {
          method: "post",
          url: config.apiUrl + "/api/File/Company/" + companyId,
          data: formData,
        },
        actions: [
          "",
          (data) => {
            //setPreviewUrl(data.url);
            onChange({}, { id: data.id, url: data.url });
          },
          "",
        ],
      });
    } else {
      onChange({}, {});
    }
  };

  const handleOndragOver = (event) => {
    event.preventDefault();
  };
  const handleOndrop = (event) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  };
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <div
            className="file-loader-drop_zone"
            onDragOver={handleOndragOver}
            onDrop={handleOndrop}
            onClick={() => fileInput.current.click()}
          >
            <p>Click to select or Drag and drop image here....</p>

            <br />
            <input
              type="file"
              accept="image/*"
              ref={fileInput}
              hidden
              onChange={(e) => handleFile(e.target.files[0])}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          {previewUrl && (
            <Segment padded className="file-loader-image">
              <Label attached="top" color="red" onClick={() => handleFile()} style={{cursor:"pointer"}}>
                Удалить
              </Label>
              <Image src={previewUrl} />
            </Segment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default FileLoader;
