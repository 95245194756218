import React, { Component } from 'react';
import { Grid, Header, Button, Divider, Table, Icon, Label, Popup, Loader,Pagination } from 'semantic-ui-react'
import * as actionCreators from "./actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {ChangeSettingModal} from "../../components/UserSetting/ChangeSettingModal";


import * as utils from "@hitek-crm-utils";
import DatePicker from "@hitek-crm/DatePicker";

const MODULE_NAME = "dashBoardMediumSummury";
const defaultConfig = {
    countPerPage: 25
}
const descriptionConfig={
    countPerPage:{
        description:"Колличество записей на странице",
        type:"int"
    }
}

export class OrderSummuryMedium extends Component {


    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId,
            isLoaded: false,
            id: "",
            name: "",
            userId: null,
            startDate: new Date(new Date().toDateString()),
            isOpenSettinConfigModal:false,
            activPage:1
        }

        let searchParam = new URLSearchParams(this.props.location.search);
        if (searchParam.has("date")) {

            this.state.startDate = utils.dateFromShortString(searchParam.get("date"));
        }

        this.handleChangeDateForReport = this.handleChangeDateForReport.bind(this);
        this.handleClickSettingButton = this.handleClickSettingButton.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);

        this.props.clearStore();
        console.log(this.state.startDate)
        this.loadStore(this.props.match.params.companyId, this.state.startDate.toISOString());
        // Operations usually carried out in componentWillMount go here
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.match.params.companyId != nextProps.match.params.companyId || this.props.location.search != nextProps.location.search) {
            let newp = {
                companyId: nextProps.match.params.companyId,
                isLoaded: false,
                startDate: new Date(new Date().toDateString()),
                activPage: 1
            };
            let searchParam = new URLSearchParams(nextProps.location.search);
            if (searchParam.has("date")) {

                newp.startDate = utils.dateFromShortString(searchParam.get("date"));
            }

            this.setState(newp);

            this.loadStore(nextProps.match.params.companyId, newp.startDate.toISOString());
        }
    }

    loadStore(companyId, startDate) {

        this.props.initOrderListMediumSummury({
            companyId,
            startDate: startDate
        });
        this.props.initOrderStatusList({
            companyId
        });
        this.props.initTagList({
            companyId
        });
        this.props.initCountOrdersByDay({
            companyId
        })
    }

    getCompanyName() {
        let companies = this.props.companies.companyList;
        if (!Array.isArray(companies))
            return null;
        let company = companies.find((x) => x.id == this.state.companyId);
        if (!!company) {
            return company.name;
        }
        return null;
    }

    getOrderStatusByOrderStatusId(orderStatusId) {
        let o = this.props.orderStatusList.orderStatusList.find(x => x.id === orderStatusId);
        if (o) {
            return o.name;
        }
        return orderStatusId;
    }

    getColorOrderStatusByOrderStatusId(orderStatusId) {
        let o = this.props.orderStatusList.orderStatusList.find(x => x.id === orderStatusId);
        if (o) {
            return o.color || undefined;
        }
        return undefined;
    }

    getTagByTagId(tagId) {
        let o = this.props.tagList.tagList.find(x => x.id === tagId);
        if (o) {
            return o.name;
        }
        return tagId;
    }
    getColorTagByTagId(tagId) {
        let o = this.props.tagList.tagList.find(x => x.id === tagId);
        if (o) {
            return o.color || undefined;
        }
        return undefined;
    }

    handlePaginationChange(e, target) {
        this.setState({ activPage: target.activePage });
    }
    handleClickSettingButton(e) {
        this.setState({ isOpenSettinConfigModal: !!e });
    }
    handleChangeDateForReport(date) {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: "date=" + utils.toShortDateString(date)

        }
        );
        this.setState({
            startDate: date
        });
    }
    getAverageSumm() {
        let totalSumm = 0
        let totalOrders = 0;
        if (Array.isArray(this.props.order.orderListMediumSummury)) {

            let iterator = this.props.order.orderListMediumSummury.filter(x => !x.isCanceled)[Symbol.iterator]()
            let obj = iterator.next();
            let value = null;
            while (obj.done !== true) {

                value = obj.value;

                totalSumm += value.amount;
                totalOrders++;
                obj = iterator.next();


            }

        }
        if (totalOrders > 0) {
            return totalSumm / totalOrders;

        }
        else return 0;

    }
    getTotalSumm() {
        let totalSumm = 0
        let totalOrders = 0;
        if (Array.isArray(this.props.order.orderListMediumSummury)) {

            let iterator = this.props.order.orderListMediumSummury.filter(x => !x.isCanceled)[Symbol.iterator]()
            let obj = iterator.next();
            let value = null;
            while (obj.done !== true) {

                value = obj.value;

                totalSumm += value.amount;
                totalOrders++;
                obj = iterator.next();


            }

        }
        if (totalOrders > 0) {
            return totalSumm;

        }
        else return 0;

    }
    getDataForTable() {
        if (!Array.isArray(this.props.order.orderListMediumSummury)) {
            return [];
        }
        return this.props.order.orderListMediumSummury;//.filter(x => this.state.searchOrderStatus.length == 0 || this.state.searchOrderStatus.includes(x.statusId));

    }
    renderLoader() {
        return <Grid.Row   >
            <Grid.Column>
                <Loader active inline='centered' size="big" />
            </Grid.Column>
        </Grid.Row   >;
    }

    renderContent() {

        const activeConfig ={...defaultConfig};

        if(this.props.userSettingStore.list &&  this.props.userSettingStore.list[MODULE_NAME]){
            const moduleSetting = this.props.userSettingStore.list[MODULE_NAME];
            if(moduleSetting["countPerPage"]){
                activeConfig["countPerPage"] = moduleSetting["countPerPage"];
            }
        }

        return <Grid.Row   >
            <Grid.Column>

                
                <Header size='medium'>{this.state.startDate.toLocaleDateString() == (new Date()).toLocaleDateString() ? "Последнии заказы на сегодня" : "Заказы на " + this.state.startDate.toLocaleDateString()}</Header>

                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell >Id</Table.HeaderCell>
                            <Table.HeaderCell>Адрес</Table.HeaderCell>
                            <Table.HeaderCell>Телефон</Table.HeaderCell>
                            <Table.HeaderCell> Комм.</Table.HeaderCell>
                            <Table.HeaderCell>Время заказа</Table.HeaderCell>
                            <Table.HeaderCell>Теги</Table.HeaderCell>
                            <Table.HeaderCell>Статус</Table.HeaderCell>
                            <Table.HeaderCell >Сумма заказа</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                <Button icon="setting" onClick = {this.handleClickSettingButton} />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.getDataForTable().filter((x, index) => index >= (this.state.activPage - 1) * activeConfig.countPerPage && index < this.state.activPage * activeConfig.countPerPage).map(ord => {
                            let t = new Date(ord.date);
                            let tags =  ord.tags;
                            return <Table.Row key={ord.id} negative={ord.isCanceled}>
                                <Table.Cell collapsing>
                                    <Popup
                                        trigger={<Icon name='folder' />}
                                        content={"Полный ID: " + ord.id}
                                        position='top left'
                                    />
                                    {ord.internalId}
                                </Table.Cell>
                                <Table.Cell>{ord.deliveryAddress}</Table.Cell>
                                <Table.Cell>{ord.phone}</Table.Cell>
                                <Table.Cell>{ord.note?.replaceAll('\\n', ' ')}
                                    {(!!ord.externalOrder) && <strong><br />{ord.externalOrder}</strong> }</Table.Cell>
                                <Table.Cell>{t.toLocaleString()}</Table.Cell>
                                <Table.Cell>
                                    {

                                        Array.isArray(tags)
                                        &&
                                        tags.map(x =>
                                            <Label key={x} color={this.getColorTagByTagId(x)}>
                                                {this.getTagByTagId(x)}
                                            </Label>
                                        )
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <Label color={this.getColorOrderStatusByOrderStatusId(ord.statusId)}>
                                        {this.getOrderStatusByOrderStatusId(ord.statusId)}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell textAlign='right'>{utils.toFloatToString(ord.amount)} р.</Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Button as={Link} to={"/Company/" + this.state.companyId + "/Order/" + ord.id} icon='edit' content='Подробно' />
                                </Table.Cell>
                            </Table.Row>
                        }


                        )}

                    </Table.Body>
                    <Table.Footer>
                    <Table.Row>
                            <Table.HeaderCell colSpan='3' textAlign="center">
                                Отображать по <strong>{activeConfig.countPerPage}</strong> строк {" "} 
                                <Button icon="setting" onClick = {this.handleClickSettingButton} />
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='6' textAlign="center">
                                <Pagination
                                    activePage={this.state.activPage}
                                    boundaryRange={2}
                                    onPageChange={this.handlePaginationChange}
                                    size='mini'
                                    siblingRange={2}
                                    totalPages={utils.toCorrectFloat(this.getDataForTable().length / activeConfig.countPerPage + 0.49, 0)}
                                />
                            </Table.HeaderCell>

                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell colSpan='7'>
                                Средний чек
                        </Table.HeaderCell>


                            <Table.HeaderCell textAlign='right'>
                                {utils.toFloatToString(this.getAverageSumm())} р.
                        </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>

                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell colSpan='7'>
                                Общая суммa
                        </Table.HeaderCell>


                            <Table.HeaderCell textAlign='right'>
                                {utils.toFloatToString(this.getTotalSumm())} р.
                        </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Grid.Column>
            <ChangeSettingModal 
                open={this.state.isOpenSettinConfigModal} 
                actionConfig={activeConfig} 
                descriptionConfig={descriptionConfig} 
                moduleName={MODULE_NAME}
                onClose={this.handleClickSettingButton}/>
        </Grid.Row>;
    }
    render() {
        return <Grid>
            <Grid.Row columns={3}>
                <Grid.Column width={10}>
                    <Header size='huge'>Сводка для компании {this.getCompanyName()}</Header>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5} >

                    Фильтр по дате:
                        <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleChangeDateForReport}
                        includeDates={Array.from(this.props.dashBoardState.orderByDay, x => x.date)}

                        className="ui input"
                    />

                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Button color="green" icon="sync alternate" onClick={()=>{this.loadStore(this.props.match.params.companyId, this.state.startDate.toISOString());}}>
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            {this.props.order.isLoading ? this.renderLoader() : this.renderContent()}


        </Grid>;

    }
};

function mapStateToProps(state) {

    return {

        companies: state.companyList,
        category: state.category,
        order: state.order,
        orderStatusList: state.orderStatusList,
        tagList: state.tagList,
        dashBoardState: state.dashBoard,
        userSettingStore: state.userSettingStore
    };
}


export const OrderSummuryMediumContainer = connect(mapStateToProps, actionCreators)(OrderSummuryMedium);
