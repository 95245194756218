import * as config from "Config";


export function clearStore(newObject) {
    return {
      type:"OPERATOR_PHONE_CALL_LIST_CLEAR_STORE"
    };
}

export function initListData(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/OperatorPhoneCall/Company/"+params.companyId + "/Summury",
          data:params
      },
      actions:["OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_START","OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_SUCCESS","OPERATOR_PHONE_CALL_LIST_SUMMURY_LOAD_FAIL"]
    };
}





