import React, { Component } from 'react';
import { Grid, Header, Divider, Table, Button, Modal, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as utils from "@hitek-crm-utils";
import * as actionCreators from "./actions";

export class DiscountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteShowModal: false,
            currentCompanyId: this.props.match.params.companyId,
            idForRemove: null

        };

        this.props.clearStore();
        this.props.initListData({ companyId: this.state.currentCompanyId });
        this.handleCloseopenDeleteModal = this.handleCloseopenDeleteModal.bind(this);
        // Operations usually carried out in componentWillMount go here
    }


    getDataForTable() {
        return this.props.discount.discountListSummury;

    }
    componentWillReceiveProps(nextProps) {

        if (this.props.discount.isShouldReload !== nextProps.discount.isShouldReload && nextProps.discount.isShouldReload) {
            this.props.clearStore();
            this.props.initListData({ companyId: this.state.currentCompanyId });

        }
        if (this.props.match.params.companyId != nextProps.match.params.companyId) {

            this.setState({
                currentCompanyId: nextProps.match.params.companyId
            })
            this.props.initListData({ companyId: nextProps.match.params.companyId });
        }
    }

    handleCloseopenDeleteModal(e, button) {
        this.setState({ openDeleteShowModal: false })
        if (button.positive) {
            this.props.deleteDiscount(
                {
                    id: this.state.idForRemove,
                    companyId: this.state.currentCompanyId
                }
            );
        }
    }
    onRemoveDiscount(id) {
        console.log(id);
        this.setState({ openDeleteShowModal: true, idForRemove: id })
    }

    getItemById(id) {
        if (!this.props.discount || !id) {
            return {};
        }
        return this.props.discount.discountListSummury.find(x => x.id == id) || {};
    }

    render() {
        return <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='huge'>Список Скидок</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Grid.Row>
                        <Button color="green" as={Link} to={"/Company/" + this.state.currentCompanyId + "/Discount/New"}>Добавить новый скидку</Button>
                    </Grid.Row>
                    <Table >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Название</Table.HeaderCell>
                                <Table.HeaderCell>Значение</Table.HeaderCell>
                                <Table.HeaderCell>Действия</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {this.getDataForTable().map((status) => {
                                return <Table.Row key={status.id}>
                                    <Table.Cell>
                                        {status.id}
                                    </Table.Cell>
                                    <Table.Cell>{status.name}</Table.Cell>
                                    <Table.Cell>
                                        {!Array.isArray(status.detail) ? (
                                            status.type == 'fixed' ? utils.toFloatToString(status.value) + " р." : status.value + " " + status.type)
                                            : <Icon name="boxes" />
                                        }

                                    </Table.Cell>
                                    <Table.Cell >
                                        {/*<Button as={Link} to = {"/Company/"+this.state.currentCompanyId + "/Discount/"+status.id } icon='search' content='Перейти' />      */}
                                        <Button as={Link} to={"/Company/" + this.state.currentCompanyId + "/Discount/" + status.id + "/Edit"} icon='edit' content='Редактировать' />
                                        <Button onClick={() => { this.onRemoveDiscount(status.id) }} icon='remove' color="red" content='Удалить' />
                                    </Table.Cell>
                                </Table.Row>
                            })
                            }
                        </Table.Body>
                    </Table>
                    <Modal
                        open={this.state.openDeleteShowModal}
                        onClose={this.handleCloseopenDeleteModal}
                    >
                        <Modal.Header>Удалить Скидку "{this.getItemById(this.state.idForRemove).name}" </Modal.Header>
                        <Modal.Content >
                            Уверены что хотите удалить данную скидку?
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseopenDeleteModal}  >Нет</Button>
                            <Button positive onClick={this.handleCloseopenDeleteModal} labelPosition='right' icon='checkmark' content='Да' />
                        </Modal.Actions>
                    </Modal>

                </Grid.Column>
            </Grid.Row>
        </Grid>;

    }
};


function mapStateToProps(state) {

    return {

        discount: state.discountList
    };
}


export const DiscountListContainer = connect(mapStateToProps, actionCreators)(DiscountList);
