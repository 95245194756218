import * as config from "Config";
import * as constants from './constants';

export function clearStore(newObject) {
    return {
      type:"DASHBOARD_CLEAR_STORE"
    };
}


export function getDashBoardForCompany(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Company/" + params.companyId + "/DashBoard"
      },
      actions:["COMPANY_DASHBOARD_START","COMPANY_DASHBOARD_SUCCESS","COMPANY_DASHBOARD_FAIL"]
    };
}

export function getDashBoardForRealization(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Statistic/Company/" + params.companyId + "/Realization",
          data: params
      },
      actions:["REALIZATION_DASHBOARD_START","REALIZATION_DASHBOARD_SUCCESS","REALIZATION_DASHBOARD_FAIL"]
    };
}

export function initOrderListSummury(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Order/Company/"+params.companyId+"/Summury" ,
          data: params
      },
      actions:["ORDER_LIST_SUMMURY_LOAD_START","ORDER_LIST_SUMMURY_LOAD_SUCCESS","ORDER_LIST_SUMMURY_LOAD_FAIL"]
    };
}

export function initOrderListMediumSummury(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Order/Company/"+params.companyId+"/MediumSummury" ,
          data: params
      },
      actions:["ORDER_LIST_MEDIUM_SUMMURY_LOAD_START","ORDER_LIST_MEDIUM_SUMMURY_LOAD_SUCCESS","ORDER_LIST_MEDIUM_SUMMURY_LOAD_START"]
    };
}

export function initOrderStatusList(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/OrderStatus/Company/"+params.companyId
      },
      actions:["ORDER_STATUS_LIST_LOAD_START","ORDER_STATUS_LIST_LOAD_SUCCESS","ORDER_STATUS_LIST_LOAD_FAIL"]
    };
}

export function initTagList(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Tag/Company/"+params.companyId 
      },
      actions:["TAG_LIST_LOAD_START","TAG_LIST_LOAD_SUCCESS","TAG_LIST_LOAD_FAIL"]
    };
}

export function printCouerSummury(params) {
    var newWin = open();

    let param = params.orders.join("&orderId=")
    if(params.orders.length>0){
        param = "?orderId="+param
    }
    //newWin.document.write("");
    newWin.document.write('<iframe src="'+config.apiUrl+'/PreBill/Company/'+params.companyId+'/Summury'+param+'" style=" width:100%; height:100%; border: none" align="left">Loading</iframe>');
    newWin.focus();

    return {
        type:"none"
      };
}

export function initCountOrdersByDay(params) {
  return {
      remote: {
          method: 'get',
          url: `${config.apiUrl}/api/Order/Company/${params.companyId}/DateOrders`,
          token: true,
      },
      actions: [
          constants.ORDER_COUNT_BY_DAY_LOAD_START,
          constants.ORDER_COUNT_BY_DAY_LOAD_SUCCESS,
          constants.ORDER_COUNT_BY_DAY_LOAD_FAIL,
      ],
  };
}

export function initDashboardFinance(params) {
    return {
        remote: {
            method: 'get',
            url: `${config.apiUrl}/api/Dashboard/Company/${params.companyId}/Finance`,
            data: params,
            token: true,
        },
        actions: [
            constants.dashboardFinanceLoadStart,
            constants.dashboardFinanceLoadSuccess,
            constants.dashboardFinanceLoadFail,
        ],
    };
  }

  export function initDashboardPayment(params) {
    return {
        remote: {
            method: 'get',
            url: `${config.apiUrl}/api/Dashboard/Company/${params.companyId}/Payment`,
          data: params,
            token: true,
        },
        actions: [
            constants.dashboardPaymentLoadStart,
            constants.dashboardPaymentLoadSuccess,
            constants.dashboardPaymentLoadFail,
        ],
    };
}
    export function initDashboardOrder(params) {
        return {
            remote: {
                method: 'get',
                url: `${config.apiUrl}/api/Dashboard/Company/${params.companyId}/Order`,
                data: params,
                token: true,
            },
            actions: [
                constants.dashboardOrderLoadStart,
                constants.dashboardOrderLoadSuccess,
                constants.dashboardOrderLoadFail,
            ],
        };
  }


