export const clearStore = "TRANSFER_LIST_LIST_STORE";
export const clearItemStore = "TRANSFER_LIST_ITEM_CLEAR";

export const listLoadStart = 'TRANSFER_LIST_LIST_LOAD_START';
export const listLoadSuccess = 'TRANSFER_LIST_LIST_LOAD_SUCCESS';
export const listLoadFail = 'TRANSFER_LIST_LIST_LOAD_FAIL';

export const itemSaveStart = 'TRANSFER_LIST_SAVE_START';
export const itemSaveSuccess = 'TRANSFER_LIST_SAVE_SUCCESS';
export const itemSaveFail = 'TRANSFER_LIST_SAVE_FAIL';

export const itemLoadStart = 'TRANSFER_LIST_ITEM_LOAD_START';
export const itemLoadSuccess = 'TRANSFER_LIST_ITEM_LOAD_SUCCESS';
export const itemLoadFail = 'TRANSFER_LIST_ITEM_LOAD_FAIL';

export const itemDeleteStart = 'TRANSFER_LIST_ITEM_DELETE_START';
export const itemDeleteSuccess = 'TRANSFER_LIST_ITEM_DELETE_SUCCESS';
export const itemDeleteFail = 'TRANSFER_LIST_ITEM_DELETE_FAIL';

export const itemApproveStart = 'TRANSFER_LIST_ITEM_APPROVE_START';
export const itemApproveSuccess = 'TRANSFER_LIST_ITEM_APPROVE_SUCCESS';
export const itemApproveFail = 'TRANSFER_LIST_ITEM_APPROVE_FAIL';

export const availableCompanyLoadStart = 'TRANSFER_LIST_AVAILABLE_COMPANY_LOAD_START';
export const availableCompanyLoadSuccess = 'TRANSFER_LIST_AVAILABLE_COMPANY_LOAD_SUCCESS';
export const availableCompanyLoadFail = 'TRANSFER_LIST_AVAILABLE_COMPANY_LOAD_FAIL';

export const initPackingListForStockItemLoadStart = 'TRANSFER_LIST_PL_FOR_StokItem_LOAD_START';
export const initPackingListForStockItemLoadSuccess = 'TRANSFER_LIST_PL_FOR_StokItem_LOAD_SUCCESS';
export const initPackingListForStockItemLoadFail = 'TRANSFER_LIST_PL_FOR_StokItem_LOAD_FAIL';





