import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Header,
  Button,
  Divider,
  Form,
  Input,
  Message,
  Icon,
  TextArea,
} from "semantic-ui-react";
import * as actionCreators from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import * as utils from "@hitek-crm-utils";


const defaultFormState = {
  name: "",
  description: "",
};

function loadData(dispatch, state) {
  if (state.supplierId) {
    dispatch(
      actionCreators.initItemData({
        companyId: state.companyId,
        id: state.supplierId,
      })
    );
  }
  
}

export function SupplierAddEdit() {
  const { companyId, supplierId } = useParams();
  const [formState, setFormState] = useState(defaultFormState);
  const dispatch = useDispatch();
  const history = useHistory();

  const { supplierStore } = useSelector((state) => {
    return {
      supplierStore: state.supplierStore,
    };
  });

  useEffect(() => {
    loadData(dispatch, { companyId, supplierId });
    if (!supplierId) {
      setFormState(defaultFormState);
    }
    return()=>{
        dispatch(actionCreators.clearStore({onlyItem:true}))
    }
  }, [companyId, supplierId]);

  useEffect(() => {
    const data = { ...defaultFormState, ...supplierStore.item };

    setFormState(data);
  }, [supplierStore.item]);

  useEffect(() => {
    if (supplierStore.isSaved) {
      if (supplierId) {
        history.push(utils.getRoute(location.pathname, 2));
      } else {
        history.push(utils.getRoute(location.pathname, 1));
      }
    }
  }, [supplierStore.isSaved, supplierId]);

  const handleInputChange = useCallback((event, target) => {
    //event.preventDefault();

    let value = undefined;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "select-multiple":
        value = Array.from(target.selectedOptions, (x) => x.value);
        break;
      default:
        value = target.value;
    }
    const name = target.name;

    setFormState((old) => {
      return { ...old, [name]: value };
    });
  }, []);

  const handleSave = useCallback(
    (e) => {
      e.preventDefault();
      const data = { ...formState, companyId, supplierId };
      if (!supplierId) {
        dispatch(actionCreators.saveNewItem(data));
      } else {
        dispatch(actionCreators.saveItem(data));
      }
    },
    [companyId, supplierId, formState]
  );

  

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header size="huge">Новый Поставщик</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column>
          <Button
            color="green"
            as={Link}
            to={"/Company/" + companyId + "/Supplier"}
          >
            <Icon name="box" /> Список Поставщиков
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form
            success={supplierStore.isSave}
            error={!!supplierStore.error}
            loading={supplierStore.isLoading}
          >
            <Form.Field
              control={Input}
              label="Название"
              placeholder="Название"
              onChange={handleInputChange}
              name="name"
              value={formState.name || ""}
            />
             <Form.Field
              control={TextArea}
              label="Описание"
              placeholder="Описание"
              onChange={handleInputChange}
              name="description"
              value={formState.description || ""}
            />
            

            <Message error header="Ошибка" content={supplierStore.error} />

            <Form.Field widths={16} onClick={handleSave} control={Button}>
              Сохранить
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
