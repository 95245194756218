import React, { Component } from 'react';
import { Grid, Header, Button, Divider, Checkbox, Form, Input, Select, Message } from 'semantic-ui-react'
import * as actionCreators from "./actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as utils from "@hitek-crm-utils";

export class OrderStatusNew extends Component {


    constructor(props) {
        super(props);
        this.state = {
            currentCompanyId: this.props.match.params.companyId,
            name: "",
            order: "",
            color: "",
            isClosed: false,
            isCancel: false,
            isAddStockOperation: false,
            isEditable: true,
            isRequireReason: false,
            customField: {},

        }


        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.props.clearStore();

        // Operations usually carried out in componentWillMount go here
    }
    componentWillReceiveProps(nextProps) {


        if (this.props.match.params.companyId != nextProps.match.params.companyId) {

            this.setState({
                currentCompanyId: nextProps.match.params.companyId,
            });
            //this.resetForm();
        }

        if (this.props.orderStatus.isSave != nextProps.orderStatus.isSave && nextProps.orderStatus.isSave) {
            this.props.history.push(utils.getRoute(this.props.match.url, 1));
        }
    }

    handleInputChange(e, target) {

        let value = undefined;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'select-multiple':
                value = Array.from(target.selectedOptions, x => x.value);
                break;
            default:
                value = target.value;
        }
        const name = target.name;

        this.setState({
            [name]: value,
        });

    }


    handleSave(e) {
        e.preventDefault();
        this.props.saveNewOrderStatus(
            {
                name: this.state.name,
                companyId: this.state.currentCompanyId,
                order: this.state.order,
                color: this.state.color,
                isAddStockOperation: this.state.isAddStockOperation,
                isClosed: this.state.isClosed,
                isCancel: this.state.isCancel,
                isEditable: this.state.isEditable,
                isRequireReason:this.state.isRequireReason


            }
        );
    }


    render() {
        return (

            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size='huge'>Новая статус заказа</Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={2} >
                    <Grid.Column>
                        <Form success={this.props.orderStatus.isSave} error={!!this.props.orderStatus.error} loading={this.props.orderStatus.isLoading} >
                            <Form.Field control={Input} label='Название' placeholder='Название Позиции' name="name" onChange={this.handleInputChange} value={this.state.name} />

                            <Form.Field control={Input} label='Очередь сортировки' placeholder='Очередь сортировки' name="order" onChange={this.handleInputChange}
                                value={this.state.order} />
                            <Form.Field control={Select} label='Цвет ' placeholder='нет цвета' name="color" onChange={this.handleInputChange} value={this.state.color}
                                options={[
                                    { value: 'red', text: 'Красный', label: { empty: true, circular: true, color: "red" } },
                                    { value: 'orange', text: 'Оранжевый', label: { empty: true, circular: true, color: "orange" } },
                                    { value: 'yellow', text: 'Желтый', label: { empty: true, circular: true, color: "yellow" } },
                                    { value: 'olive', text: 'Оливковый', label: { empty: true, circular: true, color: "olive" } },
                                    { value: 'green', text: 'Зеленый', label: { empty: true, circular: true, color: "green" } },
                                    { value: 'teal', text: 'Голубой', label: { empty: true, circular: true, color: "teal" } },
                                    { value: 'blue', text: 'Синий', label: { empty: true, circular: true, color: "blue" } },
                                    { value: 'violet', text: 'Фиолетовый', label: { empty: true, circular: true, color: "violet" } },
                                    { value: 'purple', text: 'Пурпурный', label: { empty: true, circular: true, color: "purple" } },
                                    { value: 'pink', text: 'Розовый', label: { empty: true, circular: true, color: "pink" } },
                                    { value: 'brown', text: 'Коричневый', label: { empty: true, circular: true, color: "brown" } },
                                    { value: 'grey', text: 'Серый', label: { empty: true, circular: true, color: "grey" } },
                                    { value: 'black', text: 'Черный', label: { empty: true, circular: true, color: "black" } },
                                    { value: '', text: 'Не указан', label: { empty: true, circular: true, color: "grey" } }
                                ]}
                            />
                            <Form.Field control={Checkbox} label='Статус закрывает заказ' name="isClosed" onChange={this.handleInputChange}
                                checked={this.state.isClosed} />
                            <Form.Field control={Checkbox} label='Заказ не учитывается как совершенный' name="isCancel" onChange={this.handleInputChange}
                                checked={this.state.isCancel} />

                            <Form.Field control={Checkbox} label='Вносить изменения в Склад' name="isAddStockOperation" onChange={this.handleInputChange}
                                checked={this.state.isAddStockOperation} />

                            <Form.Field control={Checkbox} label='Заказ можно редактировать' name="isEditable" onChange={this.handleInputChange}
                                checked={this.state.isEditable} />
                            <Form.Field control={Checkbox} label='Причина указать обязательно' name="isRequireReason" onChange={this.handleInputChange}
                                checked={this.state.isRequireReason} />
                            
                            <Message
                                error
                                header="Ошибка"
                                content={this.props.orderStatus.error}
                            />
                            <Message
                                success
                            >
                                <Message.Content>
                                    Успешно Сохранено
                                <Button as={Link} to={"/Company/" + this.state.currentCompanyId + "/OrderStatus"}>
                                        Вернуться к списку
                                </Button>
                                </Message.Content>
                            </Message>
                            <Form.Field disabled={this.props.orderStatus.isLoading || this.props.orderStatus.isSave} onClick={this.handleSave} control={Button}>Сохранить</Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>

            </Grid>);

    }
};

function mapStateToProps(state) {

    return {

        orderStatus: state.orderStatusEdit


    };
}


export const OrderStatusNewContainer = connect(mapStateToProps, actionCreators)(OrderStatusNew);
