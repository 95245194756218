export const listSummuryLoadStart = "PAYMENT_METHOD_LIST_SUMMURY_LOAD_START";
export const listSummuryLoadSuccess = "PAYMENT_METHOD_LIST_SUMMURY_LOAD_SUCCESS";
export const listSummuryLoadFail = "PAYMENT_METHOD_LIST_SUMMURY_LOAD_FAIL";
export const listLoadStart = "PAYMENT_METHOD_LIST_LOAD_START";
export const listLoadSuccess = "PAYMENT_METHOD_LIST_LOAD_SUCCESS";
export const listLoadFail = "PAYMENT_METHOD_LIST_LOAD_FAIL";
export const itemRemoveStart = "PAYMENT_METHOD_LIST_REMOVE_START";
export const itemRemoveSuccess = "PAYMENT_METHOD_LIST_REMOVE_SUCCESS";
export const itemRemoveFail = "PAYMENT_METHOD_LIST_REMOVE_FAIL";
export const itemSaveStart = "PAYMENT_METHOD_SAVE_START";
export const itemSaveSuccess = "PAYMENT_METHOD_SAVE_SUCCESS";
export const itemSaveFail = "PAYMENT_METHOD_SAVE_FAIL";
export const itemLoadStart = "PAYMENT_METHOD_DETAIL_START";
export const itemLoadSuccess = "PAYMENT_METHOD_DETAIL_SUCCESS";
export const itemLoadFail = "PAYMENT_METHOD_DETAIL_FAIL";
export const listFinanceSourseLoadStart = "PAYMENT_METHOD_FINANCE_SOURCE_LOAD_START";
export const listFinanceSourseLoadSuccess = "PAYMENT_METHOD_FINANCE_SOURCE_LOAD_SUCCESS";
export const listFinanceSourseLoadFail = "PAYMENT_METHOD_FINANCE_SOURCE_LOAD_FAIL";
export const listExternalPaymentMethodLoadStart = "PAYMENT_METHOD_External_P_M_LOAD_START";
export const listExternalPaymentMethodLoadSuccess = "PAYMENT_METHOD_External_P_M_LOAD_SUCCESS";
export const listExternalPaymentMethodLoadFail = "PAYMENT_METHOD_External_P_M_LOAD_FAIL";
