import React, { Component } from "react";
import {
    Grid,
    Header,
    Button,
    Divider,
    Form,
    Input,
    Select,
    TextArea,
    Table,
    Icon,
    Modal,
    Dimmer,
    Loader,
    Tab,
    Message,
} from "semantic-ui-react";
import * as actionCreators from "./actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tree } from "./components/Tree";

import { CustomerInfo } from "./components/CustomerInfo";
import { GridPosition } from "./components/GridPosition";

import * as utils from "@hitek-crm-utils";
import DatePicker from "@hitek-crm/DatePicker";

export class OrderEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId,
            orderId: this.props.match.params.orderId,
            isLoaded: false,
            id: "",
            name: "",
            date: undefined,
            orderPosition: [],
            tags: [],
            orderTotalSumm: 0,
            orderTotalCount: 0,
            orderDeliveryAddress: "",
            orderDeliveryAddressBuilding: "",
            orderDeliveryAddressApartmentNumber: "",
            orderDeliveryAddressLevel: "",
            orderDeliveryAddressEntrance: "",
            orderNote: "",
            orderExternalNote: "",
            orderPhone: undefined,
            isCorrectOrderPhone: false,
            orderDiscountId: undefined,
            operatorPhoneCallId: "",
            sujDeliveryAdressRaw: [],
            sujDeliveryAdressStreet: [],
            sujDeliveryAdressBuilding: [],
            isShowExternalNote: false,
        };

        if (this.props.location.state && this.props.location.state.orderPhoneCallId) {
            this.state.orderPhone = this.props.location.state.orderPhoneCallNumber;
            this.state.operatorPhoneCallId = this.props.location.state.orderPhoneCallId;
            this.state.isCorrectOrderPhone = true;
        }

        this.handleChangeOrderDeliveryAddress = this.handleChangeOrderDeliveryAddress.bind(this);
        this.handleChangeOrderDeliveryAddressBuilding = this.handleChangeOrderDeliveryAddressBuilding.bind(
            this
        );
        this.handleChangeOrderDeliveryAddressApartmenNumber = this.handleChangeOrderDeliveryAddressApartmenNumber.bind(
            this
        );
        this.handleChangeOrderDeliveryAddressLevel = this.handleChangeOrderDeliveryAddressLevel.bind(this);
        this.handleChangeOrderDeliveryAddressEntrance = this.handleChangeOrderDeliveryAddressEntrance.bind(this);
        this.handleChangeOrderPhone = this.handleChangeOrderPhone.bind(this);
        this.handleChangeOrderNote = this.handleChangeOrderNote.bind(this);
        this.handleChangeTags = this.handleChangeTags.bind(this);

        this.handleChangeDiscount = this.handleChangeDiscount.bind(this);
        this.handleRemoveDiscount = this.handleRemoveDiscount.bind(this);

        this.handleRemovePositionFromOrder = this.handleRemovePositionFromOrder.bind(this);
        this.handleRemoveItemFromOrder = this.handleRemoveItemFromOrder.bind(this);
        this.handleAddItemToOrder = this.handleAddItemToOrder.bind(this);
        this.handleSaveOrder = this.handleSaveOrder.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);

        this.initData({
            companyId: this.state.companyId,
            orderId: this.state.orderId,
        });
    }

    getCompanyName() {
        let companies = this.props.companies.companyList;
        if (!Array.isArray(companies)) return null;
        let company = companies.find((x) => x.id == this.state.companyId);
        if (!!company) {
            return company.name;
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.match.params.companyId != nextProps.match.params.companyId ||
            this.props.match.params.orderId != nextProps.match.params.orderId
        ) {
            this.setState({
                companyId: nextProps.match.params.companyId,
                orderId: nextProps.match.params.orderId,
                orderPosition: [],
                orderDeliveryAddress: "",
                orderDeliveryAddressBuilding: "",
                orderDeliveryAddressApartmentNumber: "",
                orderDeliveryAddressLevel: "",
                orderDeliveryAddressEntrance: "",
                orderNote: "",
                orderExternalNote: "",
                orderPhone: "",
                orderDiscountId: null,
                operatorPhoneCallId: "",
                date: undefined,
                tags: [],
            });
            this.initData({
                companyId: nextProps.match.params.companyId,
                orderId: nextProps.match.params.orderId,
            });
        }

        if (this.state.orderId && this.props.order.orderEdit != nextProps.order.orderEdit) {
            const orderEditInfo = nextProps.order.orderEdit;
            this.setState({
                orderPosition: orderEditInfo.items,
                orderDeliveryAddress: orderEditInfo.deliveryAddress,
                orderDeliveryAddressBuilding: orderEditInfo.deliveryAddressBuilding,
                orderDeliveryAddressApartmentNumber: orderEditInfo.deliveryAddressApartmentNumber,
                orderDeliveryAddressLevel: orderEditInfo.deliveryAddressLevel,
                orderDeliveryAddressEntrance: orderEditInfo.deliveryAddressEntrance,
                orderNote: orderEditInfo.note,
                orderExternalNote: orderEditInfo.externalNote,
                orderPhone: orderEditInfo.phone || undefined,
                orderDiscountId: orderEditInfo.discountId,
                tags:
                    orderEditInfo.tags && Array.isArray(orderEditInfo.tags)
                        ? orderEditInfo.tags
                        : [],
                operatorPhoneCallId: orderEditInfo.operatorPhoneCallId,
                date: orderEditInfo.date,
            });
            this.recalculateGeneralOrder(orderEditInfo.items, orderEditInfo.discountId);

            this.handleChangeOrderPhone(
                { preventDefault: () => {} },
                {
                    value: orderEditInfo.phone,
                }
            );
        }
        if (
            this.state.orderId &&
            this.props.discountList.discountList != nextProps.discountList.discountList
        ) {
            //this.recalculateGeneralOrder(this.state.orderPosition, this.state.orderDiscountId);
            let self = this;
            let callback = () => {
                self.recalculateGeneralOrder(self.state.orderPosition, self.state.orderDiscountId);
            };
            setTimeout(callback, 200);
        }

        if (
            this.props.order.isSaved !== nextProps.order.isSaved &&
            nextProps.order.isSaved === true
        ) {
            if (this.state.orderId) {
                this.props.history.replace(utils.getRoute(this.props.match.url, 1));
            } else {
                this.props.history.replace(nextProps.order.id);
            }
        }
    }
    componentWillUnmount() {
        this.props.clearStore();
    }

    initData(param) {
        if (param.orderId) {
            this.props.initOrderEditData({ companyId: param.companyId, orderId: param.orderId });
        }

        this.props.initItemList({ companyId: param.companyId });
        this.props.initCategoryList({ companyId: param.companyId });
        this.props.initTagList({ companyId: param.companyId });
        this.props.initDiscountList({ companyId: param.companyId });

        if (param.operatorPhoneCallId) {
            this.props.initCustomerInfoByPhone({
                phone: this.state.orderPhone,
                companyId: param.companyId,
            });
        } else {
        }
    }

    handleChangeOrderDeliveryAddress(e, target) {
        this.setState({ orderDeliveryAddress: target.value });
        if (target.value == "") {
            return;
        }
        fetch("https://gis.hiteka.net/v1/search/" + target.value)
            .then((response) => response.json())
            .then((data) => {
                const street = Array.from(data, (x) => x["addr:street"])
                    .filter((value, index, ar) => {
                        return ar.indexOf(value) == index;
                    })
                    .slice(0, 5);

                let buil = [];
                if (street.length == 1) {
                    buil = Array.from(
                        data.filter((x) => x["addr:street"] === target.value),
                        (x) => x["addr:housenumber"]
                    ).sort();
                }
                this.setState({
                    sujDeliveryAdressStreet: street,
                    //sujDeliveryAdressRaw: data,
                    sujDeliveryAdressBuilding: buil,
                });
            });
    }
    handleChangeOrderDeliveryAddressBuilding(e, target) {
        this.setState({ orderDeliveryAddressBuilding: target.value });
    }
    handleChangeOrderDeliveryAddressApartmenNumber(e, target) {
        this.setState({ orderDeliveryAddressApartmentNumber: target.value });
    }
    handleChangeOrderDeliveryAddressLevel(e, target) {
        this.setState({ orderDeliveryAddressLevel: target.value });
    }
    handleChangeOrderDeliveryAddressEntrance(e, target) {
        this.setState({ orderDeliveryAddressEntrance: target.value });
    }
    handleChangeOrderPhone(e, target) {
        if (!target.value) {
            e.preventDefault();
            return;
        }

        let matchPhone = target.value.match(/(^\+\d{0,12})|(^\d{0,11})/);
        if (matchPhone && matchPhone[0].length == target.value.length) {
            let r = {
                orderPhone: target.value,
                isCorrectOrderPhone: !!target.value.match(/(^\+\d{12})|(^\d{11})/),
            };
            //this.setState(r);
            if (r.isCorrectOrderPhone && target.value != this.state.orderPhone) {
                this.props.initCustomerInfoByPhone({
                    phone: target.value,
                    companyId: this.state.companyId,
                });
                r.operatorPhoneCallId = undefined;
            }

            this.setState(r);
        } else {
            e.preventDefault();
        }
    }

    handleChangeOrderNote(e, target) {
        this.setState({ orderNote: target.value });
    }

    handleAddItemToOrder(e, target) {
        e.preventDefault();
        let itemId = target["data-id"];
        let categoryId = target["data-category-id"];

        let item = this.props.items.itemList.find((x) => x.id === itemId);
        let category =
            categoryId < 0
                ? { id: null, name: "-" }
                : this.props.categories.categoryList.find((x) => x.id === categoryId);

        let orderPosition = this.state.orderPosition;
        let orderitem = orderPosition.find((x) => x.id == itemId && x.categoryId == categoryId);
        if (!!orderitem) {
            orderitem.count++;
        } else {
            orderPosition.push({
                id: itemId,
                categoryId: categoryId,
                categoryName: category.fullName,
                name: item.name,
                price: item.price,
                count: 1,
            });
        }

        this.recalculateGeneralOrder(orderPosition, this.state.orderDiscountId);
    }

    handleRemovePositionFromOrder(e, target) {
        e.preventDefault();
        let itemId = target["data-id"];
        let categoryId = target["data-category-id"];

        let orderPosition = this.state.orderPosition;
        let orderitem = orderPosition.find((x) => x.id == itemId && x.categoryId == categoryId);
        if (!!orderitem) {
            orderitem.count--;
        }

        orderPosition = orderPosition.filter((x) => x.count > 0);

        let orderTotalCount = 0;
        let orderTotalSumm = 0;
        let iterator = orderPosition[Symbol.iterator]();
        let obj = iterator.next();
        while (obj.done !== true) {
            orderTotalCount += obj.value.count;
            orderTotalSumm += obj.value.count * obj.value.price;
            obj = iterator.next();
        }
        this.recalculateGeneralOrder(orderPosition, this.state.orderDiscountId);
    }

    handleRemoveItemFromOrder(e, target) {
        e.preventDefault();
        let itemId = target["data-id"];
        let categoryId = target["data-category-id"];

        let orderPosition = this.state.orderPosition;
        let orderitemIndex = orderPosition.findIndex(
            (x) => x.id == itemId && x.categoryId == categoryId
        );
        if (orderitemIndex > -1) {
            orderPosition.splice(orderitemIndex, 1);
        }

        this.recalculateGeneralOrder(orderPosition, this.state.orderDiscountId);
    }

    handleSaveOrder(e) {
        e.preventDefault();
        let order = {
            items: [],
            phone: this.state.orderPhone,
            deliveryAddress: this.state.orderDeliveryAddress,
            deliveryAddressBuilding: this.state.orderDeliveryAddressBuilding,
            deliveryAddressApartmentNumber: this.state.orderDeliveryAddressApartmentNumber,
            deliveryAddressLevel: this.state.orderDeliveryAddressLevel,
            deliveryAddressEntrance: this.state.orderDeliveryAddressEntrance,
            note: this.state.orderNote,
            companyId: this.state.companyId,
            orderId: this.state.orderId,
            discountId: this.state.orderDiscountId,
            tags: this.state.tags,
            operatorPhoneCallId: this.state.operatorPhoneCallId,
        };
        if (!!this.state.date) {
            order.date = this.state.date.toISOString();
        }

        let iterator = this.state.orderPosition[Symbol.iterator]();
        let obj = iterator.next();
        let value = null;
        while (obj.done !== true) {
            value = obj.value;

            order.items.push({
                itemId: value.id,
                categoryId: value.categoryId,
                count: value.count,
            });
            obj = iterator.next();
        }
        if (!this.state.orderId) {
            this.props.saveNewOrder(order);
        } else {
            order.orderId = this.state.orderId;
            this.props.saveOrderEditData(order);
        }
    }

    handleChangeTags(e, target) {
        this.setState({ tags: target.value });
    }
    handleChangeDiscount(e, target) {
        this.setState({ orderDiscountId: target.value });
        this.recalculateGeneralOrder(null, target.value);
    }
    handleRemoveDiscount() {
        this.setState({ orderDiscountId: undefined });
        this.recalculateGeneralOrder(null, undefined);
    }

    recalculateGeneralOrder(orderPositionInput, newDiscountId) {
        const discount = this.props.discountList.discountList.find((x) => x.id == newDiscountId);
        let orderPosition = orderPositionInput
            ? Array.from(orderPositionInput)
            : Array.from(this.state.orderPosition);

        let orderTotalCount = 0;
        let orderTotalSumm = 0;
        let orderTotalDiscount = 0;

        let iterator = orderPosition[Symbol.iterator]();
        let orderItemIterator = iterator.next();
        while (orderItemIterator.done !== true) {
            const orderItem = orderItemIterator.value;

            let discountItem = null;

            if (discount && Array.isArray(discount.detail)) {
                discountItem = discount.detail.find(
                    (x) => x.categoryId == orderItem.categoryId && x.itemId == orderItem.id
                );
                if (!discountItem) {
                    discountItem = discount.detail.find(
                        (x) => x.categoryId == orderItem.categoryId && x.itemId === -1
                    );
                }
                if (!discountItem) {
                    discountItem = discount.detail.find(
                        (x) => x.categoryId === -1 && x.itemId === -1
                    );
                }
            }

            console.log(discountItem);
            let discountItemValue = 0;
            orderItem.discountDescription = null;
            if (discountItem) {
                if (discountItem.type == "fixed") {
                    discountItemValue = discountItem.value;
                    orderItem.discountDescription =
                        "-" + utils.toFloatToString(discountItem.value) + " р.";
                }
                if (discountItem.type == "%") {
                    discountItemValue = Math.round(orderItem.price * discountItem.value) / 100;
                    orderItem.discountDescription =
                        "-" + utils.toFloatToString(discountItem.value) + "%";
                }
            }

            orderItem.totalSummRaw = utils.toCorrectFloat(orderItem.price * orderItem.count);
            orderItem.totalSumm = utils.toCorrectFloat(
                orderItem.totalSummRaw - orderItem.count * discountItemValue > 0
                    ? orderItem.totalSummRaw - orderItem.count * discountItemValue
                    : 0
            );

            orderTotalSumm += orderItem.totalSumm;
            orderTotalCount += orderItem.count;
            orderTotalDiscount += utils.toCorrectFloat(
                orderItem.totalSummRaw - orderItem.count * discountItemValue > 0
                    ? orderItem.count * discountItemValue
                    : orderItem.totalSummRaw
            );

            orderItemIterator = iterator.next();
        }

        if (discount && discount.detail == null) {
            let discountValue = 0;
            let discountItem = discount;

            if (discountItem.type == "fixed") {
                discountValue = discountItem.value;
            }
            if (discountItem.type == "%") {
                discountValue = Math.round(orderTotalSumm * discountItem.value) / 100;
            }

            orderTotalDiscount = utils.toCorrectFloat(
                orderTotalSumm - discountValue > 0 ? discountValue : discountValue
            );
            orderTotalSumm = utils.toCorrectFloat(
                orderTotalSumm - discountValue > 0 ? orderTotalSumm - discountValue : 0
            );
        }

        this.setState({
            orderTotalDiscount: orderTotalDiscount,
            orderTotalCount: orderTotalCount,
            orderTotalSumm: orderTotalSumm,
            orderPosition: orderPosition,
        });
    }
    handleChangeDate(date) {
        this.setState({
            date: date,
        });
    }
    render() {
        const discount = this.props.discountList.discountList.find(
            (x) => x.id == this.state.orderDiscountId
        );
        return (
            <Grid>
                <Dimmer.Dimmable>
                    <Loader size="medium">Loading</Loader>
                </Dimmer.Dimmable>
                <Grid.Row>
                    <Grid.Column>
                        {!this.state.orderId ? (
                            <Header size="huge">
                                {" "}
                                Новый заказ для компании {this.getCompanyName()}{" "}
                            </Header>
                        ) : (
                            <Header size="huge">
                                {" "}
                                Редактирвоание заказа для компании {this.getCompanyName()}{" "}
                            </Header>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Divider />

                <Grid.Row centered columns={2}>
                    <Grid.Column width={8}>
                        <Header size="medium"> Выбранные позиции</Header>

                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Название</Table.HeaderCell>
                                    <Table.HeaderCell>Категория</Table.HeaderCell>
                                    <Table.HeaderCell>Стоим.</Table.HeaderCell>
                                    <Table.HeaderCell>Кол.</Table.HeaderCell>
                                    <Table.HeaderCell>Сумма</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {Array.isArray(this.state.orderPosition) &&
                                    this.state.orderPosition.map((pos) => (
                                        <Table.Row key={pos.id + "." + pos.categoryId}>
                                            <Table.Cell>
                                                <Icon name="file outline" />
                                                {pos.name}
                                            </Table.Cell>
                                            <Table.Cell>{pos.categoryName}</Table.Cell>
                                            <Table.Cell>
                                                {utils.toFloatToString(pos.price)} р
                                                <br />
                                                {pos.discountDescription}
                                            </Table.Cell>
                                            <Table.Cell>{pos.count}</Table.Cell>
                                            <Table.Cell textAlign="right">
                                                {pos.totalSumm} р
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    data-id={pos.id}
                                                    data-category-id={pos.categoryId}
                                                    compact
                                                    color="red"
                                                    onClick={this.handleRemoveItemFromOrder}
                                                    icon="remove"
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell>Скидка</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="3" textAlign="right">
                                        <Select
                                            loading={this.props.discountList.isLoading}
                                            placeholder="Скидка"
                                            search
                                            clearable
                                            options={Array.from(
                                                this.props.discountList.discountList.filter(
                                                    (x) => !x.isRemoved
                                                ),
                                                (x) => {
                                                    return { text: x.name, value: x.id };
                                                }
                                            )}
                                            value={this.state.orderDiscountId}
                                            onChange={this.handleChangeDiscount}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">
                                        {utils.toFloatToString(this.state.orderTotalDiscount)} p.
                                    </Table.HeaderCell>
                                    {/*<Table.HeaderCell >
                                {this.state.orderTotalCount}
                                </Table.HeaderCell>
                                <Table.HeaderCell  textAlign='right'>
                                    { utils.toFloatToString(this.state.orderTotalSumm)} р.
                                </Table.HeaderCell>*/}
                                    <Table.HeaderCell>
                                        <Button
                                            compact
                                            color="red"
                                            onClick={this.handleRemoveDiscount}
                                            icon="remove"
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="3">Итого</Table.HeaderCell>
                                    <Table.HeaderCell>{this.state.totalCount}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">
                                        {utils.toFloatToString(this.state.orderTotalSumm)} р.
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right"></Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8}>
                                    <Form error={!!this.props.order.error}>
                                        <Form.Field>
                                            <label>Заказ на дату</label>
                                            <Grid>
                                                <Grid.Row columns={16}>
                                                    <Grid.Column width={13}>
                                                        <DatePicker
                                                            selected={this.state.date}
                                                            showTimeSelect
                                                            onChange={this.handleChangeDate}
                                                            dateFormat="Pp"
                                                            placeholderText="Сейчас"
                                                            withPortal
                                                            // minDate={new Date()}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={2}>
                                                        <Button
                                                            negative
                                                            icon="remove"
                                                            onClick={() =>
                                                                this.handleChangeDate(
                                                                    this.state.orderId
                                                                        ? this.props.order.orderEdit
                                                                              .date
                                                                        : undefined
                                                                )
                                                            }
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Form.Field>

                                        <Form.Field
                                            control={Input}
                                            type="tel"
                                            label="Контактный телефон"
                                            placeholder="номер телефона"
                                            icon={
                                                this.state.isCorrectOrderPhone
                                                    ? { name: "checkmark", color: "green" }
                                                    : { name: "remove", color: "red" }
                                            }
                                            iconPosition="left"
                                            value={this.state.orderPhone}
                                            onChange={this.handleChangeOrderPhone}
                                        />

                                        <Form.Field
                                            control={Input}
                                            label="Адрес доставки"
                                            placeholder="Улица"
                                            value={this.state.orderDeliveryAddress}
                                            onChange={this.handleChangeOrderDeliveryAddress}
                                            list="street"
                                        />
                                        <datalist id="street">
                                            {this.state.sujDeliveryAdressStreet.map((item, key) => (
                                                <option key={key} value={item} />
                                            ))}
                                        </datalist>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Form.Input
                                                        label="Дом"
                                                        placeholder="Дом"
                                                        value={
                                                            this.state.orderDeliveryAddressBuilding
                                                        }
                                                        onChange={
                                                            this
                                                                .handleChangeOrderDeliveryAddressBuilding
                                                        }
                                                        list="building"
                                                    />
                                                    <datalist id="building">
                                                        {this.state.sujDeliveryAdressBuilding.map(
                                                            (item, key) => (
                                                                <option key={key} value={item} />
                                                            )
                                                        )}
                                                    </datalist>
                                                </Grid.Column>
                                                <Grid.Column width={6}>
                                                    <Form.Input
                                                        label="Квартира"
                                                        placeholder="Квартира"
                                                        value={
                                                            this.state
                                                                .orderDeliveryAddressApartmentNumber
                                                        }
                                                        onChange={
                                                            this
                                                                .handleChangeOrderDeliveryAddressApartmenNumber
                                                        }
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Form.Input
                                                        label="Подъезд"
                                                        placeholder="Подъезд"
                                                        value={
                                                            this.state.orderDeliveryAddressEntrance
                                                        }
                                                        onChange={
                                                            this
                                                                .handleChangeOrderDeliveryAddressEntrance
                                                        }
                                                        
                                                    />
                                                    
                                                </Grid.Column>
                                                <Grid.Column width={6}>
                                                    <Form.Input
                                                        label="Этаж"
                                                        placeholder="Этаж"
                                                        value={
                                                            this.state
                                                                .orderDeliveryAddressLevel
                                                        }
                                                        onChange={
                                                            this
                                                                .handleChangeOrderDeliveryAddressLevel
                                                        }
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        {this.state.orderExternalNote && (
                                            <Form.Field>
                                                <label> Примечание из внешней системы</label>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({ isShowExternalNote: true });
                                                    }}>
                                                    Просмотр
                                                </Button>
                                            </Form.Field>
                                        )}
                                        <Form.Field
                                            control={TextArea}
                                            label="Примечание"
                                            placeholder="Примечание"
                                            value={this.state.orderNote}
                                            onChange={this.handleChangeOrderNote}
                                        />

                                        <Form.Field
                                            control={Select}
                                            loading={this.props.tagList.isLoading}
                                            label="Теги"
                                            placeholder="Теги"
                                            selection
                                            search
                                            multiple
                                            options={Array.from(
                                                this.props.tagList.tagList.filter(
                                                    (x) => !x.isRemoved
                                                ),
                                                (x) => {
                                                    return { text: x.name, value: x.id };
                                                }
                                            )}
                                            value={this.state.tags}
                                            onChange={this.handleChangeTags}
                                        />
                                        <Message
                                            error
                                            header="Ошибка"
                                            content={this.props.order.error}
                                        />
                                        <Grid.Row>
                                            <Button
                                                as={Link}
                                                to={
                                                    "/Company/" +
                                                    this.state.companyId +
                                                    (this.state.orderId
                                                        ? "/Order/" + this.state.orderId
                                                        : "")
                                                }
                                                loading={this.props.order.isLoading}
                                                disabled={
                                                    this.props.order.isLoading ||
                                                    this.props.order.isSaved
                                                }
                                                negative>
                                                Отмена
                                            </Button>
                                            <Button
                                                loading={this.props.order.isLoading}
                                                disabled={
                                                    this.props.order.isLoading ||
                                                    this.props.order.isSaved
                                                }
                                                positive
                                                onClick={this.handleSaveOrder}>
                                                Сохранить
                                            </Button>
                                        </Grid.Row>
                                    </Form>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <CustomerInfo
                                        isCorrectOrderPhone={this.state.isCorrectOrderPhone}
                                        isCustomerInfoLoading={
                                            this.props.order.isCustomerInfoLoading
                                        }
                                        orderCustomerInfo={this.props.order.orderCustomerInfo}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <Tab
                            panes={[
                                {
                                    menuItem: "Дерево",
                                    render: () => (
                                        <Tab.Pane key="tab1">
                                            <Tree
                                                categoryList={this.props.categories.categoryList}
                                                itemList={this.props.items.itemList}
                                                orderPosition={this.state.orderPosition}
                                                handleRemovePositionFromOrder={
                                                    this.handleRemovePositionFromOrder
                                                }
                                                handleAddItemToOrder={this.handleAddItemToOrder}
                                            />
                                        </Tab.Pane>
                                    ),
                                },
                                {
                                    menuItem: "Плитка",
                                    render: () => (
                                        <Tab.Pane key="tab2">
                                            <GridPosition
                                                categoryList={this.props.categories.categoryList}
                                                itemList={this.props.items.itemList}
                                                orderPosition={this.state.orderPosition}
                                                handleRemovePositionFromOrder={
                                                    this.handleRemovePositionFromOrder
                                                }
                                                handleAddItemToOrder={this.handleAddItemToOrder}
                                            />
                                        </Tab.Pane>
                                    ),
                                },
                            ]}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Modal size="mini" closeOnDimmerClick={false} open={this.props.order.isSaved}>
                    <Modal.Header>Заказ изменен</Modal.Header>
                    <Modal.Content>
                        <p>
                            Внутрений номер заказа: {this.props.order.internalId}
                            <br />
                            Id заказа: {this.props.order.id}
                            <br />
                            Итоговая сумма: {utils.toFloatToString(this.props.order.amount)} р.
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            as={Link}
                            to={"/Company/" + this.state.companyId}
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Ok"
                        />
                    </Modal.Actions>
                </Modal>
                {this.state.orderExternalNote && (
                    <Modal
                        size="mini"
                        closeOnDimmerClick={true}
                        onClose={() => {
                            this.setState({ isShowExternalNote: false });
                        }}
                        open={this.state.isShowExternalNote}>
                        <Modal.Header>Примечание из внешней системы</Modal.Header>
                        <Modal.Content>
                            <p>{this.state.orderExternalNote}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    this.setState({ isShowExternalNote: false });
                                }}
                                labelPosition="right"
                                content="Ok"
                            />
                        </Modal.Actions>
                    </Modal>
                )}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        companies: state.companyList,
        categories: state.categoryList,
        items: state.itemList,
        order: state.order,
        tagList: state.tagList,
        discountList: state.discountList,
    };
}

export const OrderEditContainer = connect(mapStateToProps, actionCreators)(OrderEdit);
