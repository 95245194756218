export const ORDER_COUNT_BY_DAY_LOAD_START = "ORDER_COUNT_BY_DAY_LOAD_START";
export const ORDER_COUNT_BY_DAY_LOAD_SUCCESS = "ORDER_COUNT_BY_DAY_LOAD_SUCCESS";
export const ORDER_COUNT_BY_DAY_LOAD_FAIL = "ORDER_COUNT_BY_DAY_LOAD_FAIL";


export const dashboardFinanceLoadStart = "DASHBOARD_FINANCE_LOAD_START";
export const dashboardFinanceLoadSuccess = "DASHBOARD_FINANCE_LOAD_SUCCESS";
export const dashboardFinanceLoadFail = "DASHBOARD_FINANCE_LOAD_FAIL";

export const dashboardPaymentLoadStart = "DASHBOARD_PAYMENT_LOAD_START";
export const dashboardPaymentLoadSuccess = "DASHBOARD_PAYMENT_LOAD_SUCCESS";
export const dashboardPaymentLoadFail = "DASHBOARD_PAYMENT_LOAD_FAIL";

export const dashboardOrderLoadStart = "DASHBOARD_ORDER_LOAD_START";
export const dashboardOrderLoadSuccess = "DASHBOARD_ORDER_LOAD_SUCCESS";
export const dashboardOrderLoadFail = "DASHBOARD_ORDER_LOAD_FAIL";