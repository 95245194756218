
import config from "ConfigExternal";

let apiUrli = CONFIG_API_URL;
let appUrli = CONFIG_APP_URL;
let ajaxTimeOuti = 20000;
if(config.force){
    apiUrli = config.apiUrl;
    appUrli = config.appUrl;
    ajaxTimeOuti = config.ajaxTimeOut;
} 
   
export const apiUrl = apiUrli;
export const appUrl = appUrli;
export const ajaxTimeOut = ajaxTimeOuti;
export const appVersion = VERSION_APP_BUILD;
export const appName = APPLICATION_NANE;



