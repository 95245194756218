import * as config from "Config";
import * as constants from "./constants";

export function clearStore(newObject) {
  return {
    type: constants.ITEM_LIST_CLEAR_STORE,
  };
}

export function initListData(params) {
  let url = config.apiUrl + "/api/Item";
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }
  url+= "/Summury";
  return {
    remote: {
      method: "get",
      url,
    },
    actions: [
      constants.ITEM_LIST_SUMMURY_LOAD_START,
      constants.ITEM_LIST_SUMMURY_LOAD_SUCCESS,
      constants.ITEM_LIST_SUMMURY_LOAD_FAIL,
    ],
  };
}

export function initListEstimationCost(params) {
  let url = config.apiUrl + "/api/Item";
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }
  url+= "/EstimatedCost";
  return {
    remote: {
      method: "get",
      url,
    },
    actions: [
      constants.ITEM_LIST_ESTIMATE_COST_LOAD_START,
      constants.ITEM_LIST_ESTIMATE_COST_LOAD_SUCCESS,
      constants.ITEM_LIST_ESTIMATE_COST_LOAD_FAIL,
    ],
  };
}

export function deleteItem(params) {
  let url = config.apiUrl + "/api/Item/" + params.id ;
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }
  return {
    remote: {
      method: "delete",
      url,
    },

    actions: [
      constants.ITEM_LIST_REMOVE_START,
      constants.ITEM_LIST_REMOVE_SUCCESS,
      constants.ITEM_LIST_REMOVE_FAIL,
    ],
  };
}
