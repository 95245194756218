import * as config from "Config";
import * as constants from "./constants";

export function clearStore() {
  return {
    type: constants.clearStore,
  };
}

export function initListData(params) {
  return {
    remote: {
      method: "get",
      url: config.apiUrl + "/api/UserSetting",
      data: { ...params, appName: config.appName },
    },
    actions: [
      constants.listLoadStart,
      constants.listLoadSuccess,
      constants.listLoadFail,
    ],
  };
}

export function saveSettings(params) {
  return {
    remote: {
      method: "post",
      url: config.apiUrl + "/api/UserSetting",
      data: { ...params, appName: config.appName },
    },
    actions: [
      constants.itemSaveStart,
      constants.itemSaveSuccess,
      constants.itemSaveFail,
    ],
  };
}

/*
export function saveNewItem(params) {
  return {
    remote:{
        method: "post",
        url: config.apiUrl + "/api/StockOperation/Company/"+params.companyId,
        data:params
    },
    actions:[constants.itemSaveStart,constants.itemSaveSuccess,constants.itemSaveFail ]
  };
}

export function calculateStock(params) {
  return {
    remote:{
        method: "post",
        url: config.apiUrl + "/api/StockOperation/Company/"+params.companyId+"/Calculate"
    },
    actions:[constants.itemSaveStart,constants.itemSaveSuccess,constants.itemSaveFail ]
  };
}

*/
