import React, { useCallback, useEffect, useState } from "react";
import { Grid, Header, Button, Divider, Form, Input, Message, Checkbox } from "semantic-ui-react";
import * as actionCreators from "./actions";
import { initListData } from "./../actions";
import { useDispatch, useSelector } from "react-redux";
import * as utils from "@hitek-crm-utils";
import { useParams, useLocation, useHistory } from "react-router";

function loadData(dispatch, state) {
    if (state.categoryId) {
        dispatch(
            actionCreators.getDetailCategory({
                id: state.categoryId,
                companyId: state.companyId,
                companyGroupId: state.companyGroupId,
            })
        );
    }

    dispatch(
        initListData({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        })
    );

    dispatch(
        actionCreators.initCustomFields({
            companyId: state.companyId,
        })
    );
}

const defaultFormState = {
    id: "",
    name: "",
    parentId: null,
    imageFile: null,
    customField: {},
    isActive: true,
};
export function CategoryEditContainer() {
    const { companyId, companyGroupId, categoryId } = useParams();
    const dispatch = useDispatch();
    const { categoryStore, categoryEditStore } = useSelector((state) => {
        return {
            categoryStore: state.categoryList,
            categoryEditStore: state.categoryEdit,
        };
    });
    const [formState, setFormState] = useState(defaultFormState);
    const location = useLocation();
    const history = useHistory();

    useEffect(
        function () {
            loadData(dispatch, { companyId, companyGroupId, categoryId });
            return function () {
                dispatch(actionCreators.clearStore());
            };
        },
        [companyId, companyGroupId, categoryId]
    );

    useEffect(() => {
        const da = { ...defaultFormState, ...categoryEditStore.data };
        if (da.customField === null) {
            da.customField = {};
        }
        setFormState(da);
    }, [categoryEditStore.data]);

    useEffect(() => {
        if (categoryEditStore.isSave) {
            if (categoryId) {
                history.push(utils.getRoute(location.pathname, 2));
            } else {
                history.push(utils.getRoute(location.pathname, 1));
            }
        }
    }, [categoryEditStore.isSave, categoryId]);

    const handleInputChange = useCallback((event, target) => {
        //event.preventDefault();
        let value = undefined;
        let name = undefined;
        if (target) {
            switch (target.type) {
                case "checkbox":
                    value = target.checked;
                    break;
                case "select-multiple":
                    value = Array.from(target.selectedOptions, (x) => x.value);
                    break;
                default:
                    value = target.value;
            }
            name = target.name;
        } else {
            if (event.target.type == "file") {
                value = event.target.files[0];
                name = event.target.name;
            }
        }

        if (!name) {
            return;
        }
        setFormState((old) => {
            return { ...old, [name]: value };
        });
    }, []);
    const handleCustomFieldChange = useCallback((e, target) => {
        const { name, value } = target;
        setFormState((old) => {
            return { ...old, customField: { ...old.customField, [name]: value } };
        });
    }, []);
    const handleSave = useCallback(
        function (e) {
            e.preventDefault();
            const dataFull = {
                name: formState.name,
                id: categoryId,
                parentId: formState.parentId,
                customField: formState.customField,
            };

            const formData = new FormData();

            if (formState.imageFile) {
                formData.append("imageFile", formState.imageFile, formState.imageFile.name);
            }
            utils.getFormData(formData, dataFull, undefined);

            const data = {
                id: categoryId,
                companyId,
                companyGroupId,
                formData,
            };

            if (!categoryId) {
                dispatch(actionCreators.saveNewCategory(data));
            } else {
                dispatch(actionCreators.saveCategory(data));
            }
        },
        [formState, companyId, companyGroupId, categoryId]
    );

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="huge">Редактирвоание Категории #</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row columns="two">
                <Grid.Column>
                    <Form
                        success={categoryEditStore.isSave}
                        error={!!categoryEditStore.error}
                        loading={categoryEditStore.isLoading}>
                        <Form.Field
                            disabled
                            control={Input}
                            label="Id"
                            placeholder="Название категории"
                            value={categoryId}
                        />

                        <Form.Field
                            control={Input}
                            label="Название"
                            placeholder="Название категории"
                            onChange={handleInputChange}
                            name="name"
                            value={formState.name}
                        />

                        <Form.Select
                            loading={categoryStore.isCategoryListLoading}
                            placeholder="выберите категорию"
                            fluid
                            selection
                            label="Родительская категория"
                            options={Array.from(
                                categoryStore.categoryList.filter(
                                    (x) => x.isCompanyGroup == !!companyGroupId
                                ),
                                (x) => {
                                    return { text: x.fullName, value: x.id };
                                }
                            )}
                            value={formState.parentId}
                            clearable
                            name="parentId"
                            onChange={handleInputChange}
                        />

                        {Array.isArray(categoryEditStore.customFieldList) &&
                            categoryEditStore.customFieldList.map((key) => (
                                <Form.Field
                                    key={key.id}
                                    control={Input}
                                    label={key.title || key.name}
                                    placeholder={key.title || key.name}
                                    name={key.name}
                                    onChange={handleCustomFieldChange}
                                    value={formState.customField[key.name] || ""}
                                />
                            ))}
                        <Form.Field>
                            <label> Изображение</label>
                            {formState.imageUrl && <img src={formState.imageUrl} />}
                            <input type="file" name="imageFile" onChange={handleInputChange} />
                        </Form.Field>
                        <Form.Field
                            control={Checkbox}
                            label="Включена"
                            onChange={handleInputChange}
                            name="isActive"
                            checked={formState.isActive}
                        />
                        <Message error header="Ошибка" content={categoryEditStore.error} />
                        <Message success header="Данные сохранены" content="Сохранено" />
                        <Form.Field color="green" onClick={handleSave} control={Button}>
                            Сохранить
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
