import * as constants from "./constants";

export default function (state, action) {
  if (!state || action.type == "DASHBOARD_CLEAR_STORE") {
    state = {
      realization: [],
      orderByDay: [],
      dashboardFinance: {},
      dashboardPayment: {},
      dashboardOrder: {},
    };
  }
  //"REALIZATION_DASHBOARD_START","REALIZATION_DASHBOARD_SUCCESS","REALIZATION_DASHBOARD_FAIL"
  switch (action.type) {
    case "REALIZATION_DASHBOARD_START":
      return { ...state, ...{ isLoading: true, error: undefined } };
    case "REALIZATION_DASHBOARD_SUCCESS":
      return {
        ...state,
        ...{
          realization: action.data,
          isLoading: false,
        },
      };
    case "REALIZATION_DASHBOARD_FAIL":
      return { ...state, ...{ isLoading: false, error: action.error } };
    case constants.ORDER_COUNT_BY_DAY_LOAD_START:
      return {
        ...state,
        ...{ orderByDay: [], isLoading: true, error: undefined },
      };
    case constants.ORDER_COUNT_BY_DAY_LOAD_SUCCESS:
      return {
        ...state,
        ...{
          orderByDay: Array.from(action.data, (x) => {
            return { ...x, date: new Date(x.date) };
            //return x;
          }),
          isLoading: false,
        },
      };
    case constants.ORDER_COUNT_BY_DAY_LOAD_FAIL:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.ORDER_COUNT_BY_DAY_LOAD_SUCCESS:
      return {
        ...state,
        ...{
          orderByDay: Array.from(action.data, (x) => {
            return { ...x, date: new Date(x.date) };
            //return x;
          }),
          isLoading: false,
        },
      };
    case constants.ORDER_COUNT_BY_DAY_LOAD_FAIL:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.dashboardFinanceLoadStart:
      return { ...state, ...{ dashboardFinance: { isLoading: true } } };
    case constants.dashboardFinanceLoadSuccess:
      return { ...state, ...{ dashboardFinance: action.data } };
    case constants.dashboardFinanceLoadSuccess:
      return { ...state, ...{ dashboardFinance: { error: action.error } } };

    case constants.dashboardPaymentLoadStart:
      return { ...state, ...{ dashboardPayment: { isLoading: true } } };
    case constants.dashboardPaymentLoadSuccess:
      return { ...state, ...{ dashboardPayment: { list: action.data.list || [],  requred:action.data.requred||0} } };
    case constants.dashboardPaymentLoadSuccess:
      return { ...state, ...{ dashboardPayment: { error: action.error } } };

      case constants.dashboardOrderLoadStart:
      return { ...state, ...{ dashboardOrder: { isLoading: true } } };
    case constants.dashboardOrderLoadSuccess:
      return { ...state, ...{ dashboardOrder:  action.data  } };
    case constants.dashboardOrderLoadSuccess:
      return { ...state, ...{ dashboardOrder: { error: action.error } } };
    default:
      return state;
  }
}
