import ReactDOM from "react-dom";
import React,{Suspense,lazy } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import * as reducers from "./reducer";
import { Provider } from "react-redux";
import middleware from "./middlewareWithRefreshToken";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import  DatePickerLocaleRu from 'date-fns/locale/ru';
//import DatePickerLocaleEn from 'date-fns/locale/en';

import {Dimmer,Loader,Segment} from "semantic-ui-react"
import * as config from "Config";

import {configureUserSettingStore} from  "./hooks/useUserSettingHook";



import { App } from "./applications/App/App";
import { HelloWorld } from "./components/HelloWorld";


import { CompanyContainer } from "./applications/Company/Company";
import { CompanyEditContainer } from "./applications/Company/EditCompany";

import { CompanyListContainer } from "./applications/CompanyList/CompanyList";

import { RoleCompanyListContainer } from "./applications/RoleCompany/RoleCompanyList";
import { RoleCompanyDetailContainer } from "./applications/RoleCompany/RoleCompanyDetail";
import { RoleCompanyAddEditContainer } from "./applications/RoleCompany/RoleCompanyAddEdit";


import { DashBoard } from "./applications/DashBoard/DashBoard";

import { CompanyDashBoardContainer } from "./applications/DashBoard/CompanyDashBoard";
import { DashBoardRealizationContainer } from "./applications/DashBoard/Realization";
import { OrderSummuryMediumContainer } from "./applications/DashBoard/OrderSummuryMedium";
import { CurrentOpenOrdersContainer } from "./applications/DashBoard/CurrentOpenOrders";

import {OrderCalendar} from "./applications/DashBoard/OrderCalendar";

import { CategoryListContainer } from "./applications/Category/CategoryList";
//import {CategoryNewContainer} from "./applications/Category/Edit/NewCategory";
import { CategoryEditContainer } from "./applications/Category/Edit/EditCategory";


import { ItemListContainer } from "./applications/Item/ItemList";
import { ItemEditContainer } from "./applications/Item/Edit/EditItem";

import { OrderStatusListContainer } from "./applications/OrderStatus/OrderStatusList";
import { OrderStatusNewContainer } from "./applications/OrderStatus/Edit/NewOrderStatus";
import { OrderStatusEditContainer } from "./applications/OrderStatus/Edit/EditOrderStatus";


import { DiscountListContainer } from "./applications/Discount/DiscountList";
import { DiscountNewContainer } from "./applications/Discount/Edit/NewDiscount";

import { PaymentMethodListContainer } from "./applications/PaymentMethod/PaymentMethodList";
import { PaymentMethodAddEdit } from "./applications/PaymentMethod/PaymentMethodAddEdit";

import { TagListContainer } from "./applications/Tag/TagList";
import { TagNewContainer } from "./applications/Tag/Edit/NewTag";
import { TagEditContainer } from "./applications/Tag/Edit/EditTag";

//import {OrderNewContainer} from "./applications/Order/NewOrder";
import { OrderDetailContainer } from "./applications/Order/OrderDetail";
import { OrderEditContainer } from "./applications/Order/EditOrder";



import { OperatorPhoneListContainer } from "./applications/OperatorPhone/OperatorPhoneList";
import { OperatorPhoneNewContainer } from "./applications/OperatorPhone/Edit/NewOperatorPhone";
import { OperatorPhoneEditContainer } from "./applications/OperatorPhone/Edit/EditOperatorPhone";

import { StockListContainer } from "./applications/Stock/StockList";

import { StockOperationAddContainer } from "./applications/StockOperation/StockOperationAdd";
import { StockOperationListContainer } from "./applications/StockOperation/StockOperationList";


import { SupplierAddEdit } from "./applications/Supplier/SupplierAddEdit";
import { SupplierDetail } from "./applications/Supplier/SupplierDetail";

import { SupplierList } from "./applications/Supplier/SupplierList";

import { OperatorPhoneCallListContainer } from "./applications/OperatorPhoneCall/OperatorPhoneCallList";


import { MainBoard } from "./applications/MainBoard/MainBoard";

const  PrinterApp = lazy(() => import('./applications/Printer/'));

const  ShopApp = lazy(() => import('./applications/Shop'));
const  PackingListApp = lazy(() => import("./applications/PackingList"));

const AdminApp = lazy(() => import("./applications/Admin"));
const  AccountApp = lazy(() => import("./applications/Account"));


const  ReportApp = lazy(() => import('./applications/Report'));

const  ComapnyGroupApp = lazy(() => import('./applications/CompanyGroup'));
const  StockItemApp = lazy(() => import("./applications/StockItem"));
const  StockGroupApp = lazy(() => import("./applications/StockGroup"));
const  TransferListApp = lazy(() => import("./applications/TransferList"));
const  PaymentApp = lazy(() => import("./applications/Payment"));

const  FinanceApp = lazy(() => import("./applications/Finance"));
const  FeedbackApp = lazy(() => import("./applications/Feedback"));

const  InventoryApp = lazy(() => import('./applications/Inventory'));
const  InventoryGroupApp = lazy(() => import('./applications/InventoryGroup'));
const  CustomerApp = lazy(() => import('./applications/Customer'));
const  PaymentMethodExternalApp = lazy(() => import('./applications/PaymentMethodExternal'));
const KitchenApp = lazy(() => import('./applications/Kitchen'));
const DelivaryApp = lazy(() => import('./applications/Delivery'));

const  ModuleConfigurationnApp = lazy(() => import('./applications/ModuleConfiguration'));

const  EmployeeApp = lazy(() => import('./applications/Company/Employee'));

if (localStorage.getItem("isLogin") !== "true" || localStorage.getItem("loginExpiresIn") < (new Date).getTime()) {
    location.href = config.appUrl + "login.html";
}

window.CRM = {
  middleware: [middleware],
  isAdmin : localStorage.getItem("roles") == "Admin"
};


registerLocale("ru",DatePickerLocaleRu);
//registerLocale("en",DatePickerLocaleEn);

setDefaultLocale (window.navigator.language.slice(0, 2));
const reducer = combineReducers(reducers);
const createStoreWithMiddleware = applyMiddleware(middleware)(createStore);
const store = createStoreWithMiddleware(reducer, {});

configureUserSettingStore(store);
function checkNotification(firstTyme = false) {
    function callbackSuccess(data) {
        if (!firstTyme) {
            var notificationStore = store.getState().notificationStore;

            var messages = data.filter((x) => !notificationStore.list.find((y) => y.id == x.id));
            for (var i in messages) {
                var m = messages[i];
                window.notifyMe({ id: m.id, title: m.title, message: m.message });
            }
        }
        store.dispatch({ type: "NOTIFICATION_UPDATE", data });
        setTimeout(checkNotification, 1000 * 10);
    }

    function calbackkFail() {
        setTimeout(checkNotification, 1000 * 10);
    }
    var action = {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Notification",
        },
        actions: ["null", callbackSuccess, checkNotification],
    };

    store.dispatch(action);
}
checkNotification(true);
//const html = require("./index.html");

ReactDOM.render(

    <Provider store={store}>
        <Router basename={config.appUrl}>
            <App>
            <Suspense fallback={
              <Segment style={{height:"300px"}}>
                <Dimmer active inverted>
                  <Loader size='large'>Загрузка ресурсов</Loader>
                </Dimmer>
              </Segment>
            }>
                <Switch>
                    <Route  path={"/Admin"} component={AdminApp} />

                    
                    <Route  path={"/Account"} component={AccountApp} />
                    
                    <Route exact path={"/Company"} component={CompanyListContainer} />

                    <Route exact path={"/Company/:companyId/RoleCompany"} component={RoleCompanyListContainer} />
                    <Route exact path={"/Company/:companyId/RoleCompany/New"} component={RoleCompanyAddEditContainer} />
                    <Route exact path={"/Company/:companyId/RoleCompany/:roleId"} component={RoleCompanyDetailContainer} />
                    <Route exact path={"/Company/:companyId/RoleCompany/:roleId/Edit"} component={RoleCompanyAddEditContainer} />


                    <Route exact path={"/Company/New"} component={CompanyEditContainer} />
                    <Route exact path={"/Company/:id/Edit"} component={CompanyEditContainer} />

                    <Route exact path={"/Company/:companyId"} component={CurrentOpenOrdersContainer} />
                    <Route exact path={"/Company/:companyId/Operations"} component={CompanyContainer} />
                    
                    <Route exact path={"/CompanyGroup/:companyGroupId/Category"} component={CategoryListContainer} />
                    <Route exact path={"/CompanyGroup/:companyGroupId/Category/New"} component={CategoryEditContainer} />
                    <Route exact path={"/CompanyGroup/:companyGroupId/Category/:categoryId/Edit"} component={CategoryEditContainer} />

                    <Route exact path={"/Company/:companyId/Category"} component={CategoryListContainer} />
                    <Route exact path={"/Company/:companyId/Category/New"} component={CategoryEditContainer} />
                    <Route exact path={"/Company/:companyId/Category/:categoryId/Edit"} component={CategoryEditContainer} />

                    <Route exact path={"/Company/:companyId/Item"} component={ItemListContainer} />
                    <Route exact path={"/Company/:companyId/Item/New"} component={ItemEditContainer} />
                    <Route exact path={"/Company/:companyId/Item/:itemId/Edit"} component={ItemEditContainer} />

                    <Route exact path={"/CompanyGroup/:companyGroupId/Item"} component={ItemListContainer} />
                    <Route exact path={"/CompanyGroup/:companyGroupId/Item/New"} component={ItemEditContainer} />
                    <Route exact path={"/CompanyGroup/:companyGroupId/Item/:itemId/Edit"} component={ItemEditContainer} />


                    <Route exact path={"/Company/:companyId/OrderStatus"} component={OrderStatusListContainer} />
                    <Route exact path={"/Company/:companyId/OrderStatus/New"} component={OrderStatusNewContainer} />
                    <Route exact path={"/Company/:companyId/OrderStatus/:itemId/Edit"} component={OrderStatusEditContainer} />

                    <Route exact path={"/Company/:companyId/Discount"} component={DiscountListContainer} />
                    <Route exact path={"/Company/:companyId/Discount/New"} component={DiscountNewContainer} />
                    <Route exact path={"/Company/:companyId/Discount/:itemId/Edit"} component={DiscountNewContainer} />

                    <Route exact path={"/Company/:companyId/PaymentMethod"} component={PaymentMethodListContainer} />
                    <Route exact path={"/Company/:companyId/PaymentMethod/New"} component={PaymentMethodAddEdit} />
                    <Route exact path={"/Company/:companyId/PaymentMethod/:paymentMethodId/Edit"} component={PaymentMethodAddEdit} />

                    <Route exact path={"/Company/:companyId/Tag"} component={TagListContainer} />
                    <Route exact path={"/Company/:companyId/Tag/New"} component={TagNewContainer} />
                    <Route exact path={"/Company/:companyId/Tag/:itemId/Edit"} component={TagEditContainer} />

                    <Route exact path={"/Company/:companyId/OperatorPhone"} component={OperatorPhoneListContainer} />
                    <Route exact path={"/Company/:companyId/OperatorPhone/New"} component={OperatorPhoneNewContainer} />
                    <Route exact path={"/Company/:companyId/OperatorPhone/:itemId/Edit"} component={OperatorPhoneEditContainer} />

                    <Route exact path={"/Company/:companyId/Call"} component={OperatorPhoneCallListContainer} />

                    <Route exact path={"/Company/:companyId/Order/New"} component={OrderEditContainer} />
                    <Route exact path={"/Company/:companyId/Order/:orderId"} component={OrderDetailContainer} />
                    <Route exact path={"/Company/:companyId/Order/:orderId/Edit"} component={OrderEditContainer} />

                    <Route exact path={"/Company/:companyId/DashBoard"} component={DashBoard} />
                    <Route exact path={"/Company/:companyId/DashBoard/Realization"} component={DashBoardRealizationContainer} />
                    <Route exact path={"/Company/:companyId/DashBoard/MediumSummury"} component={OrderSummuryMediumContainer} />
                    <Route exact path={"/Company/:companyId/DashBoard/OrderCalendar"} component={OrderCalendar} />
                    <Route exact path={"/Company/:companyId/DashBoard/Summury"} component={CompanyDashBoardContainer } />


                    <Route exact path={"/Company/:companyId/Stock"} component={StockListContainer} />

                    <Route exact path={"/Company/:companyId/StockOperation"} component={StockOperationListContainer} />
                    <Route exact path={"/Company/:companyId/StockOperation/Add"} component={StockOperationAddContainer} />

                    <Route exact path={"/Company/:companyId/Supplier"} component={SupplierList} />
                    <Route exact path={"/Company/:companyId/Supplier/Add"} component={SupplierAddEdit} />
                    <Route exact path={"/Company/:companyId/Supplier/:supplierId/Detail"} component={SupplierDetail} />
                    <Route exact path={"/Company/:companyId/Supplier/:supplierId/Edit"} component={SupplierAddEdit} />
                    
                    <Route  path={"/Feedback"} component={FeedbackApp} />


                    <Route  path={"/Company/:companyId/Employee"} component={EmployeeApp} />
                    <Route  path={"/Company/:companyId/Printer"} component={PrinterApp} />
                    <Route  path={"/Company/:companyId/Shop"} component={ShopApp} />
                    <Route  path={"/Company/:companyId/Inventory"} component={InventoryApp} />
                    <Route  path={"/Company/:companyId/InventoryGroup"} component={InventoryGroupApp} />
                    <Route  path={"/Company/:companyId/Finance"} component={FinanceApp} />
                    <Route  path={"/Company/:companyId/PackingList"} component={PackingListApp} />

                    <Route  path={"/CompanyGroup/:companyGroupId/StockItem"} component={StockItemApp} />
                    <Route  path={"/Company/:companyId/StockItem"} component={StockItemApp} />

                    <Route  path={"/Company/:companyId/StockGroup"} component={StockGroupApp} />

                    <Route  path={"/Company/:companyId/TransferList"} component={TransferListApp} />
                    <Route  path={"/Company/:companyId/Payment"} component={PaymentApp} />
                    
                    <Route path={"/Company/:companyId/PaymentMethodExternal"} component={PaymentMethodExternalApp} />
            <Route path={"/Company/:companyId/Kitchen"} component={KitchenApp} />
            <Route path={ "/Company/:companyId/Delivery"} component={DelivaryApp} />

            
                    <Route  path={"/CompanyGroup"} component={ComapnyGroupApp} />  
                    
                    <Route  path={"/Company/:companyId/Report"} component={ReportApp} />
                    <Route path={"/Company/:companyId/Customer"} component={CustomerApp} />
                    <Route  path={"/Company/:companyId/ModuleConfiguration"} component={ModuleConfigurationnApp} />
            
                    
{/*
                    <Route exact path={"/Company/:companyId/Report"} component={ReportContainer} />
                    <Route exact path={"/Company/:companyId/ReportPayment"} component={ReportPaymentContainer} />
                    <Route exact path={"/Company/:companyId/ReportVigoda"} component={ReportVigodaContainer} />

                    
                    <Route exact path={"/Company/:companyId/Report/General"} component={ReportDownloadGeneralContainer} />
*/}
                    <Route exact path={"/Info"}  component={HelloWorld} />
                    <Route component={MainBoard} />

                </Switch>
                </Suspense>
            </App>
        </Router>
    </Provider>
    /*
    <Navbar>
       <Navbar.Header>
         <Navbar.Brand>
           <a href="#" style={ {marginTop : '-10px'} } >
               <img src='image\ra_leadportal.png'/>
           </a>
         </Navbar.Brand>
       </Navbar.Header>
       <Nav>
         <NavItem eventKey={1} href="#">Link</NavItem>
         <NavItem eventKey={2} href="#">Link</NavItem>
         <NavDropdown eventKey={3} title="Dropdown" id="basic-nav-dropdown">
           <MenuItem eventKey={3.1}>Action</MenuItem>
           <MenuItem eventKey={3.2}>Another action</MenuItem>
           <MenuItem eventKey={3.3}>Something else here</MenuItem>
           <MenuItem divider />
           <MenuItem eventKey={3.3}>Separated link</MenuItem>
         </NavDropdown>
       </Nav>
     </Navbar>
    */

    ,
    document.getElementById("app2")
);


window.onresize = () => changeSize();

function changeSize() {

    var sidebar = document.getElementById("general-content");
    sidebar.style.minHeight = (window.innerHeight - 60) + "px";

}
changeSize();

;

let toggleSidebar = document.getElementById("toggle-sidebar");
toggleSidebar.addEventListener("click", () => {
    let sp = document.getElementById("sidebar-parent");
    sp.style.display = sp.style.display == "block" ? "none" : "block";
    /* if(sp.style.display != "none"){
         sp.addEventListener("mouseout",ttt);
     }*/

});


