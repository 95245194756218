import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid, Header, Divider, Table, Label, Button, Modal ,Loader } from 'semantic-ui-react'
import * as actionCreators from "./actions";

function loadData(dispatch, state) {
    dispatch(actionCreators.initListData({ companyId: state.companyId }));

    if (state.force) {
        dispatch(actionCreators.initListPermission());
    }
}

export function RoleCompanyListContainer() {
    const { data } = useSelector(state => {
        return { data: state.roleCompanyStore };
    });

    const { companyId } = useParams();
    const location = useLocation();

    const dispatch = useDispatch();
    const prevStateRef = useRef({});
    const [openDeleteShowModal, setOpenDeleteShowModal] = useState(false);
    const [idItemForRemove, setIdItemForRemove] = useState(false);

    const history = useHistory();

    useEffect(() => {
        loadData(dispatch, {
            companyId,
            force:
                companyId !== prevStateRef.current.companyId
        });
        if (!prevStateRef.current) {
            prevStateRef.current = {};
        }
        prevStateRef.current.companyId = companyId;
    }, [companyId]);

    useEffect(() => {
        if (data.isShouldReload) {
            loadData(dispatch, { companyId });
        }
    }, [data.isShouldReload]);

    const showOrder = useCallback(e => {
        const target = e.currentTarget;

        history.push(`/Company/${target.dataset.companyId}/Order/${target.dataset.orderId}`);
    });
    const handleOnCliclRemove = useCallback((e, target) => {
        const id = e.currentTarget.dataset.id;

        setIdItemForRemove(id);
        setOpenDeleteShowModal(true);
    });
    const handleCloseopenDeleteModal = useCallback((e, target) => {

        if (target.positive) {
            dispatch(actionCreators.deleteItem({ roleId: idItemForRemove, companyId: companyId }))
        }
        setIdItemForRemove(null);
        setOpenDeleteShowModal(false);

    });

    return <Grid>
        <Grid.Row>
            <Grid.Column>
                <Header size='huge'>Все роли компании</Header>
            </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
            <Grid.Column>
                <Grid.Row>
                    <Button color="green" as={Link} to={location.pathname + "/New"}>Добавить роль</Button>
                </Grid.Row>

                <Table >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Название</Table.HeaderCell>

                            <Table.HeaderCell>Public</Table.HeaderCell>
                            {!companyId && <Table.HeaderCell >Компания</Table.HeaderCell>}
                            <Table.HeaderCell>Действия</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.isLoading && <Table.Row >
                                <Table.Cell colSpan="100">
                                    <Loader active inline="centered" size="big" />
                                </Table.Cell>
                            </Table.Row>
                        }
                        {data.list.map((item) => {
                            return <Table.Row key={item.id}>
                                <Table.Cell>
                                    {item.id}
                                </Table.Cell>
                                <Table.Cell>{item.name}</Table.Cell>
                                <Table.Cell>{item.isPublic.toString()}</Table.Cell>
                                {!companyId && <Table.Cell>{item.companyId}</Table.Cell>}
                                <Table.Cell>
                                    <Button as={Link} to={location.pathname + "/" + item.id} icon='search' content='Просмотр' />
                                    <Button as={Link} to={location.pathname + "/" + item.id + "/Edit"} disabled={item.isPublic && companyId} icon='edit' content='Редактировать' />
                                    <Button onClick={handleOnCliclRemove} data-id={item.id} disabled={item.isPublic && companyId} icon='remove' color="red" content='Удалить' />
                                </Table.Cell>
                            </Table.Row>
                        })
                        }
                    </Table.Body>
                </Table>
            </Grid.Column>
            <Modal
                open={openDeleteShowModal}
                onClose={handleCloseopenDeleteModal}
            >
                <Modal.Header>Удалить Роль?</Modal.Header>
                <Modal.Content >
                    Уверены,что хотите удалить роль?
                    </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={handleCloseopenDeleteModal}  >Нет</Button>
                    <Button positive onClick={handleCloseopenDeleteModal} labelPosition='right' icon='checkmark' content='Да' />
                </Modal.Actions>
            </Modal>
        </Grid.Row>
    </Grid>;

}

