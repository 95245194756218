import * as  config from "Config";
import * as constants from "./constants";

export function clearStore(newObject) {
    return {
        type: "ORDER_CLEAR_STORE"
    };
}


export function initOrderDetailData(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Order/Company/" + params.companyId + "/" + params.orderId,
        },
        actions: ["ORDER_DETAIL_LOAD_START", "ORDER_DETAIL_LOAD_SUCCESS", "ORDER_DETAIL_LOAD_FAIL"]
    };
}

export function initOrderEditData(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Order/Company/" + params.companyId + "/" + params.orderId,
        },
        actions: ["ORDER_EDIT_LOAD_START", "ORDER_EDIT_LOAD_SUCCESS", "ORDER_EDIT_LOAD_FAIL"]
    };
}

export function saveOrderEditData(params) {
    return {
        remote: {
            method: "post",
            url: config.apiUrl + "/api/Order/" + params.orderId + "/Company/" + params.companyId,
            data: params
        },
        actions: ["ORDER_EDIT_SAVE_START", "ORDER_EDIT_SAVE_SUCCESS", "ORDER_EDIT_SAVE_FAIL"]
    };
}

export function initCustomerInfoByPhone(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Customer/Company/" + params.companyId + "/" + params.phone,
        },
        actions: ["ORDER_CUSTOMER_INFO_LOAD_START", "ORDER_CUSTOMER_INFO_LOAD_SUCCESS", "ORDER_CUSTOMER_INFO_LOAD_FAIL"]
    };
}


export function saveNewOrder(params) {
    return {
        remote: {
            method: "post",
            url: config.apiUrl + "/api/Order/Company/" + params.companyId,
            data: params
        },
        actions: ["ORDER_NEW_SAVE_START", "ORDER_NEW_SAVE_SUCCESS", "ORDER_NEW_SAVE_FAIL"]
    };
}

export function saveOrderChangeStatus(params) {
    return {
        remote: {
            method: "PATCH",
            url: config.apiUrl + "/api/Order/Company/" + params.companyId + "/" + params.orderId,
            data: params
        },
        actions: ["ORDER_CHANGE_STATUS_START", "ORDER_CHANGE_STATUS_SUCCESS", "ORDER_CHANGE_STATUS_FAIL"]
    };
}

export function initOrderPayment(params) {
    return {
        remote: {
            method: 'get',
            url: `${config.apiUrl}/api/Order/${params.orderId}/Company/${params.companyId}/Payment`,
            //data: params,
            token: true,
        },
        actions: [
            "ORDER_PAYMENT_LOAD_START", "ORDER_PAYMENT_LOAD_SUCCESS", "ORDER_PAYMENT_LOAD_FAIL"
        ],
    };
}

export function saveOrderPayment(params) {
    return {
        remote: {
            method: 'post',
            url: `${config.apiUrl}/api/Order/${params.orderId}/Company/${params.companyId}/Payment`,
            data: params,
            token: true,
        },
        actions: [
            "ORDER_CHANGE_STATUS_START", "ORDER_CHANGE_STATUS_SUCCESS", "ORDER_CHANGE_STATUS_FAIL"
        ],
    };
}

export function saveOrderExternalPayment(params) {
    return {
        remote: {
            method: "post",
            url: `${config.apiUrl}/api/Order/${params.orderId}/Company/${params.companyId}/ExternalPayment`,
            data: params,
            token: true,
        },
        actions: [
            constants.itemSaveInvoiceStart,
            constants.itemSaveInvoiceSuccess,
            constants.itemSaveInvoiceFail,
        ],
    };
}
export function saveOrderExternalPaymentCheck(params) {
    return {
        remote: {
            method: "post",
            url: `${config.apiUrl}/api/Order/${params.orderId}/Company/${params.companyId}/CheckExternalPayment/${params.paymentId}`,
            data: params,
            token: true,
        },
        actions: [
            constants.itemCheckInvoiceStart,
            constants.itemCheckInvoiceSuccess,
            constants.itemCheckInvoiceFail,
        ],
    };
}
export function saveOrderPaymentConfirm(params) {
    return {
        remote: {
            method: "post",
            url: `${config.apiUrl}/api/Order/${params.orderId}/Company/${params.companyId}/ConfirmPayment/${params.paymentId}`,
            data: params,
            token: true,
        },
        actions: [
            constants.itemCheckInvoiceStart,
            constants.itemCheckInvoiceSuccess,
            constants.itemCheckInvoiceFail,
        ],
    };
}
export function saveOrderPaymentReject(params) {
    return {
        remote: {
            method: "post",
            url: `${config.apiUrl}/api/Order/${params.orderId}/Company/${params.companyId}/RejectPayment/${params.paymentId}`,
            data: params,
            token: true,
        },
        actions: [
            constants.itemCheckInvoiceStart,
            constants.itemCheckInvoiceSuccess,
            constants.itemCheckInvoiceFail,
        ],
    };
}
export function InitOrderList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Order/Company/" + params.companyId,
            data: params
        },
        actions: ["ORDER_LIST_LOAD_START", "ORDER_LIST_LOAD_SUCCESS", "ORDER_LIST_LOAD_FAIL"]
    };
}
export function InitOrderListSummury(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Order/Company/" + params.companyId + "/Summury",
            data: params
        },
        actions: ["ORDER_LIST_SUMMURY_LOAD_START", "ORDER_LIST_SUMMURY_LOAD_SUCCESS", "ORDER_LIST_SUMMURY_LOAD_FAIL"]
    };
}

export function initCategoryList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Category/Company/" + params.companyId
        },
        actions: ["CATEGORY_LIST_LOAD_START", "CATEGORY_LIST_LOAD_SUCCESS", "CATEGORY_LIST_LOAD_FAIL"]
    };
}

export function initItemList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Item/Company/" + params.companyId
        },
        actions: ["ITEM_LIST_LOAD_START", "ITEM_LIST_LOAD_SUCCESS", "ITEM_LIST_LOAD_FAIL"]
    };
}
export function initStatusOrderList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/OrderStatus/Company/" + params.companyId
        },
        actions: ["ORDER_STATUS_LIST_LOAD_START", "ORDER_STATUS_LIST_LOAD_SUCCESS", "ORDER_STATUS_LIST_LOAD_FAIL"]
    };
}

export function initTagList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Tag/Company/" + params.companyId
        },
        actions: ["TAG_LIST_LOAD_START", "TAG_LIST_LOAD_SUCCESS", "TAG_LIST_LOAD_FAIL"]
    };
}

export function initDiscountList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Discount/Company/" + params.companyId
        },
        actions: ["DISCOUNT_LIST_LOAD_START", "DISCOUNT_LIST_LOAD_SUCCESS", "DISCOUNT_LIST_LOAD_FAIL"]
    };
}

export function printPreBill(params) {
    //window.print();

    return {
        remote: {
            method: "post",
            url: `${config.apiUrl}/PreBill/Company/${params.companyId}/${params.orderId}`
        },
        actions: ["none", "none", "none"]
    };

    /*   let  data = '<iframe src="'+config.apiUrl+'/PreBill/Company/'+params.companyId+'/'+params.orderId+'" style="height:90%; width:90%;" align="left"></iframe>';
   //var newWin = open("data:text/html," + encodeURIComponent(data), "_blank", 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,top=-100,left=-300,width=400,height=800');
   
   var newWin = open();
   
   //newWin.document.write("");
   newWin.document.write('<iframe src="'+config.apiUrl+'/PreBill/Company/'+params.companyId+'/'+params.orderId+'" style=" width:10cm; height:100%; border: none" align="left">Loading</iframe>');
   newWin.focus();
   return {
       type:"none"
   };*/
}


export function printKitchen(params) {
    return {
        remote: {
            method: "post",
            url: `${config.apiUrl}/PreBill/Company/${params.companyId}/${params.orderId}/Kitchen`
        },
        actions: ["none", "none", "none"]
    };

}

export function printUsedStockItems(params) {
    return {
        remote: {
            method: "post",
            url: `${config.apiUrl}/PreBill/Company/${params.companyId}/${params.orderId}/UsedStockItems`
        },
        actions: ["none", "none", "none"]
    };

}


export function initItemHistory(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/OrderHistory/" + params.orderId + "/Company/" + params.companyId
        },
        actions: ["ITEM_HISTORY_LOAD_START", "ITEM_HISTORY_LOAD_SUCCESS", "ITEM_HISTORY_LOAD_FAIL"]
    };
}

export function initPrimeCost(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Order/Company/" + params.companyId + "/" + params.orderId + "/PrimeCost"
        },
        actions: [constants.itemPrimeCostLoadStart, constants.itemPrimeCostLoadSuccess, constants.itemPrimeCostLoadFail]
    };
}