import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == "COMPANY_CLEAR_STORE") {
        state = {
            data: {},
            userList: [],
            isUserListLoading:false,
            isUserListSaved:false,
            isLoading: false,
            isSave: false,
            isLoaded: false,
        };
    }

    switch (action.type) {
        case "COMPANY_NEW_SAVE_START":
            return { ...state, ...{ isLoading: true, error: undefined } };
        case "COMPANY_NEW_SAVE_SUCCESS":
            return {
                ...state,
                ...{  isLoading: false, isSave: true },
            };
        case "COMPANY_NEW_SAVE_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "COMPANY_SAVE_START":
            return { ...state, ...{ isLoading: true, error: undefined } };
        case "COMPANY_SAVE_SUCCESS":
            return {
                ...state,
                ...{isLoading: false, isSave: true },
            };
        case "COMPANY_SAVE_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "COMPANY_DETAIL_START":
            return { ...state, ...{ isLoading: true, error: undefined } };
        case "COMPANY_DETAIL_SUCCESS":
            return {
                ...state,
                ...{ data: action.data, isLoading: false, isLoaded: true },
            };
        case "COMPANY_DETAIL_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.COMPANY_USER_LIST_LOAD_START:
            return {
                ...state,
                ...{ userList: [], isUserListLoading: true, isUserListSaved:false,error: undefined },
            };
        case constants.COMPANY_USER_LIST_LOAD_SUCCESS:
            return {
                ...state,
                ...{ userList: action.data, isUserListLoading: false },
            };
        case constants.COMPANY_USER_LIST_LOAD_FAIL:
            return { ...state, ...{ isUserListLoading: false, error: action.error } };

            case constants.COMPANY_USER_ADD_START:
                return {
                    ...state,
                    ...{  isUserListLoading: true, error: undefined },
                };
            case constants.COMPANY_USER_ADD_SUCCESS:
                return {
                    ...state,
                    ...{  isUserListLoading: false,isUserListSaved:true},
                };
            case constants.COMPANY_USER_ADD_FAIL:
                return { ...state, ...{ isUserListLoading: false, error: action.error } };
    
        default:
            return state;
    }
}
