import React, { useCallback, useEffect, useState } from "react";
import { Grid, Header, Divider, Table, Label, Button, Modal, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as actionCreators from "./actions";

function loadData(dispatch, state) {
    dispatch(
        actionCreators.initListDataSummury({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        })
    );
}

export function CategoryListContainer() {
    const [idForRemove, setIdForRemove] = useState(null);
    const { companyId, companyGroupId } = useParams();
    const dispatch = useDispatch();
    const { categoryStore } = useSelector((state) => {
        return {
            categoryStore: state.categoryList,
        };
    });
    useEffect(() => {
        loadData(dispatch, { companyId, companyGroupId });
    }, [companyId, companyGroupId]);

    useEffect(() => {
        if (categoryStore.isRequiredReload) {
            loadData(dispatch, { companyId, companyGroupId });
        }
    }, [companyId, companyGroupId, categoryStore.isRequiredReload]);

    function getitemById(id) {
        if (!categoryStore.categoryListSummury || !id) {
            return {};
        }
        return categoryStore.categoryListSummury.find((x) => x.id === id) || {};
    }
    const handleCloseopenDeleteModal = useCallback(
        (e, button) => {
            if (button.positive) {
                dispatch(
                    actionCreators.deleteCategory({
                        id: idForRemove,
                        companyId,
                        companyGroupId,
                    })
                );
            }
            setIdForRemove(null);
        },
        [companyId, companyGroupId, idForRemove]
    );

    let urlPrefix = "";
    if (companyGroupId) {
        urlPrefix = "/CompanyGroup/" + companyGroupId;
    }
    if (companyId) {
        urlPrefix = "/Company/" + companyId;
    }
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="huge">Все Категории</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Grid.Row>
                        <Button color="green" as={Link} to={urlPrefix + "/Category/New"}>
                            Добавить новую категорию{" "}
                        </Button>
                    </Grid.Row>

                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Название</Table.HeaderCell>

                                <Table.HeaderCell>Количество товаров</Table.HeaderCell>
                                <Table.HeaderCell>Включена</Table.HeaderCell>

                                <Table.HeaderCell>Действия</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {!!categoryStore.categoryListSummury &&
                                categoryStore.categoryListSummury.map((item) => {
                                    return (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item.id}</Table.Cell>
                                            <Table.Cell>{item.name}</Table.Cell>
                                            <Table.Cell>
                                                <Label circular color="purple">
                                                    {item.countItems}
                                                </Label>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.isActive ? (
                                                    <Icon name="check" />
                                                ) : (
                                                    <Icon name="ban" />
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    as={Link}
                                                    to={{
                                                        pathname: urlPrefix + "/Item",
                                                        search: "category=" + item.id,
                                                    }}
                                                    icon="search"
                                                    content="Товары"
                                                />
                                                {((!item.isCompanyGroup && companyId) ||
                                                    (item.isCompanyGroup && companyGroupId)) && (
                                                    <>
                                                        <Button
                                                            as={Link}
                                                            to={
                                                                urlPrefix +
                                                                "/Category/" +
                                                                item.id +
                                                                "/Edit"
                                                            }
                                                            icon="edit"
                                                            content="Редактировать"
                                                        />
                                                        <Button
                                                            onClick={() => {
                                                                setIdForRemove(item.id);
                                                            }}
                                                            icon="remove"
                                                            color="red"
                                                            content="Удалить"
                                                        />
                                                    </>
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Modal open={!!idForRemove} onClose={handleCloseopenDeleteModal}>
                    <Modal.Header>
                        Удалить Категорию "{getitemById(idForRemove).name}" ?
                    </Modal.Header>
                    <Modal.Content>Уверены,что хотите категорию?</Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={handleCloseopenDeleteModal}>
                            Нет
                        </Button>
                        <Button
                            positive
                            onClick={handleCloseopenDeleteModal}
                            labelPosition="right"
                            icon="checkmark"
                            content="Да"
                        />
                    </Modal.Actions>
                </Modal>
            </Grid.Row>
        </Grid>
    );
}
