import * as constants from "./constants";

const reducer = {
    [constants.listLoadStart]: function (state) {
        return { ...state, ...{ isLoading: true } };
    },

    [constants.listLoadSuccess]: function (state, action) {
        return {
            ...state,
            ...{
                list: Array.from(action.data, (x) => {
                    return { ...x, date: new Date(x.date), data: JSON.parse(x.data) };
                }).sort(compare),
                isLoading: false,
            },
        };
    },
    [constants.listLoadFail]: function (state, action) {
        return { ...state, ...{ isLoading: true, error: action.error } };
    },
    [constants.itemDeleteStart]: function (state) {
        return { ...state, ...{ isLoading: true, isShouldReload: false } };
    },
    [constants.itemDeleteSuccess]: function (state) {
        return { ...state, ...{ isLoading: false, isShouldReload: true } };
    },
    [constants.itemDeleteFail]: function (state, action) {
        return { ...state, ...{ isLoading: true, error: action.error } };
    },
};
export default function (state, action) {
    if (!state || action.type == constants.clearStore) {
        state = {
            list: [],
            isLoading: false,
            isShouldReload: false,
        };
    }
    if (!!reducer[action.type]) {
        return reducer[action.type](state, action);
    }

    return state;
}

function compare(a, b) {
    const bandA = a.date;
    const bandB = b.date;

    let comparison = 0;
    if (bandA > bandB) {
        comparison = 1;
    } else if (bandA < bandB) {
        comparison = -1;
    }
    return -comparison;
}
