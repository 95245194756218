import * as config from "Config";


export function clearStore(newObject) {
    return {
      type:"OPERATOR_PHONE_LIST_CLEAR_STORE"
    };
}

export function initListData(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/OperatorPhone/Company/"+params.companyId + "/Summury"
      },
      actions:["OPERATOR_PHONE_LIST_SUMMURY_LOAD_START","OPERATOR_PHONE_LIST_SUMMURY_LOAD_SUCCESS","OPERATOR_PHONE_LIST_SUMMURY_LOAD_FAIL"]
    };
}

export function deleteOperatorPhone(params) {
    return {
      remote:{
          method: "delete",
          url: config.apiUrl + "/api/OperatorPhone/"+params.id + "/Company/" + params.companyId
      },

      actions:["OPERATOR_PHONE_LIST_REMOVE_START","OPERATOR_PHONE_LIST_REMOVE_SUCCESS","OPERATOR_PHONE_LIST_REMOVE_FAIL"]
    };
}

export function resetTokenOperatorPhone(params) {
    return {
      remote:{
          method: "post",
          url: config.apiUrl + "/api/OperatorPhone/"+params.id + "/Company/" + params.companyId+"/ResetToken"
      },

      actions:["OPERATOR_PHONE_LIST_RESET_TOKEN_START","OPERATOR_PHONE_LIST_RESET_TOKEN_SUCCESS","OPERATOR_PHONE_LIST_RESET_TOKEN_FAIL"]
    };
}



