import React, { Component } from 'react';
import { Grid, Header, Divider, Table, Label, Button } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as actionCreators from "./actions";
import * as utils from "@hitek-crm-utils";
import DatePicker from "@hitek-crm/DatePicker";


class Progress extends Component {
    render() {
        if (!this.props.start) return <div />;

        let wait = this.props.call ? this.props.call.getTime() - this.props.start.getTime() : (this.props.end ? this.props.end - this.props.start.getTime() : Date.now());
        let call = this.props.call && this.props.end ? this.props.end.getTime() - this.props.call.getTime() : 0;
        let total = wait + call;

        return <div style={{ "height": "24px" }}>

            <div style={{ height: "100%", "width": wait / total * 100 + "%", backgroundColor: "grey", display: "inline-block", textAlign: "center" }} alt={"Ожидание"} > {Math.round(wait / 1000)} {wait / total * 100 > 17 ? "с" : ""}</div>
            <div style={{ height: "100%", "width": call / total * 100 + "%", backgroundColor: "#21ba45", display: (call == 0 ? "none" : "inline-block"), textAlign: "center" }}>{Math.round(call / 1000)} с</div>
        </div>;

    }

}
export class OperatorPhoneCallList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId,
            startDate: new Date(new Date().toDateString())
        };

        let searchParam = new URLSearchParams(this.props.location.search);
        if (searchParam.has("date")) {

            this.state.startDate = new Date(searchParam.get("date"));
        };

        this.statusCallColor = {
            "StartRing": "blue",
            "Call": "purpure",
            "EndCall": "green",
            "EndRing": "red"

        };
        this.statusCall = {
            "StartRing": "Звонок",
            "Call": "Заговор",
            "EndCall": "Звонок обработан",
            "EndRing": "Звонок непринят"

        };

        this.props.clearStore();
        this.props.initListData({ companyId: this.state.companyId, startDate: this.state.startDate.toISOString() });
        // Operations usually carried out in componentWillMount go here
        this.handleChangeDateForReport = this.handleChangeDateForReport.bind(this);
    }

    getDataForTable() {
        return this.props.operatorPhoneCall.listSummury;

    }

    componentWillReceiveProps(nextProps) {

        if (!!nextProps.operatorPhone && nextProps.operatorPhone.isShouldReload) {
            this.props.clearStore();
            this.props.initListData({ companyId: this.state.currentCompanyId });

        }
        if (this.props.match.params.companyId != nextProps.match.params.companyId || this.props.location.search != nextProps.location.search) {

            let newp = {
                companyId: nextProps.match.params.companyId,
                startDate: new Date(new Date().toDateString())

            };

            let searchParam = new URLSearchParams(nextProps.location.search);
            if (searchParam.has("date")) {

                newp.startDate = new Date(searchParam.get("date"));
            }

            this.setState(newp);
            this.props.initListData({ companyId: nextProps.match.params.companyId, startDate: newp.startDate.toISOString(("YYYY-MM-DDT00:00:00.000Z")) });
        }
    }
    handleChangeDateForReport(date) {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: "date=" + utils.toShortDateString(date)

        }
        );
        this.setState({
            startDate: date
        });
    }
    render() {
        return <Grid>
            <Grid.Row columns={3}>
                <Grid.Column width={10}>
                    <Header size='huge'>Список поступивших вызовов</Header>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5} className="ui small input">

                    Фильтр по дате: <DatePicker selected={this.state.startDate}
                        onChange={this.handleChangeDateForReport}
                        maxDate={new Date()} />

                </Grid.Column>
                <Grid.Column width={1}>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Header size='medium'>{this.state.startDate.toLocaleDateString() == (new Date()).toLocaleDateString() ? "Входящие звонки на сегодня" : "Входящие звонки на " + this.state.startDate.toLocaleDateString()}</Header>


                    <Table >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Входящий номер</Table.HeaderCell>
                                <Table.HeaderCell>Принимающий номер</Table.HeaderCell>
                                <Table.HeaderCell>Дата звонка</Table.HeaderCell>
                                <Table.HeaderCell>Время звонка</Table.HeaderCell>
                                <Table.HeaderCell>Статус звонка</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Действия</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {this.getDataForTable().map((call) => {
                                return <Table.Row key={call.id}>
                                    <Table.Cell>
                                        {call.number}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Label circular color={call.operatorPhoneColor}>{call.operatorPhoneNumber}</Label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {call.startRing ? (new Date(call.startRing)).toLocaleDateString() : ""}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {call.startRing ? (new Date(call.startRing)).toLocaleTimeString() : ""}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Label circular color={this.statusCallColor[call.statusCall]}>{this.statusCall[call.statusCall]}</Label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Progress
                                            start={call.startRing ? (new Date(call.startRing)) : null}
                                            call={call.call ? (new Date(call.call)) : null}
                                            end={call.endRing ? (new Date(call.endRing)) : null}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {!call.orderId ?
                                            <Button as={Link}
                                                to={
                                                    {
                                                        pathname: "/Company/" + this.state.companyId + "/Order/New",
                                                        state: { orderPhoneCallId: call.id, orderPhoneCallNumber: call.number }
                                                    }
                                                }
                                                size="small"
                                                icon='add'
                                                primary
                                            />
                                            :
                                            <Button as={Link} to={"/Company/" + this.state.companyId + "/Order/" + call.orderId}
                                                size="small"
                                                icon='search'

                                            />
                                        }
                                        {/*<Button  onClick={()=>{this.onRemoveOperatorPhone(status.id)}} icon='remove' color="red" content='Удалить' /> */}

                                    </Table.Cell>
                                </Table.Row>
                            })
                            }
                        </Table.Body>
                        <Table.Footer>

                        </Table.Footer>
                    </Table>

                </Grid.Column>
            </Grid.Row>
        </Grid>;

    }
};


function mapStateToProps(state) {

    return {

        operatorPhoneCall: state.operatorPhoneCallList
    };
}


export const OperatorPhoneCallListContainer = connect(mapStateToProps, actionCreators)(OperatorPhoneCallList);
