import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == constants.CATEGORY_LIST_CLEAR_STORE) {
        let oldCategoryList = undefined;
        if (!!state) {
            oldCategoryList = state.categoryList;
        }

        state = {
            categoryListSummury: [],
            categoryList: oldCategoryList || [],
            userList: [],
            isCategoryListLoading: false,
            isLoading: false,
            isSave: false,
            isLoaded: false,
            isRequiredReload: false,
        };
    }
    switch (action.type) {
        case constants.CATEGORY_LIST_SUMMURY_LOAD_START:
            return {
                ...state,
                ...{
                    categoryListSummury: [],
                    isRequiredReload: false,
                    isLoading: true,
                    error: undefined,
                },
            };
        case constants.CATEGORY_LIST_SUMMURY_LOAD_SUCCESS:
            return {
                ...state,
                ...{ categoryListSummury: action.data.sort(compare), isLoading: false },
            };
        case constants.CATEGORY_LIST_SUMMURY_LOAD_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "CATEGORY_LIST_LOAD_START":
            return {
                ...state,
                ...{
                    categoryList: [],
                    isRequiredReload: false,
                    isCategoryListLoading: true,
                    error: undefined,
                },
            };
        case "CATEGORY_LIST_LOAD_SUCCESS":
            return {
                ...state,
                ...{
                    categoryList: createFullName(action.data).sort(compare("fullName")),
                    isCategoryListLoading: false,
                },
            };
        case "CATEGORY_LIST_LOAD_FAIL":
            return {
                ...state,
                ...{ isCategoryListLoading: false, error: action.error },
            };

        case constants.CATEGORY_LIST_REMOVE_START:
            return { ...state, ...{ isLoading: true } };
        case constants.CATEGORY_LIST_REMOVE_SUCCESS:
            return {
                ...state,
                ...{ isRequiredReload: true, isLoading: false, isShouldReload: true },
            };
        case constants.CATEGORY_LIST_REMOVE_FAIL:
            return { ...state, ...{ isLoading: true, error: action.error } };
        default:
            return state;
    }
}

function compare(compare = "name") {
    return function (a, b) {
        const bandA = a[compare].toUpperCase();
        const bandB = b[compare].toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    };
}

function createFullName(arr) {
    let res = [];
    for (let i = 0; i < arr.length; i += 1) {
        const t = { ...arr[i], fullName: arr[i].name + "" };
        let pi = t.parentId;
        while (pi != undefined) {
            let k = arr.find((x) => x.id === pi);
            if (!k) {
                break;
            }
            t.fullName = k.name + " - " + t.fullName;
            pi = k.parentId;
        }
        res.push(t);
    }
    return res;
}
