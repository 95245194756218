import * as config from "Config";
import * as constants from "./constants";


export function clearStore(newObject) {
  return {
    type: constants.CLEAR_STORE
  };
}

export function initListPermission(params) {
  return {
    remote: {
      method: "get",
      url: config.apiUrl + "/api/RoleCompany/Permissions"
    },
    actions: [constants.ROLE_COMPANY_PERMISSION_LIST_LOAD_START, constants.ROLE_COMPANY_PERMISSION_LIST_LOAD_SUCCESS, constants.ROLE_COMPANY_PERMISSION_LIST_LOAD_FAIL]
  };
}

export function initListData(params) {
  return {
    remote: {
      method: "get",
      url: config.apiUrl + "/api/RoleCompany" + (params.companyId ? "/Company/" + params.companyId : "")
    },
    actions: [constants.ROLE_COMPANY_LIST_LOAD_START, constants.ROLE_COMPANY_LIST_LOAD_SUCCESS, constants.ROLE_COMPANY_LIST_LOAD_FAIL]
  };
}

export function initItemData(params) {
  return {
    remote: {
      method: "get",
      url: config.apiUrl + "/api/RoleCompany/" + params.roleId + (params.companyId ? "/Company/" + params.companyId : "")
    },
    actions: [constants.ROLE_COMPANY_ITEM_LOAD_START, constants.ROLE_COMPANY_ITEM_LOAD_SUCCESS, constants.ROLE_COMPANY_ITEM_LOAD_FAIL]
  };
}

export function saveNewItemData(params) {
  return {
    remote: {
      method: "post",
      url: config.apiUrl + "/api/RoleCompany" + (params.companyId ? "/Company/" + params.companyId : ""),
      data: params.data
    },
    actions: [constants.ROLE_COMPANY_ITEM_SAVE_START, constants.ROLE_COMPANY_ITEM_SAVE_SUCCESS, constants.ROLE_COMPANY_ITEM_SAVE_FAIL]
  };
}

export function saveEditItemData(params) {
  return {
    remote: {
      method: "put",
      url: config.apiUrl + "/api/RoleCompany/" + params.roleId + (params.companyId ? "/Company/" + params.companyId : ""),
      data: params.data
    },
    actions: [constants.ROLE_COMPANY_ITEM_SAVE_START, constants.ROLE_COMPANY_ITEM_SAVE_SUCCESS, constants.ROLE_COMPANY_ITEM_SAVE_FAIL]
  };
}





export function deleteItem(params) {
  return {
    remote: {
      method: "delete",
      url: config.apiUrl + "/api/RoleCompany/" + params.roleId + (params.companyId ? "/Company/" + params.companyId : "")
    },

    actions: [constants.ROLE_COMPANY_ITEM_DELETE_START, constants.ROLE_COMPANY_ITEM_DELETE_SUCCESS, constants.ROLE_COMPANY_ITEM_DELETE_FAIL]
  };
}

