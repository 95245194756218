import { useCallback, useEffect, useState } from "react";
const _defaultConfig = {
    countPerPage: 25,
};
const _descriptionConfig = {
    countPerPage: {
        description: "Колличество записей на странице",
        type: "int",
    },
};

let store = null;

let listenersMethod = {};
export function _useUserSetting({
    moduleName,
    defaultConfig = {},
    defaultDescriptionConfig = {},
    callback,
}) {
    /* const { userSettingStore } = useSelector((state) => {
    return {
      userSettingStore: state.userSettingStore,
    };
  });
*/
    const { userSettingStore } = store.getState();
    let moduleSetting = {};
    if (userSettingStore.list[moduleName]) {
        moduleSetting = userSettingStore.list[moduleName];
        console.log(moduleSetting);
    }

    if (listenersMethod[moduleName]) {
        listenersMethod[moduleName] = {
            moduleName,
            defaultConfig,
            defaultConfig,
            callback,
        };
    } else {
        listenersMethod[moduleName] = {
            moduleName,
            callback,
        };
    }
    return {
        activeConfig: { ..._defaultConfig, ...defaultConfig, ...moduleSetting },
        descriptionConfig: { ..._descriptionConfig, ...defaultDescriptionConfig },
    };
}

let lastUpdateDate = null;
function handleChange() {
    const { userSettingStore } = store.getState();

    if (userSettingStore.updateDate != lastUpdateDate) {
        lastUpdateDate = userSettingStore.updateDate;
        for (const name of Object.keys(listenersMethod)) {
            const conf = listenersMethod[name];
            let moduleSetting = userSettingStore.list[conf.moduleName] || {};

            let res = {
                activeConfig: { ..._defaultConfig, ...conf.defaultConfig, ...moduleSetting },
                descriptionConfig: { ..._descriptionConfig },
            };
            if (conf.callback) {
                conf.callback(res);
            }
        }
    }
}
export function configureUserSettingStore(_store) {
    store = _store;
    window.CRM.getUserSetting = function ({ moduleName, defaultConfig = {}, callback }) {
        return _useUserSetting({ moduleName, defaultConfig, callback });
    };
    window.CRM.unsubscribeUserSettingStore = function (moduleName) {
        delete listenersMethod[moduleName];
    };

    const unsubscribe = store.subscribe(handleChange);
    //unsubscribe()
}

export function unsubscribeUserSettingStore(moduleName) {
    delete listenersMethod[moduleName];
}

export function useUserSetting(MODULE_NAME, defaultConfig, defaultDescription) {
    const [activeConfig, setActiveConfig] = useState({ ..._defaultConfig, ...defaultConfig });
    const [descriptionConfig, setDescriptionConfig] = useState({
        ..._descriptionConfig,
        ...defaultDescription,
    });

    useEffect(() => {
        const {
            activeConfig: _activeConfig,
            descriptionConfig: _descriptionConfig,
        } = window.CRM.getUserSetting({
            moduleName: MODULE_NAME,
            defaultConfig: activeConfig,
            defaultDescriptionConfig: descriptionConfig,
            callback: function ({
                activeConfig: _activeConfig,
                descriptionConfig: _descriptionConfig,
            }) {
                setActiveConfig(_activeConfig);
                setDescriptionConfig(_descriptionConfig);
            },
        });
        setDescriptionConfig(descriptionConfig);
        setActiveConfig(_activeConfig);

        return () => {
            window.CRM.unsubscribeUserSettingStore(MODULE_NAME);
        };
    }, []);

    const handler = useCallback(() => {
        window.CRM.changeSettingModal(MODULE_NAME, activeConfig, descriptionConfig);
    }, [activeConfig, descriptionConfig, MODULE_NAME]);

    return [activeConfig, handler];
}
