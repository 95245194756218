import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  Header,
  Button,
  Divider,
  Dimmer,
  Loader,
  Card,
} from "semantic-ui-react";
import * as actionCreators from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";

import * as utils from "@hitek-crm-utils";
import DatePicker from "@hitek-crm/DatePicker";

function getCompanyName(companyList, icurrentId) {
  let companies = companyList;
  if (!Array.isArray(companies)) return null;
  let company = companies.find((x) => x.id == icurrentId);
  if (!!company) {
    return company.name;
  }
  return null;
}

function loadData(dispatch, state) {
  dispatch(
    actionCreators.initDashboardFinance({
      companyId: state.companyId,
      startDate: state.startDate,
    })
  );
  dispatch(
    actionCreators.initDashboardPayment({
      companyId: state.companyId,
      startDate: state.startDate,
    })
  );
  dispatch(
    actionCreators.initDashboardOrder({
      companyId: state.companyId,
      startDate: state.startDate,
    })
  );
}
export function DashBoard() {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [selectedMonth, setselectedMonth] = useState(
    new Date(new Date().toDateString())
  );
  const { dashBoardStore, companyStore } = useSelector((state) => {
    return {
      dashBoardStore: state.dashBoard,
      companyStore: state.companyList,
    };
  });

  const companyName = getCompanyName(companyStore.companyList, companyId);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let startDate = new Date(new Date().toDateString());
    if (params.get("date")) {
      startDate = utils.dateFromShortString(params.get("date"));
      setselectedMonth(startDate);
    }
    loadData(dispatch, { companyId, startDate });
  }, [companyId, location.search]);


  const data = {};

  let maxOrderDate = new Date();
  let minOrderDate = new Date();

  for (const o of dashBoardStore.orderByDay) {
    if (maxOrderDate < o.date) {
      maxOrderDate = o.date;
    }
    if (minOrderDate > o.date) {
      minOrderDate = o.date;
    }
    if (
      o.date.getMonth() == selectedMonth.getMonth() &&
      o.date.getFullYear() == selectedMonth.getFullYear()
    ) {
      data[o.date.getDate()] = { count: o.count };
    }
  }

  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={10}>
          <Header size="huge">Доска компании {companyName}</Header>
        </Grid.Column>
        <Grid.Column textAlign="right" width={5}>
          {/* Фильтр по дате: 
          <DatePicker
            selected={selectedMonth}
            onChange={handlerChangeMonth}
            //includeDates={Array.from(this.props.dashBoardState.orderByDay, x => new Date(x.date))}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            className="ui input"
            maxDate={maxOrderDate}
            minDate={minOrderDate}
          />
         */}
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column>
          <Header as="h2">
            {selectedMonth.toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Header>
          <Card.Group>
            <Card>
              <Card.Content>
                <Card.Header>Финансы</Card.Header>
                <Card.Meta>Расходы доходы за день</Card.Meta>
                <Card.Description>
                  {dashBoardStore.dashboardFinance.isLoading && (
                    <Dimmer active>
                      <Loader />
                    </Dimmer>
                  )}
                  Сумма всех заказов :{" "}
                  {utils.toFloatToString(
                    dashBoardStore.dashboardFinance.amount,
                    2
                  )}
                  <br />
                  Сумма закрытых заказов:{" "}
                  {utils.toFloatToString(
                    dashBoardStore.dashboardFinance.realIncome,
                    2
                  )}
                  <br />
                  Планируемые расходны :{" "}
                  {utils.toFloatToString(
                    dashBoardStore.dashboardFinance.totalExpense,
                    2
                  )}
                  <br />
                  Зафиксирвоаные расходы :{" "}
                  {utils.toFloatToString(
                    dashBoardStore.dashboardFinance.realExpense,
                    2
                  )}
                  <br />
                  <strong>
                    Доходы :{" "}
                    {utils.toCorrectFloat(
                      dashBoardStore.dashboardFinance.realIncome -
                        dashBoardStore.dashboardFinance.realExpense,
                      2
                    )}
                  </strong>
                  <br />
                </Card.Description>
              </Card.Content>
            </Card>

            <Card>
              <Card.Content>
                <Card.Header>Платежи <Button as={Link} color="blue" size="tiny" to={`/Company/${companyId}/Payment`} icon="eye"/></Card.Header>
                <Card.Meta>принятые платежи</Card.Meta>
                <Card.Description>
                  {dashBoardStore.dashboardPayment.isLoading && (
                    <Dimmer active>
                      <Loader />
                    </Dimmer>
                  )}
                  {(dashBoardStore.dashboardPayment.list || []).map((x) => {
                    return (
                      <>
                        {x.name}: {utils.toFloatToString(x.amount, 2)}
                        <br />
                      </>
                    );
                  })}
                  <hr />
                  Всего:{" "}
                  {utils.toFloatToString(
                    Array.from(
                      dashBoardStore.dashboardPayment.list || [],
                      (x) => x.amount
                    ).reduce((a, b) => a + b, 0),
                    2
                  )}
                  <br />
                  <strong>
                    Требуется принять :{" "}
                    {utils.toFloatToString(
                      dashBoardStore.dashboardPayment.requred,
                      2
                    )}
                  </strong>
                </Card.Description>
              </Card.Content>
            </Card>

            <Card>
              <Card.Content>
                <Card.Header>Заказы <Button as={Link} color="blue" size="tiny" to={`/Company/${companyId}/DashBoard/Summury`} icon="eye"/></Card.Header>
                <Card.Meta>информация о заказах</Card.Meta>
                <Card.Description>
                  
                  {dashBoardStore.dashboardOrder.isLoading && (
                    <Dimmer active>
                      <Loader />
                    </Dimmer>
                  )}
                  Всего :{" "} {dashBoardStore.dashboardOrder.totalCount}
                  <br />
                  Открытых:{" "}
                  {dashBoardStore.dashboardOrder.openCount}
                  <br />
                  Закрытых :{" "}{dashBoardStore.dashboardOrder.closeCount}
                  <br />
                  Отмененных :{" "}{dashBoardStore.dashboardOrder.canceledCount}
                  <br />
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
