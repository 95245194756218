import React, { Component } from 'react';
import {
    Grid, Header, Button, Divider, Checkbox
    , Table, Icon, Label, Popup, Loader, Dropdown, Pagination
} from 'semantic-ui-react'

import {ChangeSettingModal} from "../../components/UserSetting/ChangeSettingModal";
import * as actionCreators from "./actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as utils from "@hitek-crm-utils";
import DatePicker from "@hitek-crm/DatePicker";


const MODULE_NAME = "dashBoard";
const defaultConfig = {
    countPerPage: 25
}
const descriptionConfig={
    countPerPage:{
        description:"Колличество записей на странице",
        type:"int"
    }
}
export class CompanyDashBoard extends Component {


    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId,
            isLoaded: false,
            id: "",
            name: "",
            userId: null,
            startDate: new Date(new Date().toDateString()),
            endDate: null,
            forPrint: [],
            searchOrderStatus: [],
            searchOrderTag:[],
            activPage: 1,
            isOpenSettinConfigModal:false
        }

        let searchParam = new URLSearchParams(this.props.location.search);
        if (searchParam.has("date")) {

            this.state.startDate = utils.dateFromShortString(searchParam.get("date"));// moment(searchParam.get("date"),"YYYY-MM-DD") ;
        }
        if (searchParam.has("startDate")) {

            this.state.startDate = utils.dateFromShortString(searchParam.get("startDate"));
        }
        if (searchParam.has("endDate")) {

            this.state.endDate = utils.dateFromShortString(searchParam.get("endDate"),{endDate:true});
        }
        if (searchParam.has("orderStatusId")) {

            const t = parseInt( searchParam.get("orderStatusId"))
            if(! isNaN(t)){
                this.state.searchOrderStatus = [t];

            }
        }
        if (searchParam.has("orderTagId")) {

            const t = parseInt( searchParam.get("orderTagId"))
            if(! isNaN(t)){
                this.state.searchOrderTag = [t];

            }
        }

        this.handleChangeDateForReport = this.handleChangeDateForReport.bind(this);
        this.handleCheckboxClick = this.handleCheckboxClick.bind(this);

        this.handlePrint = this.handlePrint.bind(this);
        this.handleChangeSearchOrderStatus = this.handleChangeSearchOrderStatus.bind(this);

        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.handleClickSettingButton = this.handleClickSettingButton.bind(this);
        this.props.clearStore();

        this.loadStore({
            companyId:this.props.match.params.companyId, 
            startDate: this.state.startDate.toISOString(),
             endDate:this.state.endDate ? this.state.endDate.toISOString() : null,
             searchOrderStatus:this.state.searchOrderStatus ,
             searchOrderTag:this.state.searchOrderTag
        });

        // Operations usually carried out in componentWillMount go here
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.match.params.companyId != nextProps.match.params.companyId || this.props.location.search != nextProps.location.search) {
            let newp = {
                companyId: nextProps.match.params.companyId,
                isLoaded: false,
                forPrint: [],
                startDate: new Date(new Date().toDateString()),
                activPage: 1

            };
            let searchParam = new URLSearchParams(nextProps.location.search);
            if (searchParam.has("date")) {

                newp.startDate = utils.dateFromShortString(searchParam.get("date"));//moment(searchParam.get("date"),"YYYY-MM-DD") ;
            }
            if (searchParam.has("startDate")) {

                newp.startDate = utils.dateFromShortString(searchParam.get("startDate"));
            }
            if (searchParam.has("endDate")) {

                newp.endDate = utils.dateFromShortString(searchParam.get("endDate"),{endDate:true});
            }
            if (searchParam.has("orderStatusId")) {

                const t = parseInt( searchParam.get("orderStatusId"))
                if(! isNaN(t)){
                    newp.searchOrderStatus = [t];
    
                }
            }
            if (searchParam.has("orderTagId")) {

                const t = parseInt( searchParam.get("orderTagId"))
                if(! isNaN(t)){
                    newp.searchOrderTag = [t];
    
                }
            }

            this.setState(newp);

            this.loadStore({
                companyId:nextProps.match.params.companyId, 
                startDate: newp.startDate.toISOString(),
                 endDate:newp.endDate ? newp.endDate.toISOString() : null, 
                 searchOrderStatus:newp.searchOrderStatus,
                 searchOrderTag:newp.searchOrderTag
            });
        }
    }

    loadStore({companyId, startDate, endDate,searchOrderStatus,searchOrderTag}) {

        this.props.initOrderListSummury({
            companyId,
            startDate: startDate,
            endDate: endDate || null,
            orderStatusId:searchOrderStatus||null,
            orderTagId:searchOrderTag||null
        });
        this.props.initOrderStatusList({
            companyId
        });
        this.props.initTagList({
            companyId
        });
        this.props.initCountOrdersByDay({
            companyId
        })
    }

    getCompanyName() {
        let companies = this.props.companies.companyList;
        if (!Array.isArray(companies))
            return null;
        let company = companies.find((x) => x.id == this.state.companyId);
        if (!!company) {
            return company.name;
        }
        return null;
    }

    getOrderStatusByOrderStatusId(orderStatusId) {
        let o = this.props.orderStatusList.orderStatusList.find(x => x.id === orderStatusId);
        if (o) {
            return o.name;
        }
        return orderStatusId;
    }

    getColorOrderStatusByOrderStatusId(orderStatusId) {
        let o = this.props.orderStatusList.orderStatusList.find(x => x.id === orderStatusId);
        if (o) {
            return o.color || undefined;
        }
        return undefined;
    }

    getTagByTagId(tagId) {
        let o = this.props.tagList.tagList.find(x => x.id === tagId);
        if (o) {
            return o.name;
        }
        return tagId;
    }
    getColorTagByTagId(tagId) {
        let o = this.props.tagList.tagList.find(x => x.id === tagId);
        if (o) {
            return o.color || undefined;
        }
        return undefined;
    }

    handleChangeDateForReport(date) {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: "date=" + utils.toShortDateString(date)

        }
        );
        this.setState({
            startDate: date
        });
    }
    handleCheckboxClick(e, target) {
        let forPrint = this.state.forPrint;


        if (target.checked) {
            forPrint.push(target.value);
        }
        else {
            forPrint = forPrint.filter(x => x != target.value)
        }
        console.log(forPrint);
        this.setState({
            forPrint
        });
    }
    handlePrint() {

        this.props.printCouerSummury({
            orders: this.state.forPrint,
            companyId: this.state.companyId
        });
    }
    handleChangeSearchOrderStatus(e, target) {

        this.setState({
            searchOrderStatus: target.value,
            activPage: 1
        });
    }

    handlePaginationChange(e, target) {
        this.setState({ activPage: target.activePage });
    }
    handleClickSettingButton(e) {
        this.setState({ isOpenSettinConfigModal: !!e });
    }

    getDataForTable() {
        if (!Array.isArray(this.props.order.orderListSummury)) {
            return [];
        }
        return this.props.order.orderListSummury.filter(x => this.state.searchOrderStatus.length == 0 || this.state.searchOrderStatus.includes(x.statusId));

    }
    getAverageSumm() {
        let totalSumm = 0
        let totalOrders = 0;

        let iterator = this.getDataForTable().filter(x => !x.isCanceled)[Symbol.iterator]()
        let obj = iterator.next();
        let value = null;
        while (obj.done !== true) {

            value = obj.value;

            totalSumm += value.amount;
            totalOrders++;
            obj = iterator.next();


        }

        if (totalOrders > 0) {
            return totalSumm / totalOrders;

        }
        else return 0;

    }
    getTotalSumm() {
        let totalSumm = 0
        let totalOrders = 0;

        let iterator = this.getDataForTable().filter(x => !x.isCanceled)[Symbol.iterator]()
        let obj = iterator.next();
        let value = null;
        while (obj.done !== true) {

            value = obj.value;

            totalSumm += value.amount;
            totalOrders++;
            obj = iterator.next();


        }


        if (totalOrders > 0) {
            return totalSumm;

        }
        else return 0;

    }

    renderLoader() {
        return <Grid.Row   >
            <Grid.Column>
                <Loader active inline='centered' size="big" />
            </Grid.Column>
        </Grid.Row   >;
    }
    renderContent() {

        const activeConfig ={...defaultConfig};

        if(this.props.userSettingStore.list &&  this.props.userSettingStore.list[MODULE_NAME]){
            const moduleSetting = this.props.userSettingStore.list[MODULE_NAME];
            if(moduleSetting["countPerPage"]){
                activeConfig["countPerPage"] = moduleSetting["countPerPage"];
            }
        }
        let header = "Последнии заказы на сегодня";
        if(this.state.startDate.toLocaleDateString() != (new Date()).toLocaleDateString() || this.state.endDate){

            header = "Заказы на " + this.state.startDate.toLocaleDateString();
            if(this.state.endDate){
                header += " - " + this.state.endDate.toLocaleDateString();
            }
        }
        return <Grid.Row   >
            <Grid.Column>

                <Header size='medium'>{header}</Header>

                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell >Id</Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell>Время заказа</Table.HeaderCell>
                            <Table.HeaderCell>Теги</Table.HeaderCell>
                            <Table.HeaderCell>Статус</Table.HeaderCell>
                            <Table.HeaderCell >Сумма заказа</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" > 
                                <Button icon="setting" onClick = {this.handleClickSettingButton} />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                                <Dropdown loading={this.props.orderStatusList.isLoading} placeholder='Категория'
                                    selection
                                    search
                                    multiple
                                    clearable
                                    options={Array.from(this.props.orderStatusList.orderStatusList.filter(x => !x.isRemoved), x => { return { text: x.name, value: x.id, label: { color: x.color, empty: true, circular: true } }; })}
                                    value={this.state.searchOrderStatus}
                                    onChange={this.handleChangeSearchOrderStatus} /></Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                            <Table.HeaderCell ></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.getDataForTable().filter((x, index) => index >= (this.state.activPage - 1) * activeConfig.countPerPage && index < this.state.activPage * activeConfig.countPerPage).map(ord => {
                            let t = new Date(ord.date);
                            const tags = ord.tags ;
                            return <Table.Row key={ord.id} negative={ord.isCanceled}>
                                <Table.Cell collapsing>
                                    <Popup
                                        trigger={<Icon name='folder' />}
                                        content={"Полный ID: " + ord.id}
                                        position='top left'
                                    />
                                    {ord.internalId}
                                </Table.Cell>
                                <Table.Cell><Checkbox value={ord.id} onClick={this.handleCheckboxClick} /></Table.Cell>
                                <Table.Cell>{t.toLocaleString()}</Table.Cell>
                                <Table.Cell>
                                    {

                                        Array.isArray(tags)
                                        &&
                                        tags.map(x =>
                                            <Label key={x} color={this.getColorTagByTagId(x)}>
                                                {this.getTagByTagId(x)}
                                            </Label>
                                        )
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <Label color={this.getColorOrderStatusByOrderStatusId(ord.statusId)}>
                                        {this.getOrderStatusByOrderStatusId(ord.statusId)}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    {!ord.isCanceled ? (utils.toFloatToString(ord.amount) + "р.") : <span style={{ textDecoration: "line-through" }}>{utils.toFloatToString(ord.amount)}р.</span>}
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Button as={Link} to={"/Company/" + this.state.companyId + "/Order/" + ord.id} icon='edit' content='Подробно' />
                                </Table.Cell>
                            </Table.Row>
                        }


                        )}

                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3' textAlign="center">
                                Отображать по <strong>{activeConfig.countPerPage}</strong> строк {" "} 
                                <Button icon="setting" onClick = {this.handleClickSettingButton} />
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='5' textAlign="center">
                                <Pagination
                                    activePage={this.state.activPage}
                                    boundaryRange={2}
                                    onPageChange={this.handlePaginationChange}
                                    size='mini'
                                    siblingRange={2}
                                    totalPages={utils.toCorrectFloat(this.getDataForTable().length / activeConfig.countPerPage + 0.49, 0)}
                                />
                            </Table.HeaderCell>

                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5'>
                                Общая суммa
                                </Table.HeaderCell>


                            <Table.HeaderCell textAlign='right'>
                                {utils.toFloatToString(this.getTotalSumm())} р.
                                </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>

                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5'>
                                Средний чек
                                </Table.HeaderCell>


                            <Table.HeaderCell textAlign='right'>
                                {utils.toFloatToString(this.getAverageSumm())} р.
                                </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>

                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5'>
                                Общая суммa
                                </Table.HeaderCell>


                            <Table.HeaderCell textAlign='right'>
                                {utils.toFloatToString(this.getTotalSumm())} р.
                                </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Button disabled={this.state.forPrint.length == 0} onClick={this.handlePrint}> Печать группы предчеков</Button>
            </Grid.Column>
            <ChangeSettingModal 
                open={this.state.isOpenSettinConfigModal} 
                actionConfig={activeConfig} 
                descriptionConfig={descriptionConfig} 
                moduleName={MODULE_NAME}
                onClose={this.handleClickSettingButton}/>
        </Grid.Row>
    }
    render() {
        return <Grid>
            <Grid.Row columns={3}>
                <Grid.Column width={10}>
                    <Header size='huge'>Сводка для компании {this.getCompanyName()}</Header>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5} className="ui small input">

                    Фильтр по дате:
                        <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleChangeDateForReport}

                        includeDates={Array.from(this.props.dashBoardState.orderByDay, x => x.date)}
                    // maxDate={new Date()} 

                    />

                </Grid.Column>
                <Grid.Column width={1} textAlign="right">
                    <Button color="green" icon="sync alternate"  onClick={() => {
                        
                        this.loadStore({
                            companyId:this.props.match.params.companyId, 
                            startDate: this.state.startDate.toISOString(),
                             endDate:this.state.endDate ? this.state.endDate.toISOString() : null,
                             searchOrderStatus:this.state.searchOrderStatus ,
                             searchOrderTag:this.state.searchOrderTag
                        });
                    }}>
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Divider />

            {this.props.order.isLoading ? this.renderLoader() : this.renderContent()}
        </Grid>;

    }
};

function mapStateToProps(state) {

    return {

        companies: state.companyList,
        category: state.category,
        order: state.order,
        orderStatusList: state.orderStatusList,
        tagList: state.tagList,
        dashBoardState: state.dashBoard,
        userSettingStore: state.userSettingStore


    };
}


export const CompanyDashBoardContainer = connect(mapStateToProps, actionCreators)(CompanyDashBoard);
