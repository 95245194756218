export const clearStore = "STOCK_CLEAR_STORE";


export const listLoadStart = 'STOCK_OPERATION_LIST_LOAD_START';
export const listLoadSuccess = 'STOCK_OPERATION_LIST_LOAD_SUCCESS';
export const listLoadFail = 'STOCK_OPERATION_LIST_LOAD_FAIL';

export const itemSaveStart = 'STOCK_OPERATION_SAVE_START';
export const itemSaveSuccess = 'STOCK_LOPERATION_SAVESUCCESS';
export const itemSaveFail = 'STOCK_OPERATION_SAVE_FAIL';

