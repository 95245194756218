import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == "PAYMENT_METHOD_CLEAR_STORE") {
        let oldPaymentMethodList = undefined;
        if (!!state) {
            oldPaymentMethodList = state.paymentMethodList;
        }

        state = {
            paymentMethodListSummury: [],
            paymentMethodList: oldPaymentMethodList || [],
            externalPaymentMethod: [],
            isLoading: false,
            isSaved: false,
            isLoaded: false,
            item: {},
            financeSource: [],
        };
    }
    switch (action.type) {
        case "PAYMENT_METHOD_LIST_SUMMURY_LOAD_START":
            return {
                ...state,
                ...{ paymentMethodListSummury: [], isLoading: true, error: undefined },
            };
        case "PAYMENT_METHOD_LIST_SUMMURY_LOAD_SUCCESS":
            return {
                ...state,
                ...{ paymentMethodListSummury: action.data.sort(sortByOrder), isLoading: false },
            };
        case "PAYMENT_METHOD_LIST_SUMMURY_LOAD_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "PAYMENT_METHOD_LIST_LOAD_START":
            return { ...state, ...{ paymentMethodList: [], isLoading: true, error: undefined } };
        case "PAYMENT_METHOD_LIST_LOAD_SUCCESS":
            return {
                ...state,
                ...{ paymentMethodList: action.data.sort(sortByOrder), isLoading: false },
            };
        case "PAYMENT_METHOD_LIST_LOAD_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.itemRemoveStart:
            return { ...state, ...{ isLoading: true } };
        case constants.itemRemoveSuccess:
            return { ...state, ...{ isLoading: false, isShouldReload: true } };
        case constants.itemRemoveFail:
            return { ...state, ...{ isLoading: true, error: action.error } };

        case constants.itemSaveStart:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.itemSaveSuccess:
            return { ...state, ...{ isLoading: false, isSaved: true } };
        case constants.itemSaveFail:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case "PAYMENT_METHOD_DETAIL_START":
            return { ...state, ...{ isLoading: true, error: undefined } };
        case "PAYMENT_METHOD_DETAIL_SUCCESS":
            return { ...state, ...{ item: action.data, isLoading: false, isLoaded: true } };
        case "PAYMENT_METHOD_DETAIL_FAIL":
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.listFinanceSourseLoadStart:
            return { ...state, ...{ financeSource: [], isLoading: true, error: undefined } };
        case constants.listFinanceSourseLoadSuccess:
            return {
                ...state,
                ...{ financeSource: action.data, isLoading: false, isLoaded: true },
            };
        case constants.listFinanceSourseLoadFail:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.listExternalPaymentMethodLoadStart:
            return {
                ...state,
                ...{ externalPaymentMethod: [], isLoading: true, error: undefined },
            };
        case constants.listExternalPaymentMethodLoadSuccess:
            return {
                ...state,
                ...{ externalPaymentMethod: action.data, isLoading: false, isLoaded: true },
            };
        case constants.listExternalPaymentMethodLoadFail:
            return { ...state, ...{ isLoading: false, error: action.error } };

        default:
            return state;
    }
}

function sortByOrder(a, b) {
    return a.order - b.order;
}
