import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid, Header, Divider, Table, Label, Button, Modal, List, Form, Input, Checkbox } from 'semantic-ui-react'
import * as actionCreators from "./actions";
import * as utils from "@hitek-crm-utils";

function loadData(dispatch, state) {
    if (state.roleId) {
        dispatch(actionCreators.initItemData({ companyId: state.companyId, roleId: state.roleId }));
    }
    if (state.force) {
        dispatch(actionCreators.initListPermission());
    }
}

const defaultValueState = {
    name: "",
    isPublic: false,
    companyId: '',
    permissions: {}

}
export function RoleCompanyAddEditContainer() {
    const { data } = useSelector(state => {
        return { data: state.roleCompanyStore };
    });

    const { companyId, roleId } = useParams();
    const location = useLocation();

    const dispatch = useDispatch();
    const prevStateRef = useRef({});
    const [valueState, setValueState] = useState(defaultValueState);

    const history = useHistory();


    //const rolePermission = data.item.permissions || {};
    useEffect(() => {
        return () => {
            dispatch(actionCreators.clearStore());
        }
    }, []);
    useEffect(() => {
        loadData(dispatch, {
            companyId,
            roleId,
            force:
                companyId !== prevStateRef.current.companyId || data.permissionList.length < 1
        });
        if (!roleId) {
            setValueState(defaultValueState);
        }
        if (!prevStateRef.current) {
            prevStateRef.current = {};
        }
        prevStateRef.current.companyId = companyId;
    }, [companyId, roleId]);

    useEffect(() => {
        if (data.isShouldReload) {
            loadData(dispatch, { companyId });
        }
    }, [data.isShouldReload]);
    useEffect(() => {
        setValueState({ ...defaultValueState, ...data.item, companyId: data.item.companyId || "", permissions: data.item.permissions || {} });
    }, [data.item]);

    useEffect(() => {
        if (data.isShouldReload) {
            loadData(dispatch, { companyId });
        }
    }, [data.isShouldReload]);
    useEffect(() => {
        if (data.isSaved) {
            if (roleId) {
                history.push(utils.getRoute(location.pathname, 2));
            }
            else {
                history.push(utils.getRoute(location.pathname, 1));
            }

        }
    }, [data.isSaved, roleId]);



    const onChangeInput = useCallback((e, target) => {
        let value = target.value;
        if (target.type === "checkbox") {
            value = target.checked;

        }
        setValueState({ ...valueState, [target.name]: value });
    }, [valueState]);
    
    const onChangePermition = useCallback((e, target) => {
        let value = target.value;
        if (target.type === "checkbox") {
            value = 1 & target.checked;
        }

        if (value !== "") {
            value = parseInt(value);
            if (isNaN(value)) {
                return;
            }
        }

        const per = { ...valueState.permissions, [target.name]: value }
        if (value === "" || value === 0) {
            delete per[target.name];
        }
        setValueState({ ...valueState, permissions: per });
    }, [valueState]);

    const onSaveCallback = useCallback(e => {
        const data = { data: valueState };
        if (roleId) {
            data.roleId = roleId;
        }
        if (companyId) {
            data.companyId = companyId;
        }

        if (!roleId) {
            dispatch(actionCreators.saveNewItemData(data));
        } else {
            dispatch(actionCreators.saveEditItemData(data));
        }
    }, [valueState, companyId, roleId]);
    return <Grid>
        <Grid.Row>
            <Grid.Column>
                <Header size='huge'>Настройка Ролей компании</Header>
            </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
            <Grid.Column>
                <Grid.Row>
                    <Button color="green" onClick={onSaveCallback} >Сохранить</Button>
                </Grid.Row>
                <Form success={data.isSave} error={!!data.error} loading={data.isLoading} >
                    <Form.Field control={Input} label='Название' placeholder='Название статуса' name="name" onChange={onChangeInput} value={valueState.name} />
                    {!companyId && <Form.Field control={Checkbox} label='Is Public' name="isPublic" onChange={onChangeInput} checked={valueState.isPublic} />}

                    {!companyId && <Form.Field control={Input} label='Компания' name="companyId" onChange={onChangeInput} value={valueState.companyId} />}
                </Form>
                <Header size="medium">Настроеные права</Header>
                <Grid.Row>
                    <Table celled structured>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Название</Table.HeaderCell>
                                <Table.HeaderCell>Имя</Table.HeaderCell>
                                <Table.HeaderCell >Значение</Table.HeaderCell>

                            </Table.Row>

                        </Table.Header>

                        <Table.Body>
                            {data.permissionList.map((permission) => {
                                return <Table.Row key={permission.name}>
                                    <Table.Cell>{permission.title}</Table.Cell>
                                    <Table.Cell>{permission.name}</Table.Cell>
                                    <Table.Cell>
                                        {permission.type == "bool"
                                            ?
                                            <Form.Field control={Checkbox}
                                                name={permission.name} onChange={onChangePermition} checked={!!valueState.permissions[permission.name]} />
                                            :
                                            <Form.Field control={Input}
                                                name={permission.name} onChange={onChangePermition} value={valueState.permissions[permission.name] || ""} />
                                        }
                                    </Table.Cell>

                                </Table.Row>

                            }

                            )}


                        </Table.Body>
                    </Table>

                </Grid.Row>

            </Grid.Column>

        </Grid.Row>
    </Grid>;

}

