import {browserHistory } from 'react-router';

export default function(state ,action){
    if ( !state  || action.type=='ACCOUNT_CLEAR_STORE'){


            state =  {
                data: {},
                isLoading : false,
                isSave: false,
                isLoaded:false
            };

    }
//"ACCOUNT_CHANGE_PASSWORD_START","ACCOUNT_CHANGE_PASSWORD_SUCCESS","ACCOUNT_CHANGE_PASSWORD_FAIL"
//ACCOUNT_DETAIL_START","ACCOUNT_DETAIL_SUCCESS","ACCOUNT_DETAIL_FAIL
//"ACCOUNT_SAVE_START","ACCOUNT_SAVE_SUCCESS","ACCOUNT_SAVE_FAIL"
    switch(action.type){

        case "ACCOUNT_CHANGE_PASSWORD_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "ACCOUNT_CHANGE_PASSWORD_SUCCESS":
            return { ...state, ...{isLoading:false, isSave:action.data.success, error:action.data.error}} ;
        case "ACCOUNT_CHANGE_PASSWORD_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};       
            
        case "ACCOUNT_DETAIL_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "ACCOUNT_DETAIL_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isLoaded:true}} ;
        case "ACCOUNT_DETAIL_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        default:
            return state;
    }
}