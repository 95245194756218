import React, { Component, useState, useRef, useCallback } from "react";
import {
  Grid,
  Button,
  Icon,
  Accordion,
  Label,
  Container,
  Header,
  Card,
  Image,
} from "semantic-ui-react";
import * as config from "Config";

function getCategoryList(categoryList, callback) {
  return [
    categoryList.map((x) => (
      <Card key={x.id} onClick={callback} data-category-id={x.id} raised>
        <Image
          wrapped
          src={x.imageUrl ? x.imageUrl : `https://ui-avatars.com/api/?format=svg&bold=true&name=${x.name}`}
        />
        <Card.Content>

          <Card.Header as={Header} size='medium'>{x.name}</Card.Header>
        </Card.Content>
      </Card>
    )),
  ];
}
function getItemList(
  itemList,
  orderPositions,
  categoryId,
  callbackAdd,
  callbackRemove
) {
  return [
    itemList.map((x) => {
      const orderPositioCount = (
        orderPositions.find((y) => x.id === y.id) || {}
      ).count;

      return (
        <Card
          key={x.id}
          raised
        >
          <Image
            as={Button}
            wrapped
            src={x.imageUrl ? x.imageUrl : `https://ui-avatars.com/api/?format=svg&bold=true&name=` + x.name}
            data-id={x.id}
            data-category-id={categoryId}
            onClick={callbackAdd}
          />
          <Card.Content>
            <Card.Header as={Header} size='medium'>{x.name} </Card.Header>
            <Card.Description>
              Цена: {x.price} р.{" "}
              {orderPositioCount && (
                <Label circular color="blue">
                  {orderPositioCount}
                </Label>
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className="ui two buttons">
              <Button
                data-id={x.id}
                data-category-id={categoryId}
                onClick={callbackRemove}
                size="tiny"
                color="red"
                icon="minus"
              />

              <Button
                data-id={x.id}
                data-category-id={categoryId}
                onClick={callbackAdd}
                size="tiny"
                color="green"
                icon="plus"
              />
            </div>
          </Card.Content>
        </Card>
      );
    }),
  ];
}

export function GridPosition(props) {
  const heightBlock = window.innerHeight - 210;

  const [selectedCategory, setSelectedCategory] = useState(null);

  const onSelectCategory = useCallback((e) => {
    e.preventDefault();
    let categoryId = null;
    if (e.currentTarget.dataset.categoryId !== undefined) {
      categoryId = parseInt(e.currentTarget.dataset.categoryId, 10);
    }
    setSelectedCategory(categoryId);
  });

  return (
    <Grid>
      <Grid.Column>
        <Grid.Row>
          <Button
            onClick={onSelectCategory}
            data-category-id={
              (props.categoryList.find((y) => y.id == selectedCategory) || {})
                .parentId
            }
            icon
            primary
          >
            <Icon name="level up" />
          </Button>
        </Grid.Row>{" "}
        <Grid.Row style={{ marginTop: "10px" }}>
          <Container
            style={{
              padding: "5px",
              maxHeight: heightBlock + "px",
              width: "calc(100% - 30px)",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <Header size="small">
              Категория{" "}
              {
                (
                  props.categoryList.find((x) => x.id === selectedCategory) ||
                  {}
                ).fullName
              }{" "}
            </Header>
            <Card.Group itemsPerRow={4}>
              {getCategoryList(
                props.categoryList.filter(
                  (x) => x.parentId === selectedCategory
                ),
                onSelectCategory
              )}
            </Card.Group>
            <Header size="small">Блюда</Header>
            <Card.Group itemsPerRow={4}>
              {getItemList(
                props.itemList.filter(
                  (x) =>
                    Array.isArray(x.categoriesId) &&
                    x.categoriesId.includes(selectedCategory)
                ),
                props.orderPosition,
                selectedCategory,

                props.handleAddItemToOrder,
                props.handleRemovePositionFromOrder
              )}
            </Card.Group>
          </Container>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
}
export default GridPosition;
