export const ITEM_CLEAR_STORE = "ITEM_CLEAR_STORE";
export const ITEM_NEW_SAVE_START = "ITEM_NEW_SAVE_START";
export const ITEM_NEW_SAVE_SUCCESS = "ITEM_NEW_SAVE_SUCCESS";
export const ITEM_NEW_SAVE_FAIL = "ITEM_NEW_SAVE_FAIL";
export const ITEM_SAVE_START = "ITEM_SAVE_START";
export const ITEM_SAVE_SUCCESS = "ITEM_SAVE_SUCCESS";
export const ITEM_SAVE_FAIL = "ITEM_SAVE_FAIL";
export const ITEM_DETAIL_START = "ITEM_DETAIL_START";
export const ITEM_DETAIL_SUCCESS = "ITEM_DETAIL_SUCCESS";
export const ITEM_DETAIL_FAIL = "ITEM_DETAIL_FAIL";

export const ITEM_CF_LOAD_START = "ITEM_CF_LOAD_START";
export const ITEM_CF_LOAD_SUCCESS = "ITEM_CF_LOAD_SUCCESS";
export const ITEM_CF_LOAD_FAIL = "ITEM_CF_LOAD_FAIL";
