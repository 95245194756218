export const CLEAR_STORE = "ROLE_COMPANY_CLEAR_STORE";

export const ROLE_COMPANY_LIST_LOAD_START = "ROLE_COMPANY_LIST_LOAD_START";
export const ROLE_COMPANY_LIST_LOAD_SUCCESS = "ROLE_COMPANY_LIST_LOAD_SUCCESS";
export const ROLE_COMPANY_LIST_LOAD_FAIL = "ROLE_COMPANY_LIST_LOAD_FAIL";

export const ROLE_COMPANY_PERMISSION_LIST_LOAD_START = "ROLE_COMPANY_PERMISSION_LIST_LOAD_START";
export const ROLE_COMPANY_PERMISSION_LIST_LOAD_SUCCESS = "ROLE_COMPANY_PERMISSION_LIST_LOAD_SUCCESS";
export const ROLE_COMPANY_PERMISSION_LIST_LOAD_FAIL = "ROLE_COMPANY_PERMISSION_LIST_LOAD_FAIL";

export const ROLE_COMPANY_ITEM_LOAD_START = "ROLE_COMPANY_ITEM_LOAD_START";
export const ROLE_COMPANY_ITEM_LOAD_SUCCESS = "ROLE_COMPANY_ITEM_LOAD_SUCCESS";
export const ROLE_COMPANY_ITEM_LOAD_FAIL = "ROLE_COMPANY_ITEM_LOAD_FAIL";

export const ROLE_COMPANY_ITEM_SAVE_START = "ROLE_COMPANY_ITEM_SAVE_START";
export const ROLE_COMPANY_ITEM_SAVE_SUCCESS = "ROLE_COMPANY_ITEM_SAVE_SUCCESS";
export const ROLE_COMPANY_ITEM_SAVE_FAIL = "ROLE_COMPANY_ITEM_SAVE_FAIL";

export const ROLE_COMPANY_ITEM_DELETE_START = "ROLE_COMPANY_ITEM_DELETE_START";
export const ROLE_COMPANY_ITEM_DELETE_SUCCESS = "ROLE_COMPANY_ITEM_DELETE_SUCCESS";
export const ROLE_COMPANY_ITEM_DELETE_FAIL = "ROLE_COMPANY_ITEM_DELETE_FAIL";