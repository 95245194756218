
import * as constants from "./constants";

export default function (state, action) {
  if (!state || action.type == constants.CLEAR_STORE) {
    let oldCategoryList = undefined;
    if (!!state) {
      oldCategoryList = state.categoryList;
    }

    state = {
      list: [],
      item: {},
      permissionList: [],
      isLoading: false,
      isSaved: false,
      isLoaded: false,
      isShouldReload: false
    };
  }

  switch (action.type) {

    // LIST 
    case constants.ROLE_COMPANY_LIST_LOAD_START:
      return {
        ...state,
        ...{ list: [], isLoading: true, error: undefined }
      };
    case constants.ROLE_COMPANY_LIST_LOAD_SUCCESS:
      return {
        ...state,
        ...{ list: action.data, isLoading: false }
      };
    case constants.ROLE_COMPANY_LIST_LOAD_FAIL:
      return { ...state, ...{ isLoading: false, error: action.error } };
    //ITEM
    case constants.ROLE_COMPANY_ITEM_LOAD_START:
      return {
        ...state,
        ...{ item: {}, isLoading: true, error: undefined }
      };
    case constants.ROLE_COMPANY_ITEM_LOAD_SUCCESS:
      return {
        ...state,
        ...{ item: action.data, isLoading: false }
      };
    case constants.ROLE_COMPANY_ITEM_LOAD_FAIL:
      return { ...state, ...{ isLoading: false, error: action.error } };

    //ITEM SAVE
    case constants.ROLE_COMPANY_ITEM_SAVE_START:
      return {
        ...state,
        ...{ isSaved: false, isLoading: true, error: undefined }
      };
    case constants.ROLE_COMPANY_ITEM_SAVE_SUCCESS:
      return {
        ...state,
        ...{ isSaved: true, isLoading: false }
      };
    case constants.ROLE_COMPANY_ITEM_SAVE_FAIL:
      return { ...state, ...{ isLoading: false, error: action.error } };

    //ITEM DELETE
    case constants.ROLE_COMPANY_ITEM_DELETE_START:
      return {
        ...state,
        ...{ isShouldReload: false, isLoading: true, error: undefined }
      };
    case constants.ROLE_COMPANY_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        ...{ isShouldReload: true, isLoading: false }
      };
    case constants.ROLE_COMPANY_ITEM_DELETE_FAIL:
      return { ...state, ...{ isLoading: false, error: action.error } };

    //PERMISSION LIST
    case constants.ROLE_COMPANY_PERMISSION_LIST_LOAD_START:
      return {
        ...state,
        ...{ permissionList: [], error: undefined }
      };
    case constants.ROLE_COMPANY_PERMISSION_LIST_LOAD_SUCCESS:
      return {
        ...state,
        ...{ permissionList: action.data.sort((a,b)=> compare(a,b,"name")) }
      };
    case constants.ROLE_COMPANY_PERMISSION_LIST_LOAD_FAIL:
      return { ...state, ...{ error: action.error } };


    default:
      return state;
  }
}

function compare(a, b,field="name") {
  const bandA = a[field].toUpperCase();
  const bandB = b[field].toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

function createFullName(arr) {
  let res = [];
  for (let i = 0; i < arr.length; i += 1) {
    const t = { ...arr[i], fullName: arr[i].name + "" };
    let pi = t.parentId;
    while (pi != undefined) {
      let k = arr.find(x => x.id === pi);
      if (!k) {
        break;
      }
      t.fullName = k.name + " - " + t.fullName;
      pi = k.parentId;
    }
    res.push(t);
  }
  return res;
}
