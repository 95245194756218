import {browserHistory } from 'react-router';

export default function(state ,action){
    if ( !state  || action.type == 'COMPANY_LIST_CLEAR_STORE'){
        
        let oldcompanyList = undefined;
        if( !!state ){
            oldcompanyList = state.companyList;
        }

            state =  {
                companyListSummury: [],
                companyList: oldcompanyList || [],
                isLoading : false,
                isShouldReload: false
            };

    }
//"COMPANY_LIST_LOAD_START","COMPANY_LIST_LOAD_SUCCESS","COMPANY_LIST_LOAD_FAIL"
//"COMPANY_REMOVE_START","COMPANY_REMOVE_SUCCESS","COMPANY_REMOVE_FAIL"
    switch(action.type){
        case "COMPANY_LIST_SUMMURY_LOAD_START":
            return {...state, ...{isLoading:true}};
        case "COMPANY_LIST_SUMMURY_LOAD_SUCCESS":
            return { ...state, ...{companyListSummury:action.data,isLoading:false}} ;
        case "COMPANY_LIST_SUMMURY_LOAD_FAIL":
            return {...state, ...{isLoading:true,error:action.error}};

        case "COMPANY_LIST_LOAD_START":
            return {...state, ...{isLoading:true}};
        case "COMPANY_LIST_LOAD_SUCCESS":
            return { ...state, ...{companyList:action.data,isLoading:false}} ;
        case "COMPANY_LIST_LOAD_FAIL":
            return {...state, ...{isLoading:true,error:action.error}};

        case "COMPANY_LIST_REMOVE_START":
            return {...state, ...{isLoading:true}};
        case "COMPANY_LIST_REMOVE_SUCCESS":
            return { ...state, ...{isLoading:false,isShouldReload:true}} ;
        case "COMPANY_LIST_REMOVE_FAIL":
            return {...state, ...{isLoading:true,error:action.error}};

        default:
            return state;
    }
}