import React, { Component } from 'react';
import { Grid, Header, Divider, Table, Label, Icon, Button, Modal } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionCreators from "./actions";

export class OrderStatusList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteShowModal: false,
            currentCompanyId: this.props.match.params.companyId,
            idForRemove: null

        };

        this.props.clearStore();
        this.props.initListData({ companyId: this.state.currentCompanyId });
        this.handleCloseopenDeleteModal = this.handleCloseopenDeleteModal.bind(this);
        // Operations usually carried out in componentWillMount go here
    }


    getDataForTable() {
        return this.props.orderStatus.orderStatusListSummury;

    }
    componentWillReceiveProps(nextProps) {

        if (!!nextProps.orderStatus && nextProps.orderStatus.isShouldReload) {
            this.props.clearStore();
            this.props.initListData({ companyId: this.state.currentCompanyId });

        }
        if (this.props.match.params.companyId != nextProps.match.params.companyId) {

            this.setState({
                currentCompanyId: nextProps.match.params.companyId
            })
            this.props.initListData({ companyId: nextProps.match.params.companyId });
        }
    }

    handleCloseopenDeleteModal(e, button) {
        this.setState({ openDeleteShowModal: false })
        if (button.positive) {
            this.props.deleteOrderStatus(
                {
                    id: this.state.idForRemove,
                    companyId: this.state.currentCompanyId
                }
            );
        }
    }
    onRemoveOrderStatus(id) {
        console.log(id);
        this.setState({ openDeleteShowModal: true, idForRemove: id })
    }

    getItemById(id) {
        if (!this.props.orderStatus || !id) {
            return {};
        }

        return this.props.orderStatus.orderStatusListSummury.find(x => x.id === id) || {};
    }

    render() {
        return <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size='huge'>Список Статусов заказов</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Grid.Row>
                        <Button color="green" as={Link} to={"/Company/" + this.state.currentCompanyId + "/OrderStatus/New"}>Добавить новый статус заказов</Button>
                    </Grid.Row>
                    <Table >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Название</Table.HeaderCell>
                                <Table.HeaderCell>Цвет</Table.HeaderCell>
                                <Table.HeaderCell>Порядок сортировки</Table.HeaderCell>
                                <Table.HeaderCell>Заказ можно редактировать</Table.HeaderCell>
                                <Table.HeaderCell>Закрытие заказа</Table.HeaderCell>
                                <Table.HeaderCell>Заказ не учитывается как совершенный</Table.HeaderCell>
                                <Table.HeaderCell>Производить операции по складу</Table.HeaderCell>
                                <Table.HeaderCell>Причина Обязательна</Table.HeaderCell>
                                <Table.HeaderCell>Служебный</Table.HeaderCell>
                                <Table.HeaderCell>Действия</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {this.getDataForTable().map((status) => {
                                return <Table.Row key={status.id}>
                                    <Table.Cell>
                                        {status.id}
                                    </Table.Cell>
                                    <Table.Cell>{status.name}</Table.Cell>
                                    <Table.Cell>
                                        <Label circular color={status.color}></Label>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {status.order}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {status.isEditable && <Icon name="check" />}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {status.isClosed && <Icon name="check" />}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {status.isCancel && <Icon name="check" />}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {status.isAddStockOperation && <Icon name="check" />}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {status.isRequireReason && <Icon name="check" />}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {status.isTechnical && <Icon name="check" />}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {/*<Button as={Link} to = {"/Company/"+this.state.currentCompanyId + "/OrderStatus/"+status.id } icon='search' content='Перейти' />      */}
                                        <Button as={Link} to={"/Company/" + this.state.currentCompanyId + "/OrderStatus/" + status.id + "/Edit"} icon='edit' content='Редактировать' />
                                        <Button onClick={() => { this.onRemoveOrderStatus(status.id) }} icon='remove' color="red" content='Удалить' />
                                    </Table.Cell>
                                </Table.Row>
                            })
                            }
                        </Table.Body>
                        <Table.Footer>

                        </Table.Footer>
                    </Table>
                    <Modal
                        open={this.state.openDeleteShowModal}
                        onClose={this.handleCloseopenDeleteModal}
                    >
                        <Modal.Header>Удалить Статус "{this.getItemById(this.state.idForRemove).name}"</Modal.Header>
                        <Modal.Content >
                            Уверены что хотите удалить данный статус?
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseopenDeleteModal}  >Нет</Button>
                            <Button positive onClick={this.handleCloseopenDeleteModal} labelPosition='right' icon='checkmark' content='Да' />
                        </Modal.Actions>
                    </Modal>

                </Grid.Column>
            </Grid.Row>
        </Grid>;

    }
};


function mapStateToProps(state) {

    return {

        orderStatus: state.orderStatusList
    };
}


export const OrderStatusListContainer = connect(mapStateToProps, actionCreators)(OrderStatusList);
