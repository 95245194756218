import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Form, Input, Message } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "./actions";

export function ChangeSettingModal({
  open,
  actionConfig,
  descriptionConfig,
  moduleName ,
  onClose,
}) {
  const [editableConfig, setEditableConfig] = useState({});

  useEffect(() => {
    setEditableConfig(actionConfig);
  }, [actionConfig]);

  const dispatch = useDispatch();

  const { userSettingStore } = useSelector((state) => {
    return {
      userSettingStore: state.userSettingStore,
    };
  });

  useEffect(() => {
    if (userSettingStore.isSaved) {
      dispatch(actionCreators.clearStore());
      dispatch(actionCreators.initListData());
      if (onClose) {
        onClose();
      }
    }
  }, [userSettingStore.isSaved]);

  const handleCloseopenDeleteModal = useCallback(
    (e, target) => {
      const { positive } = target;

      if (positive) {
        dispatch(
          actionCreators.saveSettings({
            module: moduleName,
            settings: editableConfig,
          })
        );
      } else {
        if (onClose) {
          onClose();
        }
      }
    },
    [editableConfig]
  );

  const handleChangeInput = useCallback(
    (e, target) => {
      const { name } = target;
      let { value } = target;
      if (descriptionConfig[name] && descriptionConfig[name].type === "int") {
        value = parseInt(value);
        if (isNaN(value) || value < 0) {
          value = 0;
        }
      }
      setEditableConfig((x) => {
        return { ...x, [name]: value };
      });
    },
    [editableConfig]
  );

  return (
    <Modal open={open} onClose={handleCloseopenDeleteModal}>
      <Modal.Header>Настройки отображения</Modal.Header>
      <Modal.Content>
        <Form
          error={!!userSettingStore.error}
          loading={userSettingStore.isLoading}
        >
          {Object.keys(editableConfig).map((key) => (
            <Form.Field
              inline
              control={Input}
              label={
                descriptionConfig[key]
                  ? descriptionConfig[key].description
                  : key
              }
              name={key}
              value={editableConfig[key]}
              onChange={handleChangeInput}
            />
          ))}
          <Message error header="Ошибка" content={userSettingStore.error} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleCloseopenDeleteModal}>
          Не сохранять
        </Button>
        <Button
          positive
          onClick={handleCloseopenDeleteModal}
          labelPosition="right"
          icon="save"
          content="Сохранить"
        />
      </Modal.Actions>
    </Modal>
  );
}
