import React, { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Header,
  Divider,
  Table,
  Icon,
  Button,
  Modal,
  Loader,
  Popup,
  Pagination,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as actionCreators from "./actions";
import { ChangeSettingModal } from "../../components/UserSetting/ChangeSettingModal";
import * as utils from "@hitek-crm-utils";

import { _useUserSetting } from "../../hooks/useUserSettingHook";

const MODULE_NAME = "SupplierList";

function loadData(dispatch, state) {
  dispatch(actionCreators.initListData({ companyId: state.companyId }));
}

export function SupplierList() {
  const [activPage, setActivPage] = useState(1);
  const [idForRemove, setIdForRemove] = useState(null);

  const [isOpenSettinConfigModal, setIsOpenSettinConfigModal] = useState(false);

  const { companyId } = useParams();

  const { supplierStore } = useSelector((state) => {
    return {
      supplierStore: state.supplierStore,
    };
  });
  const dispatch = useDispatch();

  const { activeConfig, descriptionConfig } = _useUserSetting({
    moduleName: MODULE_NAME,
  });
  useEffect(() => {
    loadData(dispatch, { companyId });
    setActivPage(1);
    setIdForRemove(null);
  }, [companyId, supplierStore.isRequreReload]);

  const getDataForTable = useCallback(() => {
    if (!Array.isArray(supplierStore.list)) return [];

    return supplierStore.list;
  }, [supplierStore.list]);

  const handlerReloadData = useCallback(
    (e) => {
      e.preventDefault();
      loadData(dispatch, { companyId });
      setActivPage(1);
    },
    [companyId]
  );

  const handlePaginationChange = useCallback((e, target) => {
    setActivPage(target.activePage);
  }, []);

  const handleClickSettingButton = useCallback((e) => {
    setIsOpenSettinConfigModal(!!e);
  }, []);
  const handleCloseDeleteModal = useCallback(
    (e, target) => {
      if (target["data-id"] && target["data-operation"] == "remove") {
        dispatch(
          actionCreators.deleteItem({
            companyId,
            id: target["data-id"],
          })
        );
      } else {
        setIdForRemove(null);
      }
    },
    [companyId]
  );
  const handleOpenDeleteModal = useCallback((e, target) => {
    setIdForRemove(target["data-id"]);
  }, []);



  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header size="huge">Постащики</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row columns={2}>
        <Grid.Column>
          <Button
            color="blue"
            as={Link}
            to={"/Company/" + companyId + "/Stock"}
          >
            <Icon name="warehouse" /> Склад
          </Button>

          <Button
            color="green"
            as={Link}
            to={"/Company/" + companyId + "/Supplier/Add"}
          >
            <Icon name="add" /> Добавить нового поставщика
          </Button>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button color="green" onClick={handlerReloadData}>
            {" "}
            <Icon name="sync alternate" />  Обновить
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Название </Table.HeaderCell>
                <Table.HeaderCell>Дата добавления</Table.HeaderCell>
                <Table.HeaderCell>Статус</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {supplierStore.isLoading && (
                <Table.Row>
                  <Table.Cell colSpan="100">
                    <Loader active inline="centered" size="big" />
                  </Table.Cell>
                </Table.Row>
              )}
              {getDataForTable()
                .filter(
                  (x, index) =>
                    index >= (activPage - 1) * activeConfig.countPerPage &&
                    index < activPage * activeConfig.countPerPage
                )
                .map((item) => {
                  return (
                    <Table.Row
                      key={item.id}
                      positive={item.isProcessed}
                      warning={!item.isProcessed}
                    >
                      <Table.Cell collapsing>
                        <Popup
                          trigger={<Icon name="folder" />}
                          content={"Полный ID: " + item.id}
                          position="top left"
                        />
                      </Table.Cell>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>
                        {item.creationDate.toLocaleString()}
                      </Table.Cell>
                      <Table.Cell>
                        {item.isActive ? (
                          <Icon name="check" />
                        ) : (
                          <Icon name="clock outline" />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          as={Link}
                          to={`/Company/${companyId}/Supplier/${item.id}/Detail`}
                          icon="eye"
                          primary
                          content="просмотр"
                        />
                        <Button
                          as={Link}
                          to={`/Company/${companyId}/Supplier/${item.id}/Edit`}
                          icon="edit"
                          content="Редактировать"
                        />
                        
                        <Button
                          icon="remove"
                          onClick={handleOpenDeleteModal}
                          data-id={item.id}
                          negative
                          content="Удалить"
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="2" textAlign="center">
                  Отображать по <strong>{activeConfig.countPerPage}</strong>{" "}
                  строк{" "}
                  <Button icon="setting" onClick={handleClickSettingButton} />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="100" textAlign="center">
                  <Pagination
                    activePage={activPage}
                    boundaryRange={2}
                    onPageChange={handlePaginationChange}
                    size="mini"
                    siblingRange={2}
                    totalPages={utils.toCorrectFloat(
                      getDataForTable().length / activeConfig.countPerPage +
                        0.49,
                      0
                    )}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <ChangeSettingModal
        open={isOpenSettinConfigModal}
        actionConfig={activeConfig}
        descriptionConfig={descriptionConfig}
        moduleName={MODULE_NAME}
        onClose={handleClickSettingButton}
      />
      {idForRemove && (
        <Modal open={!!idForRemove} onClose={handleCloseDeleteModal}>
          <Modal.Header>Уверены,что хотите Постащика?</Modal.Header>
          <Modal.Content>
            Удалить Постащика 
            "{getDataForTable().find((x) => x.id == idForRemove).name}
            "?
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleCloseDeleteModal}>Нет</Button>
            <Button
              negative
              data-id={idForRemove}
              data-operation="remove"
              onClick={handleCloseDeleteModal}
              labelPosition="right"
              icon="trash"
              content="Удалить"
            />
          </Modal.Actions>
        </Modal>
      )}
      
    </Grid>
  );
}
