import * as config from "Config";
import * as constants from "./constants";


export function clearStore(newObject) {
  return {
    type: constants.clearStore
  };
}

export function initListData(params) {
  return {
    remote: {
      method: "get",
      url: config.apiUrl + "/api/Notification",
      data: params
    },
    actions: [constants.listLoadStart, constants.listLoadSuccess, constants.listLoadFail]
  };
}
export function AdminClean(params) {
  return {
    remote: {
      method: "post",
      url: config.apiUrl + "/api/Notification/Admin/Clean",
      data: params
    },
    actions: [constants.itemDeleteStart, constants.itemDeleteSuccess, constants.itemDeleteFail]
  };
}

export function updateReadedNotify(params) {
  return {
    remote: {
      method: "post",
      url: config.apiUrl + "/api/Notification/" + params.id,
      data: params
    },
    actions: ["null", "null", 'null']
  };
}
