import React, { Component } from "react";
import {
    Grid,
    Header,
    Divider,
    Table,
    Icon,
    Button,
    Modal,
    Pagination,
    Input,
    Loader,
    Popup,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actionCreators from "./actions";
import { ChangeSettingModal } from "../../components/UserSetting/ChangeSettingModal";
import * as utils from "@hitek-crm-utils";

const MODULE_NAME = "StockList";
const defaultConfig = {
    countPerPage: 25,
};
const descriptionConfig = {
    countPerPage: {
        description: "Колличество записей на странице",
        type: "int",
    },
};

export class StockList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeleteShowModal: false,
            companyId: this.props.match.params.companyId,
            idForRemove: null,
            displayAll: false,
            activPage: 1,
            searchItemName: "",
            showUsedInStockItem: undefined,
        };

        let searchParam = new URLSearchParams(this.props.location.search);
        if (searchParam.has("displayAll")) {
            this.state.displayAll = searchParam.get("displayAll") == "true";
        }

        this.props.clearStore();

        this.handleCloseopenDeleteModal = this.handleCloseopenDeleteModal.bind(this);
        this.onRemoveCompany = this.onRemoveCompany.bind(this);
        this.handlerReloadData = this.handlerReloadData.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.handleClickSettingButton = this.handleClickSettingButton.bind(this);
        this.handleChangeSearchItemName = this.handleChangeSearchItemName.bind(this);
        this.handleShowUsedInModal = this.handleShowUsedInModal.bind(this);
        this.handleCloseUsedInModal = this.handleCloseUsedInModal.bind(this);
    }

    componentDidMount() {
        this.loadData.call(this, {
            companyId: this.state.companyId,
            displayAll: this.state.displayAll,
        });
    }

    loadData(state) {
        this.props.initListData({
            companyId: state.companyId,
            displayAll: state.displayAll,
        });
    }

    getDataForTable() {
        if (!this.props.data) {
            return [];
        }
        let res = this.props.data.list;

        if (this.state.searchItemName.length > 2) {
            res = res.filter((x) => x.title.toLowerCase().indexOf(this.state.searchItemName) > -1);
        }
        return res;
    }
    componentWillReceiveProps(nextProps) {
        if (
            !!nextProps.data &&
            nextProps.data.isRequreReload &&
            this.props.data.isRequreReload != nextProps.data.isRequreReload
        ) {
            this.props.clearStore();
            this.loadData.call(this, { companyId: this.state.companyId });
        }

        if (this.props.match.params.companyId != nextProps.match.params.companyId) {
            this.setState({
                companyId: nextProps.match.params.companyId,
                searchCategories: [],
                activPage: 1,
                searchItemName: "",
            });
            this.loadData.call(this, { companyId: nextProps.match.params.companyId, force: true });
        }
    }

    handleCloseUsedInModal(obj, button) {
        this.setState({ showUsedInStockItem: undefined });
    }
    handleShowUsedInModal(obj, button) {
        var item = this.props.data.list.find((x) => x.id == button.stockItemId);
        this.setState({ showUsedInStockItem: item });
    }
    handleCloseopenDeleteModal(obj, button) {
        this.setState({ openDeleteShowModal: false });
        if (button.positive) {
            this.props.hideItem({
                id: this.state.idForRemove,
                companyId: this.state.companyId,
                isHidden: true,
            });
        }
    }

    onRemoveCompany(e) {
        e.preventDefault();
        this.setState({
            openDeleteShowModal: true,
            idForRemove: e.currentTarget.dataset.id,
        });
    }

    handlerReloadData(e) {
        e.preventDefault();
        this.loadData.call(this, { companyId: this.state.companyId });
    }
    handlePaginationChange(e, target) {
        this.setState({ activPage: target.activePage });
    }
    handleClickSettingButton(e) {
        this.setState({ isOpenSettinConfigModal: !!e });
    }
    handleChangeSearchItemName(e, target) {
        this.setState({
            searchItemName: target.value.toLowerCase(),
            activPage: 1,
        });
    }

    render() {
        const activeConfig = { ...defaultConfig };

        if (this.props.userSettingStore.list && this.props.userSettingStore.list[MODULE_NAME]) {
            const moduleSetting = this.props.userSettingStore.list[MODULE_NAME];
            if (moduleSetting["countPerPage"]) {
                activeConfig["countPerPage"] = moduleSetting["countPerPage"];
            }
        }
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size="huge">Позиции на складе</Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Button
                            color="green"
                            as={Link}
                            to={"/Company/" + this.state.companyId + "/StockOperation"}>
                            <Icon name="file alternate outline" /> Операции на складе
                        </Button>
                        <Button
                            color="green"
                            as={Link}
                            to={"/Company/" + this.state.companyId + "/StockItem"}>
                            <Icon name="box" />
                            Позиции на складе
                        </Button>
                        <Button
                            color="green"
                            as={Link}
                            to={"/Company/" + this.state.companyId + "/PackingList"}>
                            <Icon name="box" />
                            Поставки
                        </Button>
                        <Button
                            color="green"
                            as={Link}
                            to={"/Company/" + this.state.companyId + "/TransferList"}>
                            <Icon name="box" />
                            Перемещения
                        </Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button color="green" onClick={this.handlerReloadData}>
                            <Icon name="sync alternate" />  Обновить
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.HeaderCell>Название</Table.HeaderCell>
                                    <Table.HeaderCell> В наличиии</Table.HeaderCell>
                                    <Table.HeaderCell>В резерве</Table.HeaderCell>
                                    <Table.HeaderCell>Ед измер</Table.HeaderCell>
                                    <Table.HeaderCell>Статус</Table.HeaderCell>
                                    <Table.HeaderCell>Используется в</Table.HeaderCell>
                                    <Table.HeaderCell>Действия</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell>
                                        <Input
                                            value={this.state.searchItemName}
                                            onChange={this.handleChangeSearchItemName}
                                            placeholder="Фильтр"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />

                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.props.data.isLoading && (
                                    <Table.Row>
                                        <Table.Cell colSpan="100">
                                            <Loader active inline="centered" size="big" />
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                {this.getDataForTable()
                                    .filter(
                                        (x, index) =>
                                            index >=
                                                (this.state.activPage - 1) *
                                                    activeConfig.countPerPage &&
                                            index < this.state.activPage * activeConfig.countPerPage
                                    )
                                    .map((item) => {
                                        return (
                                            <Table.Row key={item.id} negative={item.count < 0}>
                                                <Table.Cell>{item.id}</Table.Cell>
                                                <Table.Cell>{item.title}</Table.Cell>

                                                <Table.Cell>{item.count}</Table.Cell>
                                                <Table.Cell disabled={!item.reservedCount}>
                                                    {item.reservedCount}
                                                    {Array.isArray(item.reservedOrders) && (
                                                        <Popup
                                                            trigger={
                                                                <Button size="tiny" icon="eye" />
                                                            }
                                                            flowing
                                                            hoverable>
                                                            {item.reservedOrders?.map((x) => (
                                                                <Button
                                                                    as={Link}
                                                                    icon="eye"
                                                                    to={`/Company/${this.state.companyId}/Order/${x.orderId}`}>
                                                                    {x.internalOrderId}
                                                                </Button>
                                                            ))}
                                                        </Popup>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>{item.unit}</Table.Cell>
                                                <Table.Cell>
                                                    {!item.isHidden ? (
                                                        <Icon color="green" name="eye" />
                                                    ) : (
                                                        <Icon
                                                            color="red"
                                                            name="eye slash outline"
                                                        />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.countUsed}
                                                    {item.countUsed > 0 && (
                                                        <Button
                                                            size="tiny"
                                                            icon="eye"
                                                            stockItemId={item.id}
                                                            onClick={this.handleShowUsedInModal}
                                                        />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button
                                                        data-id={item.id}
                                                        onClick={this.onRemoveCompany}
                                                        icon="eye slash outline"
                                                        color="grey"
                                                        content="Скрыть"
                                                    />
                                                    <Button
                                                        as={Link}
                                                        to={`StockItem/${item.id}/Balance`}
                                                        icon="eye"
                                                        color="blue"
                                                        content="Баланс"
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="3" textAlign="center">
                                        Отображать по <strong>{activeConfig.countPerPage}</strong>{" "}
                                        строк{" "}
                                        <Button
                                            icon="setting"
                                            onClick={this.handleClickSettingButton}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell colSpan="5" textAlign="center">
                                        <Pagination
                                            activePage={this.state.activPage}
                                            boundaryRange={2}
                                            onPageChange={this.handlePaginationChange}
                                            size="mini"
                                            siblingRange={2}
                                            totalPages={utils.toCorrectFloat(
                                                this.getDataForTable().length /
                                                    activeConfig.countPerPage +
                                                    0.49,
                                                0
                                            )}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="7" textAlign="center">
                                        Всего позиций:{" "}
                                        <strong>{this.getDataForTable().length}</strong>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Modal
                    open={this.state.openDeleteShowModal}
                    onClose={this.handleCloseopenDeleteModal}>
                    <Modal.Header>Скрыть инвентарную единицу {this.state.idForRemove}</Modal.Header>
                    <Modal.Content>Уверены,что хотите скрыть инвентарную единицу?</Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={this.handleCloseopenDeleteModal}>
                            Нет
                        </Button>
                        <Button
                            positive
                            onClick={this.handleCloseopenDeleteModal}
                            labelPosition="right"
                            icon="checkmark"
                            content="Да"
                        />
                    </Modal.Actions>
                </Modal>

                {!!this.state.showUsedInStockItem && (
                    <Modal
                        open={!!this.state.showUsedInStockItem}
                        onClose={this.handleCloseUsedInModal}>
                        <Modal.Header>Использование позиции на складе </Modal.Header>
                        <Modal.Content>
                            Позиция {this.state.showUsedInStockItem.title} используется:
                            <br />
                            Позиции в меню:
                            {Array.isArray(this.state.showUsedInStockItem.usedInItems) &&
                                this.state.showUsedInStockItem.usedInItems.map((x) => (
                                    <Button
                                        as={Link}
                                        size="small"
                                        to={`/Company/${this.state.companyId}/Item/${x.id}/Edit`}>
                                        {x.name}
                                    </Button>
                                ))}
                            <br />
                            Составные позиции на складе:
                            {Array.isArray(this.state.showUsedInStockItem.usedInStockItems) &&
                                this.state.showUsedInStockItem.usedInStockItems.map((x) => (
                                    <Button
                                        as={Link}
                                        size="small"
                                        to={`/Company/${this.state.companyId}/StockItem/${x.id}/Edit`}>
                                        {x.name}
                                    </Button>
                                ))}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={this.handleCloseUsedInModal}>Ok</Button>
                        </Modal.Actions>
                    </Modal>
                )}

                <ChangeSettingModal
                    open={this.state.isOpenSettinConfigModal}
                    actionConfig={activeConfig}
                    descriptionConfig={descriptionConfig}
                    moduleName={MODULE_NAME}
                    onClose={this.handleClickSettingButton}
                />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.stock,
        userSettingStore: state.userSettingStore,
    };
}
export const StockListContainer = connect(mapStateToProps, actionCreators)(StockList);
