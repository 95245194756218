import React, { Component } from "react";
import { Header, Icon, Dimmer, Loader } from "semantic-ui-react";
import * as utils from "@hitek-crm-utils";

export class CustomerInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAccordion: [],
            heightForTree: window.innerHeight - 210
        };
        this.clickAccordion = this.clickAccordion.bind(this);
    }

    clickAccordion(props) {
        console.log(props);
        let openAccordion = Array.from(this.state.openAccordion);

        if (openAccordion.includes(props.index)) {
            openAccordion = openAccordion.filter(x => x != props.index);
        } else {
            openAccordion.push(props.index);
        }
        this.setState({
            openAccordion
        })
    }
    render() {
        return <div>
            <Dimmer active={this.props.isCustomerInfoLoading} inverted >
                <Loader />
            </Dimmer>
            <Header size='medium'> Инфориация о клиенте</Header>

            {(!this.props.isCorrectOrderPhone || !this.props.orderCustomerInfo)
                ?
                <Header size='small' >{(this.props.isCorrectOrderPhone) ? "Новый клиент" : "Информация отсутсвует"}</Header>
                :
                <div>
                    <Header key="11" size='small' >Псевдоним</Header>
                    <p key="12" ><Icon name='user circle outline' />
                        <strong> {this.props.orderCustomerInfo.alias}</strong>
                    </p>
                    <Header key="1" size='small' > Контактный телефон </Header>
                    <p key="2" ><Icon name='phone' />{this.props.orderCustomerInfo.phone}</p>
                    <Header key="3" size='small' >Количество заказов</Header>
                    <p key="4" >{this.props.orderCustomerInfo.orderCount}</p>
                    <Header key="5" size='small' >Средний чек</Header>
                    <p key="6" ><strong>{utils.toFloatToString(this.props.orderCustomerInfo.avaragePay)} р.</strong></p>
                    <Header key="7" size='small' >Общая сумма заказов</Header>
                    <p key="8" >{utils.toFloatToString(this.props.orderCustomerInfo.totalPay)} р.</p>
                    <Header key="9" size='small' >Примечание</Header>
                    <p key="10" ></p>
                </div>
            }

        </div>



    }
}
export default CustomerInfo;