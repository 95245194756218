export const clearStore = "PAYMENT_LIST_STORE";
export const clearItemStore = "PAYMENT_ITEM_CLEAR";

export const listLoadStart = 'PAYMENT_LIST_LOAD_START';
export const listLoadSuccess = 'PAYMENT_LIST_LOAD_SUCCESS';
export const listLoadFail = 'PAYMENT_LIST_LOAD_FAIL';

export const itemSaveStart = 'PAYMENT_SAVE_START';
export const itemSaveSuccess = 'PAYMENT_SAVE_SUCCESS';
export const itemSaveFail = 'PAYMENT_SAVE_FAIL';

export const itemLoadStart = 'PAYMENT_ITEM_LOAD_START';
export const itemLoadSuccess = 'PAYMENT_ITEM_LOAD_SUCCESS';
export const itemLoadFail = 'PAYMENT_ITEM_LOAD_FAIL';

export const itemDeleteStart = 'PAYMENT_ITEM_DELETE_START';
export const itemDeleteSuccess = 'PAYMENT_ITEM_DELETE_SUCCESS';
export const itemDeleteFail = 'PAYMENT_ITEM_DELETE_FAIL';

export const listQueueLoadStart = 'PAYMENT_QUEUE_LIST_LOAD_START';
export const listQueueLoadSuccess = 'PAYMENT_QUEUE_LIST_LOAD_SUCCESS';
export const listQueueLoadFail = 'PAYMENT_QUEUE_LIST_LOAD_FAIL';




