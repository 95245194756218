import {browserHistory } from 'react-router';

export default function(state ,action){
    if ( !state  || action.type=='OPERATOR_PHONE_CLEAR_STORE'){


            state =  {
                data: {},
                isLoading : false,
                isSave: false,
                isLoaded:false
            };

    }
//"OPERATOR_PHONE_NEW_SAVE_START","OPERATOR_PHONE_NEW_SAVE_SUCCESS","OPERATOR_PHONE_NEW_SAVE_FAIL"
//"OPERATOR_PHONE_INIT_CATEGORY_LIST_START","OPERATOR_PHONE_INIT_CATEGORY_LIST_SUCCESS","OPERATOR_PHONE_INIT_CATEGORY_LIST_FAIL"
//OPERATOR_PHONE_DETAIL_START","OPERATOR_PHONE_DETAIL_SUCCESS","OPERATOR_PHONE_DETAIL_FAIL
//"OPERATOR_PHONE_SAVE_START","OPERATOR_PHONE_SAVE_SUCCESS","OPERATOR_PHONE_SAVE_FAIL"
    switch(action.type){
        case "OPERATOR_PHONE_NEW_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "OPERATOR_PHONE_NEW_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "OPERATOR_PHONE_NEW_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
        case "OPERATOR_PHONE_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "OPERATOR_PHONE_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "OPERATOR_PHONE_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
            
        case "OPERATOR_PHONE_DETAIL_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "OPERATOR_PHONE_DETAIL_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isLoaded:true}} ;
        case "OPERATOR_PHONE_DETAIL_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        default:
            return state;
    }
}