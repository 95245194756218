import React, { Component } from 'react';
import { Grid, Header, Button, Divider, Form, Input, Select, Message } from 'semantic-ui-react'
import * as actionCreators from "./actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as utils from "@hitek-crm-utils";

export class TagNew extends Component {


    constructor(props) {
        super(props);
        this.state = {
            currentCompanyId: this.props.match.params.companyId,
            name: "",
            order: 0,
            color: ""
        }


        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeOrder = this.handleChangeOrder.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.props.clearStore();

        // Operations usually carried out in componentWillMount go here
    }

    componentWillReceiveProps(nextProps) {


        if (this.props.match.params.companyId != nextProps.match.params.companyId) {

            this.setState({
                currentCompanyId: nextProps.match.params.companyId,
            });
            //this.resetForm();
        }

        if (this.props.tag.isSave != nextProps.tag.isSave && nextProps.tag.isSave) {
            this.props.history.push(utils.getRoute(this.props.match.url, 1));
        }
    }

    handleChangeName(e, target) {
        this.setState({ name: target.value });
    }

    handleChangeOrder(e, target) {
        this.setState({ order: target.value });
    }

    handleChangeColor(e, target) {
        this.setState({ color: target.value });
    }

    handleSave(e) {
        e.preventDefault();
        this.props.saveNewTag(
            {
                name: this.state.name,
                companyId: this.state.currentCompanyId,
                order: this.state.order,
                color: this.state.color
            }
        );
    }


    render() {
        return (

            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size='huge'>Новая тег</Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={2} >
                    <Grid.Column>
                        <Form success={this.props.tag.isSave} error={!!this.props.tag.error} loading={this.props.tag.isLoading} >
                            <Form.Field control={Input} label='Название' placeholder='Название тега' onChange={this.handleChangeName} value={this.state.name} />

                            {/*<Form.Field  control={Input} label='Очередь сортировки' placeholder='Очередь сортировки' onChange={this.handleChangeOrder} 
                            value ={this.state.order}  />
                            */}
                            <Form.Field control={Select} label='Цвет ' placeholder='нет цвета' onChange={this.handleChangeColor} value={this.state.color}
                                options={[
                                    { value: 'red', text: 'Красный', label: { empty: true, circular: true, color: "red" } },
                                    { value: 'orange', text: 'Оранжевый', label: { empty: true, circular: true, color: "orange" } },
                                    { value: 'yellow', text: 'Желтый', label: { empty: true, circular: true, color: "yellow" } },
                                    { value: 'olive', text: 'Оливковый', label: { empty: true, circular: true, color: "olive" } },
                                    { value: 'green', text: 'Зеленый', label: { empty: true, circular: true, color: "green" } },
                                    { value: 'teal', text: 'Голубой', label: { empty: true, circular: true, color: "teal" } },
                                    { value: 'blue', text: 'Синий', label: { empty: true, circular: true, color: "blue" } },
                                    { value: 'violet', text: 'Фиолетовый', label: { empty: true, circular: true, color: "violet" } },
                                    { value: 'purple', text: 'Пурпурный', label: { empty: true, circular: true, color: "purple" } },
                                    { value: 'pink', text: 'Розовый', label: { empty: true, circular: true, color: "pink" } },
                                    { value: 'brown', text: 'Коричневый', label: { empty: true, circular: true, color: "brown" } },
                                    { value: 'grey', text: 'Серый', label: { empty: true, circular: true, color: "grey" } },
                                    { value: 'black', text: 'Черный', label: { empty: true, circular: true, color: "black" } },
                                    { value: '', text: 'Не указан', label: { empty: true, circular: true, color: "grey" } }
                                ]}
                            />
                            <Message
                                error
                                header="Ошибка"
                                content={this.props.tag.error}
                            />
                            <Message
                                success
                            >
                                <Message.Content>
                                    Успешно Сохранено
                                <Button as={Link} to={"/Company/" + this.state.currentCompanyId + "/Tag"}>
                                        Вернуться к списку
                                </Button>
                                </Message.Content>
                            </Message>
                            <Form.Field disabled={this.props.tag.isLoading || this.props.tag.isSave} onClick={this.handleSave} control={Button}>Сохранить</Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>

            </Grid>);

    }
};

function mapStateToProps(state) {

    return {

        tag: state.tagEdit


    };
}


export const TagNewContainer = connect(mapStateToProps, actionCreators)(TagNew);
