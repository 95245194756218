import React, { useState, useCallback, useEffect } from "react";
import { Grid, Header, Button, Divider, Table, Loader } from "semantic-ui-react";
import * as actionCreators from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import * as utils from "@hitek-crm-utils";
import DatePicker from "@hitek-crm/DatePicker";

function prepare(month, year) {
    function getDay(date) {
        // получить номер дня недели, от 0 (пн) до 6 (вс)
        let day = date.getDay();
        if (day == 0) day = 7; // сделать воскресенье (0) последним днем
        return day - 1;
    }

    let mon = month - 1; // месяцы в JS идут от 0 до 11, а не от 1 до 12

    let d = new Date(year, mon);
    const weeks = [];
    let currentWeek = 0;
    for (let i = 0; i < getDay(d); i++) {
        if (!weeks[currentWeek]) {
            weeks[currentWeek] = [];
        }
        weeks[currentWeek][i] += {};
    }

    while (d.getMonth() == mon) {
        if (!weeks[currentWeek]) {
            weeks[currentWeek] = [];
        }

        weeks[currentWeek][getDay(d)] = { day: d.getDate() };

        if (getDay(d) % 7 == 6) {
            // вс, последний день - перевод строки
            currentWeek += 1;
        }

        d.setDate(d.getDate() + 1);
    }

    while (!!weeks[currentWeek] && weeks[currentWeek].length < 7) {
        weeks[currentWeek][weeks[currentWeek].length] += {};
    }

    return weeks;
}

function isToday(day, month, year) {
    const date = new Date();
    return date.getDate() === day && date.getMonth() === month && date.getFullYear() === year;
}
function getCompanyName(companyList, icurrentId) {
    let companies = companyList;
    if (!Array.isArray(companies)) return null;
    let company = companies.find((x) => x.id == icurrentId);
    if (!!company) {
        return company.name;
    }
    return null;
}

function loadData(dispatch, state) {
    dispatch(actionCreators.initCountOrdersByDay({ companyId: state.companyId }));
}
export function OrderCalendar() {
    const { companyId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [selectedMonth, setselectedMonth] = useState(new Date());

    const { dashBoardStore, companyStore } = useSelector((state) => {
        return {
            dashBoardStore: state.dashBoard,
            companyStore: state.companyList,
        };
    });

    const companyName = getCompanyName(companyStore.companyList, companyId);

    useEffect(() => {
        loadData(dispatch, { companyId });
    }, [companyId]);

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        setselectedMonth(utils.dateFromShortString(params.get("date")));
    }, [location.search]);

    const handlerChangeMonth = useCallback(
        (date) => {
            setselectedMonth(date);

            history.push({
                pathname: location.pathname,
                search: "date=" + utils.toShortDateString(date),
            });
        },
        [location.pathname]
    );

    const [maxOrderDate, setMaxOrderDate] = useState(new Date());
    const [minOrderDate, setMinOrderDate] = useState(new Date());
    const [data, setData] = useState({});
    useEffect(() => {
        let maxOrderDate = new Date();
        let minOrderDate = new Date();
        const data = {};
        for (const o of dashBoardStore.orderByDay) {
            if (maxOrderDate < o.date) {
                // debugger
                maxOrderDate = o.date;
            }
            if (minOrderDate > o.date) {
                minOrderDate = o.date;
            }
            if (
                o.date.getMonth() == selectedMonth.getMonth() &&
                o.date.getFullYear() == selectedMonth.getFullYear()
            ) {
                data[o.date.getDate()] = { count: o.count };
            }
        }

        setMaxOrderDate(maxOrderDate);
        setMinOrderDate(minOrderDate);
        setData(data);
    }, [dashBoardStore.orderByDay, selectedMonth]);

    if (dashBoardStore.isLoading) {
        return  <Loader active inline="centered" size="big" />
    }
    return (
        <Grid>
            <Grid.Row columns={3}>
                <Grid.Column width={10}>
                    <Header size="huge">Календарь заказов для {companyName}</Header>
                </Grid.Column>
                <Grid.Column textAlign="right" width={5}>
                    Фильтр по дате:
                    <DatePicker
                        selected={selectedMonth}
                        onChange={handlerChangeMonth}
                        //includeDates={Array.from(this.props.dashBoardState.orderByDay, x => new Date(x.date))}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        className="ui input"
                        maxDate={maxOrderDate}
                        minDate={minOrderDate}
                    />
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Header as="h2">
                        {selectedMonth.toLocaleDateString(undefined, {
                            year: "numeric",
                            month: "long",
                        })}
                    </Header>
                    <Table celled striped fixed>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Пн.</Table.HeaderCell>
                                <Table.HeaderCell>Вт.</Table.HeaderCell>
                                <Table.HeaderCell>Ср.</Table.HeaderCell>
                                <Table.HeaderCell>Чт.</Table.HeaderCell>
                                <Table.HeaderCell>Пт.</Table.HeaderCell>
                                <Table.HeaderCell>Сб.</Table.HeaderCell>
                                <Table.HeaderCell>Вс.</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {prepare(selectedMonth.getMonth() + 1, selectedMonth.getFullYear()).map(
                                (month, i) => (
                                    <Table.Row key={i}>
                                        {month.map((day, i) => (
                                            <Table.Cell
                                                key={i}
                                                positive={isToday(
                                                    day.day,
                                                    selectedMonth.getMonth(),
                                                    selectedMonth.getFullYear()
                                                )}>
                                                {day.day} <br />
                                                {data[day.day] && (
                                                    <Button
                                                        as={Link}
                                                        size="mini"
                                                        to={`/Company/${companyId}/DashBoard/Summury?date=${selectedMonth.getFullYear()}-${
                                                            selectedMonth.getMonth() + 1
                                                        }-${day.day}`}
                                                        color="blue">
                                                        Заказов: {data[day.day].count}
                                                    </Button>
                                                )}
                                            </Table.Cell>
                                        ))}
                                    </Table.Row>
                                )
                            )}
                        </Table.Body>
                        {/*<Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='7'>
                                Средний чек
                        </Table.HeaderCell>


                            <Table.HeaderCell textAlign='right'>
                                {utils.toFloatToString(this.getAverageSumm())} р.
                        </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>

                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell colSpan='7'>
                                Общая суммa
                        </Table.HeaderCell>


                            <Table.HeaderCell textAlign='right'>
                                {utils.toFloatToString(this.getTotalSumm())} р.
                        </Table.HeaderCell>
                            <Table.HeaderCell>
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Footer>*/}
                    </Table>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
