import React, { Component } from "react";
import {
    Grid,
    Header,
    Divider,
    Table,
    Icon,
    Button,
    Modal,
    Dropdown,
    Loader,
    Pagination,
    Popup,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actionCreators from "./actions";
import { initListData as loadListStockItem } from "../StockItem/actions";
import { ChangeSettingModal } from "../../components/UserSetting/ChangeSettingModal";
import * as utils from "@hitek-crm-utils";
import DatePicker from "@hitek-crm/DatePicker";

const MODULE_NAME = "StockOperationList";
const defaultConfig = {
    countPerPage: 25,
};
const descriptionConfig = {
    countPerPage: {
        description: "Колличество записей на странице",
        type: "int",
    },
};

export class StockOperationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeleteShowModal: false,
            currentCompanyId: this.props.match.params.companyId,
            idForRemove: null,
            searchStockItem: null,
            activPage: 1,
            startDate: null,
            endDate:null
        };

        const date = utils.readparamsFromSearch(this.props.location,{endDateIsOptional:true});

        this.state.startDate = date.startDate;
        this.state.endDate = date.endDate;
        let searchParam = new URLSearchParams(this.props.location.search);
        if (searchParam.has("stockItem")) {
            
            this.state.searchStockItem = parseInt(searchParam.get("stockItem"));
        }


        this.props.clearStore();

        this.handleChangeSearchStockItem = this.handleChangeSearchStockItem.bind(this);
        this.handleClickRecalculate = this.handleClickRecalculate.bind(this);
        this.handlerReloadData = this.handlerReloadData.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.handleClickSettingButton = this.handleClickSettingButton.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    }

    componentDidMount() {

        this.loadData.call(this, {
            companyId: this.state.currentCompanyId,
            searchStockItem: this.state.searchStockItem,
            startDate: this.state.startDate,
            endDate:this.state.endDate,
            
        });
    }

    loadData(state) {
        this.props.initListData({
            companyId: state.companyId,
            stockItemId: state.searchStockItem,
            startDate: state.startDate,
            endDate: state.endDate
        });
        if (this.props.stockItem.list.length == 0 || state.force) {
            this.props.loadListStockItem({ companyId: state.companyId });
        }
    }

    getDataForTable() {
        if (!this.props.data) return [];

        return this.props.data.list;
    }
    componentWillReceiveProps(nextProps) {
        if (
            !!nextProps.data &&
            nextProps.data.isRequreReload &&
            this.props.data.isRequreReload != nextProps.data.isRequreReload
        ) {
            this.props.clearStore();
            this.loadData.call(this, {
                companyId: this.state.currentCompanyId,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            });
        }

        if (this.props.match.params.companyId != nextProps.match.params.companyId) {
            this.setState({
                currentCompanyId: nextProps.match.params.companyId,
                searchCategories: [],
                activPage: 1,
                startDate: null,
                endDate:null
            });
            this.loadData.call(this, { companyId: nextProps.match.params.companyId, force: true });
        }
        if (this.props.location.search != nextProps.location.search) {
            const date = utils.readparamsFromSearch(nextProps.location,{endDateIsOptional:true});
            let searchParam = new URLSearchParams(nextProps.location.search);
            let newp = { ...date };
            if (searchParam.has("stockItem")) {
                newp.searchStockItem = parseInt(searchParam.get("stockItem"));
            }else {
                newp.searchStockItem=null;
            }
            this.setState(newp);
            this.loadData.call(this, {
                companyId: nextProps.match.params.companyId,
                startDate: newp.startDate,
                endDate:newp.endDate,
                searchStockItem:newp.searchStockItem ,
                force: true,
            });
        }
    }

    handleClickRecalculate(e) {
        e.preventDefault();
        this.props.calculateStock({
            companyId: this.state.currentCompanyId,
        });
    }
    handleChangeSearchStockItem(e, target) {
        /*this.setState({ searchStockItem: target.value });
        this.loadData.call(this, {
            companyId: this.state.currentCompanyId,

            searchStockItem: target.value,
            startDate: this.state.startDate,
                endDate:this.state.endDate,
        });*/
        
        const search = [];
        if(this.state.startDate){
            search.push("startDate="+utils.toShortDateString(this.state.startDate))
        }
        if(this.state.endDate){
            search.push("endDate="+utils.toShortDateString(this.state.endDate))
        }
        if(target.value){
            search.push("stockItem="+ target.value)

        }
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: search.join("&"),
        });
    }
    handleChangeStartDate(date) {
        const [startDate,endDate] = date;
        startDate.setHours(0);
        startDate.setMinutes(0);
        if(endDate){
            endDate.setHours(23);
            endDate.setMinutes(59);
        }
        this.setState({ startDate: startDate ,endDate:endDate});


        const search = ["startDate=" + utils.toShortDateString(startDate)];
        if(endDate){
            search.push("endDate="+utils.toShortDateString(endDate))
        }
        if(this.state.searchStockItem){
            search.push("stockItem="+ this.state.searchStockItem)

        }
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: search.join("&"),
        });
    }

    handlerReloadData(e) {
        e.preventDefault();
        this.loadData.call(this, {
            companyId: this.state.currentCompanyId,
            searchStockItem: this.state.searchStockItem,
        });
        
    }
    handlePaginationChange(e, target) {
        this.setState({ activPage: target.activePage });
    }
    handleClickSettingButton(e) {
        this.setState({ isOpenSettinConfigModal: !!e });
    }

    render() {
        const activeConfig = { ...defaultConfig };

        if (this.props.userSettingStore.list && this.props.userSettingStore.list[MODULE_NAME]) {
            const moduleSetting = this.props.userSettingStore.list[MODULE_NAME];
            if (moduleSetting["countPerPage"]) {
                activeConfig["countPerPage"] = moduleSetting["countPerPage"];
            }
        }
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size="huge">Операции по складу</Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Button
                            color="blue"
                            as={Link}
                            to={"/Company/" + this.state.currentCompanyId + "/Stock"}>
                            <Icon name="warehouse" /> Склад
                        </Button>

                        <Button
                            color="green"
                            as={Link}
                            to={"/Company/" + this.state.currentCompanyId + "/StockOperation/Add"}>
                            <Icon name="add" /> Добавить операцию по складу
                        </Button>
                        <Button color="green" onClick={this.handleClickRecalculate}>
                            {" "}
                            <Icon name="calculator" /> Пересчитать позиции
                        </Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button color="green" onClick={this.handlerReloadData}>
                            {" "}
                            <Icon name="sync alternate" />  Обновить
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.HeaderCell>Название</Table.HeaderCell>
                                    <Table.HeaderCell>Единица изменения </Table.HeaderCell>
                                    <Table.HeaderCell>Дата</Table.HeaderCell>
                                    <Table.HeaderCell>Статус</Table.HeaderCell>
                                    <Table.HeaderCell>Стоимость</Table.HeaderCell>
                                    <Table.HeaderCell>Переход на заказ</Table.HeaderCell>
                                    <Table.HeaderCell>Комментарий / №ТН</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <Dropdown
                                            loading={this.props.stockItem.isLoading}
                                            placeholder="Фильтр"
                                            selection
                                            search
                                            clearable
                                            options={Array.from(this.props.stockItem.list, (x) => {
                                                return { text: x.title, value: x.id };
                                            })}
                                            value={this.state.searchStockItem}
                                            onChange={this.handleChangeSearchStockItem}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            startDate={this.state.startDate}
                                            onChange={this.handleChangeStartDate}
                                            selectsRange
      endDate={this.state.endDate}
                                        />
                                      
                                    </Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.props.data.isLoading && (
                                    <Table.Row>
                                        <Table.Cell colSpan="100">
                                            <Loader active inline="centered" size="big" />
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                {this.getDataForTable()
                                    .filter(
                                        (x, index) =>
                                            index >=
                                                (this.state.activPage - 1) *
                                                    activeConfig.countPerPage &&
                                            index < this.state.activPage * activeConfig.countPerPage
                                    )
                                    .map((item) => {
                                        return (
                                            <Table.Row
                                                key={item.id}
                                                positive={item.operationType === "+"}
                                                negative={item.operationType === "-"}>
                                                <Table.Cell collapsing>
                                                    <Popup
                                                        trigger={<Icon name="folder" />}
                                                        content={"Полный ID: " + item.id}
                                                        position="top left"
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>{item.stockItemTitle}</Table.Cell>
                                                <Table.Cell>
                                                    {item.operationType} {item.count}{" "}
                                                    {item.stockItemUnit}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.date.toLocaleString()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.isProcessed ? (
                                                        <Icon name="check" />
                                                    ) : (
                                                        <Icon name="clock outline" />
                                                    )}
                                                    {item.isReserved && <Icon name="thumbtack" />}
                                                    {item.isLocked && <Icon name="lock" />}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.amount > 0 ? (
                                                        <strong>{item.amount}</strong>
                                                    ) : (
                                                        0
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.orderId && (
                                                        <Button
                                                            color="orange"
                                                            as={Link}
                                                            to={
                                                                "/Company/" +
                                                                this.state.currentCompanyId +
                                                                "/Order/" +
                                                                item.orderId
                                                            }>
                                                            {" "}
                                                            К заказу
                                                        </Button>
                                                    )}
                                                    {item.packingListId && (
                                                        <Button
                                                            color="blue"
                                                            as={Link}
                                                            to={
                                                                "/Company/" +
                                                                this.state.currentCompanyId +
                                                                "/PackingList/" +
                                                                item.packingListId +
                                                                "/Detail"
                                                            }>
                                                            {" "}
                                                            К накладной
                                                        </Button>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>{item.comment}</Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="3" textAlign="center">
                                        Отображать по <strong>{activeConfig.countPerPage}</strong>{" "}
                                        строк{" "}
                                        <Button
                                            icon="setting"
                                            onClick={this.handleClickSettingButton}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell colSpan="5" textAlign="center">
                                        <Pagination
                                            activePage={this.state.activPage}
                                            boundaryRange={2}
                                            onPageChange={this.handlePaginationChange}
                                            size="mini"
                                            siblingRange={2}
                                            totalPages={utils.toCorrectFloat(
                                                this.getDataForTable().length /
                                                    activeConfig.countPerPage +
                                                    0.49,
                                                0
                                            )}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <ChangeSettingModal
                    open={this.state.isOpenSettinConfigModal}
                    actionConfig={activeConfig}
                    descriptionConfig={descriptionConfig}
                    moduleName={MODULE_NAME}
                    onClose={this.handleClickSettingButton}
                />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.stockOperation,
        stockItem: state.stockItem,
        userSettingStore: state.userSettingStore,
    };
}
export const StockOperationListContainer = connect(mapStateToProps, {
    ...actionCreators,
    loadListStockItem,
})(StockOperationList);
