export const clearStore = "STOCK_CLEAR_STORE";


export const listLoadStart = 'STOCK_LIST_LOAD_START';
export const listLoadSuccess = 'STOCK_LIST_LOAD_SUCCESS';
export const listLoadFail = 'STOCK_LIST_LOAD_FAIL';

export const itemSaveStart = 'STOCK_SAVE_START';
export const itemSaveSuccess = 'STOCK_SAVE_SUCCESS';
export const itemSaveFail = 'STOCK_SAVE_FAIL';
