import * as config from "Config";
import * as constants from "./constants";
import { ITEM_LIST_LOAD_START, ITEM_LIST_LOAD_SUCCESS, ITEM_LIST_LOAD_FAIL } from "./../constants";

export function clearStore() {
    return {
        type: constants.ITEM_CLEAR_STORE,
    };
}

export function initItemList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Item/Company/" + params.companyId,
        },
        actions: [ITEM_LIST_LOAD_START, ITEM_LIST_LOAD_SUCCESS, ITEM_LIST_LOAD_FAIL],
    };
}

export function initCustomFields(params) {
    let url = config.apiUrl + "/api/CustomField";
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }

    return {
        remote: {
            method: "get",
            url,
            data: { type: "item" },
        },
        actions: [
            constants.ITEM_CF_LOAD_START,
            constants.ITEM_CF_LOAD_SUCCESS,
            constants.ITEM_CF_LOAD_FAIL,
        ],
    };
}

export function saveNewItem(params) {
    let url = config.apiUrl + "/api/Item";
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }

    return {
        remote: {
            method: "post",
            url,
            data: params.formData,
        },
        actions: [
            constants.ITEM_NEW_SAVE_START,
            constants.ITEM_NEW_SAVE_SUCCESS,
            constants.ITEM_NEW_SAVE_FAIL,
        ],
    };
}

export function saveItem(params) {
    let url = config.apiUrl + "/api/Item/" + params.id;
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }

    return {
        remote: {
            method: "put",

            url,
            data: params.formData,
        },
        actions: [constants.ITEM_SAVE_START, constants.ITEM_SAVE_SUCCESS, constants.ITEM_SAVE_FAIL],
    };
}

export function getDetailItem(params) {
    let url = config.apiUrl + "/api/Item/" + params.id;
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }
    return {
        remote: {
            method: "get",
            url,
        },
        actions: [
            constants.ITEM_DETAIL_START,
            constants.ITEM_DETAIL_SUCCESS,
            constants.ITEM_DETAIL_FAIL,
        ],
    };
}
