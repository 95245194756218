import { browserHistory } from "react-router";
import * as constants from "./constants";

export default function(state, action) {
  if (!state || action.type == constants.clearStore) {
    state = {
      list: [],
      item: {},
      isLoading: false,
      isSave: false,
      isSaved: false,
      isLoaded: false,
      isRequreReload: false
    };
  }
  switch (action.type) {
    case constants.listLoadStart:
      return {
        ...state,
        ...{ list: [], isLoading: true, error: undefined }
      };
    case constants.listLoadSuccess:
      return {
        ...state,
        ...{
          list: action.data.sort((a, b) => a.title.localeCompare(b.title)),
          isLoading: false
        }
      };
    case constants.listLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };

 /*   case constants.itemLoadStart:
      return {
        ...state,
        ...{ item: {}, isLoading: true, error: undefined }
      };
    case constants.itemLoadSuccess:
      return {
        ...state,
        ...{
          item: action.data,
          isLoading: false
        }
      };
    case constants.itemLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };
*/
    case constants.itemSaveStart:
      return {
        ...state,
        ...{
          isRequreReload: true,
          isSaved: false,
          isSaving: true,
          error: undefined
        }
      };
    case constants.itemSaveSuccess:
      return { ...state, isRequreReload: true, isSaved: true, isSaving: false };
    case constants.itemSaveFail:
      return {
        ...state,
        ...{ isSaved: false, isSaving: false, error: action.error }
      };

    /* case constants.itemDeleteStart:
        return { ...state, ...{ isRequreReload: false, error: undefined } };
    case constants.itemDeleteSuccess:
        return { ...state, isRequreReload: true };
    case constants.itemDeleteFail:
        return { ...state, ...{ isRequreReload: false, error: action.error } };*/
    default:
      return state;
  }
}
