import * as config from "Config";


export function clearStore(newObject) {
    return {
      type:"DISCOUNT_LIST_CLEAR_STORE"
    };
}

export function initListData(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Discount/Company/"+params.companyId + "/Summury"
      },
      actions:["DISCOUNT_LIST_SUMMURY_LOAD_START","DISCOUNT_LIST_SUMMURY_LOAD_SUCCESS","DISCOUNT_LIST_SUMMURY_LOAD_FAIL"]
    };
}

export function deleteDiscount(params) {
    return {
      remote:{
          method: "delete",
          url: config.apiUrl + "/api/Discount/"+params.id + "/Company/" + params.companyId
      },

      actions:["DISCOUNT_LIST_REMOVE_START","DISCOUNT_LIST_REMOVE_SUCCESS","DISCOUNT_LIST_REMOVE_FAIL"]
    };
}



