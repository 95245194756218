export const clearStore = "PACKING_LIST_LIST_STORE";
export const clearItemStore = "PACKING_LIST_ITEM_CLEAR";

export const listLoadStart = 'PACKING_LIST_LIST_LOAD_START';
export const listLoadSuccess = 'PACKING_LIST_LIST_LOAD_SUCCESS';
export const listLoadFail = 'PACKING_LIST_LIST_LOAD_FAIL';

export const itemSaveStart = 'PACKING_LIST_SAVE_START';
export const itemSaveSuccess = 'PACKING_LIST_SAVE_SUCCESS';
export const itemSaveFail = 'PACKING_LIST_SAVE_FAIL';

export const itemLoadStart = 'PACKING_LIST_ITEM_LOAD_START';
export const itemLoadSuccess = 'PACKING_LIST_ITEM_LOAD_SUCCESS';
export const itemLoadFail = 'PACKING_LIST_ITEM_LOAD_FAIL';

export const itemDeleteStart = 'PACKING_LIST_ITEM_DELETE_START';
export const itemDeleteSuccess = 'PACKING_LIST_ITEM_DELETE_SUCCESS';
export const itemDeleteFail = 'PACKING_LIST_ITEM_DELETE_FAIL';

export const itemApproveStart = 'PACKING_LIST_ITEM_APPROVE_START';
export const itemApproveSuccess = 'PACKING_LIST_ITEM_APPROVE_SUCCESS';
export const itemApproveFail = 'PACKING_LIST_ITEM_APPROVE_FAIL';





