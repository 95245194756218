import React, { useState, useEffect } from "react";
import {
  Grid,
  Header,
  Button,
  Divider,
  Icon,
  Loader,
  Table,
} from "semantic-ui-react";
import * as actionCreators from "./actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import * as utils from "@hitek-crm-utils";

import { initListData as initStockItemData } from "../StockItem/actions";

const defaultFormState = {
  itemNumber: "",
  date: null,
  comment: "",
  items: [],
};

function loadData(dispatch, state) {
  if (state.supplierId) {
    dispatch(
      actionCreators.initItemData({
        companyId: state.companyId,
        id: state.supplierId,
      })
    );
  }
  dispatch(
    initStockItemData({
      companyId: state.companyId,
    })
  );
}

export function SupplierDetail() {
  const { companyId, supplierId } = useParams();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState(null);

  const { supplierStore, stockItemStore } = useSelector((state) => {
    return {
      supplierStore: state.supplierStore,
      stockItemStore: state.stockItem,
    };
  });

  useEffect(() => {
    loadData(dispatch, { companyId, supplierId });

    return () => {
      setFormState(null);
      dispatch(actionCreators.clearStore({ onlyItem: true }));
    };
  }, [companyId, supplierId]);

  useEffect(() => {
    const data = { ...defaultFormState, ...supplierStore.item };

    const newitems = [];
    for (var v of data.items) {
      v["totalPrice"] = v.count * v.price;
      v["totalVat"] = utils.toCorrectFloat((v.vat / 100) * v.totalPrice, 5);
      v["amount"] = utils.toCorrectFloat(v.totalVat + v.totalPrice, 5);
      newitems.push(v);
    }
    data.items = newitems;
    setFormState(data);
  }, [supplierStore.item]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header size="huge">Накладная</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column>
          <Button
            color="green"
            as={Link}
            to={"/Company/" + companyId + "/Supplier"}
          >
            <Icon name="box" /> Список накладных
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {!formState || supplierStore.isLoading ? (
          <Loader active inline="centered" size="big" />
        ) : (
          <Grid.Column>
            <label>Номер:</label> {formState.itemNumber}
            <br />
            <label>От даты:</label>{" "}
            {formState.date ? formState.date.toLocaleDateString() : "-"}
            <br />
            <label>Создана:</label>{" "}
            {formState.creationDate ? formState.creationDate.toLocaleString() : "-"}
            <br />
            <label>Коментарий:</label> {formState.comment}
            <br />
            <h5>Состав</h5>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Наименование</Table.HeaderCell>
                  <Table.HeaderCell>Ед измерения </Table.HeaderCell>
                  <Table.HeaderCell>Колличество</Table.HeaderCell>
                  <Table.HeaderCell>Цена</Table.HeaderCell>
                  <Table.HeaderCell>Стоимость</Table.HeaderCell>
                  <Table.HeaderCell>НДС %</Table.HeaderCell>
                  <Table.HeaderCell>Сумма с НДС </Table.HeaderCell>

                  <Table.HeaderCell>Стоимость с НДС</Table.HeaderCell>
                  <Table.HeaderCell>На балансе</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {(formState.items || []).map((item) => {
                  const stockItem =
                    stockItemStore.list.find((x) => x.id == item.stockItemId) ||
                    {};
                  return (
                    <Table.Row key={item.stockItemId}>
                      <Table.Cell collapsing>{stockItem.title}</Table.Cell>
                      <Table.Cell>{stockItem.unit}</Table.Cell>
                      <Table.Cell>{item.count}</Table.Cell>
                      <Table.Cell>{item.price}</Table.Cell>
                      <Table.Cell>{item.totalPrice}</Table.Cell>
                      <Table.Cell>{item.vat}</Table.Cell>
                      <Table.Cell>{item.totalVat}</Table.Cell>
                      <Table.Cell>{item.amount}</Table.Cell>
                      <Table.Cell
                        positive={item.balance > 0}
                        negative={item.balance < 0}
                      >
                        {item.balance}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
}
