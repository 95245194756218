import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == "ORDER_STATUS_CLEAR_STORE") {
        state = {
            data: {},
            isLoading: false,
            isSave: false,
            isLoaded: false,
            customFieldList: []
        };
    }
    switch (action.type) {
        case constants.ORDER_STATUS_NEW_SAVE_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.ORDER_STATUS_NEW_SAVE_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isSave: true } };
        case constants.ORDER_STATUS_NEW_SAVE_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.ORDER_STATUS_SAVE_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.ORDER_STATUS_SAVE_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isSave: true } };
        case constants.ORDER_STATUS_SAVE_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.ORDER_STATUS_DETAIL_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.ORDER_STATUS_DETAIL_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isLoaded: true } };
        case constants.ORDER_STATUS_DETAIL_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.ORDER_STATUS_CF_LOAD_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.ORDER_STATUS_CF_LOAD_SUCCESS:
            return { ...state, ...{ isLoading: false, customFieldList: action.data } };
        case constants.ORDER_STATUS_CF_LOAD_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        default:
            return state;
    }
}
