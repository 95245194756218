import * as config from "Config";
import * as constants from "./constants";

export function clearStore() {
    return {
        type: constants.CATEGORY_CLEAR_STORE,
    };
}
export function initCustomFields(params) {
    let url = config.apiUrl + "/api/CustomField";
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }

    return {
        remote: {
            method: "get",
            url,
            data: { type: "category" },
        },
        actions: [
            constants.CATEGORY_CF_LOAD_START,
            constants.CATEGORY_CF_LOAD_SUCCESS,
            constants.CATEGORY_CF_LOAD_FAIL,
        ],
    };
}

export function saveNewCategory(params) {
    let url = config.apiUrl + "/api/Category";
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }
    return {
        remote: {
            method: "post",
            url: url,
            data: params.formData,
        },
        actions: [
            constants.CATEGORY_NEW_SAVE_START,
            constants.CATEGORY_NEW_SAVE_SUCCESS,
            constants.CATEGORY_NEW_SAVE_FAIL,
        ],
    };
}

export function saveCategory(params) {
    let url = config.apiUrl + "/api/Category/" + params.id;
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }

    return {
        remote: {
            method: "put",
            url,
            data: params.formData,
        },
        actions: [
            constants.CATEGORY_SAVE_START,
            constants.CATEGORY_SAVE_SUCCESS,
            constants.CATEGORY_SAVE_FAIL,
        ],
    };
}

export function getDetailCategory(params) {
    let url = config.apiUrl + "/api/Category/" + params.id;
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }

    return {
        remote: {
            method: "get",
            url,
        },
        actions: [
            constants.CATEGORY_DETAIL_START,
            constants.CATEGORY_DETAIL_SUCCESS,
            constants.CATEGORY_DETAIL_FAIL,
        ],
    };
}
