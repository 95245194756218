import React from "react";
import { Grid, Header, Divider, Card, Icon } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export function MainBoard() {
    const { dashBoardStore, companyStore } = useSelector((state) => {
        return {
            dashBoardStore: state.dashBoard,
            companyStore: state.companyList,
        };
    });

    const companies = companyStore.companyList;
    const comapniesDasboard = [];
    const list = [];

    for (let com of companyStore.companyList) {
        const company = {
            title: com.name,
            icon: "gem",
            items: [],
        };
        company.items.push({
            as: Link,
            to: `/Company/${com.id}/Dashboard`,
            header: "Состояние компании",
            meta: "meta",
            description: "Текущее состояние дохода компании",
        });
        company.items.push({
            as: Link,
            to: `/Company/${com.id}/Payment`,
            header: "принятые платежи за сегодня",
            description: "Список принятых платежей за сегодня",
        });
        company.items.push({
          as: Link,
          to: `/Company/${com.id}/Operations`,
          header: "Управление компанией",
          meta: "meta",
          description: "Упарвление компанией",
      });
        list.push(company);
    }

    list.push({
        title: "Новая Компания",
        icon: "gem",
        items: [
            {
                as: Link,
                to: `/Company/New`,
                header: "Добавить новую компанию",
                meta: "meta",
                description: "Добавление нововй компании",
            },
        ],
    });

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="huge">Состояние дел</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />

            <Grid.Row columns={1}>
                {list.map((l, i) => {
                    return (
                        <Grid.Column key={i}>
                            <Divider horizontal>
                                <Header as="h4">
                                    {l.icon && <Icon name={l.icon} />}
                                    {l.title}
                                </Header>
                            </Divider>

                            <Card.Group items={l.items} />
                        </Grid.Column>
                    );
                })}
            </Grid.Row>
        </Grid>
    );
}
