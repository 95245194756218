import * as config from "Config";
import * as constants from "./constants";

export function clearStore(newObject) {
    return {
        type: "PAYMENT_METHOD_CLEAR_STORE",
    };
}

export function initListData(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/PaymentMethod/Company/" + params.companyId + "/Summury",
        },
        actions: [
            "PAYMENT_METHOD_LIST_SUMMURY_LOAD_START",
            "PAYMENT_METHOD_LIST_SUMMURY_LOAD_SUCCESS",
            "PAYMENT_METHOD_LIST_SUMMURY_LOAD_FAIL",
        ],
    };
}

export function deletePaymentMethod(params) {
    return {
        remote: {
            method: "delete",
            url: config.apiUrl + "/api/PaymentMethod/" + params.id + "/Company/" + params.companyId,
        },

        actions: [constants.itemRemoveStart, constants.itemRemoveSuccess, constants.itemRemoveFail],
    };
}

export function saveNewItem(params) {
    return {
        remote: {
            method: "post",
            url: config.apiUrl + "/api/PaymentMethod/Company/" + params.companyId,
            data: params,
        },
        actions: [constants.itemSaveStart, constants.itemSaveSuccess, constants.itemSaveFail],
    };
}

export function saveItem(params) {
    return {
        remote: {
            method: "put",
            url: config.apiUrl + "/api/PaymentMethod/" + params.id + "/Company/" + params.companyId,
            data: params,
        },
        actions: [constants.itemSaveStart, constants.itemSaveSuccess, constants.itemSaveFail],
    };
}

export function getDetailPaymentMethod(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/PaymentMethod/" + params.id + "/Company/" + params.companyId,
        },
        actions: [
            "PAYMENT_METHOD_DETAIL_START",
            "PAYMENT_METHOD_DETAIL_SUCCESS",
            "PAYMENT_METHOD_DETAIL_FAIL",
        ],
    };
}

export function getFinanceSourceList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/FinanceSource/Company/" + params.companyId,
        },
        actions: [
            constants.listFinanceSourseLoadStart,
            constants.listFinanceSourseLoadSuccess,
            constants.listFinanceSourseLoadFail,
        ],
    };
}
export function getExternalPaymentMethodList(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/ExternalPaymentMethod/Company/" + params.companyId,
        },
        actions: [
            constants.listExternalPaymentMethodLoadStart,
            constants.listExternalPaymentMethodLoadSuccess,
            constants.listExternalPaymentMethodLoadFail,
        ],
    };
}
