import {browserHistory } from 'react-router';

export default function(state ,action){
    if ( !state  || action.type=='TAG_CLEAR_STORE'){


            state =  {
                data: {},
                isLoading : false,
                isSave: false,
                isLoaded:false
            };

    }
//"TAG_NEW_SAVE_START","TAG_NEW_SAVE_SUCCESS","TAG_NEW_SAVE_FAIL"
//"TAG_INIT_CATEGORY_LIST_START","TAG_INIT_CATEGORY_LIST_SUCCESS","TAG_INIT_CATEGORY_LIST_FAIL"
//TAG_DETAIL_START","TAG_DETAIL_SUCCESS","TAG_DETAIL_FAIL
//"TAG_SAVE_START","TAG_SAVE_SUCCESS","TAG_SAVE_FAIL"
    switch(action.type){
        case "TAG_NEW_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "TAG_NEW_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "TAG_NEW_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
        case "TAG_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "TAG_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "TAG_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
            
        case "TAG_DETAIL_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "TAG_DETAIL_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isLoaded:true}} ;
        case "TAG_DETAIL_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        default:
            return state;
    }
}