import * as config from "Config";
import * as constants from "./constants";

export function clearStore() {
    return {
        type: "ORDER_STATUS_CLEAR_STORE",
    };
}

export function saveNewOrderStatus(params) {
    return {
        remote: {
            method: "post",
            url: config.apiUrl + "/api/OrderStatus/Company/" + params.companyId,
            data: params,
        },
        actions: [
            constants.ORDER_STATUS_NEW_SAVE_START,
            constants.ORDER_STATUS_NEW_SAVE_SUCCESS,
            constants.ORDER_STATUS_NEW_SAVE_FAIL,
        ],
    };
}

export function saveOrderStatus(params) {
    return {
        remote: {
            method: "put",
            url: config.apiUrl + "/api/OrderStatus/" + params.id + "/Company/" + params.companyId,
            data: params,
        },
        actions: [
            constants.ORDER_STATUS_SAVE_START,
            constants.ORDER_STATUS_SAVE_SUCCESS,
            constants.ORDER_STATUS_SAVE_FAIL,
        ],
    };
}

export function getDetailOrderStatus(params) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/OrderStatus/" + params.id + "/Company/" + params.companyId,
        },
        actions: [
            constants.ORDER_STATUS_DETAIL_START,
            constants.ORDER_STATUS_DETAIL_SUCCESS,
            constants.ORDER_STATUS_DETAIL_FAIL,
        ],
    };
}

export function initCustomFields(params) {
    let url = config.apiUrl + "/api/CustomField";
    if (params.companyId) {
        url += "/Company/" + params.companyId;
    }
    if (params.companyGroupId) {
        url += "/CompanyGroup/" + params.companyGroupId;
    }

    return {
        remote: {
            method: "get",
            url,
            data: { type: "orderStatus" },
        },
        actions: [
            constants.ORDER_STATUS_CF_LOAD_START,
            constants.ORDER_STATUS_CF_LOAD_SUCCESS,
            constants.ORDER_STATUS_CF_LOAD_FAIL,
        ],
    };
}
