import React, { Component } from "react";
import { Menu, Dropdown, Icon, Input, Divider, Header } from "semantic-ui-react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";

export class LeftMenu extends Component {
    constructor(props) {
        super(props);
        // Operations usually carried out in componentWillMount go here
        this.activeItem = "";

        this.state = {
            scrolledSet: {},
        };
        this.handleToggleItems = this.handleToggleItems.bind(this);
    }

    handleToggleItems(e, target) {
        e.preventDefault();
        let id = parseInt(e.currentTarget.dataset.id);
        let scrolledSet = this.state.scrolledSet;
        if (scrolledSet[id]) {
            scrolledSet[id] = false;
        } else {
            scrolledSet[id] = true;
        }
        this.setState({ scrolledSet });
    }
    renderShortCompanyMeny(item) {
        return (
            <Menu.Item key={item.name}>
                <Header size={"large"} inverted={true}>
                    {item.name}
                </Header>
            </Menu.Item>
        );
    }
    renderFullCompanyMeny(item) {
        return [
            <Menu.Item key="company-name" as={Link} to={"/Company/" + item.id + "/Dashboard"}>
                <Header size={"large"} inverted={true}>
                    {item.name}
                </Header>
            </Menu.Item>,
            <Menu.Item
                key="company-new-order"
                link
                as={Link}
                to={"/Company/" + item.id + "/Order/New"}
                icon="add square"
                name="Новый заказ"
            />,
            <Menu.Item key="company-blank-1" />,
            <Menu.Item
                key="company-dashboard-0"
                link
                as={Link}
                to={"/Company/" + item.id}
                name="Незакрытые заказы"
            />,
            <Menu.Item
                key="company-dashboard-1"
                link
                as={Link}
                to={"/Company/" + item.id + "/DashBoard/Summury"}
                name="Сводка по заказам"
            />,
            <Menu.Item
                key="company-dashboard-2"
                link
                as={Link}
                to={"/Company/" + item.id + "/DashBoard/MediumSummury"}
                name="Расширеная сводка по заказам"
            />,
            <Menu.Item
                key="company-dashboard-3"
                link
                as={Link}
                to={"/Company/" + item.id + "/DashBoard/Realization"}
                name="Сводка по продажам"
            />,
            <Menu.Item
                key="company-dashboard-OrderCalendar"
                link
                as={Link}
                to={"/Company/" + item.id + "/DashBoard/OrderCalendar"}
                name="Календарь заказов"
            />,
            <Menu.Item key="company-blank-2" />,
            <Menu.Item key="company-customers" as={Link} to={"/Company/" + item.id + "/Customer"}>
                <Icon name="users" /> Клиенты
            </Menu.Item>,
            <Menu.Item key="company-phone-call" as={Link} to={"/Company/" + item.id + "/Call"}>
                <Icon name="call square" /> Звонки
            </Menu.Item>,
            <Menu.Item key="company-stock" as={Link} to={"/Company/" + item.id + "/Stock"}>
                <Icon name="warehouse" /> Склад
            </Menu.Item>,
            <Menu.Item key="company-settings" as={Link} to={"/Company/" + item.id + "/Operations"}>
                <Icon name="cogs" /> Настройка компании
            </Menu.Item>,
            <Menu.Item key="company-reports" as={Link} to={"/Company/" + item.id + "/Report"}>
                <Icon name="area graph" /> Отчеты
            </Menu.Item>,
        ];
    }
    render() {
        return (
            <Menu fixed={"left"} inverted={true} vertical id="sidebar">
                {!!this.props.companyes.companyList &&
                    this.props.companyes.companyList.map((item) => {
                        if (!this.state.scrolledSet[item.id]) {
                            return [
                                this.renderFullCompanyMeny(item),

                                <Divider key="devider" horizontal inverted>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        data-id={item.id}
                                        onClick={this.handleToggleItems}>
                                        <Icon name="angle double up" />
                                    </span>
                                </Divider>,
                            ];
                        } else {
                            return [
                                this.renderShortCompanyMeny(item),

                                <Divider key="devider" horizontal inverted>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        data-id={item.id}
                                        onClick={this.handleToggleItems}>
                                        <Icon name="angle double down" />
                                    </span>
                                </Divider>,
                            ];
                        }
                    })}
                    <Menu.Item as={Link} to={"/PrintCenter"}>
                    <Icon name="print" /> Центр печати
                </Menu.Item>
                <Menu.Item as={Link} to={"/Feedback"}>
                    <Icon name="send" /> Центр вопросов
                </Menu.Item>
                <Menu.Item as={Link} to={"/Info"}>
                    <Icon name="info" /> О приложении
                </Menu.Item>
            </Menu>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyes: state.companyList,
    };
}

export const LeftMenuContainer = connect(mapStateToProps)(LeftMenu);
