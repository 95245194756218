import * as constants from "./constants";

export default function (state, action) {
  if (!state || action.type == constants.clearStore) {
    state = {
      list: [],
      item: {},

      listAvailableCompany: [],
      packingListForTransfer:{},
      isLoading: false,
      isLoadingAvailableCompany: false,
      isLoadingPackingListForTransfer:false,
      isSave: false,
      isSaved: false,
      isLoaded: false,
      isRequreReload: false,
    };
  }
  switch (action.type) {
    case constants.clearItemStore:
      return {
        ...state,
        ...{ item: {}, isSaved: false,listAvailableCompany:[],packingListForTransfer:{} },
      };
    case constants.listLoadStart:
      return {
        ...state,
        ...{
          list: [],
          isRequreReload: false,
          isLoading: true,
          error: undefined,
        },
      };
    case constants.listLoadSuccess:
      return {
        ...state,
        ...{
          list: Array.from(action.data, (x) => {
            return {
              ...x,
              ...{
                sendingDate: x.sendingDate ? new Date(x.sendingDate) : null,
                acceptingDate: x.acceptingDate ? new Date(x.acceptingDate) : null,
                creationDate: new Date(x.creationDate),
              },
            };
          }).sort(sort),
          isLoading: false,
        },
      };
    case constants.listLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.itemLoadStart:
      return {
        ...state,
        ...{ item: {}, isLoading: true, error: undefined },
      };
    case constants.itemLoadSuccess:
      return {
        ...state,
        ...{
          item: {
            ...action.data,
            sendingDate: action.data.sendingDate ? new Date(action.data.sendingDate) : null,
            acceptingDate: action.data.acceptingDate ? new Date(action.data.acceptingDate) : null,
            creationDate: new Date(action.data.creationDate),
          },
          isLoading: false,
        },
      };
    case constants.itemLoadFail:
      return { ...state, ...{ isLoading: false, error: action.error } };

    case constants.itemSaveStart:
      return {
        ...state,
        ...{
          isRequreReload: false,
          isSaved: false,
          isSaving: true,
          error: undefined,
        },
      };
    case constants.itemSaveSuccess:
      return { ...state, isSaved: true, isSaving: false, isRequreReload: true };
    case constants.itemSaveFail:
      return {
        ...state,
        ...{
          isRequreReload: false,
          isSaved: false,
          isSaving: false,
          error: action.error,
        },
      };

    case constants.itemDeleteStart:
    case constants.itemApproveStart:
      return { ...state, ...{ isRequreReload: false, error: undefined } };
    case constants.itemDeleteSuccess:
    case constants.itemApproveSuccess:
      return { ...state, isRequreReload: true };
    case constants.itemDeleteFail:
    case constants.itemApproveFail:
      return { ...state, ...{ isRequreReload: false, error: action.error } };

    case constants.availableCompanyLoadStart:
      return {
        ...state,
        ...{
          listAvailableCompany: [],
          isRequreReload: false,
          isLoadingAvailableCompany: true,
          error: undefined,
        },
      };
    case constants.availableCompanyLoadSuccess:
      return {
        ...state,
        ...{
          listAvailableCompany: action.data,
          isLoadingAvailableCompany: false,
        },
      };
    case constants.availableCompanyLoadFail:
      return { ...state, ...{ isLoadingAvailableCompany: false, error: action.error } };


      case constants.initPackingListForStockItemLoadStart:
        return {
          ...state,
          ...{
            //packingListForTransfer: [],
            isRequreReload: false,
            isLoadingPackingListForTransfer: true,
            error: undefined,
          },
        };
      case constants.initPackingListForStockItemLoadSuccess:
        const newplft = {...state.packingListForTransfer};
        for(let i =0; i<action.data.length;i++){
          newplft[action.data[i].stockItemId] = action.data[i].packingList
        }
        console.info(newplft);
        return {
          ...state,
          ...{
            packingListForTransfer: newplft,
            isLoadingPackingListForTransfer: false,
          },
        };
      case constants.initPackingListForStockItemLoadFail:
        return { ...state, ...{ isLoadingPackingListForTransfer: false, error: action.error } };

      


    default:
      return state;
  }
}

function sort(a, b) {
  if (a.date > b.date) {
    return -1;
  }
  if (a.date < b.date) {
    return 1;
  }

  if (a.creationDate < b.creationDate) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
