import * as config from "Config";
import * as constants from "./constants";


export function clearStore({onlyItem}) {
  if(onlyItem){
    return {
      type: constants.clearItemStore 
    };
  }
    return {
      type: constants.clearStore 
    };
}

export function initListData(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Supplier/Company/"+params.companyId+"/Summury",
          data:params
      },
      actions:[constants.listLoadStart,constants.listLoadSuccess,constants.listLoadFail ]
    };
}

export function saveNewItem(params) {
  return {
    remote:{
        method: "post",
        url: config.apiUrl + "/api/Supplier/Company/"+params.companyId,
        data:params
    },
    actions:[constants.itemSaveStart,constants.itemSaveSuccess,constants.itemSaveFail ]
  };
}




export function initItemData(params) {
  return {
    remote:{
        method: "get",
        url: config.apiUrl + "/api/Supplier/" + params.id+ "/Company/"+params.companyId
    },
    actions:[constants.itemLoadStart,constants.itemLoadSuccess,constants.itemLoadFail]
  };
}

export function deleteItem(params) {
    return {
      remote:{
          method: "delete",
          url: config.apiUrl + "/api/Supplier/"+params.id + "/Company/" + params.companyId
      },

      actions:[constants.itemDeleteStart,constants.itemDeleteSuccess,constants.itemDeleteFail]
    };
}


export function saveItem(params) {
  return {
    remote:{
        method: "put",
        url: config.apiUrl + "/api/Supplier/"+params.id + "/Company/"+params.companyId,
        data:params
    },
    actions:[constants.itemSaveStart,constants.itemSaveSuccess,constants.itemSaveFail]
  };
}

