import React, { Component } from "react";
import { Grid, Header, Divider, Table, Loader } from "semantic-ui-react";
import * as actionCreators from "./actions";
import { connect } from "react-redux";

import * as utils from "@hitek-crm-utils";
import DatePicker from "@hitek-crm/DatePicker";

export class RealizationDashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId,
            isLoaded: false,
            id: "",
            name: "",
            userId: null,
            startDate: new Date(new Date().toDateString()),
            totalSumm: 0,
        };

        let searchParam = new URLSearchParams(this.props.location.search);
        if (searchParam.has("date")) {
            this.state.startDate = utils.dateFromShortString(searchParam.get("date"));
        }

        this.handleChangeDateForReport = this.handleChangeDateForReport.bind(this);
        this.props.clearStore();

        this.loadStore(this.props.match.params.companyId, this.state.startDate.toISOString());
        // Operations usually carried out in componentWillMount go here
    }
    componentWillReceiveProps(nextProps) {
        if (
            this.props.match.params.companyId != nextProps.match.params.companyId ||
            this.props.location.search != nextProps.location.search
        ) {
            let newp = {
                companyId: nextProps.match.params.companyId,
                isLoaded: false,
                startDate: new Date(new Date().toDateString()),
            };
            let searchParam = new URLSearchParams(nextProps.location.search);
            if (searchParam.has("date")) {
                newp.startDate = utils.dateFromShortString(searchParam.get("date"));
            }

            this.setState(newp);

            this.loadStore(nextProps.match.params.companyId, newp.startDate.toISOString());
        }
    }

    loadStore(companyId, startDate) {
        this.props.getDashBoardForRealization({
            companyId,
            startDate: startDate,
        });
        this.props.initCountOrdersByDay({
            companyId,
        });
    }

    getCompanyName() {
        let companies = this.props.companies.companyList;
        if (!Array.isArray(companies)) return null;
        let company = companies.find((x) => x.id == this.state.companyId);
        if (!!company) {
            return company.name;
        }
        return null;
    }

    handleChangeDateForReport(date) {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: "date=" + utils.toShortDateString(date),
        });
        this.setState({
            startDate: date,
        });
    }

    renderLoader() {
        return (
            <Grid.Row>
                <Grid.Column>
                    <Loader active inline="centered" size="big" />
                </Grid.Column>
            </Grid.Row>
        );
    }

    renderContent() {
        return (
            <Grid.Row>
                <Grid.Column>
                    <Header size="medium">
                        {this.state.startDate.toLocaleDateString() ==
                        new Date().toLocaleDateString()
                            ? "Последнии заказы на сегодня"
                            : "Заказы на " + this.state.startDate.toLocaleDateString()}
                    </Header>
                    <Header size="small">
                       Готовится
                    </Header>
                    <Table celled striped >
                        <Table.Header>
                            <Table.Row warning>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Название позиции</Table.HeaderCell>
                                <Table.HeaderCell>Название категории</Table.HeaderCell>
                                <Table.HeaderCell>Колличество</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.props.dashBoard.realization
                                .filter((x) => !x.isClosed)
                                .map((ord) => {
                                    return (
                                        <Table.Row key={ord.itemId} warning>
                                            <Table.Cell>{ord.itemId}</Table.Cell>
                                            <Table.Cell>{ord.itemName}</Table.Cell>
                                            <Table.Cell>{ord.categoryName}</Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <strong> {ord.count} </strong>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="3">Колличество</Table.HeaderCell>

                                <Table.HeaderCell textAlign="right">
                                    {this.props.dashBoard.realization
                                        .filter((x) => !x.isClosed)
                                        .reduce((a, b) => a + b.count, 0)}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                    <Header size="small">
                      Отдано клиенту
                    </Header>
                    <Table celled striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Название позиции</Table.HeaderCell>
                                <Table.HeaderCell>Название категории</Table.HeaderCell>
                                <Table.HeaderCell>Колличество</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.props.dashBoard.realization
                                .filter((x) => x.isClosed)
                                .map((ord) => {
                                    return (
                                        <Table.Row key={ord.itemId}>
                                            <Table.Cell>{ord.itemId}</Table.Cell>
                                            <Table.Cell>{ord.itemName}</Table.Cell>
                                            <Table.Cell>{ord.categoryName}</Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <strong> {ord.count} </strong>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="3">Колличество</Table.HeaderCell>

                                <Table.HeaderCell textAlign="right">
                                    {this.props.dashBoard.realization
                                        .filter((x) => x.isClosed)
                                        .reduce((a, b) => a + b.count, 0)}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>
            </Grid.Row>
        );
    }
    render() {
        return (
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={10}>
                        <Header size="huge">Сводка для компании {this.getCompanyName()}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={5} className="ui small input">
                        Фильтр по дате:
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleChangeDateForReport}
                            includeDates={Array.from(
                                this.props.dashBoard.orderByDay,
                                (x) => x.date
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={1}></Grid.Column>
                </Grid.Row>
                <Divider />

                {this.props.dashBoard.isLoading ? this.renderLoader() : this.renderContent()}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        companies: state.companyList,
        dashBoard: state.dashBoard,
    };
}

export const DashBoardRealizationContainer = connect(
    mapStateToProps,
    actionCreators
)(RealizationDashBoard);
