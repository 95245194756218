import * as  config from "Config";


export function clearStore() {
    return {
      type:"OPERATOR_PHONE_CLEAR_STORE"
    };
}

export function saveNewOperatorPhone(params) {
    return {
      remote:{
          method: "post",
          url: config.apiUrl + "/api/OperatorPhone/Company/"+params.companyId,
          data:params
      },
      actions:["OPERATOR_PHONE_NEW_SAVE_START","OPERATOR_PHONE_NEW_SAVE_SUCCESS","OPERATOR_PHONE_NEW_SAVE_FAIL"]
    };
}


export function saveOperatorPhone(params) {
    return {
      remote:{
          method: "put",
          url: config.apiUrl + "/api/OperatorPhone/"+params.id + "/Company/"+params.companyId,
          data:params
      },
      actions:["OPERATOR_PHONE_SAVE_START","OPERATOR_PHONE_SAVE_SUCCESS","OPERATOR_PHONE_SAVE_FAIL"]
    };
}

export function getDetailOperatorPhone(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/OperatorPhone/" + params.id+ "/Company/"+params.companyId
      },
      actions:["OPERATOR_PHONE_DETAIL_START","OPERATOR_PHONE_DETAIL_SUCCESS","OPERATOR_PHONE_DETAIL_FAIL"]
    };
}

