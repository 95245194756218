import { browserHistory } from "react-router";
import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == constants.clearStore) {
        state = {
            list: [],
            listStockGroup: [],
            item: {},
            itemBalance: {},
            listEstimationCost: [],
            isLoading: false,
            isSave: false,
            isSaved: false,
            isLoaded: false,
            isRequreReload: false,
        };
    }
    switch (action.type) {
        case constants.listLoadStart:
            return {
                ...state,
                ...{ list: [], isLoading: true, error: undefined },
            };
        case constants.listLoadSuccess:
            return {
                ...state,
                ...{
                    list: action.data.sort((a, b) => a.title.localeCompare(b.title)),
                    isLoading: false,
                },
            };
        case constants.listLoadFail:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.listStockGroupLoadStart:
            return {
                ...state,
                ...{ listStockGroup: [], isLoading: true, error: undefined },
            };
        case constants.listStockGroupLoadSuccess:
            return {
                ...state,
                ...{
                    listStockGroup: action.data.sort((a, b) => a.name.localeCompare(b.name)),
                    isLoading: false,
                },
            };
        case constants.listStockGroupLoadFail:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.itemLoadStart:
            return {
                ...state,
                ...{ item: {}, isLoading: true, error: undefined },
            };
        case constants.itemLoadSuccess:
            return {
                ...state,
                ...{
                    item: {
                        ...action.data,
                        composition: action.data.composition.sort(sort),
                    },
                    isLoading: false,
                },
            };
        case constants.itemLoadFail:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.itemSaveStart:
            return {
                ...state,
                ...{ isSaved: false, isSaving: true, error: undefined },
            };
        case constants.itemSaveSuccess:
            return { ...state, isSaved: true, isSaving: false };
        case constants.itemSaveFail:
            return {
                ...state,
                ...{ isSaved: false, isSaving: false, error: action.error },
            };

        case constants.itemDeleteStart:
            return { ...state, ...{ isRequreReload: false, error: undefined } };
        case constants.itemDeleteSuccess:
            return { ...state, isRequreReload: true };
        case constants.itemDeleteFail:
            return { ...state, ...{ isRequreReload: false, error: action.error } };

        case constants.itemBalanceLoadStart:
            return {
                ...state,
                ...{ itemBalance: {}, isLoading: true, error: undefined },
            };
        case constants.itemBalanceLoadSuccess:
            return {
                ...state,
                ...{
                    itemBalance: action.data,
                    isLoading: false,
                },
            };
        case constants.itemBalanceLoadFail:
            return { ...state, ...{ isLoading: false, error: action.error } };
        case constants.itemListEstimateCostLoadStart:
            return {
                ...state,
                ...{ listEstimationCost: [], isLoading: true, error: undefined },
            };
        case constants.itemListEstimateCostLoadSuccess:
            return {
                ...state,
                ...{
                    listEstimationCost: action.data,
                    isLoading: false,
                },
            };
        case constants.itemListEstimateCostLoadFail:
            return { ...state, ...{ isLoading: false, error: action.error } };

        default:
            return state;
    }
}

function sort(a, b) {
    if (a.stockItemId > b.stockItemId) {
        return -1;
    }
    if (a.stockItemId < b.stockItemId) {
        return 1;
    }
    // a must be equal to b
    return 0;
}
