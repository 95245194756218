import React, { Component } from 'react';
import { Grid, Header, Button, Divider, Form, Input, Select, Message } from 'semantic-ui-react'
import * as actionCreators from "./actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as utils from "@hitek-crm-utils";

export class EditTag extends Component {


    constructor(props) {
        super(props);
        this.state = {
            currentCompanyId: this.props.match.params.companyId,
            currentTagId: this.props.match.params.itemId,
            isLoaded: false,
            id: "",
            name: "",
            order: "",
            color: ""
        }


        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeOrder = this.handleChangeOrder.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
        this.handleSave = this.handleSave.bind(this);

        this.props.clearStore();


        this.props.getDetailTag(
            {
                id: this.state.currentTagId,
                companyId: this.state.currentCompanyId
            }
        );
        // Operations usually carried out in componentWillMount go here
    }
    componentWillReceiveProps(nextProps) {
        if (!this.state.isLoaded && nextProps.tag.isLoaded) {
            this.setState({
                name: nextProps.tag.data.name,
                id: nextProps.tag.data.id,
                categoriesId: nextProps.tag.data.categoriesId,
                order: nextProps.tag.data.order,
                color: nextProps.tag.data.color,
                isLoaded: true
            });
            console.log(this.state);
        }
        if (this.props.tag.isSave != nextProps.tag.isSave && nextProps.tag.isSave) {
            this.props.history.push(utils.getRoute(this.props.match.url, 2));
        }
    }

    handleChangeName(e, target) {
        this.setState({ name: target.value });
    }

    handleChangeOrder(e, target) {
        this.setState({ order: target.value });
    }

    handleChangeColor(e, target) {
        this.setState({ color: target.value });
    }


    handleSave(e) {
        e.preventDefault();
        this.props.saveTag({
            name: this.state.name,
            id: this.state.id,
            order: this.state.order,
            companyId: this.state.currentCompanyId,
            color: this.state.color
        });
    }


    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size='huge'>Редактирвоание Тега #</Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Form success={this.props.tag.isSave} error={!!this.props.tag.error} loading={this.props.tag.isLoading} >
                            <Form.Field disabled control={Input} label='Id' placeholder='Id тега' value={this.state.id} />
                            <Form.Field control={Input} label='Название' placeholder='Название тега' onChange={this.handleChangeName} value={this.state.name} />
                            {/*<Form.Field  control={Input} label='Очередь сортировки' placeholder='очередь сортировки' onChange={this.handleChangeOrder} value ={this.state.order}  />
                            */}
                            <Form.Field control={Select} label='Цвет ' placeholder='нет цвета' onChange={this.handleChangeColor} value={this.state.color}
                                options={[
                                    { value: 'red', text: 'Красный', label: { empty: true, circular: true, color: "red" } },
                                    { value: 'orange', text: 'Оранжевый', label: { empty: true, circular: true, color: "orange" } },
                                    { value: 'yellow', text: 'Желтый', label: { empty: true, circular: true, color: "yellow" } },
                                    { value: 'olive', text: 'Оливковый', label: { empty: true, circular: true, color: "olive" } },
                                    { value: 'green', text: 'Зеленый', label: { empty: true, circular: true, color: "green" } },
                                    { value: 'teal', text: 'Голубой', label: { empty: true, circular: true, color: "teal" } },
                                    { value: 'blue', text: 'Синий', label: { empty: true, circular: true, color: "blue" } },
                                    { value: 'violet', text: 'Фиолетовый', label: { empty: true, circular: true, color: "violet" } },
                                    { value: 'purple', text: 'Пурпурный', label: { empty: true, circular: true, color: "purple" } },
                                    { value: 'pink', text: 'Розовый', label: { empty: true, circular: true, color: "pink" } },
                                    { value: 'brown', text: 'Коричневый', label: { empty: true, circular: true, color: "brown" } },
                                    { value: 'grey', text: 'Серый', label: { empty: true, circular: true, color: "grey" } },
                                    { value: 'black', text: 'Черный', label: { empty: true, circular: true, color: "black" } },
                                    { value: '', text: 'Не указан', label: { empty: true, circular: true, color: "grey" } }
                                ]}
                            />
                            <Message
                                error
                                header='Ошибка'
                                content={this.props.tag.error}
                            />
                            <Message success >
                                <Message.Content>
                                    Успешно Сохранено
                                <br />
                                    <br />
                                    <Button as={Link} to={"/Company/" + this.state.currentCompanyId + "/Tag"}>
                                        Вернуться к списку
                                </Button>
                                </Message.Content>
                            </Message>
                            <Form.Field color="green" onClick={this.handleSave} control={Button}>Сохранить</Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>

            </Grid>);

    }
};

function mapStateToProps(state) {

    return {

        tag: state.tagEdit


    };
}


export const TagEditContainer = connect(mapStateToProps, actionCreators)(EditTag);
