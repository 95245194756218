export const ORDER_STATUS_CF_LOAD_START = "ORDER_STATUS_CF_LOAD_START";
export const ORDER_STATUS_CF_LOAD_SUCCESS = "ORDER_STATUS_CF_LOAD_SUCCESS";
export const ORDER_STATUS_CF_LOAD_FAIL = "ORDER_STATUS_CF_LOAD_FAIL";

export const ORDER_STATUS_DETAIL_START = "ORDER_STATUS_DETAIL_START";
export const ORDER_STATUS_DETAIL_SUCCESS = "ORDER_STATUS_DETAIL_SUCCESS";
export const ORDER_STATUS_DETAIL_FAIL = "ORDER_STATUS_DETAIL_FAIL";

export const ORDER_STATUS_NEW_SAVE_START = "ORDER_STATUS_NEW_SAVE_START";
export const ORDER_STATUS_NEW_SAVE_SUCCESS = "ORDER_STATUS_NEW_SAVE_SUCCESS";
export const ORDER_STATUS_NEW_SAVE_FAIL = "ORDER_STATUS_NEW_SAVE_FAIL";

export const ORDER_STATUS_SAVE_START = "ORDER_STATUS_SAVE_START";
export const ORDER_STATUS_SAVE_SUCCESS = "ORDER_STATUS_SAVE_SUCCESS";
export const ORDER_STATUS_SAVE_FAIL = "ORDER_STATUS_SAVE_FAIL";
