export const clearStore = "SUPPLIER_LIST_STORE";
export const clearItemStore = "SUPPLIER_ITEM_CLEAR";

export const listLoadStart = 'SUPPLIER_LIST_LOAD_START';
export const listLoadSuccess = 'SUPPLIER_LIST_LOAD_SUCCESS';
export const listLoadFail = 'SUPPLIER_LIST_LOAD_FAIL';

export const itemSaveStart = 'SUPPLIER_SAVE_START';
export const itemSaveSuccess = 'SUPPLIER_SAVE_SUCCESS';
export const itemSaveFail = 'SUPPLIER_SAVE_FAIL';

export const itemLoadStart = 'SUPPLIER_ITEM_LOAD_START';
export const itemLoadSuccess = 'SUPPLIER_ITEM_LOAD_SUCCESS';
export const itemLoadFail = 'SUPPLIER_ITEM_LOAD_FAIL';

export const itemDeleteStart = 'SUPPLIER_ITEM_DELETE_START';
export const itemDeleteSuccess = 'SUPPLIER_ITEM_DELETE_SUCCESS';
export const itemDeleteFail = 'SUPPLIER_ITEM_DELETE_FAIL';






