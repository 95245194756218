import * as config from "Config";


export function clearStore(newObject) {
    return {
      type:"TAG_LIST_CLEAR_STORE"
    };
}

export function initListData(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Tag/Company/"+params.companyId + "/Summury"
      },
      actions:["TAG_LIST_SUMMURY_LOAD_START","TAG_LIST_SUMMURY_LOAD_SUCCESS","TAG_LIST_SUMMURY_LOAD_FAIL"]
    };
}

export function deleteTag(params) {
    return {
      remote:{
          method: "delete",
          url: config.apiUrl + "/api/Tag/"+params.id + "/Company/" + params.companyId
      },

      actions:["TAG_LIST_REMOVE_START","TAG_LIST_REMOVE_SUCCESS","TAG_LIST_REMOVE_FAIL"]
    };
}



