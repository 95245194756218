import {browserHistory } from 'react-router';

export default function(state ,action){
    if ( !state  || action.type=='DISCOUNT_CLEAR_STORE'){


            state =  {
                data: {},
                isLoading : false,
                isSave: false,
                isLoaded:false
            };

    }
//"DISCOUNT_NEW_SAVE_START","DISCOUNT_NEW_SAVE_SUCCESS","DISCOUNT_NEW_SAVE_FAIL"
//"DISCOUNT_INIT_CATEGORY_LIST_START","DISCOUNT_INIT_CATEGORY_LIST_SUCCESS","DISCOUNT_INIT_CATEGORY_LIST_FAIL"
//DISCOUNT_DETAIL_START","DISCOUNT_DETAIL_SUCCESS","DISCOUNT_DETAIL_FAIL
//"DISCOUNT_SAVE_START","DISCOUNT_SAVE_SUCCESS","DISCOUNT_SAVE_FAIL"
    switch(action.type){
        case "DISCOUNT_NEW_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "DISCOUNT_NEW_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "DISCOUNT_NEW_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
        case "DISCOUNT_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "DISCOUNT_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "DISCOUNT_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
            
        case "DISCOUNT_DETAIL_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "DISCOUNT_DETAIL_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isLoaded:true}} ;
        case "DISCOUNT_DETAIL_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        default:
            return state;
    }
}