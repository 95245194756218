import * as config from "Config";
import * as constants from "./constants";

export function clearStore() {
  return {
    type: constants.clearStore,
  };
}

export function initListData(params) {
  let url = config.apiUrl + "/api/StockItem";
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }
  return {
    remote: {
      method: "get",
      url,
    },
    actions: [constants.listLoadStart, constants.listLoadSuccess, constants.itemLoadFail],
  };
}

export function initStockGroupListData(params) {
  let url = config.apiUrl + "/api/StockGroup";
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }
  return {
    remote: {
      method: "get",
      url,
    },
    actions: [constants.listStockGroupLoadStart, constants.listStockGroupLoadSuccess, constants.listStockGroupLoadFail],
  };
}


export function initItemData(params) {

  let url = config.apiUrl + "/api/StockItem/" + params.id ;
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }

  return {
    remote: {
      method: "get",
      url,
    },
    actions: [constants.itemLoadStart, constants.itemLoadSuccess, constants.itemLoadFail],
  };
}

export function deleteItem(params) {
  let url = config.apiUrl + "/api/StockItem/" + params.id ;
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }

  return {
    remote: {
      method: "delete",
      url,
    },

    actions: [constants.itemDeleteStart, constants.itemDeleteSuccess, constants.itemDeleteFail],
  };
}
export function saveNewItem(params) {

  let url = config.apiUrl + "/api/StockItem";
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }

  return {
    remote: {
      method: "post",
      url,
      data: params,
    },
    actions: [constants.itemSaveStart, constants.itemSaveSuccess, constants.itemSaveFail],
  };
}
export function saveItem(params) {
  let url = config.apiUrl + "/api/StockItem/" + params.id ;
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }

  return {
    remote: {
      method: "put",
      url,
      data: params,
    },
    actions: [constants.itemSaveStart, constants.itemSaveSuccess, constants.itemSaveFail],
  };
}

export function initItemBalance(params) {
  
  return {
    remote: {
      method: "get",
      url:
        `${config.apiUrl}/api/StockItem/${params.id}/Company/${params.companyId}/Balance`,
      data: params,
    },
    actions: [
      constants.itemBalanceLoadStart,
      constants.itemBalanceLoadSuccess,
      constants.itemBalanceLoadFail,
    ],
  };
}

export function initListEstimationCost(params) {
  let url = config.apiUrl + "/api/StockItem";
  if (params.companyId) {
    url += "/Company/" + params.companyId;
  }
  if (params.companyGroupId) {
    url += "/CompanyGroup/" + params.companyGroupId;
  }
  url+= "/EstimatedCost";
  
  return {
    remote: {
      method: "get",
      url,
    },
    actions: [
      constants.itemListEstimateCostLoadStart,
      constants.itemListEstimateCostLoadSuccess,
      constants.itemListEstimateCostLoadFail,
    ],
  };
}
