import * as config from "Config";
import * as constants from "./constants";

export function clearStore(newObject) {
    return {
        type: "COMPANY_CLEAR_STORE",
    };
}

export function saveNewCompany(newObject) {
    return {
        remote: {
            method: "post",
            url: config.apiUrl + "/api/Company",
            data: newObject,
        },
        actions: ["COMPANY_NEW_SAVE_START", "COMPANY_NEW_SAVE_SUCCESS", "COMPANY_NEW_SAVE_FAIL"],
    };
}

export function saveCompany(params) {
    return {
        remote: {
            method: "put",
            url: config.apiUrl + "/api/Company/" + params.companyId,
            data: params,
        },
        actions: ["COMPANY_SAVE_START", "COMPANY_SAVE_SUCCESS", "COMPANY_SAVE_FAIL"],
    };
}

export function getDetailCompany(id) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Company/" + id,
        },
        actions: ["COMPANY_DETAIL_START", "COMPANY_DETAIL_SUCCESS", "COMPANY_DETAIL_FAIL"],
    };
}

export function initUserList(id) {
    return {
        remote: {
            method: "get",
            url: config.apiUrl + "/api/Company/" + id + "/User",
        },
        actions: [
            constants.COMPANY_USER_LIST_LOAD_START,
            constants.COMPANY_USER_LIST_LOAD_SUCCESS,
            constants.COMPANY_USER_LIST_LOAD_FAIL,
        ],
    };
}
export function addUserCompany(params) {
    return {
        remote: {
            method: "post",
            url: config.apiUrl + "/api/Company/" + params.companyId + "/User",
            data: params,
        },
        actions: [
            constants.COMPANY_USER_ADD_START,
            constants.COMPANY_USER_ADD_SUCCESS,
            constants.COMPANY_USER_ADD_FAIL,
        ],
    };
}
export function deleteUserCompany(params) {
    return {
        remote: {
            method: "delete",
            url: config.apiUrl + "/api/Company/" + params.companyId + "/User",
            data: params,
        },
        actions: [
            constants.COMPANY_USER_ADD_START,
            constants.COMPANY_USER_ADD_SUCCESS,
            constants.COMPANY_USER_ADD_FAIL,
        ],
    };
}
