import * as constants from "./constants";

export default function (state, action) {
    if (!state || action.type == constants.ITEM_CLEAR_STORE) {
        state = {
            data: {},
            categoryList: [],
            isLoading: false,
            customFieldList: [],
            isSave: false,
            isLoaded: false,
        };
    }
    switch (action.type) {
        case constants.ITEM_NEW_SAVE_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.ITEM_NEW_SAVE_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isSave: true } };
        case constants.ITEM_NEW_SAVE_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.ITEM_SAVE_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.ITEM_SAVE_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isSave: true } };
        case constants.ITEM_SAVE_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.ITEM_DETAIL_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.ITEM_DETAIL_SUCCESS:
            return { ...state, ...{ data: action.data, isLoading: false, isLoaded: true } };
        case constants.ITEM_DETAIL_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        case constants.ITEM_CF_LOAD_START:
            return { ...state, ...{ isLoading: true, error: undefined } };
        case constants.ITEM_CF_LOAD_SUCCESS:
            return { ...state, ...{ isLoading: false, customFieldList: action.data } };
        case constants.ITEM_CF_LOAD_FAIL:
            return { ...state, ...{ isLoading: false, error: action.error } };

        default:
            return state;
    }
}
