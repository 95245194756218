import React, { Component } from 'react';
import { Grid, Header, Divider, Label, Icon, Button, TextArea, Form, Message, Input, Select, Checkbox } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as utils from "@hitek-crm-utils";

import * as actionCreators from "./actions";

import { initListData as loadListStockItem } from "../StockItem/actions";

export class StockOperationAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteShowModal: false,
            currentCompanyId: this.props.match.params.companyId,
            idForRemove: null,

            stockItemId: "",
            count: 0,
            operationType: "",
            comment: "",
            isIgnoreSubProcedure:false


        };


        this.props.clearStore();

        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleClickSave = this.handleClickSave.bind(this);

    }


    componentDidMount() {
        this.loadData.call(this, { companyId: this.state.currentCompanyId });
    }

    loadData(state) {
        //this.props.initListData({companyId: state.companyId});
        if (this.props.stockitem.list.length == 0 || state.force) {
            this.props.loadListStockItem({ companyId: state.companyId });
        }
    }


    getDataForTable() {
        if (!this.props.data)
            return [];


        return this.props.data.list;

    }
    componentWillReceiveProps(nextProps) {

        if (!!nextProps.data && nextProps.data.isRequreReload && this.props.data.isRequreReload != nextProps.data.isRequreReload) {
            this.props.clearStore();
            this.loadData.call(this, { companyId: this.state.currentCompanyId });

        }

        if (this.props.match.params.companyId != nextProps.match.params.companyId) {

            this.setState({
                currentCompanyId: nextProps.match.params.companyId,
                searchCategories: []
            });
            this.loadData.call(this, { companyId: nextProps.match.params.companyId, force: true });
        }

        if (this.props.data.isSaved != nextProps.data.isSaved && nextProps.data.isSaved) {
            this.props.history.push(utils.getRoute(this.props.match.url, 1));

        }
    }

    handleInputChange(e, target) {

        let value = target.value;
        if (target.type === "checkbox") {
            value = target.checked;

        }

        const name = target.name;


        this.setState({
            [name]: value,
        });
    }
    handleClickSave() {
        this.props.saveNewItem({
            companyId: this.state.currentCompanyId,
            stockItemId: this.state.stockItemId,
            count: this.state.count,
            operationType: this.state.operationType,
            comment: this.state.comment,
            isIgnoreSubProcedure: this.state.isIgnoreSubProcedure

        })
    }

    getSelectedItem() {
        return this.props.stockitem.list.find(x => x.id == this.state.stockItemId) || {};
    }


    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size='huge'>Новая операция по складу</Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                    <Grid.Column>
                        <Button color="blue" as={Link} to={"/Company/" + this.state.currentCompanyId + "/Stock"} ><Icon name="warehouse" /> Склад</Button>
                        <Button color="green" as={Link} to={"/Company/" + this.state.currentCompanyId + "/StockOperation"} ><Icon name="file alternate outline" /> Операции по складу</Button>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>

                        <Form success={this.props.data.isSave} error={!!this.props.data.error} loading={this.props.data.isLoading} >


                            <Form.Field control={Select}
                                label="Позиция на складе"
                                placeholder='Выберите позицию'
                                onChange={this.handleInputChange}
                                value={this.state.stockItemId}
                                search
                                name="stockItemId"
                                options={Array.from(this.props.stockitem.list, x => { return { text: x.title, value: x.id }; })}
                            />
                            <Form.Field control={Input}
                                label="Колличество"
                                labelPosition='right' placeholder='Колличество'
                                onChange={this.handleInputChange}
                                name="count"
                                value={this.state.count}>
                                <input />
                                < Label>
                                    {this.getSelectedItem().unit}
                                </Label>
                            </Form.Field>
                            <Form.Field control={Select} label='Тип Операции ' placeholder='Выберите операцию' onChange={this.handleInputChange}

                                name="operationType"
                                value={this.state.operationType}
                                options={[
                                    { value: '+', text: 'Внесение', label: { empty: true, circular: true, color: "green" } },
                                    { value: '-', text: 'Изъятие', label: { empty: true, circular: true, color: "red" } }
                                ]}
                            />
                            <Form.Field control={Checkbox}
                                label="Не вычитать из баланса поставок, только склад"
                                onChange={this.handleInputChange}
                                name="isIgnoreSubProcedure"
                                value={this.state.isIgnoreSubProcedure}>
                                <input />
                                < Label>
                                    {this.getSelectedItem().unit}
                                </Label>
                            </Form.Field>
                            <Form.Field control={TextArea} label='Комментарий' placeholder='Комментарий/ # ТН' onChange={this.handleInputChange}

                                name="comment"
                                value={this.state.comment}
                            />
                            <Message
                                error
                                header="Ошибка"
                                content={this.props.data.error}
                            />

                            <Form.Field disabled={this.props.data.isLoading || this.props.data.isSaving} onClick={this.handleClickSave} control={Button}>Сохранить</Form.Field>
                        </Form>


                    </Grid.Column>
                </Grid.Row>

            </Grid>
        );

    }
};


function mapStateToProps(state) {

    return {
        data: state.stockOperation,
        stockitem: state.stockItem
    };
}
export const StockOperationAddContainer = connect(mapStateToProps, { ...actionCreators, loadListStockItem })(StockOperationAdd);
