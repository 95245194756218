import React, { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import { LeftMenuContainer } from "../LeftMenu/LeftMenu";
import { HeaderContainer } from "../Header/Header";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actionCreators from "./actions";
import { initListData as initUserSettingAction } from "../../components/UserSetting/actions";
import { ChangeSettingModal } from "./../../components/UserSetting/ChangeSettingModal";

const defaultSetting = {
    open: false,
    actionConfig: {},
    descriptionConfig: {},
    moduleName: "",
};
export function App(props) {
    const history = useHistory();
    const [settingData, setSettingData] = useState(defaultSetting);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionCreators.initCompanyListData());
        dispatch(initUserSettingAction());
        window.CRM.changeSettingModal = function (
            moduleName,
            actionConfig,
            descriptionConfig = {}
        ) {
            setSettingData({
                ...defaultSetting,
                open: true,
                actionConfig,
                descriptionConfig,
                moduleName,
            });
        };
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            dispatch(actionCreators.sendMetric({ url: window.location.href }));
        });
    }, [history]);
    return [
        <Segment.Group style={{ border: "none" }} key="content">
            <Segment id="general-head" style={{ height: "45px" }}>
                <HeaderContainer />
            </Segment>

            <Segment.Group horizontal id="general-content" style={{ border: "none" }}>
                <Segment compact basic style={{ flex: "none" }} id="sidebar-parent">
                    <LeftMenuContainer />
                </Segment>

                <Segment basic padded id="main-content">
                    {props.children}
                </Segment>
            </Segment.Group>
        </Segment.Group>,
        <ChangeSettingModal
            key="modal"
            open={!!settingData.open}
            actionConfig={settingData.actionConfig}
            descriptionConfig={settingData.descriptionConfig}
            moduleName={settingData.moduleName}
            onClose={() => {
                setSettingData(defaultSetting);
            }}
        />,
    ];
}
export default App;
