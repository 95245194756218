
export default function(state ,action){
    if ( !state  || action.type=='DISCOUNT_LIST_CLEAR_STORE'){

        let oldDiscountList = undefined;
        if( !!state ){
            oldDiscountList = state.discountList;
        }

        state =  {
            name: "Тест",

            discountListSummury: [],
            discountList: oldDiscountList || [],
            userList: [],
            isLoading : false,
            isSave: false,
            isLoaded: false
        };

    }
//DISCOUNT_LIST_SUMMURY_LOAD_START","DISCOUNT_LIST_SUMMURY_LOAD_SUCCESS","DISCOUNT_LIST_SUMMURY_LOAD_FAIL
//DISCOUNT_LIST_LOAD_START","DISCOUNT_LIST_LOAD_SUCCESS","DISCOUNT_LIST_LOAD_FAIL
//"DISCOUNT_LIST_REMOVE_START","DISCOUNT_LIST_REMOVE_SUCCESS","DISCOUNT_LIST_REMOVE_FAIL"
    switch(action.type){
  /*      case "COMPANY_NEW_SAVE_START":
            return {...state, ...{isLoading:true, error:undefined}};
        case "COMPANY_NEW_SAVE_SUCCESS":
            return { ...state, ...{data:action.data, isLoading:false, isSave:true}} ;
        case "COMPANY_NEW_SAVE_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};
        
        */case "DISCOUNT_LIST_SUMMURY_LOAD_START":
            return {...state, ...{discountListSummury: [], isLoading:true, error:undefined}};
        case "DISCOUNT_LIST_SUMMURY_LOAD_SUCCESS":
            return { ...state, ...{discountListSummury: action.data, isLoading:false}} ;
        case "DISCOUNT_LIST_SUMMURY_LOAD_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        case "DISCOUNT_LIST_LOAD_START":
            return {...state, ...{discountList: [], isLoading:true, error:undefined}};
        case "DISCOUNT_LIST_LOAD_SUCCESS":
            return { ...state, ...{discountList: action.data, isLoading:false}} ;
        case "DISCOUNT_LIST_LOAD_FAIL":
            return {...state, ...{isLoading:false, error:action.error}};

        case "DISCOUNT_LIST_REMOVE_START":
            return {...state, ...{isLoading:true}};
        case "DISCOUNT_LIST_REMOVE_SUCCESS":
            return { ...state, ...{isLoading:false,isShouldReload:true}} ;
        case "DISCOUNT_LIST_REMOVE_FAIL":
            return {...state, ...{isLoading:true,error:action.error}};
        default:
            return state;
    }
}