export const ITEM_LIST_CLEAR_STORE = "ITEM_LIST_CLEAR_STORE";
export const ITEM_LIST_SUMMURY_LOAD_START = "ITEM_LIST_SUMMURY_LOAD_START";
export const ITEM_LIST_SUMMURY_LOAD_SUCCESS = "ITEM_LIST_SUMMURY_LOAD_SUCCESS";
export const ITEM_LIST_SUMMURY_LOAD_FAIL = "ITEM_LIST_SUMMURY_LOAD_FAIL";
export const ITEM_LIST_REMOVE_START = "ITEM_LIST_REMOVE_START";
export const ITEM_LIST_REMOVE_SUCCESS = "ITEM_LIST_REMOVE_SUCCESS";
export const ITEM_LIST_REMOVE_FAIL = "ITEM_LIST_REMOVE_FAIL";

export const ITEM_LIST_LOAD_START = "ITEM_LIST_LOAD_START";
export const ITEM_LIST_LOAD_SUCCESS = "ITEM_LIST_LOAD_SUCCESS";
export const ITEM_LIST_LOAD_FAIL = "ITEM_LIST_LOAD_FAIL";


export const ITEM_LIST_ESTIMATE_COST_LOAD_START = "ITEM_LIST_ESTIMATE_COST_LOAD_START";
export const ITEM_LIST_ESTIMATE_COST_LOAD_SUCCESS = "ITEM_LIST_ESTIMATE_COST_LOAD_SUCCESS";
export const ITEM_LIST_ESTIMATE_COST_LOAD_FAIL = "ITEM_LIST_ESTIMATE_COST_LOAD_FAIL";