export const REPORT_PAYMENT_BY_DAY_START = "REPORT_PAYMENT_BY_DAY_START";
export const REPORT_PAYMENT_BY_DAY_SUCCESS = "REPORT_PAYMENT_BY_DAY_SUCCESS";
export const REPORT_PAYMENT_BY_DAY_FAIL = "REPORT_PAYMENT_BY_DAY_FAIL";

export const REPORT_GENERAL_DOWNLOAD_START = "REPORT_GENERAL_DOWNLOAD_START";
export const REPORT_GENERAL_DOWNLOAD_SUCCESS = "REPORT_GENERAL_DOWNLOAD_SUCCESS";
export const REPORT_GENERAL_DOWNLOAD_FAIL = "REPORT_GENERAL_DOWNLOAD_FAIL";

export const REPORT_VIGODA_START = "REPORT_VIGODA_START";
export const REPORT_VIGODA_SUCCESS = "REPORT_VIGODA_SUCCESS";
export const REPORT_VIGODA_FAIL = "REPORT_VIGODA_FAIL";

export const REPORT_LOAD_START = "REPORT_LOAD_START";
export const REPORT_LOAD_SUCCESS = "REPORT_LOAD_SUCCESS";
export const REPORT_LOAD_FAIL = "REPORT_LOAD_FAIL";