import React, { Component } from "react";
import {
    Grid,
    Header,
    Button,
    Divider,
    Form,
    Input,
    Message,
    Icon,
    Checkbox,
} from "semantic-ui-react";
import * as actionCreators from "./actions";
import { connect } from "react-redux";
import { Karta } from "./Karta";
import { ModificatorKarta } from "./ModificatorKarta";
import { initListData as loadDataStockItem, initListEstimationCost } from "../../StockItem/actions";
import { initListData as loadCategoryList } from "../../Category/actions";
import * as utils from "@hitek-crm-utils";

export class EditItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId,
            companyGroupId: this.props.match.params.companyGroupId,
            currentItemId: this.props.match.params.itemId,
            isLoaded: false,
            id: "",
            name: "",
            price: "",
            categoriesId: [],
            defaultCategoryId: null,
            composition: [],
            modificators: [],
            imageFile: null,
            internalInfo: "",
            customField: {},
            isActive: true,
            vendorCode: "",
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.handleChangeCategoryId = this.handleChangeCategoryId.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleCustomFieldChange = this.handleCustomFieldChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChangeComposition = this.handleChangeComposition.bind(this);
        this.handleChangeModificators = this.handleChangeModificators.bind(this);
        this.handleChangeInternalInfo = this.handleChangeInternalInfo.bind(this);
        this.handleChangeVendorCode = this.handleChangeVendorCode.bind(this);
        this.handleChangeDefaultCategoryId = this.handleChangeDefaultCategoryId.bind(this);
        this.handleChangeIsActive = this.handleChangeIsActive.bind(this);
        this.props.clearStore();
    }

    componentDidMount() {
        this.loadData.call(this, {
            id: this.state.currentItemId,
            companyId: this.state.companyId,
            companyGroupId: this.state.companyGroupId,
        });
    }

    loadData(state) {
        if (state.id) {
            this.props.getDetailItem({
                id: state.id,
                companyId: state.companyId,
                companyGroupId: state.companyGroupId,
            });
        }

        this.props.initItemList({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        });
        this.props.initCustomFields({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        });
        this.props.loadCategoryList({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        });
        this.props.loadDataStockItem({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        });

        this.props.initListEstimationCost({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.isLoaded && nextProps.item.isLoaded) {
            this.setState({
                name: nextProps.item.data.name,
                id: nextProps.item.data.id,
                categoriesId: nextProps.item.data.categoriesId,
                defaultCategoryId: nextProps.item.data.defaultCategoryId,
                price: nextProps.item.data.price,
                composition: nextProps.item.data.composition,
                modificators: nextProps.item.data.modificators,
                internalInfo: nextProps.item.data.internalInfo || "",
                isLoaded: true,
                customField: nextProps.item.data.customField || {},
                isActive: !!nextProps.item.data.isActive,
                vendorCode: nextProps.item.data.vendorCode || "",
            });
            console.log(this.state);
        }
        if (this.props.item.isSave != nextProps.item.isSave && nextProps.item.isSave) {
            if (this.state.currentItemId) {
                this.props.history.push(utils.getRoute(this.props.match.url, 2));
            } else {
                this.props.history.push(utils.getRoute(this.props.match.url, 1));
            }
        }
    }

    handleChangeName(e, target) {
        this.setState({ name: target.value });
    }
    handleChangeInternalInfo(e, target) {
        this.setState({ internalInfo: target.value });
    }
    handleFileChange(e, target) {
        this.setState({ imageFile: e.target.files[0] });
    }
    handleCustomFieldChange(e, target) {
        const { name, value } = target;
        this.setState((old) => {
            return { ...old, customField: { ...old.customField, [name]: value } };
        });
    }

    handleChangePrice(e, target) {
        let targetValue = target.value;
        targetValue = utils.toCorrectFloat(parseFloat(targetValue), 5);
        if (target.value.length > 1 && target.value[target.value.length - 1] == ".") {
            targetValue += ".";
        }
        this.setState({ price: targetValue });
    }

    handleChangeCategoryId(e, target) {
        const value = { categoriesId: target.value };
        if (!Array.isArray(target.value) || !target.value.includes(this.state.defaultCategoryId)) {
            value.defaultCategoryId = null;
        }
        this.setState(value);
    }
    handleChangeDefaultCategoryId(e, target) {
        this.setState({ defaultCategoryId: target.value });
    }
    handleChangeIsActive(e, target) {
        this.setState({ isActive: target.checked });
    }
    handleChangeVendorCode(e, target) {
        this.setState({ vendorCode: target.value });
    }
    handleChangeComposition(value) {
        this.setState({ composition: value });
    }
    handleChangeModificators(value) {
        this.setState({ modificators: value });
    }

    handleSave(e) {
        e.preventDefault();

        const dataFull = {
            name: this.state.name,
            id: this.state.id,
            categoriesId: this.state.categoriesId,
            price: this.state.price,
            companyId: this.state.companyId,
            composition: this.state.composition,
            modificators: this.state.modificators,
            internalInfo: this.state.internalInfo,
            customField: this.state.customField,
            vendorCode: this.state.vendorCode,
            isActive: this.state.isActive,
            defaultCategoryId: this.state.defaultCategoryId,
        };

        const formData = new FormData();

        if (this.state.imageFile) {
            formData.append("imageFile", this.state.imageFile, this.state.imageFile.name);
        }
        utils.getFormData(formData, dataFull, undefined);

        const data = {
            id: this.state.currentItemId,
            companyId: this.state.companyId,
            companyGroupId: this.state.companyGroupId,
            formData,
        };
        if (this.state.currentItemId) {
            this.props.saveItem(data);
        } else {
            this.props.saveNewItem(data);
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size="huge">
                            {this.state.currentItemId ? "Редактирвоание Товара #" : "Новый Товар"}
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                    <Grid.Column>
                        <Form
                            success={this.props.item.isSave}
                            error={!!this.props.item.error}
                            loading={this.props.item.isLoading}>
                            <Form.Field
                                disabled
                                control={Input}
                                label="Id"
                                placeholder="Id позиции"
                                value={this.state.id}
                            />
                            <Form.Field
                                control={Input}
                                label="Название"
                                placeholder="Название позиции"
                                onChange={this.handleChangeName}
                                value={this.state.name}
                            />
                            <Form.Field
                                control={Input}
                                label="Цена"
                                placeholder="цена"
                                onChange={this.handleChangePrice}
                                value={this.state.price}
                            />

                            <Form.Select
                                label="Выбор категории"
                                loading={this.props.item.isCategoryListLoading}
                                placeholder="Выбрать категорию"
                                fluid
                                multiple
                                selection
                                options={Array.from(
                                    this.props.categoryStore.categoryList.filter(
                                        (x) => x.isCompanyGroup === !!this.state.companyGroupId
                                    ),
                                    (x) => {
                                        return { key: x.fullName, text: x.fullName, value: x.id };
                                    }
                                )}
                                value={this.state.categoriesId}
                                onChange={this.handleChangeCategoryId}
                            />
                            <Form.Select
                                label="Выбор категории по умолчанию"
                                loading={this.props.item.isCategoryListLoading}
                                placeholder="Выбор категории по умолчанию"
                                fluid
                                options={Array.from(
                                    this.props.categoryStore.categoryList.filter(
                                        (x) =>
                                            x.isCompanyGroup === !!this.state.companyGroupId &&
                                            this.state.categoriesId?.includes(x.id)
                                    ),
                                    (x) => {
                                        return { key: x.fullName, text: x.fullName, value: x.id };
                                    }
                                )}
                                value={this.state.defaultCategoryId}
                                onChange={this.handleChangeDefaultCategoryId}
                            />
                            <Form.Field
                                control={Input}
                                label="Артикул"
                                placeholder="актикул"
                                onChange={this.handleChangeVendorCode}
                                value={this.state.vendorCode}
                            />
                            <Form.Field>
                                <label> Изображение</label>
                                {this.props.item.data && this.props.item.data.imageUrl && (
                                    <img src={this.props.item.data.imageUrl} />
                                )}
                                <input type="file" onChange={this.handleFileChange} />
                            </Form.Field>
                            <Form.Field
                                control={Input}
                                label="Информация для повара"
                                placeholder="Информация для повара"
                                onChange={this.handleChangeInternalInfo}
                                value={this.state.internalInfo}
                            />

                            {!!this.props.item.customFieldList &&
                                this.props.item.customFieldList.map((key) => (
                                    <Form.Field
                                        key={key.id}
                                        control={Input}
                                        label={key.title || key.name}
                                        placeholder={key.title || key.name}
                                        name={key.name}
                                        onChange={this.handleCustomFieldChange}
                                        value={this.state.customField[key.name] || ""}
                                    />
                                ))}

                            <Form.Field
                                control={Checkbox}
                                label="Включена"
                                onChange={this.handleChangeIsActive}
                                checked={this.state.isActive}
                            />

                            <h5>Модификаторы</h5>
                            <ModificatorKarta
                                items={this.props.itemList.itemList}
                                categories={this.props.categoryStore.categoryList}
                                value={this.state.modificators}
                                onChange={this.handleChangeModificators}
                                currentItemId={this.state.currentItemId}
                            />
                            <h5>Составляющие товара</h5>
                            <Karta
                                stoсkItems={this.props.stockItem.list}
                                stockItemEstimationCost={this.props.stockItem.listEstimationCost}
                                value={this.state.composition}
                                onChange={this.handleChangeComposition}
                            />

                            <Message error header="Ошибка" content={this.props.item.error} />
                            <Message
                                success
                                header="Выполнено"
                                content={"Запись с id=" + this.state.id + " была успешно сохранена"}
                            />
                            <Form.Field
                                color="green"
                                fluid
                                onClick={this.handleSave}
                                control={Button}>
                                <Icon name="save" />
                                Сохранить
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        item: state.itemEdit,
        itemList: state.itemList,
        stockItem: state.stockItem,
        categoryStore: state.categoryList,
    };
}

export const ItemEditContainer = connect(mapStateToProps, {
    ...actionCreators,
    loadDataStockItem,
    loadCategoryList,
    initListEstimationCost,
})(EditItem);
