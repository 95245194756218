import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid, Header, Divider, Table, Label, Button, Modal, List, Icon } from 'semantic-ui-react'
import * as actionCreators from "./actions";

function loadData(dispatch, state) {
    dispatch(actionCreators.initItemData({ companyId: state.companyId, roleId: state.roleId }));

    if (state.force) {
        dispatch(actionCreators.initListPermission());
    }
}

export function RoleCompanyDetailContainer() {
    const { data } = useSelector(state => {
        return { data: state.roleCompanyStore };
    });

    const { companyId, roleId } = useParams();
    const location = useLocation();

    const dispatch = useDispatch();
    const prevStateRef = useRef({});
    const [openDeleteShowModal, setOpenDeleteShowModal] = useState(false);

    const history = useHistory();


    //const rolePermission = data.item.permissions || {};

    useEffect(() => {
        dispatch(actionCreators.clearStore());
    }, []);

    useEffect(() => {
        loadData(dispatch, {
            companyId,
            roleId,
            force:
                companyId !== prevStateRef.current.companyId || data.permissionList.length < 1
        });
        if (!prevStateRef.current) {
            prevStateRef.current = {};
        }
        prevStateRef.current.companyId = companyId;
    }, [companyId, roleId]);

    useEffect(() => {
        if (data.isShouldReload) {
            loadData(dispatch, { companyId });
        }
    }, [data.isShouldReload]);

    const showOrder = useCallback(e => {
        const target = e.currentTarget;

        history.push(`/Company/${target.dataset.companyId}/Order/${target.dataset.orderId}`);
    });
    const handleCloseopenDeleteModal = useCallback();

    return <Grid>
        <Grid.Row>
            <Grid.Column>
                <Header size='huge'>Натсройка разрешений для роли</Header>
            </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
            <Grid.Column>
                <Grid.Row>
                    <Button color="green" as={Link} to={location.pathname + "/Edit"} disabled={data.item.isPublic && companyId}>Редактирвоать</Button>
                </Grid.Row>
                <Header size="medium">Название: {data.item.name}</Header>
                <Header size="medium">Системная: {data.item.isPublic ? "true" : "false"}</Header>
                <Header size="medium">Настроеные права</Header>
                <Grid.Row>

                    <Table celled structured>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell> Название</Table.HeaderCell>
                                <Table.HeaderCell> Имя</Table.HeaderCell>
                                <Table.HeaderCell >Значение</Table.HeaderCell>

                            </Table.Row>

                        </Table.Header>

                        <Table.Body>
                            {Object.keys(data.item.permissions || {}).sort().map((permission) => {
                                const permitionDeatil = data.permissionList.find(x => x.name == permission) || { title: permission }

                                return <Table.Row key={permission}>
                                    <Table.Cell>{permitionDeatil.title}</Table.Cell>
                                    <Table.Cell>{permission}</Table.Cell>
                                    <Table.Cell>
                                        {permitionDeatil.type == "bool" ? data.item.permissions[permission] == 1 && <Icon color='green' name='checkmark' /> : data.item.permissions[permission]}
                                    </Table.Cell>

                                </Table.Row>

                            }

                            )}


                        </Table.Body>
                    </Table>
                </Grid.Row>

            </Grid.Column>

        </Grid.Row>
    </Grid>;

}

