import * as  config from "Config";


export function clearStore() {
    return {
      type:"TAG_CLEAR_STORE"
    };
}

export function saveNewTag(params) {
    return {
      remote:{
          method: "post",
          url: config.apiUrl + "/api/Tag/Company/"+params.companyId,
          data:params
      },
      actions:["TAG_NEW_SAVE_START","TAG_NEW_SAVE_SUCCESS","TAG_NEW_SAVE_FAIL"]
    };
}


export function saveTag(params) {
    return {
      remote:{
          method: "put",
          url: config.apiUrl + "/api/Tag/"+params.id + "/Company/"+params.companyId,
          data:params
      },
      actions:["TAG_SAVE_START","TAG_SAVE_SUCCESS","TAG_SAVE_FAIL"]
    };
}

export function getDetailTag(params) {
    return {
      remote:{
          method: "get",
          url: config.apiUrl + "/api/Tag/" + params.id+ "/Company/"+params.companyId
      },
      actions:["TAG_DETAIL_START","TAG_DETAIL_SUCCESS","TAG_DETAIL_FAIL"]
    };
}

