import React, { Component } from "react";
import { Grid, Button, Form, Input, Label, Icon, GridColumn } from "semantic-ui-react";
import { Link } from "react-router-dom";

export class Karta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCompanyId: this.props.companyId,
      name: "",
      price: "",
      categoriesId: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd = this.handleAdd.bind(this);

    this.getOptions = this.getOptions.bind(this);
  }

  componentDidMount() {
    //this.loadData.call(this, { companyId: this.state.currentCompanyId });
  }

  handleInputChange(event, target) {
    //event.preventDefault();
    //const target = event.target;
    // debugger

    let value = Array.from(this.props.value); //[];
    let id = target["data-id"];
    let v = value.find((x) => x._id == id);
    const name = target.name;

    v[name] = target.value;

    this.props.onChange(value);
  }

  handleRemove(e, target) {
    let id = target["data-id"];
    let value = [];
    for (let i = 0; i < this.props.value.length; i++) {
      if (this.props.value[i]._id === id) continue;
      value.push(this.props.value[i]);
    }

    this.props.onChange(value);
  }
  handleAdd(e, target) {
    let newObj = {
      _id: new Date().getTime(),
      categoryId: 0,
      itemId: 0,
      value: 0,
      type: "%"
    };

    if (!this.props.value.find((x) => x.categoryId === -1 && x.itemId === -1)) {
      newObj.itemId = -1;
      newObj.categoryId = -1;
    }

    let value = Array.from(this.props.value);
    value.push(newObj);
    this.props.onChange(value);
  }

  getOptions() {
    if (!this.props.categories) {
      return [];
    }
    let v = Array.from(
      this.props.value.filter((y) => y.categoryId !== 0 && y.itemId === 0),
      (x) => x.categoryId
    );
    v = !!this.props.value.find(x => x.categoryId === -1);
    return Array.from(
      [{ id: -1, fullName: "All", disabled: v }, ...this.props.categories],
      (x) => {
        return { text: x.fullName, value: x.id, disabled: x.disabled };
      }
    );
  }
  render() {
    return (
      <Grid style={{ marginBottom: "15px" }}>
        <Grid.Row>
          <Grid.Column>
            <Button color="green" fluid onClick={this.handleAdd}>
              Добавить условие скидки
            </Button>
          </Grid.Column>
        </Grid.Row>

        {this.props.value.map((item) => (
          <Grid.Row key={item._id} columns={"5"} style={{ padding: "3px" }}>
            <Grid.Column width={"5"}>
              <Form.Select
                data-id={item._id}
                //loading={this.props.company.isUserListLoading}
                placeholder="Категория"
                label="Категория"
                fluid
                selection
                value={item.categoryId}
                name="categoryId"
                search
                fluid
                onChange={this.handleInputChange}
                options={this.getOptions()}
              />
            </Grid.Column>
            <Grid.Column width={"5"}>
              <Form.Select
                data-id={item._id}
                placeholder="Товар"
                label="Товар"
                fluid
                selection
                value={item.itemId}
                name="itemId"
                search
                fluid
                onChange={this.handleInputChange}
                options={Array.from(
                  [
                    { id: -1, name: "All" },
                    ...this.props.items.filter((x) =>
                      (x.categoriesId || []).includes(item.categoryId)
                    ),
                  ],
                  (y) => {
                    return { text: y.name, value: y.id, disabled: false };
                  }
                )}
              />
            </Grid.Column>
            <GridColumn width={"2"}>
              <Form.Select
                label="Тип "
                placeholder="-"
                data-id={item._id}
                fluid
                onChange={this.handleInputChange}
                value={item.type}
                name="type"
                options={[
                  { value: "%", text: "%" },
                  { value: "fixed", text: "Фикс" },
                ]}
              />
            </GridColumn>

            <Grid.Column width={"2"}>
              <Form.Field
                data-id={item._id}
                control={Input}
                placeholder="Значение"
                label="Значение"
                name="value"
                value={item.value}
                onChange={this.handleInputChange}
              />
            </Grid.Column>

            <Grid.Column width={"2"}>
              <Form.Field
                label="Удалить"
                data-id={item._id}
                color="red"

                onClick={this.handleRemove}
                control={Button}
                icon="remove"
              />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    );
  }
}
